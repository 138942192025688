import { defineMessages } from 'react-intl';

export default defineMessages({
  quantityDecreaseBtnAriaLabel: {
    id: 'quantityInput.quantityDecreaseBtnAriaLabel',
    defaultMessage: 'quantity decrease button',
  },
  quantityIncreaseBtnAriaLabel: {
    id: 'quantityInput.quantityIncreaseBtnAriaLabel',
    defaultMessage: 'quantity increase button',
  },
});
