const getNumberFromString = (fileSize: string): number => +fileSize.replace(/[^0-9]/g, '');

const transUploadFileSizeToBytes = (fileSize: string): number => {
  const convertConstant = 1024;
  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  for (let i = 0; i < units.length; i++) {
    const reg = new RegExp(`.*\\${units[i]}$`, 'i'); // nosemgrep
    if (fileSize.match(reg)) {
      return getNumberFromString(fileSize) * convertConstant ** i;
    }
  }
  throw new Error('Wrong Size Unit.');
};

export default transUploadFileSizeToBytes;
