import { defineMessages } from 'react-intl';

export default defineMessages({
  addressLine1: {
    id: 'manualAddressInput.addressLine1',
    defaultMessage: 'Address line 1',
  },
  addressLine2: {
    id: 'manualAddressInput.addressLine2',
    defaultMessage: 'Address line 2',
  },
  country: {
    id: 'manualAddressInput.country',
    defaultMessage: 'Country',
  },
  stateRegion: {
    id: 'manualAddressInput.stateRegion',
    defaultMessage: 'State / Region',
  },
  city: {
    id: 'manualAddressInput.city',
    defaultMessage: 'City',
  },
  postcode: {
    id: 'manualAddressInput.postcode',
    defaultMessage: 'Post/ZIP code',
  },
});
