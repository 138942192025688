import { xcoverAPI } from '@whitelabel/xcover-shared/store/services/xcover/index';
import { processKeys } from '@whitelabel/xcover-shared/helpers/api';
import { getAPIHost } from '@whitelabel/xcover-shared/helpers/multiRegion';
import { RTK_CACHE_LIFE } from '@whitelabel/xcover-shared/helpers/constants';
import { IHelpPagePartner } from '@whitelabel/xcover-shared/helpers/types';

export interface IGetPartners {
  id: string;
  locale: string;
}

export const partnersAPI = xcoverAPI.injectEndpoints({
  endpoints: (builder) => ({
    getPartners: builder.query<IHelpPagePartner[], IGetPartners>({
      query: ({ id, locale }) => {
        const searchParams = new URLSearchParams({
          language: locale,
        });

        return `${getAPIHost()}/customers/${id}/partners/?${searchParams}`;
      },
      transformResponse: (response: any) => processKeys(response),
      keepUnusedDataFor: RTK_CACHE_LIFE,
    }),
  }),
});

export const { useGetPartnersQuery } = partnersAPI;

export const clearPartners = () => partnersAPI.util.resetApiState();
