import React, { useEffect } from 'react';
import { Route, Redirect, useLocation, matchPath } from 'react-router-dom';
import { connect } from 'react-redux';
import { IUser, ICustomer } from '@whitelabel/helpers/types';
import { isRedirectLinkAllowed } from '@whitelabel/xcover-shared/helpers/utils';
import ErrorBoundary from '../../ErrorBoundary';
import { useAppDispatch } from '../../../store/hooks';
import { logout } from '../../../store/actions/user';

type OwnUnauthRouteProps = {
  component: any;
  user?: IUser;
  customer?: ICustomer;
  locale: string;
  emailUpdated?: boolean;
  signingIn: boolean;
  path: string;
};

const UnauthRoute = ({
  component: Component,
  user,
  customer,
  locale,
  signingIn,
  path,
  ...rest
}: OwnUnauthRouteProps) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const urlParams = new URLSearchParams(location.search);
  const emailToken = urlParams.get('emailToken');
  const securityToken = urlParams.get('security_token') || urlParams.get('securityToken');
  const signupToken = urlParams.get('signupToken');
  const id = urlParams.get('id');
  const token = urlParams.get('token');
  const redirect = urlParams.get('redirect');

  const renderComponent =
    signingIn ||
    !(user && customer) ||
    !!emailToken ||
    (id && (token || (signupToken && securityToken)) && location.pathname.includes('/signup/confirm'));
  useEffect(() => {
    /**
     * If there is a security token in the search param in the signup/login page, it means that the user is an amazon user.
     * The logic is as follows.
     * 1. if (emailToken && !securityToken) - this is existing behavior where a non amazon user visiting a link which has email token is logged out and redireced to login page.
     * 2. (id && user && user.username !== id && !securityToken) - this is usual security check. If id in the url is not equal to logged in user then log him our and redirect to login page.
     * The reason adding !securityToken is to enable amazon user to transfer booking from one accoun to another. In this case the id in the url is different than the logged in user. Hence we should
     * not log out if user.username is not equal to id in the url IF amazon user.
     * (id && user && user.username !== id && securityToken && emailToken) - this means that amazon user is landing on sign up page. We need to make sure we log amazon user out if id in url doesn't match to user.id
     */
    if (
      (emailToken && !securityToken) ||
      (id && user && user.username !== id && !securityToken) ||
      (id && user && user.username !== id && securityToken && emailToken)
    ) {
      dispatch(logout());
    }
  }, [logout, emailToken, id, user, securityToken]);

  const renderRedirect = () => {
    // check if the redirect url matches the current route
    // if it does, then redirect to account page to avoid redirect loop
    // otherwise, trigger a reload so the request is sent to the server
    const match = redirect ? matchPath(redirect, { path }) : null;

    if (redirect && !match && isRedirectLinkAllowed(redirect)) {
      window.location.replace(redirect);
      return null;
    }
    return <Redirect to={`/${locale}/account`} />;
  };

  return (
    <Route
      render={(props) =>
        renderComponent ? (
          <ErrorBoundary>
            <Component {...props} />
          </ErrorBoundary>
        ) : (
          renderRedirect()
        )
      }
      {...rest}
    />
  );
};

const mapStateToProps = ({ user, intl, customer }: any) => ({
  user: user.data,
  customer: customer.data,
  locale: intl.locale,
  emailUpdated: customer.emailUpdated,
  signingIn: user.signingIn,
});

export default connect(mapStateToProps)(UnauthRoute);
