import styled, { css } from 'styled-components';
import { fontStyle } from '@whitelabel/helpers/style/type';
import { themeColor, color } from '@whitelabel/helpers/style/colors';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { get } from '@whitelabel/helpers/style/get';
import { focusStyle, focusVisibleMixin } from '@whitelabel/helpers/style/utils';

const activeScoreCSS = css`
  background-color: ${themeColor('primary')};
  border-color: ${themeColor('primary')};
`;

export const StyledTitle = styled.h3<{ $isPage: boolean }>`
  ${({ $isPage }) =>
    $isPage &&
    css`
      text-transform: none;
      ${fontStyle('h5')}
      ${mediaBreakpointUp('md')`
        padding-inline-end:1.5rem;
        max-width: 15rem;
      `}
    `}
  margin-bottom: 0;
`;

export const StyledScore = styled.button<{ $selected: boolean; $isPage: boolean }>`
  width: 2.5rem;
  height: 2.5rem;
  border: ${get('borderStyle')};
  border-radius: 50%;
  padding: 0.75rem 0;
  text-align: center;
  cursor: pointer;
  ${fontStyle('button')}
  background-color: ${color('white')};
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  ${focusVisibleMixin(focusStyle)}

  ${mediaBreakpointUp('sm')`
    margin-bottom: 0;
  `}
  ${mediaBreakpointUp('md')`
    width: 2rem;
    height: 2rem;
  `}
  ${mediaBreakpointUp('lg')`
    width: 3rem;
    height: 3rem;
  `}
  ${({ $isPage }) =>
    !$isPage &&
    css`
      &&& {
        ${mediaBreakpointUp('md')`
          width: 3rem;
          height: 3rem;
        `}
      }
    `}
  &:hover {
    ${activeScoreCSS}
  }

  ${({ $selected }) =>
    $selected &&
    css`
      &&& {
        ${activeScoreCSS}
      }
    `}
`;

export const StyledScoreList = styled.ol<{ $isPage: boolean }>`
  width: 19rem;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  flex: 1 0 100%;
  padding-inline-start: 0;
  ${mediaBreakpointUp('sm')`
    width: 100%;
    margin-bottom: 0.5rem;
    margin-left: -0.5rem;
    flex-wrap: nowrap;
    height: 100%;
  `}
  .score {
    margin-bottom: 0.75rem;
    width: 16.6%;

    &:nth-last-child(-n + 5) {
      padding-inline-start: 1.9rem;
    }
    ${mediaBreakpointUp('sm')`
      width: 100%;
      margin-bottom: 0;
      &:nth-last-child(-n + 5){
        padding-inline-start:0.5rem;
      }
    `}
  }
`;

export const StyledScoreWrapper = styled.div<{ $scoreSelected: boolean }>`
  margin: auto;

  ${mediaBreakpointUp('sm')`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  `}

  .positive,
  .negative {
    color: ${themeColor('subtext')};
    display: inline-block;
    margin-bottom: 0;
    ${mediaBreakpointUp('sm')`
      width: auto;
    `}
  }

  .positive {
    text-align: right;
    order: 3;
  }
  .negative {
    text-align: left;
    order: 2;
  }
`;

export const StyledLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const StyledAdditionalBlock = styled.div`
  flex: 1 0 100%;
  order: 4;

  p {
    color: ${themeColor('subtext')};
  }

  button {
    width: 100%;
    ${mediaBreakpointUp('md')`
      width: auto;
    `}
  }
`;

export const StyledVerticalDivider = styled.div`
  ${mediaBreakpointUp('sm')`
    border-left: ${get('borderStyle')};
  `}
`;

export const StyledPageWrapper = styled.div<{ $isPage: boolean }>`
  ${({ $isPage }) =>
    $isPage &&
    css`
      padding: 1rem;
      border: ${get('borderStyle')};

      ${mediaBreakpointUp('md')`   
        padding: 1.5rem;
        display: flex;
      `}
    `}
`;

export const StyledMainWrapper = styled.div<{ $isPage: boolean }>`
  padding-top: 1rem;
  ${mediaBreakpointUp('md')`   
    padding-top: 1.5rem;
  `}
  width: 100%;

  .feedback {
    width: 100%;
    margin-bottom: 0;
    ${mediaBreakpointUp('md')`
      height: 100%;
      > svg{
        align-self: center;
      }
    `};
  }
  ${({ $isPage }) =>
    $isPage &&
    css`
      ${mediaBreakpointUp('sm')`   
        padding-top: 1rem;
        padding-inline-start: 1rem;
      `}
      ${mediaBreakpointUp('md')`   
        padding-top: 0;
      `}
    `}
`;
