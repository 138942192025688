import styled, { Theme } from 'styled-components';
import { Container } from 'reactstrap';
import { mediaBreakpointUp, gridGutterWidth, breakpointKeys } from '@whitelabel/helpers/style/grid';

export const StyledContainer = styled(Container)`
  ${(props: { theme: Theme }) =>
    breakpointKeys(props.theme).map(
      (breakpoint) => mediaBreakpointUp(breakpoint)`
      margin-bottom: ${gridGutterWidth(breakpoint)};
    `,
    )}

  &:last-child,
  *:last-child {
    margin-bottom: 0;
  }
`;
