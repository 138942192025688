export const fontFamilySansSerif = `brandon-grotesque, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
  Ubuntu, Cantarell, 'Helvetica Neue', Arial, 'Kanit', 'Noto Sans JP', 'Noto Sans KR', sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'`;
export const fontFamilySerif = `brandon-grotesque, Georgia, Times, 'Times New Roman', 'Kanit', 'Noto Sans JP', 'Noto Sans KR', serif`;
export const fontFamilyMonospace = `SFMono-Regular, Menlo, Monaco, Consolas, 'Roboto Mono', 'Oxygen Mono',
  'Ubuntu Mono', 'DejaVu Sans Mono', 'Liberation Mono', 'Courier New', 'Kanit', 'Noto Sans JP', 'Noto Sans KR',
  monospace`;

export const fontFamilies = {
  base: fontFamilySansSerif,
  headings: fontFamilySansSerif,
  serif: fontFamilySerif,
  sanSerif: fontFamilySansSerif,
  monospace: fontFamilyMonospace,
};

export const internationalFontFamilies: Record<string, string> = {
  th: 'Kanit',
  ja: `'Noto Sans JP'`,
  ko: `'Noto Sans KR'`,
  vi: 'Roboto',
  lt: 'Poppins',
  lv: 'Poppins',
  tr: `'Noto Sans'`,
  az: `'Noto Sans'`,
};
