import { Auth } from '@aws-amplify/auth';
import { camelizeKeys } from 'humps';
import { IMPERSONATOR_TOKEN, POLICY_TYPES } from '@whitelabel/helpers/constants';
import { captureExceptionWithFullStory, isOnServer } from '@whitelabel/helpers/utils';
import { isJSON } from '@whitelabel/helpers/objects';
import { getUserCountry } from '@whitelabel/helpers/site';
import { IError, IAPIHelper, APIProps } from './types';
import { ERROR_CODE, FE_MESSAGES_SLUG } from './constants';

export function getEnvironmentValue(name: string): string | undefined {
  return process.env[`REACT_APP_${name}`] || process.env[`NEXT_PUBLIC_${name}`];
}

export const [
  API_HOST,
  CMS_API_HOST,
  XPAY_API_HOST,
  XPAY_CHARGE_PROVIDER_KEY,
  XPAY_CHARGE_BUNDLE_URL,
  XPAY_PAYOUT_BUNDLE_URL,
  GOOGLE_PLACES_API_KEY,
  LOCALE_HOST,
  ZENDESK_API_URL,
] = [
  process.env.REACT_APP_API_HOST || process.env.NEXT_PUBLIC_API_HOST,
  process.env.REACT_APP_CMS_API_HOST || process.env.NEXT_PUBLIC_CMS_API_HOST,
  process.env.REACT_APP_XPAY_API_HOST || process.env.NEXT_PUBLIC_XPAY_API_HOST,
  process.env.REACT_APP_XPAY_CHARGE_PROVIDER_KEY || process.env.NEXT_PUBLIC_XPAY_CHARGE_PROVIDER_KEY,
  process.env.REACT_APP_XPAY_CHARGE_BUNDLE_URL || process.env.NEXT_PUBLIC_XPAY_CHARGE_BUNDLE_URL,
  process.env.REACT_APP_XPAY_PAYOUT_BUNDLE_URL || process.env.NEXT_PUBLIC_XPAY_PAYOUT_BUNDLE_URL,
  process.env.REACT_APP_GOOGLE_PLACES_API_KEY || process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY,
  `${process.env.REACT_APP_MESSAGES_HOST || process.env.NEXT_PUBLIC_MESSAGES_HOST}/${FE_MESSAGES_SLUG}`,
  process.env.REACT_APP_ZENDESK_API_URL || process.env.NEXT_PUBLIC_ZENDESK_API_URL,
];

export const getAccessToken = (auth: any, SSRAuth?: any) => {
  if (auth && typeof auth !== 'string') {
    const currentSessionPromise = SSRAuth ? SSRAuth.currentSession() : Auth.currentSession();
    return currentSessionPromise
      .then((currentSession: any) => currentSession.accessToken.jwtToken)
      .catch((err: any) => {
        captureExceptionWithFullStory(err);

        if ((err === 'No current user' || err.message === 'Refresh Token has expired') && !isOnServer) {
          window.location.reload();
        } else throw err;
      });
  }
  return auth;
};

export const createHeaders = async (
  auth = false,
  headers: any,
  clientTimezone = false,
  impersonatorToken: string | null,
  SSRAuth?: any,
): Promise<Headers> =>
  new Headers({
    'Content-Type': 'application/json',
    ...(clientTimezone && { 'X-CLIENT-TIMEZONE': Intl.DateTimeFormat().resolvedOptions().timeZone }),
    ...(auth &&
      (impersonatorToken
        ? { Authorization: `Impersonator ${impersonatorToken}` }
        : { Authorization: `Bearer ${await getAccessToken(auth, SSRAuth)}` })),
    ...headers,
  });

export function processKeys(data: object) {
  return camelizeKeys(data, (key: any, convert: any, options: any) => {
    if (
      POLICY_TYPES.includes(key) ||
      ['_non_field_errors', '_error'].includes(key) ||
      key.includes('sectionFields') ||
      /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i.test(key)
    )
      return key;
    return convert(key, options);
  }) as unknown as any;
}

export const ignoreErrors = (pathname: string, error: IError) => {
  const errors404 =
    (pathname.endsWith('/customers/email/') ||
      pathname.startsWith('/api/v1/staticpages/') ||
      // todo: remove this check (pathname.startsWith('/api/v2/staticpages/')
      // when all api endpoints of static pages pointing to xcover backend
      // currently, some applications (namely: qb, cp) are fetch static pages from xcms
      pathname.startsWith('/api/v2/staticpages/') ||
      pathname.endsWith('/customers/forgot_password_initiate/') ||
      pathname.endsWith('/customers/lookup/') ||
      pathname.endsWith('/validate_token/') ||
      /\/messages\/partners\//.test(pathname)) &&
    error.status === 404;

  const errors422 =
    (pathname.endsWith('/validate_token/') || /\/customers\/[^/]+\/email\/$/.test(pathname)) && error.status === 422;

  const errors403 = pathname.startsWith('/api/v1/payout/') && error.status === 403;

  const otherErrors =
    // Please check https://domainpartners.atlassian.net/browse/XCOM-3577 for the APIs return the ALREADY_REGISTERED code.
    error.code === ERROR_CODE.ALREADY_REGISTERED ||
    (pathname.endsWith('/gdpr/') && error.errors?.code === ERROR_CODE.BOOKING_GDPR_CUSTOMER_NOT_EXISTED) ||
    pathname.endsWith('/api/v1/geoip/');

  return errors404 || errors422 || errors403 || otherErrors;
};

function captureError(error: any, url = '', requestId?: string | null) {
  if (url) {
    const pathname = url.startsWith('http') ? new URL(url).pathname : url.split('?')[0];
    if (ignoreErrors(pathname, error)) {
      return;
    }
  }
  captureExceptionWithFullStory(error, {
    error: JSON.stringify(error),
    url,
    ...(requestId && { 'x-request-id': requestId }),
  });
}
export const createError = (status: any, response: any, url: any, requestId?: string | null) => {
  let errorMessage = '';
  const isJSONFormat = isJSON(response);
  errorMessage = response?.message || response?.detail || response;
  if (!isJSONFormat || typeof errorMessage !== 'string') {
    errorMessage = 'Sorry! Something went wrong. Please try again later.';
  }
  const allowedAttributes = ['requestId', 'type', 'code', 'href', 'errors', 'quotes', 'extra'];
  const errorAttributes = response
    ? Object.keys(response).filter((attribute) => allowedAttributes.includes(attribute))
    : [];
  const error: IError = new Error(errorMessage);

  error.status = status;
  error.isJSON = isJSONFormat;
  if (response?.errors) {
    error.errors = response.errors;
  }
  if (response?.code) {
    error.code = response.code;
  }
  if (requestId) {
    error.requestId = requestId;
  }
  captureError(error, url, requestId);

  const updatedError = error as unknown as Record<string, unknown>;

  errorAttributes.reduce((accumulator, attribute) => {
    if (attribute === 'type') {
      accumulator.name = response[attribute];
    } else {
      accumulator[attribute] = response[attribute];
    }

    return accumulator;
  }, updatedError);

  return updatedError;
};

export const processResponse = async (response: any) => {
  let responseText;
  try {
    responseText = await response.text();
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      const responseJSON = JSON.parse(responseText);
      // log for nextjs server response
      if (isOnServer) {
        console[response.ok ? 'log' : 'error']({
          url: response.url,
          status: response.status,
          status_text: response.statusText,
          response_json: JSON.stringify(responseJSON),
        });
      }
      const data = camelizeKeys(responseJSON, (key: any, convert: any, options: any) => {
        if (
          POLICY_TYPES.includes(key) ||
          ['_non_field_errors', '_error'].includes(key) ||
          key.includes('sectionFields') ||
          /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i.test(key) ||
          /[A-Z]/.test(key.charAt(0))
        )
          return key;
        return convert(key, options);
      });
      return data;
    }
    return responseText;
  } catch (error: any) {
    const requestId = response.headers.get('x-request-id');
    const extra = {
      response: {
        url: response.url,
        status: response.status,
        statusText: response.statusText,
        responseText,
        ...(requestId && { 'x-request-id': requestId }),
      },
    };

    if (isOnServer) {
      console.error({
        url: response.url,
        status: response.status,
        status_text: response.statusText,
        response_text: responseText,
      });
    }
    captureExceptionWithFullStory(error, extra);
    throw error;
  }
};

export const handleFormikFormError = (error: any) => ({
  message: error.message,
  isJSON: error.isJSON,
  ...error.errors,
  status: error.status,
});

// TODO: Translate all the following errors from AWS with a human-readable message
export const handleFormikAWSFormError = (error: any) => {
  const genericError = {
    message: 'There was a problem submitting the form.',
  };

  if (typeof error === 'string') {
    return {
      message: error,
    };
  }

  switch (error.name) {
    case 'UserNotConfirmedException':
    case 'UserNotFoundException':
    case 'UsernameExistsException':
    case 'InvalidEmailRoleAccessPolicyException':
    case 'UserLambdaValidationException':
    case 'AliasExistsException':
      return { ...genericError, email: error.message };

    case 'InvalidPasswordException':
      return { ...genericError, password: error.message };

    case 'CodeMismatchException':
    case 'ExpiredCodeException':
      return { ...genericError, code: error.message };

    default:
      return error;
  }
};

const api: IAPIHelper & APIProps = async (url = '', auth = false, config = { headers: undefined }) => {
  const { headers, SSRAuth, ...init } = config;
  let newURL = url;
  let impersonatorToken = null;
  try {
    if (newURL.includes('language=') && !isOnServer) {
      const languageParam = /language=[^&]+/.exec(newURL)?.[0];
      const language = languageParam?.split('=')[1];
      const userCountry = getUserCountry();
      if (language === 'en' && userCountry.toLowerCase() === 'us') {
        newURL = newURL.replace(languageParam!, 'language=en-us');
      }
    }
    impersonatorToken = isOnServer ? null : sessionStorage.getItem(IMPERSONATOR_TOKEN);
  } catch {
    //  eslint-disable-next-line
    console.log('please enable cookies');
  }

  // XCMS CORS policy prevents X-CLIENT-TIMEZONE from being sent and the request fails.
  // This check prevents the header being sent on XCMS calls.
  const clientTimezone =
    !url.includes(CMS_API_HOST!) &&
    !url.includes(ZENDESK_API_URL!) &&
    !url.startsWith('https://cms.xcover.com/api/v2/xcover_website/frontend_messages/');

  const requestInit: RequestInit = {
    mode: 'cors',
    headers: await createHeaders(auth, headers, clientTimezone, impersonatorToken, SSRAuth),
    ...init,
  };

  // log for nextjs server request
  if (isOnServer) {
    const flattenObj = (obj: any, parent?: any, res: any = {}) => {
      for (const key of Object.keys(obj)) {
        const propName = parent ? `${parent}.${key}` : key;
        if (typeof obj[key] === 'object') {
          flattenObj(obj[key], propName, res);
        } else {
          res[propName] = obj[key];
        }
      }
      return res;
    };
    console.log(
      flattenObj({
        ...requestInit,
        url: newURL,
        headers: JSON.stringify(Object.fromEntries(requestInit.headers as Headers)),
      }),
    );
  }
  const response = await fetch(newURL, requestInit);
  const data = await processResponse(response);
  if (!response.ok) {
    const requestId = response.headers.get('x-request-id');
    throw createError(response.status, data, newURL, requestId);
  }
  return data;
};

const get: IAPIHelper = (url, auth, config) => api(url, auth, { ...config, method: 'GET' });
const head: IAPIHelper = (url, auth, config) => api(url, auth, { ...config, method: 'HEAD' });
const post: IAPIHelper = (url, auth, config) => api(url, auth, { ...config, method: 'POST' });
const put: IAPIHelper = (url, auth, config) => api(url, auth, { ...config, method: 'PUT' });
const patch: IAPIHelper = (url, auth, config) => api(url, auth, { ...config, method: 'PATCH' });
const del: IAPIHelper = (url, auth, config) => api(url, auth, { ...config, method: 'DELETE' });

api.get = get;
api.head = head;
api.post = post;
api.put = put;
api.patch = patch;
api.del = del;

export default api;
