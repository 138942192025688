import React from 'react';
import { get } from 'lodash/fp';
import { ICMSBlock, IBlockTypeConfig, BlockComponentSpacing, BlockComponentSize } from '@whitelabel/helpers/types';
import SectionBlock from './SectionBlock';

export interface IContentBlockProps {
  blocks?: ICMSBlock[];
  blockTypes?: IBlockTypeConfig;
  className?: string;
  componentSpacing?: BlockComponentSpacing;
  componentSize?: BlockComponentSize;
}

const ContentBlock = ({
  blocks = [],
  blockTypes,
  className,
  componentSpacing = 'md',
  componentSize = 'large',
}: IContentBlockProps): JSX.Element => (
  <div className={className} data-test-id="ContentBlock">
    {blocks.map(({ id, type, value }: any) => {
      if (get(`${type}.component`, blockTypes)) {
        const { component: Component, structBlock, props } = blockTypes![type];
        return (
          <Component key={id} id={id} {...(structBlock ? value : { value })} blockTypes={blockTypes} {...props} />
        );
      }
      return (
        type === 'section' && (
          <SectionBlock
            key={id}
            value={value}
            blockTypes={blockTypes}
            componentSpacing={componentSpacing}
            componentSize={componentSize}
          />
        )
      );
    })}
  </div>
);

export default ContentBlock;
