import React from 'react';
import Tooltip from '../components/Tooltip';
import { transformLink } from './transform';

function transformToolTip(node: any) {
  if (
    node.type === 'tag' &&
    node.name === 'span' &&
    node.attribs.title &&
    node.attribs['data-tooltip'] === '' &&
    node.children[0]?.data
  ) {
    const tooltipId = `_${Math.random().toString(36).substr(2, 9)}`;
    node.attribs.id = tooltipId;
    return (
      <Tooltip key={tooltipId} value={node.children[0].data} tooltip={node.attribs.title} tooltipId={tooltipId} />
    );
  }
  return undefined;
}

export const transform = (intl: any) => (node: any) => transformLink(intl)(node) || transformToolTip(node);
