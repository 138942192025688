import { Link } from 'react-router-dom';

type LinkProps =
  | {
      tag: 'a' | typeof Link;
      to: string;
    }
  | {
      tag: 'a' | typeof Link;
      href: string;
      rel: string;
      target: string;
    };

export const getLinkProps = (link: string, locale: string): LinkProps =>
  link.startsWith('/')
    ? {
        tag: Link,
        to: `/${locale}${link}`,
      }
    : {
        tag: 'a',
        href: link,
        rel: 'noopener noreferrer',
        target: '_blank',
      };
