import { defineMessages } from 'react-intl';

export default defineMessages({
  dayLabel: {
    id: 'dobInput.dayLabel',
    defaultMessage: 'Day',
  },
  monthLabel: {
    id: 'dobInput.monthLabel',
    defaultMessage: 'Month',
  },
  yearLabel: {
    id: 'dobInput.yearLabel',
    defaultMessage: 'Year',
  },
});
