import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';
import { useIntl } from 'react-intl';
import { FieldInputProps, FieldHelperProps } from 'formik';
import { ReactComponent as SelectedIcon } from '@whitelabel/media/icons/flags/selected-flag.svg';
import { ReactComponent as CancelIcon } from '@whitelabel/media/icons/small/cross.svg';
import { ReactComponent as ArrowRightIcon } from '@whitelabel/media/icons/small/arrow-right.svg';
import currenciesOption from '@whitelabel/helpers/currencies';
import commonMessages from '@whitelabel/helpers/messages/commonMsg';
import { ICurrencies } from '@whitelabel/helpers/types';
import { MORE_LANGUAGES } from '@whitelabel/helpers/constants';
import { onKeyDown, getCurrencySymbolOrName } from '@whitelabel/helpers/utils';
import Button from '../Button';
import Modal from '../Modal';
import messages from './messages';
import { StyledIcon, StyledModalBodyWrapper, StyledModalOption, StyledSelectInput } from './styledCurrencySelect';
import CustomOption from './CustomOption';

interface ICurrencySelectProps {
  id: string;
  field: FieldInputProps<string>;
  setCurrency: FieldHelperProps<string>['setValue'];
  currencies?: ICurrencies[];
  invalid: boolean;
  disabled?: boolean;
}

const CurrencySelect = ({
  id,
  field,
  setCurrency,
  invalid,
  disabled = false,
  currencies = currenciesOption,
}: ICurrencySelectProps): JSX.Element => {
  const theme = useTheme();
  const { formatMessage, locale } = useIntl();
  const { value: currency } = field;
  const [isCurrencyListModalOpen, setIsCurrencyListModalOpen] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: theme.gridBreakpoints.lg });
  const isMobile = !useMediaQuery({ minWidth: theme.gridBreakpoints.sm });

  const toggleModal = () => setIsCurrencyListModalOpen(!isCurrencyListModalOpen);

  const renderModalFooter = () =>
    isMobile && (
      <Button onClick={toggleModal} icon={CancelIcon} buttonType="secondary">
        {formatMessage(commonMessages.cancelButtonText)}
      </Button>
    );

  const handleOnClick = (currencyOption: ICurrencies) => () => {
    setIsCurrencyListModalOpen(false);
    setCurrency(currencyOption.value);
  };

  const ModalBody = () => (
    <StyledModalBodyWrapper>
      {currencies.map((currencyOption) => (
        <StyledModalOption
          isSelected={currencyOption.value === currency}
          onClick={handleOnClick(currencyOption)}
          key={currencyOption.value}
          tabIndex={0}
          onKeyDown={onKeyDown(handleOnClick(currencyOption))}
        >
          <StyledIcon as={currencyOption.value === currency ? SelectedIcon : currencyOption.icon} />
          {`${currencyOption.value} (${getCurrencySymbolOrName(locale, currencyOption.value)})`}
        </StyledModalOption>
      ))}
    </StyledModalBodyWrapper>
  );

  // Filter out hidden currencies options
  const filterOption = ({ data }: { data: ICurrencies }) => !data.hiddenFromList;

  // Add the 'more' button for currencies as an option to the options
  const optionsWithButton = [
    ...currencies,
    { value: MORE_LANGUAGES, label: formatMessage(commonMessages.more), icon: ArrowRightIcon },
  ];

  return (
    <>
      <Modal
        returnFocusAfterClose={false}
        toggle={toggleModal}
        body={<ModalBody />}
        header={formatMessage(messages.modalTitle)}
        footer={renderModalFooter()}
        isOpen={isCurrencyListModalOpen}
      />
      <StyledSelectInput
        reverseSelectionArrow
        customComponents={{ Option: CustomOption }}
        customSelectProps={{ filterOption }}
        isSearchable={false}
        disabled={disabled}
        invalid={invalid}
        id={id}
        input={{
          ...field,
          value: currency,
          onChange: (value) => {
            if (value === MORE_LANGUAGES) {
              setIsCurrencyListModalOpen(true);
            } else {
              setCurrency(value);
            }
          },
          onFocus: () => {
            if (!isDesktop) {
              setIsCurrencyListModalOpen(true);
            }
          },
        }}
        options={optionsWithButton}
      />
    </>
  );
};

export default CurrencySelect;
