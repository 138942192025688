import { Theme } from 'styled-components';
import { IThemeValueGetter } from '../types';

export const zIndex =
  (type: keyof Theme['zIndex']): IThemeValueGetter =>
  ({ theme }) =>
    theme.zIndex[type];

export const increasedZIndex =
  (type: keyof Theme['zIndex']): IThemeValueGetter =>
  ({ theme }) =>
    theme.zIndex[type] + 1;
