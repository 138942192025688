import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ReactStateSetter } from '@whitelabel/helpers/types';
import { useScript } from '@whitelabel/helpers/hooks';
import commonMessages from '@whitelabel/helpers/messages/commonMsg';
import { XPAY_API_HOST, XPAY_CHARGE_BUNDLE_URL, XPAY_CHARGE_PROVIDER_KEY } from '@whitelabel/helpers/api';
import { PAYMENT_METHODS, XPAY_CHARGE_BUNDLE_ID } from '@whitelabel/helpers/constants';
import Loading from '../Loading';
import { StyledWidgetBox, StyledWidgetErrorAlert } from './styledChargeWidget';

interface IChargeWidgetProps {
  renderInBox?: boolean;
  onPaymentSuccess: () => void;
  // Optional prop if you choose to hold page load till widget is ready
  setPageReady?: ReactStateSetter<boolean>;
  // Optional prop to show loading per page level instead of per widget level
  setWidgetProcessing?: ReactStateSetter<boolean>;
  // Optional prop to lift the payment error if you choose so
  setPaymentError?: ReactStateSetter<string>;
  charge: {
    token: string;
    id: string;
  };
  // Optional prop to change the default payment method
  paymentMethod?: string;
  // Optional prop to change the text on payment button
  payButtonText?: string;
  apiError?: unknown;
  renderInAModal?: boolean;
  // optional this is to track in BW
  dataAnalytics?: string;
  disableScrollTo?: boolean;
}

const ChargeWidget = ({
  renderInBox = false,
  onPaymentSuccess,
  setPageReady,
  setWidgetProcessing,
  setPaymentError,
  charge: { token, id },
  paymentMethod = PAYMENT_METHODS.CREDIT_CARD,
  payButtonText,
  apiError,
  renderInAModal,
  dataAnalytics = '',
  disableScrollTo = false,
}: IChargeWidgetProps): JSX.Element => {
  const { locale, formatMessage } = useIntl();
  useScript(XPAY_CHARGE_BUNDLE_URL, XPAY_CHARGE_BUNDLE_ID);

  const [widgetError, setWidgetError] = useState('');
  const [widgetLoading, setWidgetLoading] = useState(true);
  const [widgetReady, setWidgetReady] = useState(false);

  const loadingHandler = (payload: any) => {
    if (!disableScrollTo) window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    setWidgetLoading(!!payload.detail[0]);
    setWidgetProcessing?.(!!payload.detail[0]);
  };

  const readyHandler = () => {
    setWidgetReady(true);
    setPageReady?.(true);
    const xpayPayButton = document.getElementsByClassName('xpay-contract__pay-button');
    // track payment submit button in bright write
    if (xpayPayButton?.length && dataAnalytics) {
      xpayPayButton[0].setAttribute('data-analytics', dataAnalytics);
    }
  };

  const chargeUnauthorisedHandler = (payload: any) => {
    if (!disableScrollTo) window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    setWidgetError(payload.detail[0].message);
    setPaymentError?.(payload.detail[0].message);
  };

  const errorHandler = (payload: any) => {
    if (!disableScrollTo) window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    setWidgetError(payload.detail[0].message);
    setPaymentError?.(payload.detail[0].message);
    setWidgetProcessing?.(false);
    setWidgetLoading(false);
  };

  const chargeProcessedHandler = () => {
    onPaymentSuccess();
  };

  const addOnChargeCreatedEvents = (widget: Element | null) => {
    widget?.addEventListener('loading', loadingHandler);
    widget?.addEventListener('ready', readyHandler);
    widget?.addEventListener('charge-unauthorised', chargeUnauthorisedHandler);
    widget?.addEventListener('error', errorHandler);
    widget?.addEventListener('charge-processed', chargeProcessedHandler);
  };

  const removeOnChargeCreatedEvents = (widget: Element | null) => {
    widget?.removeEventListener('loading', loadingHandler);
    widget?.removeEventListener('ready', readyHandler);
    widget?.removeEventListener('charge-unauthorised', chargeProcessedHandler);
    widget?.removeEventListener('error', errorHandler);
    widget?.removeEventListener('charge-processed', chargeProcessedHandler);
  };

  useEffect(() => {
    const widget = document.querySelector('xpay-charge');

    const onChargeErrorHandler = () => {
      if (!disableScrollTo) window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      setWidgetError(formatMessage(commonMessages.error));
      setPaymentError?.(formatMessage(commonMessages.error));
    };

    addOnChargeCreatedEvents(widget);
    window.addEventListener('xpay-charge-error', onChargeErrorHandler);

    return () => {
      removeOnChargeCreatedEvents(widget);
      window.removeEventListener('xpay-charge-error', onChargeErrorHandler);
    };
  }, [formatMessage, onPaymentSuccess, setPageReady, setPaymentError, setWidgetProcessing]);

  const loadingState = !apiError && (!widgetReady || widgetLoading);
  const buttonText = JSON.stringify({ payButton: payButtonText });

  return (
    <StyledWidgetBox
      className="charge-widget-component"
      $renderWhiteBox={renderInBox}
      $loading={widgetLoading}
      $renderInAModal={renderInAModal}
    >
      {loadingState && <Loading />}
      {(apiError || widgetError) && !setPaymentError && !widgetLoading && (
        <StyledWidgetErrorAlert color="danger">
          {apiError ? formatMessage(commonMessages.error) : widgetError}
        </StyledWidgetErrorAlert>
      )}
      {id && token && (
        // @ts-ignore Manually commented. Remove this comment to see the full error message
        <xpay-charge
          api-url={XPAY_API_HOST}
          charge-id={id}
          token={token}
          provider-key={XPAY_CHARGE_PROVIDER_KEY}
          locale={locale}
          restrict-payment-methods={paymentMethod}
          text-override={buttonText}
        />
      )}
    </StyledWidgetBox>
  );
};

export default ChargeWidget;
