import styled from 'styled-components';
import { themeColor } from '@whitelabel/helpers/style/colors';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';

export const StyledBlockHeader = styled.h2`
  margin-bottom: 0.5rem;
`;

export const StyledBlockSubTitle = styled.p`
  margin-bottom: 1rem;
  color: ${themeColor('body')};

  ${mediaBreakpointUp('md')`
    margin-bottom: 1.5rem;
  `}
`;

export const StyledGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  ${mediaBreakpointUp('md')`
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
  `}

  ${mediaBreakpointUp('lg')`
    gap: 2rem;
  `}
`;
