import React from 'react';
import { BlockComponentSpacing, BlockComponentSize, IBlockTypeConfig } from '@whitelabel/helpers/types';
import HeadingBlock from '../HeadingBlock';
import RichTextBlock from '../RichTextBlock';
import ImagesBlock from '../ImagesBlock';
import ButtonsBlock from '../ButtonsBlock';
import ListBlock from '../ListBlock';
import DefinitionListBlock from '../DefinitionListBlock';
import FeatureColumnsBlock from '../FeatureColumnsBlock';
import FeatureListBlock from '../FeatureListBlock';
import TestimonialsBlock from '../TestimonialsBlock';
import HeroBlock from '../HeroBlock';
import HeroModernBlock from '../HeroModernBlock';
import SellingBlock from '../SellingBlock';
import ResourceBlock from '../ResourceBlock';
import CTACalloutBlock from '../CTACalloutBlock';
import AccordionBlock from '../AccordionBlock';
import ThreeColumnButtonGrid from '../ThreeColumnButtonGrid';
import ImageContentCTABlock from '../ImageContentCTABlock';
import PartnerLogoButtonGridBlock from '../PartnerLogoButtonGridBlock';
import LogoSlideBlock from '../LogoSlideBlock';
import ContentImageBlock from '../ContentImageBlock';
import TakeAwayBlock from '../TakeAwayBlock';
import CodeBlock from '../CodeBlock';
import IconListBlock from '../IconListBlock';
import ImageAnnotationBlock from '../ImageAnnotationBlock';
import InfoBannerBlock from '../InfoBannerBlock';

export const basicBlockTypes /*: TODO: need to type it IBlockTypeConfig */ = {
  heading: {
    component: HeadingBlock,
    structBlock: true,
    props: { shouldParse: true },
  },
  code: {
    component: CodeBlock,
    structBlock: false,
    props: {},
  },
  rich_text: {
    component: RichTextBlock,
    structBlock: false,
    props: {},
  },
  images: {
    component: ImagesBlock,
    structBlock: true,
    props: {},
  },
  buttons: {
    component: ButtonsBlock,
    structBlock: true,
    props: {},
  },
  list_items: {
    component: ListBlock,
    structBlock: true,
    props: {},
  },
  definition_list: {
    component: DefinitionListBlock,
    structBlock: false,
    props: {},
  },
  blockquote: {
    component: null,
    structBlock: true,
    props: {},
  },
  feature_list: {
    component: FeatureListBlock,
    structBlock: true,
    props: {},
  },
  feature_columns: {
    component: FeatureColumnsBlock,
    structBlock: true,
    props: {},
  },
  testimonials: {
    component: TestimonialsBlock,
    structBlock: true,
    props: {},
  },
  hero_heading: {
    component: HeroBlock,
    structBlock: true,
    props: {},
  },
  hero_modern_heading: {
    component: HeroModernBlock,
    structBlock: true,
    props: {},
  },
  selling_block: {
    component: SellingBlock,
    structBlock: true,
    props: {},
  },
  resources: {
    component: ResourceBlock,
    structBlock: true,
    props: {},
  },
  cta_callout: {
    component: CTACalloutBlock,
    structBlock: true,
    props: {},
  },
  accordion_block: {
    component: AccordionBlock,
    structBlock: true,
    props: {},
  },
  three_column_button_grid: {
    component: ThreeColumnButtonGrid,
    structBlock: true,
    props: {},
  },
  image_content_cta: {
    component: ImageContentCTABlock,
    structBlock: true,
    props: {},
  },
  six_column_logo_button_grid: {
    component: PartnerLogoButtonGridBlock,
    structBlock: true,
    props: {},
  },
  logo_slide: {
    component: LogoSlideBlock,
    structBlock: true,
    props: {},
  },
  content_image: {
    component: ContentImageBlock,
    structBlock: true,
    props: {},
  },
  take_aways: {
    component: TakeAwayBlock,
    structBlock: true,
    props: {},
  },
  icon_list: {
    component: IconListBlock,
    structBlock: true,
    props: {},
  },
  image_annotation: {
    component: ImageAnnotationBlock,
    structBlock: true,
    props: {},
  },
  info_banner: {
    component: InfoBannerBlock,
    structBlock: true,
    props: {},
  },
};

/*
(ts-migrate) TODO: Migrate the remaining prop types
...contentBlockPropTypes
*/
interface IBasicBlockProps {
  id?: any;
  type: string;
  value: any;
  blockTypes?: IBlockTypeConfig;
  className?: string;
  fullWidth?: boolean;
  spacing?: BlockComponentSpacing;
  size?: BlockComponentSize;
}

const BasicBlock = ({
  id,
  type,
  value,
  blockTypes,
  className,
  size,
  fullWidth = false,
  spacing = 'md',
}: IBasicBlockProps) => {
  const mergedBlockTypes = { ...basicBlockTypes, ...blockTypes };
  const { component: Component, structBlock, props } = mergedBlockTypes[type as keyof typeof mergedBlockTypes] || {};

  return Component ? (
    <Component
      key={id}
      id={id}
      spacing={spacing}
      size={size}
      fullWidth={fullWidth}
      {...(structBlock ? value : { value })}
      {...props}
      className={className}
    />
  ) : null;
};

export default BasicBlock;
