import styled, { css } from 'styled-components';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { fontWeight, fontSize } from '@whitelabel/helpers/style/type';
import { themeColor } from '@whitelabel/helpers/style/colors';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { buttonColor, getThemeProp, transitions } from '@whitelabel/helpers/style/get';
import { focusLinkStyle, focusVisibleMixin } from '@whitelabel/helpers/style/utils';
import { buttonVariant } from '@whitelabel/component-library';

export const StyledDropdownToggle = styled(DropdownToggle)`
  &&& {
    display: flex;
    align-items: center;
    padding: 0;
    text-decoration: none;
    color: ${buttonColor('tertiary', 'label')};
    background: none;
    border: none;
    ${focusVisibleMixin(focusLinkStyle)}
  }
`;

export const StyledDropdown = styled(UncontrolledDropdown)`
  display: none;
  ${mediaBreakpointUp('lg')`
    padding: 0;
    border-top: 0;
    display: block; 
  `}

  &&&&&.show > ${StyledDropdownToggle} {
    color: ${buttonColor('tertiary', 'label', 'focusVisible')};
    > svg path {
      stroke: ${buttonColor('tertiary', 'icon', 'focusVisible')};
    }
  }
`;

export const StyledNavIcon = styled.svg`
  margin-inline-start: 0.6875rem;
  vertical-align: top;
`;

export const StyledDropdownMenu = styled(DropdownMenu)`
  && {
    display: block;
    margin-top: 1.25rem;
    padding: 1.5rem 0;
    transform: scale(0);
    opacity: 0;
    transition: opacity ${transitions('duration')} ease 0s, transform 0s linear 0.9s;

    > div {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      ${({ $multiColumn }) =>
        $multiColumn &&
        css`
          min-width: 30rem;
          height: 15.25rem;

          > div {
            width: 50%;
          }
        `}
    }

    &.dropdown-menu-end {
      right: -0.5rem;
      left: auto;
    }

    &.dropdown-menu.show {
      transform: scale(1);
      opacity: 1;
      transition: transform 0s linear 0s, opacity ${transitions('duration')} ease 0.01s;
    }

    &[aria-hidden='true'] a {
      visibility: hidden;
    }
  }
`;

export const StyledDropdownColumn = styled.div`
  padding: 0 1.5rem;
  border-inline-start: ${getThemeProp('borderStyle')};
  &:not(:last-child) {
    padding-bottom: 2rem;
  }
  &:last-child a {
    margin-bottom: 0;
  }
  &:first-child {
    padding-bottom: 0;
    border: 0;
  }
`;

export const StyledDropdownItem = styled(DropdownItem)`
  && {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    padding: 0;
    transition: color ${transitions('duration')} ease-in-out;
    ${buttonVariant('tertiary')}
  }
`;

export const StyledDropdownHeaderItem = styled(DropdownItem)`
  && {
    padding: 0;
    color: ${themeColor('header')};
    font-weight: ${fontWeight('bold')};
    font-size: ${fontSize('base')};
    text-transform: uppercase;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
`;

export const StyledDropdownItemDivider = styled(DropdownItem).attrs(() => ({ divider: true }))`
  margin: 1rem;
`;
