import styled from 'styled-components';
import { rem } from 'polished';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { StyledSelect } from '../../../styled/StyledSelect';
import ToggleInput from '../../ToggleInput';

interface IStyledWrapperProps {
  $hasSingleDateQuestion?: boolean;
  $rtl?: boolean;
}

export const StyledTimeSeparator = styled.div`
  margin: 0 ${rem(4)};
  &:before {
    content: ':';
  }
`;

export const StyledTimePickerSelect = styled(StyledSelect)`
  flex-shrink: 0;
  width: ${rem(88)};
  .react-select__value-container {
    overflow: visible;
  }
  .react-select__placeholder {
    margin: 0;
  }
  .react-select__control {
    height: ${rem(46)};
  }
`;

export const StyledToggleInput = styled(ToggleInput)`
  margin-left: ${rem(16)};
`;

export const StyledWrapper = styled.div<IStyledWrapperProps>`
  display: flex;
  align-items: center;
  width: 100%;
  ${mediaBreakpointUp('sm')`
    width: auto;
    ${StyledTimePickerSelect} .react-select__control {
      ${({ $hasSingleDateQuestion }: IStyledWrapperProps) => $hasSingleDateQuestion && `height: ${rem(60)};`}
    }
  `}

  ${({ $rtl }: IStyledWrapperProps) =>
    $rtl &&
    `
    ${StyledToggleInput}  {
      margin-left: 0;
      margin-right: ${rem(16)};
    }
  `}
`;
