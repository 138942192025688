import React from 'react';
import { get } from 'lodash/fp';
import { Container } from 'reactstrap';
import {
  BlockComponentSpacing,
  BlockComponentSize,
  IBlockTypeConfig,
  IXCMSContentBlock,
} from '@whitelabel/helpers/types';
import ScrollToAnchor from '../ScrollToAnchor';
import StyledSection from '../../../styled/StyledSection';
import ContainerBlock from '../ContainerBlock';
import CarouselBlock from '../CarouselBlock';
import CardBlock from '../CardBlock';
import BasicBlock from '../BasicBlock';
import ArticleBlock from '../ArticleBlock';

// TODO: Update ISectionBlockProps typing
interface ISectionBlockProps {
  value?: IXCMSContentBlock[];
  blockTypes?: IBlockTypeConfig;
  className?: string;
  componentSpacing?: BlockComponentSpacing;
  componentSize?: BlockComponentSize;
}

const SectionBlock = ({
  value = [],
  blockTypes,
  className,
  componentSpacing = 'md',
  componentSize = 'large',
}: ISectionBlockProps): JSX.Element => {
  const isFullWidth = !!value.find(({ type }: any) => type === 'full_width');
  const backgroundColor = value.find(({ type }: any) => type === 'background_color');
  const hasHeroHeading =
    !!value.find(({ type }: any) => type === 'hero_heading' || type === 'hero_modern_heading') &&
    !value.find(({ value: valueContent }: any) => valueContent?.condensed);
  const sectionId = value.find(({ type }: any) => type === 'anchor_id')?.value;
  const hideSectionOnPrint = !!value.find(({ type }: any) => type === 'carousel');
  const marginPaddingStyle = value.find(({ type }: any) => type === 'margin_padding_style')?.value;

  const renderSectionContent = (cmsBlockValue: IXCMSContentBlock[]) =>
    cmsBlockValue.map((block: any, index: number) => {
      const { id, type, value: blockValue } = block;
      if (get(`${type}.component`, blockTypes)) {
        const { component: Component, structBlock, props } = blockTypes![type as keyof typeof blockTypes];

        return (
          <Component
            key={id}
            id={id}
            spacing={componentSpacing}
            size={componentSize}
            {...(structBlock ? blockValue : { value: blockValue })}
            blockTypes={blockTypes}
            {...props}
          />
        );
      }
      switch (type) {
        case 'articles':
          return <ArticleBlock key={id} articles={blockValue} />;
        case 'container':
          return <ContainerBlock key={id} spacing={componentSpacing} {...block} blockTypes={blockTypes} />;
        case 'card':
          return <CardBlock key={id} spacing={componentSpacing} {...block} blockTypes={blockTypes} />;
        case 'carousel':
          return <CarouselBlock key={id} spacing={componentSpacing} {...block} slides={blockValue} />;
        default:
          return (
            <BasicBlock
              key={id || index}
              spacing={componentSpacing}
              size={componentSize}
              {...block}
              blockTypes={blockTypes}
              fullWidth={isFullWidth}
            />
          );
      }
    });

  return (
    <StyledSection
      id={sectionId}
      className={(className || '') + (hideSectionOnPrint ? ' hide-on-print' : '')}
      hasHeroHeading={hasHeroHeading}
      background={backgroundColor?.value ?? null}
      marginPaddingStyle={marginPaddingStyle}
      data-test-id="SectionBlock"
    >
      <ScrollToAnchor anchor={sectionId as string} />
      {isFullWidth ? renderSectionContent(value) : <Container>{renderSectionContent(value)}</Container>}
    </StyledSection>
  );
};

export default SectionBlock;
