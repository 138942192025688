import * as React from 'react';
import { Row } from 'reactstrap';
import ListBlock from '../ListBlock';
import ImageBlock from '../ImageBlock';
import Button from '../../Button';
import {
  StyledContentCol,
  StyledImageCol,
  StyledParagraph,
  StyledContainer,
  StyledHeading,
  StyledButtonBlock,
} from './styledImageContentCTABlock';
import { IImageContentCTABlockProps } from './types';

const ImageContentCTABlock = (props: IImageContentCTABlockProps) => {
  const { backgroundColor, button, image, imageAlignment, title, text, listItems, variant, className, leadText } =
    props;
  const alignment = imageAlignment === 'right' ? 1 : 0;
  const renderButton = () => {
    if (!button.text) {
      return null;
    }
    return button.link ? (
      <StyledButtonBlock
        link={button.link}
        text={button.text}
        variant={button.variant}
        asLinkReferrer={button.asLinkReferrer}
      />
    ) : (
      <Button variant={button.variant} data-test-id="image-content-cta-button" className="image-content-cta-button">
        {button.text}
      </Button>
    );
  };
  return (
    <StyledContainer
      backgroundColor={backgroundColor}
      imageAlignment={imageAlignment}
      className={`image-content-cta-block ${className}`}
      data-test-id="image-content-block"
    >
      <Row>
        <StyledImageCol lg={{ size: variant === 'secondary' ? 3 : 5, order: alignment }} variant={variant}>
          <ImageBlock alignment={imageAlignment} image={image} altText="cta_image" />
        </StyledImageCol>
        <StyledContentCol lg={variant === 'secondary' ? 9 : 7}>
          <StyledHeading title={title} headingTag="h3" />
          {leadText && <StyledParagraph>{leadText}</StyledParagraph>}
          <StyledParagraph>{text}</StyledParagraph>
          <ListBlock items={listItems.items} listType={listItems.listType} size="small" />
          {renderButton()}
        </StyledContentCol>
      </Row>
    </StyledContainer>
  );
};

export default ImageContentCTABlock;
