import React from 'react';
import { useIntl } from 'react-intl';
import { Container } from 'reactstrap';
import { ReactComponent as IconPlay } from '@whitelabel/media/icons/small/play.svg';
import { ReactComponent as IconExternalLink } from '@whitelabel/media/icons/small/externallink.svg';
import {
  StyledSlideWrapper,
  StyledInternalGridBlockCard,
  StyledExternalGridBlockCard,
  StyledGridContainer,
} from './styledLogoSlideBlock';
import { ILogoSlideBlock } from './types';
import messages from './messages';

const isExternalLink = (link: string) => link.startsWith('http');

const getLinkToValue = (excludeAppLink: boolean, demoLink: string, locale: string) => {
  if (!excludeAppLink) {
    if (demoLink) {
      if (isExternalLink(demoLink)) {
        return demoLink;
      }

      if (demoLink.startsWith('/')) {
        return `/${locale}${demoLink}`;
      }

      return `/${locale}/${demoLink}`;
    }
    return '?show=getapp';
  }
  return '';
};

const LogoSlideBlock = ({ title, logos }: ILogoSlideBlock): JSX.Element => {
  const { formatMessage, locale } = useIntl();

  const renderIconAndMessage = (demoLink?: string) => {
    if (demoLink) {
      return (
        <>
          <IconPlay />
          {formatMessage(messages.statusDemo)}
        </>
      );
    }
    return (
      <>
        <IconExternalLink />
        {formatMessage(messages.statusGetApp)}
      </>
    );
  };

  return (
    <StyledSlideWrapper>
      <Container>
        <p>{title}</p>
        <StyledGridContainer>
          {logos.map(({ logo, demoLink, isComingSoon, excludeAppLink }, index) => {
            const linkTo = getLinkToValue(excludeAppLink, demoLink, locale);

            const innerComponent = () => (
              <>
                <img className="partner-logo" src={logo.image.file} alt={logo.altText} />

                {!excludeAppLink && (
                  <span className={`logo-status${!isComingSoon ? ' logo-icon' : ''}`}>
                    {isComingSoon && <>— {formatMessage(messages.statusComingSoon)} —</>}
                    {!isComingSoon && renderIconAndMessage(demoLink)}
                  </span>
                )}
              </>
            );

            const sharedProps = {
              $isComingSoon: isComingSoon,
              $excludeAppLink: excludeAppLink,
              key: `${logo.image.file}-${index}`,
              className: 'logo-status',
              target: demoLink ? '_blank' : undefined,
              tabIndex: demoLink ? 0 : -1,
              rel: 'noopener noreferrer',
            };
            if (isExternalLink(linkTo)) {
              return (
                <StyledExternalGridBlockCard {...sharedProps} href={linkTo}>
                  {innerComponent()}
                </StyledExternalGridBlockCard>
              );
            }

            return (
              <StyledInternalGridBlockCard {...sharedProps} to={linkTo}>
                {innerComponent()}
              </StyledInternalGridBlockCard>
            );
          })}
        </StyledGridContainer>
      </Container>
    </StyledSlideWrapper>
  );
};

export default LogoSlideBlock;
