import React, { useEffect, useCallback } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { CURRENT_LOCALE } from './constants';
import { isOnServer } from './utils';

export const PUBLIC_URL =
  process.env.REACT_APP_PUBLIC_URL || process.env.NEXT_PUBLIC_PUBLIC_URL || (isOnServer ? '' : window.location.origin);

export const isAbsoluteURL = (url: any) => /^(?:[a-z]+:)?\/\//i.test(url.trim());

export const getStandardLink = (link: string) =>
  link.startsWith('http://') || link.startsWith('https://') ? link : `http://${link}`;

export const parseURL = (url: any) => {
  const parser = document.createElement('a');
  parser.href = url;
  const { href, protocol, host, hostname, port, origin, pathname, search, hash } = parser;

  return {
    ...(isAbsoluteURL(url) && {
      href,
      protocol,
      host,
      hostname,
      port,
      origin,
    }),
    pathname,
    search,
    hash,
  };
};

export const smoothScrollToAnchor =
  (anchor: string, gap = 0) =>
  (event?: any) => {
    if (event) event.preventDefault();
    const target = document.querySelector(anchor);
    if (target) {
      const offsetTop = target.getBoundingClientRect().top + window.pageYOffset || document.documentElement.scrollTop;
      const targetOffset = offsetTop - gap;
      window.scroll({
        top: targetOffset,
        behavior: 'smooth',
      });
    }
  };

function getScrollConfig(targetSelector: string, scrollTop: number) {
  const target = document.querySelector(targetSelector);
  if (!target) return null;

  return {
    behavior: 'smooth',
    top: scrollTop + target.getBoundingClientRect().top - 24, // scroll to the target and leave at least 24 px space above the target
  };
}

export function smoothScrollModalToSelector(targetSelector: string, delay: any) {
  const modal = document.querySelector('.modal');
  if (modal) {
    const scroll = () => {
      const scrollConfig = getScrollConfig(targetSelector, modal.scrollTop);
      // @ts-expect-error ts-migrate(2345) FIXME: manually added
      if (scrollConfig) modal.scroll(scrollConfig);
    };
    if (delay) {
      setTimeout(() => {
        scroll();
      }, delay);
    } else {
      scroll();
    }
  }
}

export function smoothScrollModalToFirstAlert() {
  smoothScrollModalToSelector('.alert', 350);
}

export const smoothScrollToFirstAlert = (): void => {
  const alert = document.querySelector('.alert');
  if (alert) {
    alert.scrollIntoView({ behavior: 'smooth' });
  }
};

export function useHash(loading: boolean) {
  const history = useHistory();

  useEffect(() => {
    if (history.location.hash && !loading) {
      smoothScrollToAnchor(history.location.hash)();
    }
    return undefined;
  }, [history.location.hash, loading]);
}

export const redirectIfDifferentLocale = () => {
  const currentLocale = sessionStorage.getItem(CURRENT_LOCALE);
  const [, language, pathRemainder] =
    /\/?(.+?)(\/.+)$/.exec(window.location.href.substring(window.location.origin.length)) || [];
  if (currentLocale && currentLocale !== language) {
    const correctUrl = `/${currentLocale}${pathRemainder || ''}`;
    return <Redirect to={correctUrl} />;
  }
  return null;
};

const afterScrollHandler =
  (anchor: string, onTargetOrBottom?: () => void, gap = 0) =>
  () => {
    const target = document.querySelector(anchor);
    if (target && onTargetOrBottom) {
      const offsetTop = target.getBoundingClientRect().top + window.pageYOffset || document.documentElement.scrollTop;
      const targetOffset = offsetTop - gap;
      const isTargetReachTop = window.pageYOffset.toFixed() === targetOffset.toFixed();
      const isBottom = document.body.scrollHeight - document.body.scrollTop === document.body.clientHeight;
      if (isTargetReachTop || isBottom) {
        onTargetOrBottom();
      }
    }
  };

export const useAfterScrollHandler = (hash?: string, onTargetOrBottom?: () => void) => {
  const triggerAfterScrollHandler = useCallback(() => {
    if (hash) {
      afterScrollHandler(hash, onTargetOrBottom)();
    }
  }, []);
  return [triggerAfterScrollHandler];
};

export const PROTOCOL_DOMAIN =
  process.env.REACT_APP_PROTOCOL_DOMAIN ||
  process.env.NEXT_PUBLIC_PROTOCOL_DOMAIN ||
  process.env.VITE_PROTOCOL_DOMAIN ||
  (isOnServer ? '' : window.location.origin);

export const deleteFalsyParams = (params: { [index: string]: undefined | null | string }) => {
  Object.keys(params).forEach((param) => {
    if (params[param] === undefined || params[param] === null || params[param] === '') {
      delete params[param];
    }
  });
  return params as { [index: string]: string };
};

export const checkValidHash = (hash?: string) => {
  if (!hash) return true;
  const validHashRegex = /^(?!\d)(?!--)(?!-\d)[a-zA-Z0-9-_]+$/g;
  const isValidHash = validHashRegex.test(hash);

  return isValidHash;
};
