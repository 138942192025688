import React from 'react';
import styled, { css } from 'styled-components';
import { themeColor } from '@whitelabel/helpers/style/colors';
import { clearFix } from '@whitelabel/helpers/style/box';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { spacer, multiply } from '@whitelabel/helpers/style/units';

// Disable eslint to keep the original logic: not pass no-unused-vars
// props down to child component.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledSection = styled(({ hasHeroHeading, background, marginPaddingStyle, ...props }) => (
  <section {...props} className={`${`section-block ${props.className}`}`} />
))`
  margin-bottom: 1.5rem;

  ${mediaBreakpointUp('md')`
    margin-bottom: 2rem;
  `}

  ${mediaBreakpointUp('lg')`
    margin-bottom: 3rem;
  `}


  //hasHeroHeading + Various padding styles
  ${({ hasHeroHeading }) =>
    hasHeroHeading &&
    css`
      &&& {
        margin-top: -1.5rem;
        ${mediaBreakpointUp('md')`
           margin-top: -2rem;
        `}
        ${mediaBreakpointUp('lg')`
            margin-top: -3rem;
        `}
      }
    `}

    ${({ marginPaddingStyle, hasHeroHeading }) =>
    hasHeroHeading &&
    marginPaddingStyle === 'padding_only' &&
    css`
      &&& {
        padding-bottom: 1rem;
        margin-bottom: 0;
        ${mediaBreakpointUp('md')`
          padding-bottom: 2rem;
        `}
        ${mediaBreakpointUp('lg')`
          padding-bottom: 4rem;
        `}
      }
    `}

    ${({ marginPaddingStyle, hasHeroHeading }) =>
    hasHeroHeading &&
    marginPaddingStyle === 'none' &&
    css`
      &&& {
        padding: 0;
        margin-bottom: 0;
      }
    `}


    /* No Hero + Various Styles */
    ${({ background, hasHeroHeading }) =>
    background &&
    !hasHeroHeading &&
    css`
      background-color: ${themeColor(`${background}Bg` as 'primaryBg' | 'secondaryBg')};

      &&& {
        padding-top: ${multiply(spacer, 3)};
        padding-bottom: ${multiply(spacer, 3)};

        ${mediaBreakpointUp('md')`
            padding-top: ${multiply(spacer, 4)};
            padding-bottom: ${multiply(spacer, 4)};
          `}
      }
    `}

    ${({ marginPaddingStyle, hasHeroHeading }) =>
    marginPaddingStyle === 'padding_only' &&
    !hasHeroHeading &&
    css`
      &&& {
        padding: 1rem 0;
        margin: 0;
        ${mediaBreakpointUp('md')`
          padding: 2rem 0;
        `}
        ${mediaBreakpointUp('lg')`
          padding: 4rem 0;
        `}
      }
    `}

    ${({ marginPaddingStyle, hasHeroHeading }) =>
    marginPaddingStyle === 'none' &&
    !hasHeroHeading &&
    css`
      &&& {
        padding: 0;
        margin: 0;
      }
    `}


  & > *:last-child {
    margin-bottom: 0;
  }

  &:not(:last-child) {
    ${clearFix}
  }
`;

export default StyledSection;
