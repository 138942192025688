import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { gray, themeColor, color as styleColor } from '@whitelabel/helpers/style/colors';
import { ReactComponent as IconCheckbox } from '@whitelabel/media/icons/forms/checkbox.svg';
import { ReactComponent as IconRadio } from '@whitelabel/media/icons/forms/radio.svg';

const icons = {
  radio: IconRadio,
  checkbox: IconCheckbox,
};

const StyledCheckbox = styled.svg.attrs(({ type }) => ({ as: icons[type as keyof typeof icons] }))`
  ${({ color }) => {
    switch (color) {
      case 'checked':
        return css`
          .box {
            fill: ${themeColor('primary')};
            stroke: ${themeColor('primary')};
          }

          .tick {
            stroke: ${styleColor('white')};
          }
        `;

      case 'success':
        return css`
          .box {
            fill: ${themeColor('success')};
            stroke: ${themeColor('success')};
          }

          .tick {
            stroke: ${styleColor('white')};
          }
        `;

      case 'disabled':
      case 'gray':
        return css`
          .box {
            fill: ${gray(300)};
            stroke: ${gray(300)};
          }

          .tick {
            stroke: ${gray(500)};
          }
        `;

      case 'default':
      default:
        return css`
          .box {
            fill: ${styleColor('white')};
            stroke: ${gray(500)};
          }

          .tick {
            stroke: ${styleColor('white')};
          }
        `;
    }
  }}
`;

StyledCheckbox.propTypes = {
  type: PropTypes.oneOf(['checkbox', 'radio']),
  color: PropTypes.oneOf(['default', 'checked', 'disabled', 'success', 'gray']),
};

StyledCheckbox.defaultProps = {
  type: 'checkbox',
  color: 'default',
};

export default StyledCheckbox;
