import styled from 'styled-components';

export const StyledSyntaxHighlighterBlock = styled.div`
  position: relative;
  button {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    text-transform: uppercase;
  }
`;

export const lineNumberStyles = {
  float: 'left',
  paddingRight: '0.5rem',
} as const;

export const customStyles = {
  padding: `1.5rem`,
  paddingTop: `3rem`,
};
