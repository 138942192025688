import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { fontStyle, fontWeight, lineHeight, resetLinkStyle } from '@whitelabel/helpers/style/type';
import { get } from '@whitelabel/helpers/style/get';
import { themeColor, gray } from '@whitelabel/helpers/style/colors';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { ILocales } from '@whitelabel/helpers/types';
import IconTick from '@whitelabel/media/icons/flags/localeFlags/selected_flag.svg';
import { plainLinkStyle } from '@whitelabel/xcover-shared/helpers/style/utils';
import { focusLinkStyle, focusVisibleMixin } from '@whitelabel/helpers/style/utils';

export const StyledHeading = styled.div`
  line-height: ${lineHeight('headings')};
  /*  margin-bottom to increase the total margin to 
  overwrite the modal style and to
  match the design */
  margin-bottom: 0.5rem;
  ${mediaBreakpointUp('md')`
    font-size: 2.5rem;
  `}
  ${mediaBreakpointUp('lg')`
    margin-bottom: 0;
  `}
`;

export const StyledLocaleListItem = styled.li`
  ${fontStyle('contentMenu')}
  padding: 0;
  list-style-type: none;
`;

export const StyledLocaleLink = styled.button<{ $active: boolean }>`
  ${resetLinkStyle}
  font-weight: ${fontWeight('normal')};
  text-transform: capitalize;
  background: transparent;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  border: none;

  ${({ $active }) =>
    $active &&
    css`
      color: ${themeColor('success')};

      &::before {
        content: url(${IconTick});
        position: relative;
        top: 2px;
      }
    `}
  :focus-visible, :hover {
    color: ${themeColor('success')};
    background: transparent;
    ${plainLinkStyle}
  }
  ${focusVisibleMixin(focusLinkStyle)}
`;

export const StyledFlagWrapper = styled.div`
  display: flex;
  overflow: hidden;

  > .flag-border {
    border-radius: ${rem(1)};
    border: ${get('borderWidth')} solid ${gray(400)};
  }
`;

export const StyledGrid = styled.ul<{ $localsToDisplay: ILocales[] }>`
  display: grid;
  gap: 1rem 0.5rem;
  grid-auto-flow: column;
  padding-inline-start: 0;
  margin-bottom: 0;

  ${mediaBreakpointUp('md')`
     gap: 2rem 0.5rem;
  `}
  ${({ $localsToDisplay }) => css`
    grid-template-rows: repeat(${Math.ceil($localsToDisplay.length / 2)}, 1fr);
    grid-template-columns: repeat(2, 1fr);
    ${mediaBreakpointUp('md')`
        grid-template-rows: repeat(${Math.ceil($localsToDisplay.length / 3)}, 1fr);
        grid-template-columns: repeat(3, 1fr);
      `}
  `}
`;
