import { xcoverAPI } from '@whitelabel/xcover-shared/store/services/xcover/index';
import { processKeys } from '@whitelabel/xcover-shared/helpers/api';
import { getAPIHost } from '@whitelabel/xcover-shared/helpers/multiRegion';
import { ICOIResponse } from '@whitelabel/xcover-shared/helpers/types';
import { RTK_CACHE_LIFE } from '@whitelabel/xcover-shared/helpers/constants';

export interface IGetCOI {
  id: string;
  locale: string;
  securityToken?: string | null;
}

export const coiAPI = xcoverAPI.injectEndpoints({
  endpoints: (builder) => ({
    getCOI: builder.query<ICOIResponse, IGetCOI>({
      query: ({ id, locale, securityToken }) => {
        const searchParams = new URLSearchParams({
          language: locale,
          ...(securityToken && { security_token: securityToken }),
        });

        const url = `${getAPIHost(id)}/coi/${id}/?${searchParams}`;

        if (securityToken) {
          return {
            url,
            headers: { auth: 'false' },
          };
        }
        return url;
      },
      transformResponse: (response: any) => processKeys(response),
      keepUnusedDataFor: RTK_CACHE_LIFE,
    }),
  }),
});

export const { useGetCOIQuery } = coiAPI;

export const clearCOI = () => coiAPI.util.resetApiState();
