import styled from 'styled-components';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';

const Wrapper = styled.div`
  .card {
    margin-bottom: 0;
  }

  .row {
    margin-bottom: 0.5rem;
    ${mediaBreakpointUp('md')`
        margin-bottom: 1rem;
    `}
  }
  > .accordion-item-wrapper.row:last-child {
    margin-bottom: 0;
  }
`;

export { Wrapper };
