import { grays } from './colors';

export const borderWidth = '1px';
export const borderColor = grays[400];
export const borderStyle = `${borderWidth} solid ${borderColor}`;
export const borderRadii = {
  base: '3px',
  sm: '2px',
  lg: '5px',
};

export const boxShadows = {
  base: '0px 16px 24px rgba(45, 45, 45, 0.08)',
  md: '0px 24px 32px rgba(45, 45, 45, 0.16)',
};
