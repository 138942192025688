import React from 'react';
import { ISuggest } from '@whitelabel/helpers/types';
import { formatMatchedText } from './helper';
import { StyledSuggestItem } from './styledGeoSuggest';

interface ISuggestItemProps {
  userInput: string;
  isActive: boolean;
  suggest: ISuggest;
  isHighlightMatch: boolean;
  className?: string;
  onSelect: (suggest: ISuggest) => void;
  onMouseDown: (event: React.MouseEvent) => void;
  onMouseOut: (event: React.MouseEvent) => void;
}

/**
 * A single Geosuggest item in the list
 */
const SuggestItem = ({
  className,
  userInput,
  suggest,
  onMouseDown,
  onMouseOut,
  onSelect,
  isActive,
  isHighlightMatch,
}: ISuggestItemProps): JSX.Element => {
  const handleClick = (event: React.MouseEvent): void => {
    event.preventDefault();
    onSelect(suggest);
  };

  let content: string | JSX.Element = suggest.label;
  if (isHighlightMatch) {
    content = formatMatchedText(userInput, suggest);
  }
  return (
    <StyledSuggestItem
      className={className}
      onMouseDown={onMouseDown}
      onMouseOut={onMouseOut}
      onClick={handleClick}
      role="option"
      $isActive={isActive}
      aria-selected={isActive}
      id={suggest.placeId}
    >
      {content}
    </StyledSuggestItem>
  );
};

export default React.memo(SuggestItem);
