import React from 'react';
import styled, { css } from 'styled-components';
import { FormText, FormGroup } from 'reactstrap';
import { fontSize, lineHeight } from '@whitelabel/helpers/style/type';
import { getLeftValue } from '@whitelabel/helpers/style/box';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';

export const StyledFormText = styled(FormText)`
  margin-top: 0.5rem;
  font-size: ${fontSize('base')};
`;

// Disable eslint to keep the original logic: not pass no-unused-vars
// props down to child component.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledFormGroup = styled(({ marginBottom, marginLeft, ...props }) => <FormGroup {...props} />)`
  text-align: start;
  ${({ marginBottom }) =>
    marginBottom !== false &&
    css`
      margin-bottom: ${marginBottom}rem;
    `};
  ${({ marginLeft }) =>
    marginLeft &&
    css`
      margin-inline-start: ${marginLeft}rem;
    `};

  label {
    font-size: 1.125rem;
    line-height: ${lineHeight('base')};

    ${mediaBreakpointUp('lg')`
      font-size: 1.25rem;
    `}
  }

  > .form-content-component > :last-child {
    margin-bottom: 0;

    li:last-child > p {
      margin-bottom: 0;
    }
  }

  > .form-radio-component .form-check-label {
    padding-inline-start: 1.75rem;

    ::before,
    ::after {
      ${getLeftValue('0')}
    }
  }
`;
