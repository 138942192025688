import { defineMessages } from 'react-intl';

export default defineMessages({
  cardHeading: {
    id: 'unsupportedBrowserPage.cardHeading',
    defaultMessage: 'Please update your browser to use XCover.com',
  },
  cardBodyText: {
    id: 'unsupportedBrowserPage.cardBodyText',
    defaultMessage:
      'You are visiting this page because we detected an unsupported browser. ' +
      'We highly recommend that you update your browser. ' +
      'If you believe you have arrived here in error, please {contactUsLink}. ' +
      'Be sure to include your browser versions.',
  },
});
