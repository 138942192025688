import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { getCSSArrow } from '@whitelabel/helpers/style/utils';
import { zIndex } from '@whitelabel/helpers/style/zIndex';
import { fontStyle, fontWeight } from '@whitelabel/helpers/style/type';
import { color } from '@whitelabel/helpers/style/colors';
import { borderRadius, boxShadow } from '@whitelabel/helpers/style/box';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { get } from '@whitelabel/helpers/style/get';
import { ReactComponent as IconPin } from '@whitelabel/media/icons/small/pin.svg';
import { dropdownColors, inputColors } from '@whitelabel/global-theme/colors';

export const StyledInputWrapper = styled.div`
  position: relative;
`;

export const StyledIconPin = styled(IconPin)`
  position: absolute;
  top: 50%;
  left: 0.75rem;
  transform: translateY(-50%);
  user-select: none;
  pointer-events: none;
  ${mediaBreakpointUp('sm')`
    left: 1rem;
  `}
`;

interface IStyledGeoSuggestInputProps {
  readonly $invalid?: boolean;
}

export const StyledGeoSuggestInput = styled.input<IStyledGeoSuggestInputProps>`
  ${fontStyle('formValue')};
  width: 100%;
  height: ${rem(46)};
  padding: 0.5rem 0.75rem 0.5rem 2rem;
  border-radius: ${borderRadius('base')};
  ${mediaBreakpointUp('sm')`
    padding-left: 2.5rem;
    padding-right: 1rem;
  `}

  border: ${get('borderWidth')} solid ${inputColors.border.default};
  background-color: ${inputColors.background.default};
  color: ${inputColors.value.default};

  &:hover {
    border: ${get('borderWidth')} solid ${inputColors.border.hover};
    cursor: pointer;
    background-color: ${inputColors.background.hover};
    color: ${inputColors.value.hover};
  }

  &:focus {
    cursor: pointer;
    border: ${get('borderWidth')} solid ${inputColors.border.focus};
    background-color: ${inputColors.background.focus};
    color: ${inputColors.value.focus};
    outline: 0;
  }

  &:active {
    border: ${get('borderWidth')} solid ${inputColors.border.active};
    box-shadow: none;
    background-color: ${inputColors.background.active};
    color: ${inputColors.value.active};
  }

  &:disabled {
    border: ${get('borderWidth')} solid ${inputColors.border.disabled};
    background-color: ${inputColors.background.disabled};
    color: ${inputColors.value.disabled};
  }

  ${({ $invalid }) =>
    $invalid &&
    css`
      && {
        border: ${get('borderWidth')} solid ${inputColors.border.error};
        background-color: ${inputColors.background.error};
        color: ${inputColors.value.error};
      }
    `};
`;

export const StyledSuggestListWrapper = styled.div`
  position: relative;
  ${getCSSArrow(-11, 8)}
  ${mediaBreakpointUp('sm')(getCSSArrow(-11, 12))}
`;

// original style copied from react-geosuggest libraray
export const StyledSuggestList = styled.ul`
  position: absolute;
  top: calc(100% + 9px);
  left: 0;
  right: 0;
  padding: 0.5rem 0;
  max-height: 31.25rem;
  margin-top: -1px;
  background-color: ${color('white')};
  border: ${get('borderStyle')};
  overflow-x: hidden;
  overflow-y: auto;
  z-index: ${zIndex('content')};
  list-style: none;
  box-shadow: ${boxShadow('base')};
  transition: max-height 0.2s, border 0.2s;
`;

export const StyledSuggestItem = styled.li<{ $isActive?: boolean }>`
  cursor: pointer;
  padding: 0.5rem 1rem;

  &:hover,
  &:focus {
    background-color: ${dropdownColors.focus};
  }

  .geosuggest__item__matched-text {
    font-weight: ${fontWeight('bold')};
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      background-color: ${dropdownColors.focus};
    `}
`;

export const StyledDivider = styled.li`
  margin: 0.5rem 1rem;
  height: 1px;
  background-color: ${get('borderColor')};
`;

export const StyledGeoSuggest = styled.div<{ $size: 'base' | 'large' }>`
  position: relative;
  margin: 0 auto;
  text-align: left;

  .geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
  }

  ${({ $size }) =>
    $size === 'large' &&
    css`
      ${StyledGeoSuggestInput} {
        ${mediaBreakpointUp('sm')`
          padding-top: 1rem;
          padding-bottom: 1rem;
        `}
        ${mediaBreakpointUp('lg')`
          padding-left: 3rem;
          padding-right: 1.5rem;
        `}
      }
      ${StyledIconPin} {
        ${mediaBreakpointUp('lg')`
          left: 1.5rem;
        `}
      }
      ${StyledSuggestListWrapper} {
        ${mediaBreakpointUp('lg')(getCSSArrow(-11, 20))}
      }
    `}
`;
