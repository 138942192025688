import React from 'react';
import { camelCase } from 'lodash/fp';
import { Alignment, IButtonBlock } from '@whitelabel/helpers/types';
import ButtonBlock from '../ButtonBlock';
import { StyledButtonBox } from './styledButtonsBlock';

export interface IButtonsBlockProps {
  buttons: IButtonBlock[];
  alignment: Alignment;
  className?: string;
}

const ButtonsBlock = ({ buttons, alignment, className }: IButtonsBlockProps): JSX.Element => (
  <StyledButtonBox className={className} alignment={alignment}>
    {buttons.map((button) => (
      <ButtonBlock key={camelCase(button.text)} {...button} />
    ))}
  </StyledButtonBox>
);

export default ButtonsBlock;
