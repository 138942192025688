import { ReactComponent as LearningIcon } from '@whitelabel/media/icons/learning.svg';
import { ReactComponent as AffiliatesIcon } from '@whitelabel/media/icons/affiliates.svg';
import { ReactComponent as ClaimsIcon } from '@whitelabel/media/icons/claims.svg';
import { ReactComponent as TicketCoverIcon } from '@whitelabel/media/icons/policies/ticket.svg';
import { ReactComponent as TravelCoverIcon } from '@whitelabel/media/icons/policies/travel.svg';
import { ReactComponent as ProductCoverIcon } from '@whitelabel/media/icons/policies/product.svg';
import { ReactComponent as ParcelCoverIcon } from '@whitelabel/media/icons/policies/parcel.svg';
import { ReactComponent as TripCoverIcon } from '@whitelabel/media/icons/policies/trip.svg';
import { ReactComponent as EarthquakeCoverIcon } from '@whitelabel/media/icons/earthquake.svg';
import { ReactComponent as RentalIcon } from '@whitelabel/media/icons/Icon-rental.svg';

export const iconMap = {
  information_icon: LearningIcon,
  claim_process_icon: ClaimsIcon,
  ticket_protection_icon: TicketCoverIcon,
  travel_protection_icon: TravelCoverIcon,
  product_protection_icon: ProductCoverIcon,
  parcel_protection_icon: ParcelCoverIcon,
  ride_protection_icon: TripCoverIcon,
  earthquake_protection_icon: EarthquakeCoverIcon,
  affiliates_icon: AffiliatesIcon,
  rental_icon: RentalIcon,
};
export type IconMapType = typeof iconMap;
export interface IGridBox {
  icon: keyof IconMapType;
  title: string;
  description: string;
  link: string;
}

export interface IThreeColumnButtonGridProps {
  title: string;
  subTitle: string;
  gridBoxes: IGridBox[];
}
