import React from 'react';
import { useIntl } from 'react-intl';
import ErrorPage from '../ErrorPage';
import messages from './messages';

const NoCookiesErrorPage = (): JSX.Element => {
  const intl = useIntl();
  const title = intl.formatMessage(messages.title);
  const leadText = intl.formatMessage(messages.description);
  const button = {
    label: intl.formatMessage(messages.buttonText),
    onClick: () => window.location.reload(),
  };
  return <ErrorPage button={button} leadText={leadText} title={title} showGlobalNav={false} />;
};

export default NoCookiesErrorPage;
