import { defineMessages } from 'react-intl';

export default defineMessages({
  generalErrorTitle: {
    id: 'pages.error.generalErrorTitle',
    defaultMessage: 'Oops',
  },
  generalErrorText: {
    id: 'pages.error.generalErrorText',
    defaultMessage: `Something’s gone wrong.
    But don’t worry, our senior support staff, Toby, will help you find your way home`,
  },
  unMatchedBookingText: {
    id: 'pages.error.unMatchedBookingText',
    defaultMessage: `Something went wrong, this booking may not belong to you.
    But don’t worry, our senior support staff, Toby, will help you find your way home`,
  },
  backButtonText: {
    id: 'pages.error.backButtonText',
    defaultMessage: `Back To Home`,
  },
});
