import styled from 'styled-components';
import { fontWeight } from '@whitelabel/helpers/style/type';
import { gray } from '@whitelabel/helpers/style/colors';
import { buttonColor } from '@whitelabel/helpers/style/get';
import Button from '../Button';

export const StyledInputGroup = styled.div`
  &&& {
    display: flex;
    align-items: center;
  }
  input {
    display: none;
  }

  .value {
    width: 1rem;
    margin: 0 0.625rem;
    text-align: center;
    color: ${gray(700)};
    font-weight: ${fontWeight('normal')};
  }
`;

export const StyledButton = styled(Button)`
  background: transparent;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  padding: 0;
  > svg {
    margin: 0;
  }
  &:disabled {
    > svg {
      > path {
        stroke: ${gray(500)};
      }
    }
  }

  &.btn-secondary:not(.disabled):active,
  &.btn-secondary:not(.disabled):visited,
  &.btn-secondary:not(.disabled):focus {
    background: transparent;
    border-color: ${buttonColor('secondary', 'outline')};
  }
  &.btn-secondary:not(.disabled):hover {
    background: transparent;
    box-shadow: none;
    border-color: ${gray(700)};
  }
`;
