// @ts-ignore ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import { site } from '@whitelabel/helpers/site';

const getLabels = () => {
  const labels = {
    country: 'Phone number country',
    phone: 'Phone',
    ext: 'ext.',
    ZZ: 'International',
  };
  const countries = getCountries();
  countries.forEach((country: any) => {
    // @ts-ignore ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    labels[country] = `${site.countries[country]?.name || en[country]} +${getCountryCallingCode(country)}`;
  });

  return labels;
};

export default getLabels;
