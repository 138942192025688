import styled from 'styled-components';
import { Col } from 'reactstrap';
import { rem } from 'polished';
import { themeColor, color } from '@whitelabel/helpers/style/colors';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';

export const StyledTakeAwayWrapper = styled.div`
  padding-top: 1rem;

  ${mediaBreakpointUp('md')`
    padding-top: 2rem;
  `}

  ${mediaBreakpointUp('lg')`
    padding-top: 4rem;
  `}
`;

export const StyledTitle = styled.h1`
  ${mediaBreakpointUp('md')`
      font-size: 2rem;
    `}

  ${mediaBreakpointUp('lg')`
      font-size: 2.5rem;
    `}
`;

export const StyledLeadText = styled.p`
  margin: 0.5rem 0;
  color: ${themeColor('body')};
  line-height: 1.5;
`;

export const StyledStatNumber = styled.div`
  margin-bottom: -1rem;
  color: ${color('aquamarineBlue')};
  font-weight: 800;
  font-size: 4rem;

  ${mediaBreakpointUp('md')`
      font-size: 5rem;
    `}
`;

export const StyledStatText = styled.p`
  color: ${themeColor('body')};
`;

export const StyledStatWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledStatContainer = styled(Col)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${rem(288)}, 1fr));
  gap: 0 5%;
  height: fit-content;
`;

export const StyledBackgroundImage = styled.img`
  display: none;
  ${mediaBreakpointUp('lg')`
      display: flex;
      justify-content: flex-end;
      align-self: end;
      margin-top: -2rem;
  `}
`;

export const StyledImageContainer = styled(Col)`
  display: flex;
`;
