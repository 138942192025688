import React from 'react';
import { FieldProps } from 'formik';
import { Input } from 'reactstrap';
import { ReactComponent as IconPlus } from '@whitelabel/media/icons/small/icon-32-circle-plus.svg';
import { ReactComponent as IconMinus } from '@whitelabel/media/icons/small/icon-32-circle-minus.svg';
import { StyledInputContainer, StyledButton } from './styledNumericInput';

export interface INumericInputProps {
  id: string;
  name: string;
  value: number;
  invalid: boolean;
  form: FieldProps['form'];
  min?: number;
  max?: number;
}

const NumericInput = ({
  id,
  name,
  value,
  invalid,
  min,
  max,
  form: { setFieldValue, setFieldTouched },
}: INumericInputProps) => {
  const handleMinus = () => {
    const inputValue = String(value);
    // calculate decimal length
    const getFixLength = inputValue.includes('.') ? inputValue.length - inputValue.indexOf('.') - 1 : 0;
    setFieldValue(name, Number(value - 1).toFixed(getFixLength));
  };

  const handlePlus = () => {
    setFieldValue(name, +value + 1);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // clear leading zeros
    setFieldValue(name, event.target.value.replace(/^0+(?!\.|$)/, ''));
  };

  const handleWheel = (event: React.WheelEvent<HTMLInputElement>) => {
    // prevent value changes when scrolling
    event.currentTarget.blur();
  };

  return (
    <StyledInputContainer $invalid={invalid}>
      <StyledButton
        buttonType="secondary"
        onClick={handleMinus}
        icon={IconMinus}
        onBlur={() => setFieldTouched(name, true)}
        disabled={String(value) === ''}
      />
      <Input
        className="value"
        value={value}
        id={id}
        name={name}
        min={min}
        max={max}
        type="number"
        step="any"
        onChange={handleInputChange}
        onWheel={handleWheel}
        onBlur={() => setFieldTouched(name, true)}
      />
      <StyledButton
        buttonType="secondary"
        onClick={handlePlus}
        icon={IconPlus}
        onBlur={() => setFieldTouched(name, true)}
        disabled={String(value) === ''}
      />
    </StyledInputContainer>
  );
};

export default NumericInput;
