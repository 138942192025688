import { defineMessages } from 'react-intl';

export default defineMessages({
  loginLink: {
    id: 'navBar.loginLink',
    defaultMessage: 'Login',
  },
  quoteButton: {
    id: 'navBar.quoteButton',
    defaultMessage: 'Get A Quote',
  },
  logoutLink: {
    id: 'navBar.logoutLink',
    defaultMessage: 'Logout',
  },
  protectionLink: {
    id: 'navbar.protectionLink',
    defaultMessage: 'Protection',
  },
  appsForMerchants: {
    id: 'nav.navbar.appsForMerchantsLink',
    defaultMessage: 'Merchants',
  },
});
