import React from 'react';
import { Input, Label } from 'reactstrap';
import { InputProps } from '../types';
import { getProps } from '../helper';
import FormInputWrapper from '../../FormInputWrapper';
import { StyledCheckboxInputWrapper } from '../styledFormikInputs';
import StyledCustomInput from '../../../styled/StyledCustomInput';

const FormikCheckboxInput = (props: InputProps) => {
  const { inputProps, wrapperProps } = getProps(props);
  const { field, invalid } = wrapperProps;
  const { id, label } = inputProps;

  return (
    <FormInputWrapper {...wrapperProps}>
      <StyledCheckboxInputWrapper>
        <StyledCustomInput type="checkbox">
          <Input label={label} {...field} id={id} type="checkbox" invalid={invalid} />
          <Label check for={id}>
            {label}
          </Label>
        </StyledCustomInput>
      </StyledCheckboxInputWrapper>
    </FormInputWrapper>
  );
};

export default FormikCheckboxInput;
