import styled from 'styled-components';
import { gray } from '@whitelabel/helpers/style/colors';
import { getLeftValue } from '@whitelabel/helpers/style/box';
import { ReactComponent as IconSearch } from '@whitelabel/media/icons/small/search.svg';
import SelectInput from '../SelectInput';

export const StyledSelectInput = styled(SelectInput)`
  && .react-select__value-container {
    padding-inline-start: 2.25rem;
  }

  && .react-select__menu {
    white-space: nowrap;
  }
`;

export const StyledSearchIcon = styled(IconSearch)<{ $rtl: boolean }>`
  ${getLeftValue('1rem')};
  position: absolute;
  color: ${gray(600)};
`;
