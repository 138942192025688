import { isOnServer } from './utils';

type LiteralString<T> = T extends string ? T : string;

const storageBuilder =
  (storage: Storage) =>
  <T>(key: string, shouldParse?: boolean) => {
    const value = storage.getItem(key);

    if (shouldParse) return (value ? JSON.parse(value) : null) as T | null;

    return value as LiteralString<T> | null;
  };

/**
 * @example
 *
 * Default to parse the value from local storage and type it as string | null
 * - const value = getLocalStorage('localStorage-key');
 * - typeof value => string | null
 *
 * If you want to type the value as string literal types,
 * - const value = getLocalStorage<'firstName' | 'lastName'>('localStorage-key');
 * - typeof value => 'firstName' | 'lastName' | null
 *
 * If you want to type the value as string literal types but incorrectly set the second argument (shouldParse) to true,
 * you will receive a type error because you are trying to type a string as an object (T).
 * - const value = getLocalStorage<'firstName' | 'lastName'>('localStorage-key', true);
 * - typeof value => never
 *
 * If you want to parse the value from local storage and type it as T
 * - const value = getLocalStorage<{ name: 'string' }>('localStorage-key', true);
 * - typeof value => { name: 'string' } | null
 *
 * If you want to parse the value but not sure the type (not recommended), the type will be unknown.
 * It won't show any error hint, but be careful it may cause runtime error.
 * - const value = getLocalStorage('localStorage-key', true);
 * - typeof value => unknown
 *
 * @tutorial: function overloading to define multiple function signatures for a single function
 *
 * @param key key to get from local storage
 * @param shouldParse if true, will parse the value from local storage and type it as T
 * @returns literal string or string or null
 */
export function getLocalStorage<T extends string>(key: string): LiteralString<T> | null;
export function getLocalStorage<T>(
  key: string,
  shouldParse: T extends string ? never : true,
): T extends string ? never : T | null;
export function getLocalStorage<T>(key: string, shouldParse?: boolean) {
  if (isOnServer || !window.localStorage) return null;
  return storageBuilder(window.localStorage)<T>(key, shouldParse);
}

/**
 * @example
 *
 * Default to parse the value from session storage and type it as string | null
 * - const value = getSessionStorage('sessionStorage-key');
 * - typeof value => string | null
 *
 * If you want to type the value as string literal types,
 * - const value = getSessionStorage<'firstName' | 'lastName'>('sessionStorage-key');
 * - typeof value => 'firstName' | 'lastName' | null
 *
 * If you want to type the value as string literal types but incorrectly set the second argument (shouldParse) to true,
 * you will receive a type error because you are trying to type a string as an object (T).
 * - const value = getSessionStorage<'firstName' | 'lastName'>('sessionStorage-key', true);
 * - typeof value => never
 *
 * If you want to parse the value from session storage and type it as T
 * - const value = getSessionStorage<{ name: 'string' }>('sessionStorage-key', true);
 * - typeof value => { name: 'string' } | null
 *
 * If you want to parse the value but not sure the type (not recommended), the type will be unknown.
 * It won't show any error hint, but be careful it may cause runtime error.
 * - const value = getSessionStorage('sessionStorage-key', true);
 * - typeof value => unknown
 *
 * @tutorial: function overloading to define multiple function signatures for a single function
 *
 * @param key key to get from session storage
 * @param shouldParse if true, will parse the value from session storage and type it as T
 * @returns literal string or string or null
 */
export function getSessionStorage<T extends string>(key: string): LiteralString<T> | null;
export function getSessionStorage<T>(
  key: string,
  shouldParse: T extends string ? never : true,
): T extends string ? never : T | null;
export function getSessionStorage<T>(key: string, shouldParse?: boolean) {
  if (isOnServer || !window.sessionStorage) return null;
  return storageBuilder(window.sessionStorage)<T>(key, shouldParse);
}
