import React from 'react';
import { Row, Col } from 'reactstrap';
import { IXCMSImage } from '@whitelabel/helpers/types';
import {
  StyledTitle,
  StyledLeadText,
  StyledStatWrapper,
  StyledStatNumber,
  StyledStatText,
  StyledStatContainer,
  StyledBackgroundImage,
  StyledImageContainer,
  StyledTakeAwayWrapper,
} from './styledTakeAwayBlock';

interface ITakeAwayList {
  statNumber: string;
  statText: string;
}

export interface ITakeAwayBlockProps {
  takeAwayList: ITakeAwayList[];
  title: string;
  leadText: string;
  backgroundImage: IXCMSImage;
}

const TakeAwayBlock = ({ title, leadText, takeAwayList, backgroundImage }: ITakeAwayBlockProps): JSX.Element => (
  <StyledTakeAwayWrapper>
    <Row>
      <Col lg={8}>
        {title && <StyledTitle>{title}</StyledTitle>}
        {leadText && <StyledLeadText>{leadText}</StyledLeadText>}
      </Col>
    </Row>
    <Row>
      <StyledStatContainer sm={12} lg={8}>
        {takeAwayList.map(({ statNumber, statText }) => (
          <StyledStatWrapper key={statText}>
            <StyledStatNumber>{statNumber}</StyledStatNumber>
            <StyledStatText>{statText}</StyledStatText>
          </StyledStatWrapper>
        ))}
      </StyledStatContainer>
      <StyledImageContainer lg={4}>
        <StyledBackgroundImage src={backgroundImage?.file} alt={backgroundImage?.title} />
      </StyledImageContainer>
    </Row>
  </StyledTakeAwayWrapper>
);

export default TakeAwayBlock;
