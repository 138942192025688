import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import { useIntl } from 'react-intl';
import { Collapse } from 'reactstrap';

import { ReactComponent as IconChevronDown } from '@whitelabel/media/icons/small/chevron-down.svg';
import { ReactComponent as IconChevronUp } from '@whitelabel/media/icons/small/chevron-up.svg';
import { IButtonBlock, IImageBlock } from '@whitelabel/helpers/types';
import { onKeyDown } from '@whitelabel/helpers/utils';
import ScrollToAnchor from '../../ScrollToAnchor';
import { transformLink } from '../../../../helpers/transform';
import {
  StyledItemImageBlock,
  StyledItemHeading,
  StyledItemInner,
  StyledItemButtonBlock,
  StyledAccordionItem,
  StyledItemBody,
  StyledItemHeader,
} from './styledAccordionItem';

interface IAccordionItemProps {
  image?: IImageBlock;
  mobileImage?: IImageBlock;
  title?: string;
  leadText?: string;
  body?: string;
  button?: {
    value: IButtonBlock;
  }[];
  anchorId?: string;
}

const AccordionItem = ({ body, button, image, mobileImage, title, anchorId }: IAccordionItemProps): JSX.Element => {
  const intl = useIntl();
  const history = useHistory();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = !useMediaQuery({ minWidth: theme.gridBreakpoints.sm });
  const hasBody = body && body !== '<p></p>';
  const hasButton = button && button.length > 0;
  const accordionGap = isMobile ? 8 : 16;
  const toggle = () => setIsOpen((prevState) => !prevState);

  useEffect(() => {
    if (history.location.hash === `#${anchorId}`) {
      setIsOpen(true);
    }
  }, [anchorId, history.location.hash]);

  return (
    <StyledAccordionItem id={anchorId} data-test-id="accordion-item" tabIndex={0} onKeyDown={onKeyDown(toggle)}>
      <ScrollToAnchor anchor={anchorId} gap={accordionGap} />
      <StyledItemHeader onClick={toggle}>
        <StyledItemHeading>
          {title}
          {isOpen ? <IconChevronUp /> : <IconChevronDown />}
        </StyledItemHeading>
      </StyledItemHeader>
      <Collapse isOpen={isOpen}>
        <StyledItemInner>
          <StyledItemImageBlock
            image={mobileImage && isMobile ? mobileImage : image}
            altText={title}
            withMargin={!!title || hasBody}
          />

          {hasBody && (
            <StyledItemBody data-test-id="AccordionItem-body">
              {body && ReactHtmlParser(body, { transform: transformLink(intl) })}
            </StyledItemBody>
          )}
          {hasButton && <StyledItemButtonBlock {...button[0].value} />}
        </StyledItemInner>
      </Collapse>
    </StyledAccordionItem>
  );
};

export default AccordionItem;
