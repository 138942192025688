import { boxShadows } from './box';
import { fontSizes } from './type';
import { grays, colors } from './colors';

const popover = {
  boxShadow: boxShadows.base,
  arrowHeight: '1rem',
  arrowWidth: '1rem',
  fontSize: fontSizes.sm,
  borderColor: grays[300],
  header: {
    bg: colors.white,
    color: grays[600],
  },
  bodyColor: grays[700],
};

export default popover;
