import styled from 'styled-components';
import { Breadcrumb } from 'reactstrap';
import ChevronRight from '@whitelabel/media/icons/icon-chevronright.svg';
import { fontStyle } from '@whitelabel/helpers/style/type';
import { themeColor } from '@whitelabel/helpers/style/colors';
import { flipForRTL } from '@whitelabel/helpers/style/box';

const StyledBreadCrumb = styled(Breadcrumb)<{ $rtl: boolean }>`
  display: flex;
  margin-bottom: 0.5rem;
  > .breadcrumb {
    margin-bottom: 0;
  }

  .breadcrumb-item {
    display: flex;
    align-items: center;
    padding-right: 0;
    padding-left: 0;

    + .breadcrumb-item {
      &::before {
        ${flipForRTL}
        display: block;
        width: 1rem;
        height: 1rem;
        padding-right: 0;
        padding-left: 0;
        margin-inline-start: 0.5rem;
        margin-inline-end: 0.5rem;
        color: ${themeColor('body')};
        content: ${`url(${ChevronRight})`};
      }
    }

    > a,
    p {
      ${fontStyle('button')}
      color: ${themeColor('body')};
      text-transform: uppercase;
      margin-bottom: 0;
      &:hover {
        box-shadow: none;
      }
    }
  }
`;

export { StyledBreadCrumb };
