import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { fontStyle, fontWeight, lineHeight } from '@whitelabel/helpers/style/type';
import { themeColor } from '@whitelabel/helpers/style/colors';
import { borderRadius } from '@whitelabel/helpers/style/box';
import { mediaBreakpointUp, publicMarginBottom, setAllGridGutterWidths } from '@whitelabel/helpers/style/grid';
import { getThemeProp } from '@whitelabel/helpers/style/get';
import { ReactComponent as IconInfo } from '@whitelabel/media/icons/info-currentcolor.svg';

export const StyledInfoBox = styled.section<{ $hasTitle: boolean }>`
  display: flex;
  ${publicMarginBottom}
  h3 {
    text-transform: none;
  }
  ${({ $hasTitle }) =>
    $hasTitle
      ? setAllGridGutterWidths(['padding'])
      : css`
          padding: 1rem;
        `}
  color: ${themeColor('info')};
  background-color: ${({ theme }) => theme.alert.backgroundColor.info};
  border: ${getThemeProp('borderWidth')} solid ${({ theme }) => theme.alert.borderColor.info};
  border-radius: ${borderRadius('base')};

  && p {
    color: inherit;
  }

  a {
    color: ${themeColor('info')};
    font-weight: ${fontWeight('normal')};

    &:hover {
      color: ${themeColor('info')};
    }
  }
`;

export const StyledInfoTitle = styled.h3`
  text-transform: none;
  ${fontStyle('secondaryH2')}
  line-height: ${lineHeight('base')};
  ${mediaBreakpointUp('lg')`
    line-height: ${lineHeight('headings')};
  `}
`;

export const StyledIcon = styled(IconInfo)<{ $hasTitle: boolean }>`
  align-self: flex-start;
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  min-height: 1.5rem;
  margin-inline-end: 1rem;

  ${({ $hasTitle }) =>
    css`
      margin-top: ${$hasTitle ? rem(3) : 0};
      ${mediaBreakpointUp('md')`
        margin-top: ${$hasTitle ? rem(6) : 0};
      `}
    `}
`;
