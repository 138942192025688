import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Prompt } from 'react-router-dom';
import messages from './messages';

const handleUnload = (e: any) => {
  e.preventDefault();
  e.returnValue = 'false';
};

export interface INavigateAwayPromptProps {
  allowedPaths: string[];
}

const NavigateAwayPrompt = ({ allowedPaths }: INavigateAwayPromptProps): JSX.Element => {
  const intl = useIntl();
  // Component to fire a prompt when the user tries to close tab
  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload);
    return () => window.removeEventListener('beforeunload', handleUnload);
  }, []);
  // If allowedPaths is left out, the component will fire a prompt when navigating to anywhere.
  const nextPathAllowed = (location: any) => {
    if (allowedPaths) {
      return allowedPaths.map((path: any) => location.pathname.startsWith(`/${intl.locale}/${path}/`)).some(Boolean);
    }
    return false;
  };

  return <Prompt message={(location) => nextPathAllowed(location) || intl.formatMessage(messages.navigateAlert)} />;
};

export default NavigateAwayPrompt;
