import styled, { css } from 'styled-components';
import { StyledTitle, StyledLeadText, StyledCallout, StyledSection } from '@whitelabel/component-library';
import { zIndex } from '@whitelabel/helpers/style/zIndex';
import { fontFamily, lineHeight } from '@whitelabel/helpers/style/type';
import { gray as styleGray, themeColor, color } from '@whitelabel/helpers/style/colors';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { spacer, multiply } from '@whitelabel/helpers/style/units';
import { getThemeProp } from '@whitelabel/helpers/style/get';
import { plainLinkStyle } from '@whitelabel/xcover-shared/helpers/style/utils';

export const StyledLayout = styled.div<{ $rtl: boolean; $gray?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 0;
  direction: ${({ $rtl }) => ($rtl ? 'rtl' : 'ltr')};

  ${({ $gray }) =>
    $gray
      ? css`
          background: ${styleGray(200)};
        `
      : css`
          h1${StyledTitle} {
            line-height: ${lineHeight('headingsLg')};
            margin-inline-end: ${multiply(spacer, 0.5)};
            margin-inline-start: ${multiply(spacer, 0.5)};

            ${mediaBreakpointUp('md')`
              margin-inline-end: ${spacer};
              margin-inline-start: ${spacer};
            `}

            span {
              background: ${themeColor('primary')};
              box-shadow: ${multiply(spacer, 0.5)} 0 0 ${themeColor('primary')},
                ${multiply(spacer, -0.5)} 0 0 ${themeColor('primary')};

              ${mediaBreakpointUp('md')`
                box-shadow: ${spacer} 0 0 ${themeColor('primary')}, -${spacer} 0 0 ${themeColor('primary')};
              `};
            }
          }

          ${StyledLeadText} {
            font-family: ${fontFamily('base')};
          }
        `}
`;
export const StyledHeader = styled.header<{
  $spacing?: 'lg';
  $hasBottomBorder: boolean;
  $hasHeaderMarginBottom: boolean;
}>`
  position: relative;
  z-index: ${zIndex('header')};
  ${({ $hasBottomBorder }) =>
    $hasBottomBorder &&
    css`
      border-bottom: ${getThemeProp('borderWidth')} solid ${styleGray(300)};
    `}

  ${mediaBreakpointUp('lg')`
    border-bottom: 0;
  `}

  ${({ $spacing, $hasHeaderMarginBottom }) =>
    $spacing === 'lg'
      ? css`
          margin-bottom: ${multiply(spacer, 1.5)};

          ${mediaBreakpointUp('md')`
            margin-bottom: ${$hasHeaderMarginBottom ? '2rem' : 0};
          `}

          ${mediaBreakpointUp('lg')`
            margin-bottom: ${$hasHeaderMarginBottom ? '3rem' : 0};
          `}
        `
      : css`
          margin-bottom: ${$hasHeaderMarginBottom ? spacer : 0};

          ${mediaBreakpointUp('md')`
            margin-bottom: ${$hasHeaderMarginBottom ? '2rem' : 0};
          `}

          ${mediaBreakpointUp('lg')`
            margin-bottom: ${$hasHeaderMarginBottom ? '3rem' : 0};
          `}
        `}

  a {
    text-decoration: none;
    color: initial;

    &:hover {
      ${plainLinkStyle}
    }
  }
  @media print {
    border: 0;
  }
`;

export const StyledMain = styled.div<{ $spacing?: 'lg' }>`
  display: flex;
  flex-direction: column;
  @media print {
    display: block;
  }

  mark {
    background: ${color('lightenedInfo')};
    color: ${styleGray(700)};
    padding: 0.2em;
  }

  ${({ $spacing }) =>
    $spacing === 'lg'
      ? css`
          margin-bottom: ${multiply(spacer, 4)};

          ${mediaBreakpointUp('md')`
          margin-bottom: ${multiply(spacer, 5)};
        `}

          ${mediaBreakpointUp('lg')`
          margin-bottom: ${multiply(spacer, 8)};
        `}

        ${StyledSection} {
            margin-bottom: ${multiply(spacer, 4)};

            ${mediaBreakpointUp('md')`
            margin-bottom: ${multiply(spacer, 5)};
          `}

            ${mediaBreakpointUp('lg')`
            margin-bottom: ${multiply(spacer, 8)};
          `}

          &:last-child {
              margin-bottom: 0;

              ${StyledCallout}:last-child {
                margin-bottom: -${multiply(spacer, 4)};

                ${mediaBreakpointUp('md')`
                margin-bottom: -${multiply(spacer, 5)};
              `}

                ${mediaBreakpointUp('lg')`
                margin-bottom: -${multiply(spacer, 8)};
              `}
              }
            }
          }
        `
      : css`
          margin-bottom: ${multiply(spacer, 1.5)};

          ${mediaBreakpointUp('md')`
            margin-bottom: ${multiply(spacer, 2)};
          `}

          ${mediaBreakpointUp('lg')`
            margin-bottom: ${multiply(spacer, 3)};
          `}
        `}
`;
