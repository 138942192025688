import styled from 'styled-components';
import { rem } from 'polished';
import { fontStyle } from '@whitelabel/helpers/style/type';
import { gray } from '@whitelabel/helpers/style/colors';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;

  > .feature-image-description {
    ${fontStyle('base')}
    color: ${gray(600)};
    font-size: ${rem(18)};
    text-align: center;
    width: ${rem(270)};
  }
`;

export const StyledImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(270)};
  height: ${rem(120)};
`;

export const StyledTitle = styled.h5`
  ${fontStyle('h5')}
  font-weight: 420;
  margin-top: ${rem(8)};
  margin-bottom: 0;
`;
