import React from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as IconPower } from '@whitelabel/media/icons/small/power.svg';
import { INavItem } from '@whitelabel/helpers/types';
import messages from '../messages';
import {
  StyledDropdown,
  StyledDropdownToggle,
  StyledDropdownCustomer,
  StyledDropdownName,
  StyledDropdownSubText,
  StyledDropdownMenu,
  StyledDropdownItem,
  StyledDropdownItemDivider,
  StyledDropdownItemIcon,
  StyledNavIcon,
} from './styledGlobalCustomerDropdown';

interface ICustomer {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
}
export interface IGlobalCustomerDropdownProps {
  customer: ICustomer;
  customerItems: INavItem[];
  extraItems?: INavItem[];
  item: INavItem;
}

const GlobalCustomerDropdown = ({
  customerItems,
  extraItems,
  customer: { firstName, lastName, email, phone },
  item: { icon, messageKey: itemMessageKey },
}: IGlobalCustomerDropdownProps): JSX.Element => {
  const intl = useIntl();
  const name = [firstName !== 'N/A' ? firstName : '', lastName !== 'N/A' ? lastName : '']
    .filter(Boolean)
    .join(' ')
    .trim();
  return (
    <StyledDropdown nav inNavbar data-test-id="GlobalCustomerDropdown">
      <StyledDropdownToggle nav data-test-id="GlobalCustomerDropdown-toggle">
        <button type="button">
          <StyledNavIcon as={icon} />
          {itemMessageKey ? intl.formatMessage(messages[itemMessageKey as keyof typeof messages]) : name}
        </button>
      </StyledDropdownToggle>

      <StyledDropdownMenu $rtl={intl.bidi} end={!intl.bidi} data-test-id="GlobalCustomerDropdown-menu">
        <StyledDropdownCustomer data-test-id="GlobalCustomerDropdown-customer">
          {name && <StyledDropdownName data-test-id="GlobalCustomerDropdown-customerName">{name}</StyledDropdownName>}
          <StyledDropdownSubText data-test-id="GlobalCustomerDropdown-customerSubText">
            {email || phone}
          </StyledDropdownSubText>
        </StyledDropdownCustomer>
        <StyledDropdownItemDivider />
        {customerItems.map(({ key, messageKey, path }) => (
          <StyledDropdownItem
            key={key}
            to={`/${intl.locale}${path}`}
            data-test-id="GlobalCustomerDropdown-item"
            data-analytics={`navbarDropdown${messageKey}`}
          >
            {intl.formatMessage(messages[messageKey as keyof typeof messages])}
          </StyledDropdownItem>
        ))}
        <StyledDropdownItemDivider />
        {extraItems &&
          extraItems.map(({ key, messageKey, path }) => (
            <StyledDropdownItem
              key={key}
              to={`/${intl.locale}${path}`}
              data-test-id="GlobalCustomerDropdown-item"
              data-analytics={`navbarDropdown${messageKey}`}
            >
              {intl.formatMessage(messages[messageKey as keyof typeof messages])}
            </StyledDropdownItem>
          ))}
        {extraItems && <StyledDropdownItemDivider />}
        <StyledDropdownItem
          to={`/${intl.locale}/logout`}
          data-test-id="GlobalCustomerDropdown-item"
          data-analytics="navbarDropdownLogoutLink"
        >
          <StyledDropdownItemIcon as={IconPower} data-test-id="GlobalCustomerDropdown-itemIcon" />
          {intl.formatMessage(messages.logoutLink)}
        </StyledDropdownItem>
      </StyledDropdownMenu>
    </StyledDropdown>
  );
};

GlobalCustomerDropdown.defaultProps = {
  customer: null,
  customerItems: [],
};

export default GlobalCustomerDropdown;
