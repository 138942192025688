import React from 'react';
import { useIntl } from 'react-intl';
import { components, OptionProps } from 'react-select';
import { ReactComponent as SelectedIcon } from '@whitelabel/media/icons/flags/selected-flag.svg';
import { getCurrencySymbolOrName } from '@whitelabel/helpers/utils';
import { MORE_LANGUAGES } from '@whitelabel/helpers/constants';
import { StyledIcon } from '../styledCurrencySelect';
import { StyledSelectOption } from './styledCustomOption';

const CustomOption = (props: OptionProps<any, false, any>) => {
  const {
    innerProps: { id },
    data: { value, icon, label },
    isFocused,
    isSelected,
  } = props;
  const { locale } = useIntl();
  const showMoreButton = value === MORE_LANGUAGES;

  return (
    <components.Option {...props}>
      <StyledSelectOption
        isSelected={isSelected}
        isFocused={isFocused}
        isFirstOption={id.endsWith('0')}
        isLastOption={id.endsWith('3')}
        isMoreOption={showMoreButton}
      >
        <StyledIcon as={isSelected ? SelectedIcon : icon} />{' '}
        {showMoreButton ? label.toUpperCase() : `${value} (${getCurrencySymbolOrName(locale, value)})`}
      </StyledSelectOption>
    </components.Option>
  );
};

export default CustomOption;
