import { getLocalStorage } from '@whitelabel/helpers/storageUtils';

export const BW_CHAT_BUNDLE_URL =
  process.env.REACT_APP_BW_CHAT_BUNDLE_URL || process.env.NEXT_PUBLIC_BW_CHAT_BUNDLE_URL;
export const BW_CHAT_BOT_ID = 'bwChatBotId';
export const BW_CHAT_SCRIPT_ID = 'bwChatScriptId';
const BW_CHAT_FEATURE_FLAG = ['bwChatFeatureFlag', 'yue234bDy6Si9'] as const;

export function isBwChatEnabled() {
  const value = getLocalStorage(BW_CHAT_FEATURE_FLAG[0]);
  return value === BW_CHAT_FEATURE_FLAG[1];
}
