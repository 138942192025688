import { multiply } from './units';
import { grays, colors } from './colors';
import { spacer } from './grid';

const dropdown = {
  boxShadow: `0 24px 32px rgba(45, 45, 45, 0.16)`,
  borderColor: grays[300],
  itemPaddingX: spacer,
  itemPaddingY: multiply(spacer, 0.25),
  backgroundColor: colors.white,
};

export default dropdown;
