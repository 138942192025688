import React from 'react';
import styled, { css } from 'styled-components';
import { Alert } from 'reactstrap';
import { rem } from 'polished';
import { alertBackgroundColor, alertBorderColor } from '@whitelabel/helpers/style/alert';
import { transition } from '@whitelabel/helpers/style/animation';
import { fontStyle, fontWeight } from '@whitelabel/helpers/style/type';
import { themeColor, color as styleColor } from '@whitelabel/helpers/style/colors';
import { borderRadius } from '@whitelabel/helpers/style/box';
import { spacer } from '@whitelabel/helpers/style/units';
import { AlertColor } from '@whitelabel/helpers/types';
import Button from '../Button';
// Disable eslint to keep the original logic: not pass toggle down to Alert component.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledAlert = styled(({ toggle, ...props }) => <Alert {...props} />)`
  ${fontStyle('messaging')}
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 2rem;
  padding: ${spacer};
  border-radius: ${borderRadius('base')};

  ${({ toggle }) =>
    toggle &&
    css`
      padding-inline-end: 4.125rem;
    `}

  ${({ color }) => css`
    color: ${themeColor(color)};
    border-color: ${alertBorderColor(color)};
    background-color: ${alertBackgroundColor(color)};

    a {
      color: ${themeColor(color)};
      font-weight: ${fontWeight('normal')};
      transition: ${transition('base')};
      box-shadow: 0 -1px 0 0 ${themeColor(color)} inset;

      &:hover {
        color: ${themeColor(color)};
        box-shadow: 0 -2px 0 0 ${themeColor(color)} inset;
        text-decoration: none;
      }
    }
  `}
`;

export const StyledAlertContent = styled.div`
  font-size: ${rem(18)};
  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const StyledIcon = styled.svg`
  align-self: flex-start;
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  min-height: 1.5rem;
  margin-inline-end: 0.5rem;
`;

export const StyledButton = styled(Button)<{ buttonColor: AlertColor }>`
  position: absolute;
  right: 1rem;
  width: auto;
  padding: 0.5rem;
  line-height: 0;
  background-color: transparent;

  ${({ buttonColor: color }) => css`
    color: ${themeColor(color)};
    border-color: ${alertBorderColor(color)};

    &:hover {
      border-color: ${themeColor(color)};
    }
  `}

  svg {
    margin-inline-end: 0;
  }

  &:hover {
    background-color: ${styleColor('white')};
  }
`;
