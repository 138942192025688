import styled from 'styled-components';
import { Nav } from 'reactstrap';
import { fontSize } from '@whitelabel/helpers/style/type';
import { gray, themeColor, color } from '@whitelabel/helpers/style/colors';
import { flipForRTL } from '@whitelabel/helpers/style/box';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { multiply } from '@whitelabel/helpers/style/units';
import { getThemeProp } from '@whitelabel/helpers/style/get';

export const StyledSecondaryNav = styled.div`
  color: ${color('white')};
`;

export const StyledNavSeperator = styled.hr`
  && {
    height: 0;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-top: ${getThemeProp('borderWidth')} solid ${gray(600)};
  }
`;

export const StyledBackButton = styled.button`
  &&:not(.btn-primary) {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    svg {
      margin: 0 0.625rem 0 0;
      path {
        stroke: ${color('white')};
      }
    }
  }
`;

export const StyledNavIcon = styled.svg<{ $rtl: boolean }>`
  ${flipForRTL}
  margin-inline-start: 0.6875rem;
  vertical-align: top;

  path {
    stroke: ${gray(600)};
  }
`;

export const StyledHeading = styled.h4`
  color: ${themeColor('primary')};
  font-size: ${multiply(fontSize('base'), 1.125)};
  text-transform: uppercase;
  ${mediaBreakpointUp('md')`
    font-size: ${multiply(fontSize('base'), 1.25)};
  `};
`;

export const StyledSubHeading = styled.h6`
  color: ${themeColor('primary')};
  font-size: ${multiply(fontSize('base'), 0.875)};
  text-transform: uppercase;
  ${mediaBreakpointUp('md')`
    font-size: ${multiply(fontSize('base'), 0.875)};
  `};
`;

export const StyledNav = styled(Nav)`
  display: flex;
  flex-direction: column;
`;
