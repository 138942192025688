// theme
const turbo = '#ffe600'; // primary
const turbo30 = '#ffee4d';
const gold = '#ffd200'; // secondary
const aquamarineBlue = '#73e6d2';
const cerulean = '#00beff';
const blue = '#0057ff'; // info
const malibu = '#66D8FF'; // selection
const scarlet = '#ff3200'; // danger
const persianGreen = '#00a082'; // success
const pinkDiminishing = '#fff4f2';
const indigo = '#6610f2';
const purple = '#6f42c1';
const pink = '#e83e8c';
const white = '#fff';
const black = '#000';
const dustyGray = '#999';
const soapstone = '#fff9f7';
const blackSqueeze = '#f7fcfb';
const cadetBlue = '#acb5bf';
const artyClickSkyBlue = '#00BBE3';
const java = '#10B7B5';
const brightCerulean = '#05b8d2';
export const ceruleanGradient = `linear-gradient(92.77deg, ${artyClickSkyBlue} 0.18%, ${brightCerulean} 50.37%, ${java} 99.51%)`;
const galleryGray = '#ebebeb';
const tealGreen = '#1AAA8F';
const paleMint = '#F2FAF8';
const redOrange = '#ff471a';
const linen = '#FFF4F2';
const prussianBlue = '#002B52';
const lightenedSuccess = '#E6F6F3';
const apricot = '#FFD0B4';
const oliveDrab = '#3C5F80';
const crayola = '#A3B8C6';
const paleGrayBlue = '#D4E2EB';
const buttermilk = '#FFF599';
const lightenedInfo = '#E6EEFF';
const ghostWhite = '#F8FCFF';
const aliceBlue = '#E8F1F8';
const olive = '#807300';

// grays
const alabaster = '#fbfbfb';
const wildSand = '#f6f6f6';
const catskillWhite = '#f0f4f8';
const mercury = '#e0e4e8';
const ghost = '#c5cad1';
const rollingStone = '#73777c'; // Anchor/visited
const doveGray = '#717171';
const mineShaft = '#2d2d2d';
const outerSpace = '#484848';

// component colours
export const colors = {
  blue,
  indigo,
  purple,
  pink,
  turbo,
  persianGreen,
  aquamarineBlue,
  cerulean,
  white,
  black,
  catskillWhite,
  mercury,
  dustyGray,
  soapstone,
  blackSqueeze,
  cadetBlue,
  artyClickSkyBlue,
  java,
  brightCerulean,
  ceruleanGradient,
  galleryGray,
  scarlet,
  tealGreen,
  paleMint,
  redOrange,
  linen,
  prussianBlue,
  lightenedSuccess,
  apricot,
  oliveDrab,
  crayola,
  paleGrayBlue,
  buttermilk,
  lightenedInfo,
  outerSpace,
  ghostWhite,
  aliceBlue,
};

export const grays = {
  100: alabaster,
  200: wildSand,
  300: catskillWhite,
  400: mercury,
  500: ghost,
  600: doveGray,
  700: mineShaft,
};

export const themeColors = {
  primary: turbo,
  secondary: gold,
  anchor: rollingStone,
  selection: malibu,
  info: blue,
  danger: '#D62B00', // scarlet v2
  success: '#008068', // persian green v2
  warning: '#bb4f15',
  header: mineShaft,
  body: rollingStone,
  completed: cadetBlue,
  light: grays[200],
  dark: grays[700],
  primaryBg: white,
  secondaryBg: wildSand,
  dangerBg: pinkDiminishing,
  subtext: grays[600],
  disabled: grays[600],
};

export const inputColors = {
  label: {
    default: mineShaft,
    hover: mineShaft,
    focus: mineShaft,
    active: mineShaft,
    disabled: mineShaft,
    error: themeColors.danger,
    success: themeColors.success,
  },
  value: {
    default: mineShaft,
    hover: mineShaft,
    focus: mineShaft,
    active: mineShaft,
    disabled: rollingStone,
    error: rollingStone,
    success: themeColors.success,
  },
  background: {
    default: white,
    hover: white,
    focus: white,
    active: white,
    disabled: wildSand,
    error: white,
    success: themeColors.success,
  },
  border: {
    default: ghost,
    hover: mineShaft,
    focus: mineShaft,
    active: mineShaft,
    disabled: mercury,
    error: themeColors.danger,
    success: themeColors.success,
  },
  icon: {
    default: rollingStone,
    hover: rollingStone,
    focus: rollingStone,
    active: rollingStone,
    disabled: ghost,
    error: rollingStone,
    success: themeColors.success,
  },
};

export const dropdownColors = {
  default: 'transparent',
  hover: wildSand,
  focus: wildSand,
  active: wildSand,
  selected: turbo,
};

export const buttonColors = {
  primary: {
    bg: {
      default: turbo,
      hover: turbo30,
      focus: turbo,
      active: gold,
      disabled: catskillWhite,
      focusVisible: turbo,
    },
    label: {
      default: mineShaft,
      hover: mineShaft,
      focus: mineShaft,
      active: mineShaft,
      disabled: ghost,
      focusVisible: mineShaft,
    },
    outline: {
      default: 'transparent',
      hover: 'transparent',
      focus: 'transparent',
      active: 'transparent',
      disabled: 'transparent',
    },
    icon: {
      default: 'inherit',
      hover: 'inherit',
      focus: 'inherit',
      active: 'inherit',
      disabled: 'inherit',
      focusVisible: 'inherit',
    },
  },

  secondary: {
    bg: {
      default: white,
      hover: white,
      focus: white,
      active: white,
      disabled: white,
      focusVisible: white,
    },
    label: {
      default: mineShaft,
      hover: mineShaft,
      focus: mineShaft,
      active: mineShaft,
      disabled: ghost,
      focusVisible: mineShaft,
    },
    outline: {
      default: mercury,
      hover: mercury,
      focus: mineShaft,
      active: mineShaft,
      disabled: catskillWhite,
    },
    icon: {
      default: rollingStone,
      hover: mineShaft,
      focus: rollingStone,
      active: mineShaft,
      disabled: ghost,
      focusVisible: mineShaft,
    },
  },

  tertiary: {
    bg: {
      default: 'transparent',
      hover: 'transparent',
      focus: 'transparent',
      active: 'transparent',
      disabled: 'transparent',
      focusVisible: 'transparent',
    },
    label: {
      default: doveGray,
      hover: mineShaft,
      focus: doveGray,
      active: mineShaft,
      disabled: ghost,
      dark: white,
      focusVisible: mineShaft,
    },
    outline: {
      default: 'transparent',
      hover: 'transparent',
      focus: 'transparent',
      active: 'transparent',
      disabled: 'transparent',
    },
    icon: {
      default: doveGray,
      hover: mineShaft,
      focus: doveGray,
      active: mineShaft,
      disabled: ghost,
      dark: turbo,
      focusVisible: mineShaft,
    },
  },

  primaryDark: {
    bg: {
      default: mineShaft,
      hover: mineShaft,
      focus: mineShaft,
      active: olive,
      disabled: catskillWhite,
      focusVisible: olive,
    },
    label: {
      default: white,
      hover: white,
      focus: white,
      active: white,
      disabled: white,
      focusVisible: white,
    },
    outline: {
      default: 'transparent',
      hover: 'transparent',
      focus: 'transparent',
      active: 'transparent',
      disabled: 'transparent',
    },
    icon: {
      default: 'inherit',
      hover: 'inherit',
      focus: 'inherit',
      active: 'inherit',
      disabled: 'inherit',
      focusVisible: 'inherit',
    },
  },
};

export const contentColors = {
  listLabel: '#73777C',
  listValue: '#2D2D2D',
  borderFocus: '#2D2D2D',
  leadParagraphDesktop: grays[700],
};

export const toggleColors = {
  bg: {
    default: white,
    hover: turbo30,
    active: turbo,
    disabled: white,
  },
  label: {
    default: mineShaft,
    hover: mineShaft,
    active: mineShaft,
    disabled: ghost,
  },
  outline: {
    default: mercury,
    hover: turbo,
    active: turbo,
    disabled: catskillWhite,
  },
  icon: {
    default: rollingStone,
    hover: mineShaft,
    active: mineShaft,
    disabled: ghost,
  },
};

export const headingBlockColors = {
  headerText: grays[700],
  headerBackground: themeColors.primary,
};
