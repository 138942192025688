import { defineMessages } from 'react-intl';

export default defineMessages({
  annualCostText: {
    id: 'quoteWeeklyPrice.annualCostText',
    defaultMessage: 'One year protection for {annualCost}.{break}Cancel anytime',
  },
  weekEquivText: {
    id: 'quoteWeeklyPrice.weekEquivText',
    defaultMessage: 'per week{break}equiv.',
  },
});
