import styled from 'styled-components';
import { fontStyle } from '@whitelabel/helpers/style/type';
import { color } from '@whitelabel/helpers/style/colors';
import { mediaBreakpointDown, mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import Button from '../Button';

export const StyledBox = styled(Button)`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  text-align: center;
  background-color: ${color('white')};
  width: 100%;

  ${mediaBreakpointUp('md')`
    justify-content: center;
    flex-direction: column;
    padding: 1.5rem;
  `}

  ${mediaBreakpointUp('lg')`
    padding: 2rem;
  `}

  > .image-box-title {
    ${fontStyle('button')}
    display: flex;
    align-items: center;
    height: 2rem;
    margin-bottom: 0;
    white-space: normal;
    text-align: start;
    word-break: break-word;

    ${mediaBreakpointUp('md')`
      height: auto;
      text-align: center;
    `}
  }

  .image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    margin-inline-end: 1rem;
    /* for smaller screens we need to ensure the image and text are always aligned */
    ${mediaBreakpointDown('sm')`
      width: 5rem;
    `}
    ${mediaBreakpointUp('md')`
      margin-bottom: 1rem;
      margin-inline-end: 0;
    `}
    ${mediaBreakpointUp('lg')`
      height: 2.5rem;
    `}
    > .image-box-image {
      max-width: 5rem;
      max-height: 2rem;

      ${mediaBreakpointUp('lg')`
        max-width: 100%;
        max-height: 2.5rem;
      `}
    }
  }
`;
