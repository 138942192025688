import { Link } from 'react-router-dom';

interface IButton {
  link?: string;
  locale: string;
  handleClick?: () => void;
}

type ButtonProps =
  | {
      tag: 'a' | typeof Link;
      to: string;
    }
  | {
      tag: 'a' | typeof Link;
      href: string;
      rel: string;
      target: string;
    }
  | {
      onClick: () => void;
    };

export const getButtonProps = ({ handleClick, link, locale }: IButton): ButtonProps | void => {
  if (link) {
    return link.startsWith('/')
      ? {
          tag: Link,
          to: `/${locale}${link}`,
        }
      : {
          tag: 'a',
          href: link,
          rel: 'noopener noreferrer',
          target: '_blank',
        };
  }
  if (handleClick) {
    return {
      onClick: handleClick,
    };
  }
  return undefined;
};
