import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useIntl } from 'react-intl';
import { IButtonBlock } from '@whitelabel/helpers/types';
import { transformLink } from '../../../helpers/transform';
import ButtonBlock from '../ButtonBlock';
import { StyledCallout } from './styledCTACalloutBlock';

interface ICTACalloutBlockProps {
  button: IButtonBlock;
  text: string;
}

const CTACalloutBlock = ({ button, text }: ICTACalloutBlockProps): JSX.Element => {
  const intl = useIntl();

  return (
    <StyledCallout>
      <h3>{ReactHtmlParser(text, { transform: transformLink(intl) })}</h3>
      <ButtonBlock {...button} />
    </StyledCallout>
  );
};

export default CTACalloutBlock;
