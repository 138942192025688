import styled from 'styled-components';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { spacer, multiply } from '@whitelabel/helpers/style/units';

const Wrapper = styled.div`
  .card {
    margin-bottom: 0;
  }

  .row {
    margin-bottom: ${multiply(spacer, 0.5)};
    ${mediaBreakpointUp('md')`
        margin-bottom: ${multiply(spacer, 1)};
    `}
  }
`;

export { Wrapper };
