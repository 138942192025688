import styled, { css } from 'styled-components';
import { UncontrolledTooltip } from 'reactstrap';
import { zIndex } from '@whitelabel/helpers/style/zIndex';
import { fontStyle, fontWeight, fontFamily } from '@whitelabel/helpers/style/type';
import { gray, themeColor, color } from '@whitelabel/helpers/style/colors';
import { boxShadow } from '@whitelabel/helpers/style/box';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { get } from '@whitelabel/helpers/style/get';

export const StyledIcon = styled.span`
  z-index: ${zIndex('content')};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  margin-inline-start: 0.25rem;
  color: ${gray(600)};
  font-weight: ${fontWeight('black')};
  font-size: 0.75rem;
  font-family: ${fontFamily('base')};
  line-height: 0.875rem;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  > circle {
    stroke: ${gray(600)};
  }
  > rect {
    fill: ${gray(600)};
  }
  &:hover {
    background: ${themeColor('secondary')};
    border-color: ${themeColor('secondary')};
  }
  @media print {
    display: none;
  }
`;

export const StyledIconWrapper = styled.div`
  display: inline-block;
  margin: auto;
  align-items: center;
  justify-content: flex-end;
  svg {
    margin-bottom: 0.125rem;
  }
  ${mediaBreakpointUp('md')`
    justify-content: flex-start;
  `}
`;

export const StyledUncontrolledTooltip = styled(UncontrolledTooltip)`
  .tooltip {
    .tooltip-inner {
      ${fontStyle('paragraph')};
      padding: 0.875rem 1rem;
      color: ${themeColor('header')};
      background: ${color('white')};
      border: ${get('borderWidth')} solid ${gray(400)};
      box-shadow: ${boxShadow('base')};

      ${mediaBreakpointUp('lg')`
        padding: 1rem;
        font-size: 1.25rem;
        line-height: 1.6875rem;
      `}

      p:last-child {
        margin: 0;
      }
    }

    &.show {
      opacity: 1;
    }
  }

  .tooltip.bs-tooltip-auto {
    .arrow,
    .tooltip-arrow {
      margin: auto;

      &::before {
        height: 0.7rem;
        width: 0.7rem;
        background-color: ${color('white')};
        border: ${get('borderWidth')} solid ${gray(400)};
      }
    }
    &[data-popper-placement^='top'] .arrow {
      &::before {
        border-left: none;
        border-top: none;
        transform: translateY(0.6rem) rotate(45deg);
      }
    }

    &[data-popper-placement^='right'] .arrow {
      left: 0.3rem;
      &::before {
        border-right: none;
        border-top: none;
        transform: rotate(45deg);
      }
    }

    &[data-popper-placement^='bottom'] .arrow {
      &::before {
        border-right: none;
        border-bottom: none;
        transform: translateY(-0.6rem) rotate(45deg);
      }
    }

    &[data-popper-placement^='left'] .arrow {
      right: 0.35rem;

      &::before {
        border-left: none;
        border-bottom: none;
        transform: rotate(45deg);
      }
    }
  }

  ${({ backgroundColor, hasMaxWidth }) =>
    backgroundColor &&
    css`
  .tooltip {
    .tooltip-inner {
      max-width: ${hasMaxWidth ? `initial` : `none`};
      color: ${color('white')};
      background: ${themeColor(backgroundColor)};
      padding: 8px 16px;
    }

    &.bs-tooltip-auto {
      &[x-placement^='top'] .arrow::before {
        border-top-color: ${themeColor(backgroundColor)};
      }

      &[x-placement^='right'] .arrow::before {
        border-right-color: ${themeColor(backgroundColor)};
      }

      &[x-placement^='bottom'] .arrow {
        top: 12px;

        &::before {
          border-bottom-color: ${themeColor(backgroundColor)};
        }
      }

      &[x-placement^='left'] .arrow::before {
        border-left-color: ${themeColor(backgroundColor)};
      }
    }
  `}
`;
