import React from 'react';
import Accordion from '../../Accordion';
import BasicBlock from '../BasicBlock';
import { IArticleBlockProps } from './types';
import { StyledResponseSection } from './styledArticleBlock';

const ArticleBlock = (props: IArticleBlockProps): JSX.Element => {
  const {
    articles: { title: sectionTitle, items },
  } = props;
  const accordionItems = items.map((item) => {
    const {
      title,
      meta: { pageSlug },
      body: [{ value }],
    } = item;
    const contentBody = (
      <BasicBlock
        key={`accordion-item-${item.meta.pageSlug}`}
        spacing="sm"
        size="small"
        {...value[0]}
        fullWidth={false}
      />
    );
    return {
      title,
      anchorId: pageSlug,
      body: contentBody,
    };
  });

  return (
    <StyledResponseSection key={sectionTitle} className="response-section">
      <h3 className="section-header">{sectionTitle}</h3>
      <Accordion items={accordionItems} />
    </StyledResponseSection>
  );
};

export default ArticleBlock;
