import React, { useState, useCallback, Suspense } from 'react';
import { useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';
import { ReactComponent as ChevronDownIcon } from '@whitelabel/media/icons/small/chevron-down.svg';
import commonMsg from '@whitelabel/helpers/messages/commonMsg';
import SearchInput from '../../SearchInput';
import ImageCardButton from '../../ImageCardButton';
import {
  StyledBlockHeader,
  StyledBlockSubTitle,
  StyledShowAllButton,
  StyledDivider,
} from './styledPartnerLogoButtonGridBlock';
import messages from './messages';
import { IGridBox, IPartnerLogoButtonGridBlockProps } from './types';

const StyledGridContainer = React.lazy(() => import('./StyledGridContainer'));

const Loading = () => <div />;

const PartnerLogoButtonGridBlock = ({ title, gridBoxes, subTitle }: IPartnerLogoButtonGridBlockProps): JSX.Element => {
  const { formatMessage, bidi } = useIntl();
  const theme = useTheme();
  const [showAllPartners, setShowAllPartners] = useState(false);
  const toggleShowAllPartners = () => setShowAllPartners((prevState) => !prevState);
  const [filteredPartnerTitle, setFilteredPartnerTitle] = useState<string>('');

  const renderBlockGroup = (gridBoxesContent: IGridBox[]) =>
    gridBoxesContent
      .filter(({ subsidiary: { title: titleValue } }) => {
        if (!filteredPartnerTitle) {
          return true;
        }
        return titleValue.toLowerCase().includes(filteredPartnerTitle.toLowerCase());
      })
      .map(({ subsidiary: { slug, logo, title: titleValue } }: IGridBox) => (
        <ImageCardButton
          key={slug}
          title={titleValue}
          link={`/help/partners/${slug}`}
          image={logo}
          alt={formatMessage(commonMsg.logo, { brand: titleValue })}
        />
      ));

  const onSearchInputChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const filterredVal = e.currentTarget.value;
      setFilteredPartnerTitle(filterredVal);
      if (filterredVal && !showAllPartners) {
        setShowAllPartners(true);
      }
    },
    [setFilteredPartnerTitle, setShowAllPartners, showAllPartners],
  );

  let slicedBlockNumber = 6;
  const isDesktop = useMediaQuery({ minWidth: theme.gridBreakpoints.lg });
  if (isDesktop) {
    slicedBlockNumber = 5 * 4;
  }

  return (
    <div className="partner-logo-button-grid" id="partner-logo-buttons">
      <StyledBlockHeader>{title}</StyledBlockHeader>
      <StyledBlockSubTitle>{subTitle}</StyledBlockSubTitle>
      <SearchInput
        id="other-partners-search-input"
        onChange={onSearchInputChange}
        name="other-partners-search-input"
        placeholder={formatMessage(messages.searchPartners)}
        $rtl={bidi}
        fullWidth
        value={filteredPartnerTitle}
      />
      <StyledDivider />
      <Suspense fallback={<Loading />}>
        <StyledGridContainer layout="position" layoutId="mainWrapper">
          {renderBlockGroup(gridBoxes.slice(0, slicedBlockNumber))}
          {showAllPartners && renderBlockGroup(gridBoxes.slice(slicedBlockNumber))}

          {!showAllPartners && gridBoxes.length > slicedBlockNumber && (
            <StyledShowAllButton
              onClick={toggleShowAllPartners}
              icon={ChevronDownIcon}
              buttonType="secondary"
              $isIconOnBothSides
            >
              {formatMessage(messages.showAllPartners)}
            </StyledShowAllButton>
          )}
        </StyledGridContainer>
      </Suspense>
    </div>
  );
};

export default PartnerLogoButtonGridBlock;
