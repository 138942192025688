import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'updateAddressModal.header',
    defaultMessage: 'Update Address',
  },
  body: {
    id: 'updateAddressModal.body',
    defaultMessage: 'Enter the address of your property to ensure we can provide the protection you’re looking for.',
  },
  updateButton: {
    id: 'updateAddressModal.updateButton',
    defaultMessage: 'Update',
  },
  postcodeRequired: {
    id: 'updateAddressModal.postcodeRequired',
    defaultMessage: 'Please enter an address with a valid postal code',
  },
  locationNotSupported: {
    id: 'updateAddressModal.locationNotSupported',
    defaultMessage: 'Location not supported',
  },
});
