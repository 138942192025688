export function importIntlNumberFormatLocaleData(locale: string) {
  switch (locale) {
    case 'de':
      return import(
        '@formatjs/intl-numberformat/locale-data/de' /* webpackChunkName: 'intl-numberformat-locale-data-de' */
      );

    case 'ja':
      return import(
        '@formatjs/intl-numberformat/locale-data/ja' /* webpackChunkName: 'intl-numberformat-locale-data-ja' */
      );

    case 'es':
      return import(
        '@formatjs/intl-numberformat/locale-data/es' /* webpackChunkName: 'intl-numberformat-locale-data-es' */
      );

    case 'es-mx':
      return import(
        '@formatjs/intl-numberformat/locale-data/es-MX' /* webpackChunkName: 'intl-numberformat-locale-data-es-MX' */
      );

    case 'id':
      return import(
        '@formatjs/intl-numberformat/locale-data/id' /* webpackChunkName: 'intl-numberformat-locale-data-id' */
      );

    case 'ko':
      return import(
        '@formatjs/intl-numberformat/locale-data/ko' /* webpackChunkName: 'intl-numberformat-locale-data-ko' */
      );

    case 'ms':
      return import(
        '@formatjs/intl-numberformat/locale-data/ms' /* webpackChunkName: 'intl-numberformat-locale-data-ms' */
      );

    case 'ru':
      return import(
        '@formatjs/intl-numberformat/locale-data/ru' /* webpackChunkName: 'intl-numberformat-locale-data-ru' */
      );

    case 'th':
      return import(
        '@formatjs/intl-numberformat/locale-data/th' /* webpackChunkName: 'intl-numberformat-locale-data-th' */
      );

    case 'tr':
      return import(
        '@formatjs/intl-numberformat/locale-data/tr' /* webpackChunkName: 'intl-numberformat-locale-data-tr' */
      );

    case 'uk':
      return import(
        '@formatjs/intl-numberformat/locale-data/uk' /* webpackChunkName: 'intl-numberformat-locale-data-uk' */
      );

    case 'vi':
      return import(
        '@formatjs/intl-numberformat/locale-data/vi' /* webpackChunkName: 'intl-numberformat-locale-data-vi' */
      );

    case 'da':
      return import(
        '@formatjs/intl-numberformat/locale-data/da' /* webpackChunkName: 'intl-numberformat-locale-data-da' */
      );

    case 'fr':
      return import(
        '@formatjs/intl-numberformat/locale-data/fr' /* webpackChunkName: 'intl-numberformat-locale-data-fr' */
      );

    case 'it':
      return import(
        '@formatjs/intl-numberformat/locale-data/it' /* webpackChunkName: 'intl-numberformat-locale-data-it' */
      );

    case 'nl':
      return import(
        '@formatjs/intl-numberformat/locale-data/nl' /* webpackChunkName: 'intl-numberformat-locale-data-nl' */
      );

    case 'pl':
      return import(
        '@formatjs/intl-numberformat/locale-data/pl' /* webpackChunkName: 'intl-numberformat-locale-data-pl' */
      );

    case 'pt':
    case 'pt-br':
      return import(
        '@formatjs/intl-numberformat/locale-data/pt' /* webpackChunkName: 'intl-numberformat-locale-data-pt' */
      );

    case 'sv':
      return import(
        '@formatjs/intl-numberformat/locale-data/sv' /* webpackChunkName: 'intl-numberformat-locale-data-sv' */
      );

    case 'fi':
      return import(
        '@formatjs/intl-numberformat/locale-data/fi' /* webpackChunkName: 'intl-numberformat-locale-data-fi' */
      );

    case 'cs':
      return import(
        '@formatjs/intl-numberformat/locale-data/cs' /* webpackChunkName: 'intl-numberformat-locale-data-cs' */
      );

    case 'bg':
      return import(
        '@formatjs/intl-numberformat/locale-data/bg' /* webpackChunkName: 'intl-numberformat-locale-data-bg' */
      );

    case 'ca':
      return import(
        '@formatjs/intl-numberformat/locale-data/ca' /* webpackChunkName: 'intl-numberformat-locale-data-ca' */
      );

    case 'hr':
      return import(
        '@formatjs/intl-numberformat/locale-data/hr' /* webpackChunkName: 'intl-numberformat-locale-data-hr' */
      );

    case 'et':
      return import(
        '@formatjs/intl-numberformat/locale-data/et' /* webpackChunkName: 'intl-numberformat-locale-data-et' */
      );

    case 'el':
      return import(
        '@formatjs/intl-numberformat/locale-data/el' /* webpackChunkName: 'intl-numberformat-locale-data-el' */
      );

    case 'hu':
      return import(
        '@formatjs/intl-numberformat/locale-data/hu' /* webpackChunkName: 'intl-numberformat-locale-data-hu' */
      );

    case 'lv':
      return import(
        '@formatjs/intl-numberformat/locale-data/lv' /* webpackChunkName: 'intl-numberformat-locale-data-lv' */
      );

    case 'lt':
      return import(
        '@formatjs/intl-numberformat/locale-data/lt' /* webpackChunkName: 'intl-numberformat-locale-data-lt' */
      );

    case 'ro':
      return import(
        '@formatjs/intl-numberformat/locale-data/ro' /* webpackChunkName: 'intl-numberformat-locale-data-ro' */
      );

    case 'sr':
      return import(
        '@formatjs/intl-numberformat/locale-data/sr' /* webpackChunkName: 'intl-numberformat-locale-data-sr' */
      );

    case 'sk':
      return import(
        '@formatjs/intl-numberformat/locale-data/sk' /* webpackChunkName: 'intl-numberformat-locale-data-sk' */
      );

    case 'sl':
      return import(
        '@formatjs/intl-numberformat/locale-data/sl' /* webpackChunkName: 'intl-numberformat-locale-data-sl' */
      );

    case 'sw':
      return import(
        '@formatjs/intl-numberformat/locale-data/sw' /* webpackChunkName: 'intl-numberformat-locale-data-sw' */
      );

    case 'is':
      return import(
        '@formatjs/intl-numberformat/locale-data/is' /* webpackChunkName: 'intl-numberformat-locale-data-is' */
      );

    case 'fil':
      return import(
        '@formatjs/intl-numberformat/locale-data/fil' /* webpackChunkName: 'intl-numberformat-locale-data-fil' */
      );

    case 'ar':
      return import(
        '@formatjs/intl-numberformat/locale-data/ar' /* webpackChunkName: 'intl-numberformat-locale-data-ar' */
      );

    case 'he':
      return import(
        '@formatjs/intl-numberformat/locale-data/he' /* webpackChunkName: 'intl-numberformat-locale-data-he' */
      );

    case 'mt':
      return import(
        '@formatjs/intl-numberformat/locale-data/mt' /* webpackChunkName: 'intl-numberformat-locale-data-mt' */
      );

    case 'az':
      return import(
        '@formatjs/intl-numberformat/locale-data/az' /* webpackChunkName: 'intl-numberformat-locale-data-az' */
      );

    case 'ka':
      return import(
        '@formatjs/intl-numberformat/locale-data/ka' /* webpackChunkName: 'intl-numberformat-locale-data-ka' */
      );

    case 'gl':
      return import(
        '@formatjs/intl-numberformat/locale-data/gl' /* webpackChunkName: 'intl-numberformat-locale-data-gl' */
      );

    case 'eu':
      return import(
        '@formatjs/intl-numberformat/locale-data/eu' /* webpackChunkName: 'intl-numberformat-locale-data-eu' */
      );

    case 'fr-ca':
      return import(
        '@formatjs/intl-numberformat/locale-data/fr-CA' /* webpackChunkName: 'intl-numberformat-locale-data-fr-CA' */
      );

    case 'zh-hant':
      return import(
        '@formatjs/intl-numberformat/locale-data/zh-Hant' /* webpackChunkName: 'intl-numberformat-locale-data-zh-Hant' */
      );

    case 'zh-hans':
      return import(
        '@formatjs/intl-numberformat/locale-data/zh-Hans' /* webpackChunkName: 'intl-numberformat-locale-data-zh-Hans' */
      );

    case 'no':
      // Norwegian bokmål (nb) is the dominant language in Norway
      return import(
        '@formatjs/intl-numberformat/locale-data/nb' /* webpackChunkName: 'intl-numberformat-locale-data-nb' */
      );

    default:
      return import(
        '@formatjs/intl-numberformat/locale-data/en' /* webpackChunkName: 'intl-numberformat-locale-data-en' */
      );
  }
}
