import { Moment } from 'moment';
import { xcoverAPI } from '@whitelabel/xcover-shared/store/services/xcover/index';
import { processKeys } from '@whitelabel/xcover-shared/helpers/api';
import { getAPIHost } from '@whitelabel/xcover-shared/helpers/multiRegion';
import { IQuote } from '@whitelabel/helpers/types';
import { POLICY_TYPE } from '@whitelabel/helpers/constants';
import { POSTCODE_PARAM, RTK_CACHE_LIFE } from '@whitelabel/xcover-shared/helpers/constants';
import { appendExtraSearchParams } from '@whitelabel/xcover-shared/helpers/utils';
import { storeKWAgentInfo } from '@whitelabel/xcover-shared/helpers/hubspot';
import { IQuotePackage } from '../../../../helpers/types';

export interface ICreateQuoteResponse {
  id: string;
  securityToken: string;
  quotes: any[];
}

export interface ICreateQueryQueryProps {
  body: {
    partner_code?: string;
    destination_country?: string;
    policy_start_date?: string | Moment;
    currency?: string;
    country?: string;
    policy_type?: string;
    policy_type_version?: string;
    total_tickets_price?: number;
    number_of_adults?: number;
    number_of_children?: number;
    number_of_infants?: number;
    insured?: Record<string, unknown>[];
  };
  locale: string;
}
export interface IUpdateQuoteResponse {
  id: string;
}
export interface IUpdateQuoteProps {
  ins: string;
  locale: string;
  securityToken: string;
  fields: Record<string, any>;
  extraSearchParams?: Record<string, any>;
}

export interface IGetQuote {
  id: string;
  securityToken: string;
  locale: string;
}

interface IQuotesData {
  all: string[];
  quotes: Record<string, IQuote>;
  next: string;
}

export interface IGetCustomerQuotes {
  id: string;
  locale: string;
}

const processQuotesResponse = (response: any) => {
  const { results, next } = processKeys(response);
  // need to move client side if migrating to next js
  if (results[0]) storeKWAgentInfo(results[0]);
  const all: string[] = [];
  const quotes = results.reduce((allQuotes: any, quote: any) => {
    all.push(quote.id);
    return { ...allQuotes, [quote.id]: quote };
  }, {});

  return {
    quotes,
    all,
    next,
  };
};

export const quotesAPI = xcoverAPI.injectEndpoints({
  endpoints: (builder) => ({
    updateQuote: builder.mutation<IUpdateQuoteResponse, IUpdateQuoteProps>({
      query: ({ ins, fields, locale, securityToken, extraSearchParams }) => {
        const searchParams = new URLSearchParams({ language: locale, security_token: securityToken });
        if (extraSearchParams) {
          appendExtraSearchParams(searchParams, extraSearchParams);
        }

        return {
          url: `${getAPIHost(ins)}/quotes/${ins}/?${searchParams}`,
          method: 'PATCH',
          body: fields,
          headers: {
            'Content-Type': 'application/json',
          },
        };
      },
      transformResponse: (response: IUpdateQuoteResponse) => processKeys(response),
      invalidatesTags: ['Quote'],
    }),
    createQuote: builder.mutation<ICreateQuoteResponse, ICreateQueryQueryProps>({
      query: ({ body, locale }) => ({
        url: `${getAPIHost()}/quotes/?language=${locale}`,
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          auth: 'false',
        },
      }),
      transformResponse: (response: ICreateQuoteResponse) => {
        if (
          !response.quotes[0]?.attributes.category &&
          response.quotes[0]?.policy.policy_type === POLICY_TYPE.CYBER_INSURANCE
        ) {
          response.quotes[0].attributes.category = POLICY_TYPE.CYBER_INSURANCE;
        }

        return processKeys(response);
      },
    }),

    getQuote: builder.query<IQuotePackage, IGetQuote>({
      query: ({ id, locale, securityToken }) => {
        // need to move client side if migrating to next js
        const urlParams = new URLSearchParams(window.location.search);
        const postcodeParam = urlParams.get(POSTCODE_PARAM);
        if (postcodeParam) {
          sessionStorage.setItem(
            'quoteValues',
            JSON.stringify({
              address: {
                country: 'AU',
                postcode: atob(postcodeParam),
              },
            }),
          );
        }
        const searchParams = new URLSearchParams({ language: locale, security_token: securityToken });
        return {
          url: `${getAPIHost(id)}/quotes/${id}/?${searchParams}`,
          headers: {
            auth: 'false',
          },
        };
      },
      transformResponse: (response: IQuotePackage) => processKeys(response),
      providesTags: ['Quote'],
    }),

    getCustomerQuotes: builder.query<IQuotesData, IGetCustomerQuotes>({
      query: ({ id, locale }) => {
        const searchParams = new URLSearchParams({
          language: locale,
        });
        return `${getAPIHost()}/customers/${id}/quotes/?${searchParams}`;
      },
      transformResponse: (response: IQuotesData) => processQuotesResponse(response),
      keepUnusedDataFor: RTK_CACHE_LIFE,
      merge: (cache, newData) => {
        cache.all = [...cache.all, ...newData.all];
        cache.next = newData.next;
        Object.assign(cache.quotes, newData.quotes);
      },
      // No forceRefetch function since we don't need to refetch data when jump across different pages.
    }),
  }),
});

export const {
  useCreateQuoteMutation,
  useUpdateQuoteMutation,
  useGetQuoteQuery,
  useGetCustomerQuotesQuery,
  useLazyGetCustomerQuotesQuery,
} = quotesAPI;

export const clearQuotes = () => quotesAPI.util.resetApiState();
