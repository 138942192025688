import React from 'react';
import { Row, Col } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import { useIntl } from 'react-intl';
import { IImageBlock, IXCMSImage, Alignment } from '@whitelabel/helpers/types';
import { transformLink } from '../../../helpers/transform';
import { StyledTitle } from '../HeadingBlock/styledHeadingBlock';
import ButtonsBlock from '../ButtonsBlock';
import TrustPilot from '../../TrustPilot';
import {
  StyledHeroBlock,
  StyledWrapperContainer,
  StyledLeadInfoContainer,
  StyledNonMobileBGContainer,
  StyledMobileBGContainer,
  StyledImagesBlock,
  StyledTitleContainer,
  StyledCol,
  StyledWrapper,
  StyledLeadText,
  StyledRow,
} from './styledHeroBlock';
import DropdownLinkBlock from './DropdownLinksBlock';

interface IHeroBlockProps {
  title: string;
  leadText?: string;
  backgroundImage?: IXCMSImage;
  supportingImages?: {
    alignment?: Alignment;
    images?: IImageBlock[];
  };
  /*
    (ts-migrate) TODO: Migrate the remaining prop types
    ...contentBlockPropTypes
    */
  ctaOptions: any[];
  className?: string;
  id?: string;
  trustpilotCounter?: string;
  dataTestId?: string;
  shouldTitleBeUppercase?: boolean;
}

const HeroBlock = ({
  title,
  leadText = '',
  backgroundImage,
  supportingImages,
  ctaOptions,
  className,
  id = '',
  trustpilotCounter,
  dataTestId,
  shouldTitleBeUppercase = true,
}: IHeroBlockProps): JSX.Element => {
  const intl = useIntl();
  const bgImageForMobile = backgroundImage?.fileForMobile || backgroundImage?.file;
  const bgImageForTablet = backgroundImage?.fileForTablet || backgroundImage?.file;
  const bgImageForDesktop = backgroundImage?.file;

  const renderTitle = () => (
    <StyledTitle $shouldTitleBeUppercase={shouldTitleBeUppercase}>
      <span data-test-id={dataTestId ? `${dataTestId}-heading` : ''}>
        {ReactHtmlParser(title, { transform: transformLink(intl) })}
      </span>
    </StyledTitle>
  );

  const renderLeadText = () => (
    <StyledLeadText data-test-id={dataTestId ? `${dataTestId}-leadText` : ''}>
      {ReactHtmlParser(leadText, { transform: transformLink(intl) })}
    </StyledLeadText>
  );

  const renderCTAOptions = () => (
    <StyledWrapper>
      {ctaOptions.map(({ type, value }: any) =>
        type === 'dropdown_links' ? (
          <DropdownLinkBlock key={type} {...value} />
        ) : (
          <ButtonsBlock key={type} {...value} />
        ),
      )}
    </StyledWrapper>
  );

  const renderImagesBlock = () =>
    supportingImages &&
    supportingImages.images &&
    !!supportingImages.images.length && <StyledImagesBlock images={supportingImages.images} />;

  const renderTrustpilot = () => {
    if (!trustpilotCounter) return null;
    return <TrustPilot trustpilotCounter={trustpilotCounter} />;
  };

  const renderOneColumnLayout = () => (
    <StyledWrapperContainer>
      <StyledRow>
        <StyledMobileBGContainer $rtl={intl.bidi} $backgroundImageUrl={bgImageForMobile}>
          <StyledTitleContainer>
            <Col sm="9">{renderTitle()}</Col>
          </StyledTitleContainer>
        </StyledMobileBGContainer>
      </StyledRow>
      <StyledLeadInfoContainer>
        <Row>
          <StyledCol sm="7">{renderLeadText()}</StyledCol>
        </Row>
        <Row>
          <StyledCol sm="8">
            {renderCTAOptions()}
            {renderImagesBlock()}
            {renderTrustpilot()}
          </StyledCol>
        </Row>
      </StyledLeadInfoContainer>
    </StyledWrapperContainer>
  );

  return (
    <StyledHeroBlock id={id} className={className} data-test-id={dataTestId}>
      <StyledNonMobileBGContainer
        $rtl={intl.bidi}
        $backgroundImageUrl={bgImageForTablet}
        $bgImageForDesktop={bgImageForDesktop}
      >
        {renderOneColumnLayout()}
      </StyledNonMobileBGContainer>
    </StyledHeroBlock>
  );
};

export default HeroBlock;
