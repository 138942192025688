import styled from 'styled-components';
import { Input } from 'reactstrap';
import { fontSize } from '@whitelabel/helpers/style/type';
import { getLeftValue } from '@whitelabel/helpers/style/box';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { get } from '@whitelabel/helpers/style/get';
import { inputColors } from '@whitelabel/global-theme/colors';

export const StyledCurrencyAmountInputBox = styled.div`
  display: flex;
  position: relative;
  max-width: 100%;

  ${mediaBreakpointUp('sm')`
    max-width: fit-content;
  `}
`;
export const StyledAmountInput = styled(Input)<{ $currencyCodeWidth: number }>`
  && {
    direction: inherit;
    border-start-end-radius: 0;
    border-end-end-radius: 0;
    border-inline-end-width: 0;
    border-inline-end-color: transparent;

    padding: 0.5rem ${({ $currencySymbolWidth }) => `${(20 + ($currencySymbolWidth as number)) / 16}rem`};
    appearance: textfield;

    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      margin: 0;
      appearance: none;
    }

    ${mediaBreakpointUp('md')`
      padding: 0.5rem
        ${({ $currencySymbolWidth }: { $currencySymbolWidth: number }) => `${(24 + $currencySymbolWidth) / 16}rem`};
    `}
    :hover {
      border: ${get('borderWidth')} solid ${inputColors.border.hover};
      cursor: pointer;
    }
  }
`;

export const StyledCurrencySymbol = styled.span<{ $rtl: boolean }>`
  position: absolute;
  top: 0.5rem;
  color: ${inputColors.icon.default};
  font-size: 1.125rem;

  ${mediaBreakpointUp('lg')`
      font-size: ${fontSize('lg')};
    `}

  ${getLeftValue('0.75rem')}

  ${mediaBreakpointUp('md')`
      ${getLeftValue('1rem')};
    `}
`;
