import { css } from 'styled-components';
import { transition } from '@whitelabel/helpers/style/animation';
import { fontStyle, fontWeight, fontSize, fontFamily, lineHeight } from '@whitelabel/helpers/style/type';
import { gray, themeColor } from '@whitelabel/helpers/style/colors';
import { borderRadius } from '@whitelabel/helpers/style/box';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { spacer, multiply } from '@whitelabel/helpers/style/units';

const globalTypeStyle = css`
  ${({ theme }) => {
    const image =
      `data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg'` +
      ` width='8' height='8' viewBox='0 0 8 8' fill='none'%3E%3Ccircle cx='4' cy='4' ` +
      `r='4' fill='${theme.themeColors.primary}'/%3E%3C/svg%3E`;
    return css`
      ul {
        list-style-image: url('${image.replace(/#/g, '%23')}');
      }
    `;
  }};

  h1,
  .h1 {
    ${fontStyle('baseHeader')}
    margin-bottom: ${multiply(spacer, 0.25)};
    text-transform: uppercase;

    ${mediaBreakpointUp('md')`
      margin-bottom: ${multiply(spacer, 0.5)};
    `};
  }

  h2,
  .h2 {
    ${fontStyle('h2')}
    margin-bottom: ${multiply(spacer, 0.5)};
    text-transform: uppercase;

    ${mediaBreakpointUp('md')`
      margin-bottom: ${multiply(spacer, 1.5)};
    `}
  }

  h3,
  .h3 {
    ${fontStyle('h3')}
    margin-bottom: ${spacer};
    text-transform: uppercase;
  }

  h4,
  .h4 {
    ${fontStyle('h4')}
  }
  h5,
  .h5 {
    ${fontStyle('h5')}
  }
  h6,
  .h6 {
    ${fontStyle('h6')}
  }

  p {
    ${fontStyle('paragraph')}
  }

  .lead {
    color: ${gray(600)};
    ${fontStyle('lead')}
  }

  a {
    color: ${themeColor('info')};
    text-decoration: none;
    box-shadow: none;
    transition: ${transition('base')};

    &:hover {
      color: ${themeColor('info')};
      text-decoration: none;
      box-shadow: 0 -2px 0 0 ${themeColor('info')} inset;
    }
  }

  b,
  strong {
    font-weight: ${fontWeight('bold')};
  }

  ul,
  ol {
    padding-inline-start: ${multiply(spacer, 1.25)};
  }

  li {
    padding-inline-start: ${multiply(spacer, 0.5)};
  }

  dt,
  dd {
    margin-bottom: ${multiply(spacer, 0.5)};
    font-weight: ${fontWeight('base')};
  }

  dt {
    color: ${gray(600)};
    font-size: ${fontSize('md')};
    font-family: ${fontFamily('serif')};
    line-height: ${lineHeight('base')};
  }

  label {
    font-weight: ${fontWeight('bold')};
    font-size: ${fontSize('lg')};
  }

  img {
    max-width: 100%;
    height: auto;
  }

  ::selection {
    background: ${themeColor('selection')};
  }

  pre,
  code,
  :-ms-keyboard-active,
  samp {
    font-family: ${fontFamily('monospace')};
  }

  kbd {
    border-radius: ${borderRadius('base')};
  }

  hr {
    opacity: inherit;
  }

  html,
  body {
    -webkit-font-smoothing: antialiased;

    color: ${gray(700)};
    font-family: ${fontFamily('base')};
  }

  .text-align-center {
    text-align: center;
  }
`;

export default globalTypeStyle;
