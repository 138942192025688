import { createContext } from 'react';

interface IPDSPolicyContext {
  policyType?: string;
  policyVersion?: string;
  appendPDSUTMSearchParams: boolean;
}

export const PDSPolicyContext = createContext<IPDSPolicyContext>({ appendPDSUTMSearchParams: false });

export function formatAttributes(attribs: object) {
  let result = '';
  if (attribs) {
    result = Object.entries(attribs)
      .map(([name, value]) => `${name}="${value}"`)
      .join(' ');
  }
  return result;
}

export function formatChildren(children: any) {
  let result = '';
  children.forEach((element: any) => {
    if (element.type === 'text') {
      result += element.data;
    } else if (element.type === 'tag') {
      result += `<${element.name} ${formatAttributes(element.attribs)}>${formatChildren(element.children)}</${
        element.name
      }>`;
    }
  });
  return result;
}

export const addPDSUTMSearchParams = ({ href, pdsUTMCampaign }: { href: string; pdsUTMCampaign: string }) => {
  const hrefURL = new URL(href, window.location.origin);
  const hrefSearchParams = hrefURL.searchParams;
  if (!hrefSearchParams.get('utm_source')) {
    hrefSearchParams.append('utm_source', 'xcms');
  }
  if (!hrefSearchParams.get('utm_medium')) {
    hrefSearchParams.append('utm_medium', 'pds');
  }

  if (!hrefSearchParams.get('utm_campaign') && pdsUTMCampaign) {
    hrefSearchParams.append('utm_campaign', pdsUTMCampaign);
  }

  return hrefURL.toString().replace(hrefURL.origin, '');
};
