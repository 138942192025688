import React, { useMemo, useState } from 'react';
import { Col, CardBody } from 'reactstrap';
import { useIntl } from 'react-intl';
import commonMessages from '@whitelabel/helpers/messages/commonMsg';
import { ReactComponent as IconArrowLeft } from '@whitelabel/media/icons/small/arrow-left.svg';
import { IXCMSImage } from '@whitelabel/helpers/types';
import Button from '../../Button';
import BasicBlock from '../BasicBlock';
import HeadingBlock from '../HeadingBlock';
import StyledCard from '../../../styled/StyledCard';
import {
  StyledCarouselItemNumber,
  StyledCarouselFooter,
  StyledCarouselItemHeaderSection,
  StyledCarouselItemHeader,
  StyledCarouselIndicators,
  StyledCarouselItem,
  StyledCarouselItemDivider,
  StyledCarousel,
} from './styledCarouselBlock';

export interface ISlide {
  title: string;
  content: string;
  image: IXCMSImage;
}
export interface ICarouselBlockProps {
  slides: ISlide[];
  className?: string;
}

const CarouselBlock = ({ slides, className }: ICarouselBlockProps): JSX.Element => {
  const intl = useIntl();
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === slides.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? slides.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex: number) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const carouselItems = useMemo(
    () =>
      slides.map((item: ISlide) => (
        <StyledCarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={item.title}>
          <StyledCarouselItemHeaderSection>
            <StyledCarouselItemHeader xs={12} md={7}>
              <StyledCarouselItemNumber>
                {intl.formatMessage(commonMessages.countRange, {
                  activeIndex: activeIndex + 1,
                  total: slides.length,
                })}
              </StyledCarouselItemNumber>
              <HeadingBlock headingTag="h4" alignment="start" title={item.title} />
            </StyledCarouselItemHeader>
            <Col xs={{ size: 10, offset: 1 }} md={{ size: 5, offset: 0 }}>
              <img src={item.image.file} alt={item.title} />
            </Col>
          </StyledCarouselItemHeaderSection>
          <BasicBlock type="rich_text" value={item.content} className="slideContent" />
          <StyledCarouselItemDivider />
        </StyledCarouselItem>
      )),
    [activeIndex, intl, slides],
  );

  const carouselFooter = (
    <StyledCarouselFooter>
      <Col xs={{ order: 3, size: 12 }} md={{ order: 1, size: 4 }}>
        <Button buttonType="secondary" onClick={previous} icon={IconArrowLeft} disabled={activeIndex === 0}>
          {intl.formatMessage(commonMessages.backButtonLabel)}
        </Button>
      </Col>
      <Col xs={{ order: 1, size: 12 }} md={{ order: 2, size: 4 }}>
        <StyledCarouselIndicators items={slides} activeIndex={activeIndex} onClickHandler={goToIndex} />
      </Col>
      <Col xs={{ order: 2, size: 12 }} md={{ order: 3, size: 4 }}>
        <Button onClick={next} disabled={activeIndex === slides.length - 1}>
          {intl.formatMessage(commonMessages.nextButtonLabel)}
        </Button>
      </Col>
    </StyledCarouselFooter>
  );

  return (
    <StyledCard className={className} data-test-id="CardBlock">
      <CardBody>
        <StyledCarousel
          activeIndex={activeIndex}
          next={next}
          previous={previous}
          interval={0}
          className="carousel-fade"
        >
          {carouselItems}
          {carouselFooter}
        </StyledCarousel>
      </CardBody>
    </StyledCard>
  );
};

export default CarouselBlock;
