import styled, { css } from 'styled-components';
import { Table, List } from 'reactstrap';
import { fontStyle } from '@whitelabel/helpers/style/type';
import { gray } from '@whitelabel/helpers/style/colors';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { getThemeProp } from '@whitelabel/helpers/style/get';
import Alert from '../Alert';

export const StyledTable = styled(Table)`
  margin: 0;

  .table-head-container,
  .table-head-cell,
  .table-body-cell {
    border-bottom: ${getThemeProp('borderStyle')};
  }

  .table-head-cell {
    ${fontStyle('tableHeader')}
    color: ${gray(600)};
    height: 2.5rem;
    padding: 0 0.5rem;
    vertical-align: text-top;
  }

  .table-body-cell {
    height: 3.5rem;
    padding: 0.5rem;
    color: ${gray(700)};
    vertical-align: middle;
  }
`;

export const StyledList = styled(List).attrs({
  type: 'unstyled',
})`
  margin: 0;

  .list-item {
    padding: 0;
    border-bottom: ${getThemeProp('borderStyle')};
    &:not(:last-child) {
      margin-inline: 0;
      margin-top: 0;
      margin-bottom: 1rem;
    }

    ${({ $alertMessage }: { $alertMessage: boolean }) =>
      $alertMessage &&
      css`
        &:last-child {
          border-bottom: 0;
        }
      `}
  }

  .list-item-row {
    margin-bottom: 1rem;
  }

  .list-head-cell,
  .list-body-cell {
    ${fontStyle('base')}
    margin: 0;
  }

  .list-head-cell {
    color: ${gray(600)};
  }
  .list-body-cell {
    color: ${gray(700)};
  }
`;

export const StyledAlert = styled(Alert)`
  margin-top: 1rem;
  margin-bottom: 0;

  ${mediaBreakpointUp('sm')`
    margin-top: 1.5rem;
  `}
`;
