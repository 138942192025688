import React from 'react';
import { Helmet } from 'react-helmet';
import { useTheme } from 'styled-components';
import { useIntl } from 'react-intl';
import { IPageMeta, ILocales } from '@whitelabel/helpers/types';
import { isOnServer } from '@whitelabel/helpers/utils';
import { locales as siteLocales } from '@whitelabel/helpers/locale';
import { PROTOCOL_DOMAIN } from '@whitelabel/helpers/url';

interface IPageMetaProps extends IPageMeta {
  siteName: string;
  isTranslated?: boolean;
  locales?: Record<string, ILocales>;
  messageDisplayMode?: boolean;
}

const handleLocalization = (locale: string, locales: Record<string, ILocales>, pathname: string): JSX.Element[] =>
  Object.values(locales).map(({ code }) => (
    <link
      key={code}
      rel="alternate"
      href={`${PROTOCOL_DOMAIN}${pathname.replace(`/${locale}`, `/${code}`)}`}
      hrefLang={code}
    />
  ));

const PageMeta = ({
  title,
  seoTitle,
  searchDescription,
  socialTitle,
  socialDescription,
  socialImage,
  ogType,
  isTranslated = true,
  script,
  siteName,
  locales = siteLocales,
  messageDisplayMode = false,
}: IPageMetaProps): JSX.Element => {
  const {
    themeColors: { primary },
  } = useTheme();
  const pathname = !isOnServer ? window.location.pathname : '';
  const { locale, bidi } = useIntl();

  return (
    <Helmet defaultTitle={siteName} titleTemplate={`%s | ${siteName}`}>
      <html lang={locale} dir={bidi ? 'rtl' : 'ltr'} />

      {(seoTitle || title) && !messageDisplayMode && <title>{seoTitle || title}</title>}
      {searchDescription && <meta name="description" content={searchDescription} />}

      <link rel="mask-icon" href="/static/safari-pinned-tab.svg" color={primary} />
      <meta name="theme-color" content={primary} />

      <meta property="og:url" content={`${PROTOCOL_DOMAIN}${pathname}`} />
      {(socialTitle || seoTitle || title) && <meta property="og:title" content={socialTitle || seoTitle || title} />}
      {(socialDescription || searchDescription) && (
        <meta property="og:description" content={socialDescription || searchDescription} />
      )}
      {socialImage && <meta property="og:image" content={socialImage} />}
      {ogType && <meta property="og:type" content={ogType} />}
      <link rel="canonical" href={`${PROTOCOL_DOMAIN}${pathname}`} />
      {isTranslated && handleLocalization(locale, locales, pathname)}
      {bidi && (
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.rtl.min.css"
          integrity="sha384-gXt9imSW0VcJVHezoNQsP+TNrjYXoGcrqBZJpry9zJt8PCQjobwmhMGaDHTASo9N"
          crossOrigin="anonymous"
        />
      )}
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'http://schema.org',
          '@type': 'Organization',
          url: PROTOCOL_DOMAIN,
          name: siteName,
        })}
      </script>
      {script && script.map(({ src, ...props }) => <script key={src} {...props} />)}
    </Helmet>
  );
};

export default PageMeta;
