import styled from 'styled-components';
import { rem } from 'polished';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';

export const StyledTitle = styled.h2`
  margin-bottom: 1rem;
  ${mediaBreakpointUp('md')`
    margin-bottom: 1.5rem;
  `}

  ${mediaBreakpointUp('lg')`
    margin-bottom: 2rem;
  `}
`;

export const StyledResourceWrapper = styled.div`
  padding-top: 1rem;

  ${mediaBreakpointUp('md')`
    padding-top: 2rem;
  `}

  ${mediaBreakpointUp('lg')`
    padding-top: 4rem;
  `}
`;

export const StyledResourceContainer = styled.div`
  display: grid;
  grid-gap: 2rem;

  ${mediaBreakpointUp('sm')`
    grid-template-columns: repeat(auto-fill, minmax(${rem(545)}, 1fr));
  `}
`;

export const StyledSubtitle = styled.h4`
  margin-bottom: 0.5rem;
`;

export const StyledRichText = styled.div`
  p {
    margin-bottom: 0.5rem;
  }
`;
