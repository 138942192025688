import { css } from 'styled-components';
import { mediaBreakpointUp, gridBreakpoint, setAllGridGutterWidths } from '@whitelabel/helpers/style/grid';

const globalGridStyle = css`
  .container {
    max-width: ${gridBreakpoint('xl')};
  }

  .container,
  .container-fluid {
    ${setAllGridGutterWidths(['padding-inline-end', 'padding-inline-start'])}
  }

  .row {
    ${setAllGridGutterWidths(['margin-inline-end', 'margin-inline-start'], -0.5)}
  }

  .col,
  [class*='col-'] {
    ${setAllGridGutterWidths(['padding-inline-end', 'padding-inline-start'], 0.5)}
  }

  .offset-lg-2 {
    ${mediaBreakpointUp('lg')`
      margin-inline-start: 16.66%;
      margin-inline-end: 0;
    `}
  }

  .offset-lg-1 {
    ${mediaBreakpointUp('lg')`
      margin-inline-start: 8.33%;
      margin-inline-end: 0;
    `}
  }

  .hide-on-print {
    @media print {
      display: none;
    }
  }

  .show-on-print-only {
    display: none;
    @media print {
      display: block;
    }
  }

  @media print {
    @page {
      size: a3;
    }
    .container {
      min-width: ${gridBreakpoint('lg')} !important;
      padding: 0 1.5rem !important;
    }
    body {
      min-width: ${gridBreakpoint('lg')} !important;
    }
    .navbar {
      display: none !important;
    }
    .alert a {
      &:not(.btn) {
        text-decoration: none;
      }
    }
  }
`;

export default globalGridStyle;
