import React from 'react';
import { ISuggest, ILocation, ReactStateSetter } from '@whitelabel/helpers/types';

/**
 * Attributes allowed on input elements
 */
const allowedAttributes: string[] = [
  'autoCapitalize',
  'autoComplete',
  'autoCorrect',
  'autoFocus',
  'disabled',
  'form',
  'formAction',
  'formEncType',
  'formMethod',
  'formNoValidate',
  'formTarget',
  'height',
  'inputMode',
  'maxLength',
  'name',
  'onClick',
  'onContextMenu',
  'onCopy',
  'onCut',
  'onDoubleClick',
  'onMouseDown',
  'onMouseEnter',
  'onMouseLeave',
  'onMouseMove',
  'onMouseOut',
  'onMouseOver',
  'onMouseUp',
  'onPaste',
  'pattern',
  'placeholder',
  'readOnly',
  'required',
  'size',
  'spellCheck',
  'tabIndex',
  'title',
  'aria-atomic',
  'aria-busy',
  'aria-controls',
  'aria-current',
  'aria-describedby',
  'aria-details',
  'aria-disabled',
  'aria-dropeffect',
  'aria-errormessage',
  'aria-flowto',
  'aria-grabbed',
  'aria-haspopup',
  'aria-hidden',
  'aria-invalid',
  'aria-keyshortcuts',
  'aria-label',
  'aria-labelledby',
  'aria-live',
  'aria-owns',
  'aria-relevant',
  'aria-roledescription',
  'aria-activedescendant',
  'aria-autocomplete',
  'aria-multiline',
  'aria-placeholder',
  'aria-readonly',
  'aria-required',
];

interface IProps {
  [key: string]: any;
}

/**
 * Filter the properties for only allowed input properties
 */
export const filterInputAttributes = (props: IProps): { [key: string]: any } => {
  const attributes: { [key: string]: any } = {};

  Object.keys(props).forEach((attribute) => {
    const isDataAttribute = attribute.startsWith('data-');
    const isAllowedAttribute = allowedAttributes.includes(attribute);

    if (isAllowedAttribute || isDataAttribute) {
      attributes[attribute] = props[attribute];
    }
  });

  return attributes;
};

/**
 * Makes a text bold
 */
const makeBold = (element: string, key: string): JSX.Element => (
  <b className="geosuggest__item__matched-text" key={key}>
    {element}
  </b>
);
/**
 * Replace matched text with the same in bold
 */
export const formatMatchedText = (userInput: string, suggest: ISuggest): JSX.Element | string => {
  if (!userInput || !suggest.matchedSubstrings) {
    return suggest.label;
  }

  const start = suggest.matchedSubstrings.offset;
  const { length } = suggest.matchedSubstrings;
  const end = start + length;
  const boldPart = makeBold(suggest.label.substring(start, end), suggest.label);

  let pre = '';
  let post = '';

  if (start > 0) {
    pre = suggest.label.slice(0, start);
  }
  if (end < suggest.label.length) {
    post = suggest.label.slice(end);
  }

  return (
    <span>
      {pre}
      {boldPart}
      {post}
    </span>
  );
};

interface ISetUserInputTextArgs {
  suggest: ILocation;
  enableFNOLUserInput?: boolean;
  setUserInput: ReactStateSetter<string>;
}

const setUserInputText = ({ suggest, enableFNOLUserInput, setUserInput }: ISetUserInputTextArgs): void => {
  const userInputText = enableFNOLUserInput ? suggest.mainText ?? suggest.label : suggest.label;
  setUserInput(typeof suggest.label !== 'object' ? userInputText : suggest.description || '');
};

/**
 * Geocode a suggest
 */
interface IGeocodeSuggestArgs {
  suggestToGeocode: ISuggest;
  geocoder: React.MutableRefObject<google.maps.Geocoder | undefined>;
  placesService: React.MutableRefObject<google.maps.places.PlacesService | undefined>;
  sessionToken: React.MutableRefObject<google.maps.places.AutocompleteSessionToken | undefined>;
  googleMaps: React.MutableRefObject<typeof google.maps | undefined>;
  placeDetailFields?: string[] | null;
  bounds?: google.maps.LatLngBounds | google.maps.LatLngBoundsLiteral;
  country?: string;
  location?: google.maps.LatLng;
  enableFNOLUserInput?: boolean;
  setUserInput: ReactStateSetter<string>;
  onSuggestSelect?: (suggest?: ILocation) => void;
}

export const geocodeSuggest = ({
  suggestToGeocode,
  geocoder,
  placesService,
  sessionToken,
  googleMaps,
  placeDetailFields,
  bounds,
  country,
  location,
  enableFNOLUserInput,
  setUserInput,
  onSuggestSelect,
}: IGeocodeSuggestArgs): void => {
  if (!geocoder) {
    return;
  }

  if (suggestToGeocode.placeId && placesService.current) {
    const options: google.maps.places.PlaceDetailsRequest = {
      placeId: suggestToGeocode.placeId,
      sessionToken: sessionToken.current,
    };

    if (placeDetailFields) {
      options.fields = placeDetailFields;
      options.fields.unshift('geometry');
    }

    placesService.current.getDetails(options, (results, status) => {
      if (status === googleMaps.current?.places.PlacesServiceStatus.OK && results) {
        const gmaps = results;
        const locationValue = (gmaps.geometry && gmaps.geometry.location) as google.maps.LatLng;
        const suggest = {
          ...suggestToGeocode,
          gmaps,
          location: {
            lat: locationValue.lat(),
            lng: locationValue.lng(),
          },
        };

        setUserInputText({ suggest, enableFNOLUserInput, setUserInput });

        sessionToken.current = new google.maps.places.AutocompleteSessionToken();
        if (onSuggestSelect) {
          onSuggestSelect(suggest);
        }
      }
    });
  } else {
    const options: google.maps.GeocoderRequest = {
      address: suggestToGeocode.label,
      bounds,
      componentRestrictions: country ? { country } : undefined,
      location,
    };

    geocoder.current?.geocode(options, (results, status) => {
      if (status === googleMaps.current?.GeocoderStatus.OK && results) {
        const gmaps = results[0];
        const locationValue = gmaps.geometry && gmaps.geometry.location;
        const suggest = {
          ...suggestToGeocode,
          gmaps,
          location: {
            lat: locationValue.lat(),
            lng: locationValue.lng(),
          },
        };

        setUserInputText({ suggest, enableFNOLUserInput, setUserInput });

        if (onSuggestSelect) {
          onSuggestSelect(suggest);
        }
      }
    });
  }
};
