import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { mediaBreakpointUp, gridGutterWidth, breakpointKeys } from '@whitelabel/helpers/style/grid';
import ImageBlock from '../ImageBlock';
import HeadingBlock from '../HeadingBlock';

export const StyledIconListWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledIconListBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem 1rem;
  padding: 0 0.5rem;
  ${mediaBreakpointUp('sm')`
    padding: 0 2rem;
  `}
  ${mediaBreakpointUp('md')`
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem 1.5rem;
    padding: 0;
  `}
  ${mediaBreakpointUp('lg')`
    gap: 4rem 2rem;
  `}
`;

// Disable eslint to keep the original logic: not pass no-unused-vars
// props down to child component.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledImageBlock = styled(({ withMargin, ...props }) => <ImageBlock {...props} />)`
  max-height: 3.75rem;

  ${mediaBreakpointUp('lg')`
    max-height: 6rem;
  `}

  ${({ withMargin }) =>
    withMargin &&
    css`
      margin-bottom: 0.5rem;
    `}
`;

StyledImageBlock.propTypes = {
  withMargin: PropTypes.bool,
};

StyledImageBlock.defaultProps = {
  withMargin: true,
};

export const StyledIconTitle = styled.h4`
  margin-bottom: 0;
`;

export const StyledCol = styled(({ ...props }) => <Col {...props} />)`
  text-align: center;
`;

export const StyledHeading = styled(HeadingBlock)`
  [data-test-id='Heading-title'] {
    margin-bottom: 0.5rem;
  }

  [data-test-id='Heading-leadText'] {
    padding: 0 5%;
    ${mediaBreakpointUp('sm')`
      padding: 0;
    `}
  }
  ${({ theme }) =>
    breakpointKeys(theme).map(
      (breakpoint) =>
        mediaBreakpointUp(breakpoint)`
        margin-bottom: ${gridGutterWidth(breakpoint)};
    `,
    )}
`;
