import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { ICustomer } from '@whitelabel/helpers/types';
import { IError } from '@whitelabel/xcover-shared/helpers/types';
import {
  checkCustomer,
  getCustomer,
  getCustomerEmail,
  getEmailUpdates,
  processCustomerFeedback,
  sendSignUpVerification,
  transferBookings,
  updateBookingGDPR,
  updateCustomer,
  updateCustomerGDPR,
  validateEmail,
  validateSignUpToken,
} from '../actions/customer';

export interface ICustomerState {
  data: null | ICustomer;
  loading: boolean;
  emailUpdated: boolean;
  error: IError | null;
  validateSignUpTokenError: IError | null;
  customerChecked: boolean;
  signUpVerificationSentType: null | 'email' | 'phone';
  isSignupTokenValid: boolean;
  cookieYesModalActioned?: boolean;
}

export const initialState: ICustomerState = {
  data: null,
  loading: false,
  emailUpdated: false,
  error: null,
  validateSignUpTokenError: null,
  customerChecked: false,
  signUpVerificationSentType: null,
  isSignupTokenValid: false,
  cookieYesModalActioned: false,
};

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    clearCustomer: () => initialState,
    cookieYesModalActioned: (state) => {
      state.cookieYesModalActioned = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkCustomer.pending, (state) => {
        state.loading = true;
        state.customerChecked = false;
        state.error = null;
      })
      .addCase(sendSignUpVerification.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.signUpVerificationSentType = null;
      })
      .addCase(validateSignUpToken.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.validateSignUpTokenError = null;
      })
      .addCase(updateCustomer.pending, (state) => {
        state.error = null;
        state.loading = false;
      })

      .addCase(validateSignUpToken.fulfilled, (state, action) => {
        state.data = { ...state.data, ...action.payload };
        state.isSignupTokenValid = true;
      })
      .addCase(processCustomerFeedback.fulfilled, (state) => {
        state.data!.feedbackGiven = true;
      })
      .addCase(sendSignUpVerification.fulfilled, (state, action) => {
        state.signUpVerificationSentType = action.payload as 'email' | 'phone';
      })
      .addCase(getEmailUpdates.fulfilled, (state, action) => {
        state.data!.pendingEmail = action.payload;
      })
      .addCase(validateEmail.fulfilled, (state, action) => {
        state.emailUpdated = action.payload;
      })
      .addCase(checkCustomer.rejected, (state, action) => {
        state.data = null;
        state.error = action.payload as IError;
      })
      .addCase(validateSignUpToken.rejected, (state, action) => {
        state.validateSignUpTokenError = action.payload as IError;
      })
      .addCase(getCustomer.pending, (state, action) => {
        // don't trigger loading when calling with 'getXpayCustomerId' to avoid UI bug (loading icon appear twice in profile page)
        if (!action.meta.arg.getXpayCustomerId) {
          state.loading = true;
        }
        state.error = null;
      })
      .addMatcher(isAnyOf(updateCustomerGDPR.fulfilled, updateBookingGDPR.fulfilled), (state, action) => {
        if (state.data && action.payload) {
          state.data = { ...state.data, gdprConsent: action.payload.gdprConsent };
        }
      })
      .addMatcher(
        isAnyOf(transferBookings.pending, getCustomerEmail.pending, updateCustomerGDPR.pending, validateEmail.pending),
        (state) => {
          state.loading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isAnyOf(checkCustomer.fulfilled, getCustomer.fulfilled, getCustomerEmail.fulfilled, updateCustomer.fulfilled),
        (state, action) => {
          state.data = { ...state.data, ...action.payload };
        },
      )
      .addMatcher(
        isAnyOf(
          sendSignUpVerification.rejected,
          getCustomer.rejected,
          getCustomerEmail.rejected,
          updateCustomer.rejected,
          updateCustomerGDPR.rejected,
          validateEmail.rejected,
        ),
        (state, action) => {
          state.error = action.payload as IError;
        },
      )
      .addMatcher(
        isAnyOf(
          sendSignUpVerification.fulfilled,
          sendSignUpVerification.rejected,
          getCustomer.fulfilled,
          getCustomer.rejected,
          getCustomerEmail.fulfilled,
          getCustomerEmail.rejected,
          updateCustomer.fulfilled,
          updateCustomer.rejected,
          updateCustomerGDPR.fulfilled,
          updateCustomerGDPR.rejected,
          transferBookings.fulfilled,
          transferBookings.rejected,
          validateEmail.fulfilled,
          validateEmail.rejected,
          validateSignUpToken.fulfilled,
          validateSignUpToken.rejected,
        ),
        (state) => {
          state.loading = false;
        },
      )
      .addMatcher(isAnyOf(checkCustomer.fulfilled, checkCustomer.rejected), (state) => {
        state.loading = false;
        state.customerChecked = true;
      });
  },
});

export const { clearCustomer, cookieYesModalActioned } = customerSlice.actions;

export const customer = customerSlice.reducer;
