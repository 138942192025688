import styled, { css } from 'styled-components';
import { Container, Col, Row } from 'reactstrap';
import { backgroundStyles, backgroundImage } from '@whitelabel/helpers/style/utils';
import { fontStyle } from '@whitelabel/helpers/style/type';
import { gray, color } from '@whitelabel/helpers/style/colors';
import { mediaBreakpointUp, mediaBreakpointOnly, marginBottom } from '@whitelabel/helpers/style/grid';
import { spacer } from '@whitelabel/helpers/style/units';
import { StyledTitle } from '../HeadingBlock/styledHeadingBlock';
import ImagesBlock from '../ImagesBlock';
import { StyledImageBox } from '../ImagesBlock/styledImagesBlock';

export const StyledHeroBlock = styled.div`
  ${marginBottom}
`;

export const StyledBgContentContainer = styled(Container)`
  &&.container {
    padding-top: 0;
  }
`;

export const StyledTitleContainer = styled.div`
  position: relative;

  ${mediaBreakpointOnly('xs')`
    padding:1rem;
    > div{
      padding-inline:0;
    }
  `}
`;

export const StyledRow = styled(Row)`
  &.row {
    ${mediaBreakpointOnly('xs')`
      --bs-gutter-x: 0;
      margin-inline:0
    `}
  }
`;

export const StyledWrapperContainer = styled(Container)`
  display: grid;
  margin: auto;

  && {
    ${mediaBreakpointOnly('xs')`
      padding-inline:0;
    `}
  }

  > :last-child {
    margin-bottom: 0;
  }
`;

export const StyledLeadInfoContainer = styled.div`
  padding-inline: 1rem;

  ${mediaBreakpointUp('sm')`
    padding-inline:0;
  `}

  > :last-child {
    margin-bottom: 0;
  }
`;

export const StyledCol = styled(Col)<{ $justifyContentCenter: boolean }>`
  position: relative;

  ${StyledTitle} {
    margin-right: 0;
  }

  /* center align content with flex */
  ${({ $justifyContentCenter }) =>
    $justifyContentCenter &&
    mediaBreakpointUp('md')`
      display: flex;
      flex-direction: column;
      justify-content: center;
  `}

  ${mediaBreakpointUp('lg')`
    ${StyledTitle} {
      line-height: 4.625rem;
    }
  `}

  > div {
    ${mediaBreakpointOnly('xs')`
      box-shadow:none;
    `}
  }

  > :last-child {
    margin-bottom: 0;
  }
`;

export const StyledWrapper = styled.div`
  ${marginBottom}
`;

export const StyledImagesBlock = styled(ImagesBlock)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${mediaBreakpointUp('sm')`
    justify-content: flex-start;
  `}

  &&& ${StyledImageBox} {
    display: inline-block;
    width: auto;
    max-width: 50%;
    margin-top: 0;
    padding: 0 ${spacer} 0 0;
  }
`;

export const StyledMobileBGContainer = styled.div<{
  $rtl: boolean;
  $backgroundImageUrl: string | undefined;
}>`
  ${({ $rtl }) =>
    $rtl &&
    css`
      ${mediaBreakpointOnly('xs')`
        position: relative;
      `}
    `}

  ${({ $backgroundImageUrl }) =>
    !$backgroundImageUrl &&
    css`
      ${mediaBreakpointOnly('xs')`
        background: ${color('white')};
      `}
    `}

  ${mediaBreakpointOnly('xs')`
    display: flex;
    align-items: center;
    min-height: 11.25rem;
    overflow: inherit;
    margin-bottom: 1rem;

    .container {
      padding: 1rem;
    }

    h1 {
      margin-bottom: 0;
    }
    ${backgroundStyles}
    ${backgroundImage()}
  `}

  ${mediaBreakpointUp('sm')`
    padding:0;
  `}
`;

export const StyledNonMobileBGContainer = styled.div<{
  $rtl: boolean;
  $backgroundImageUrl: string | undefined;
  $bgImageForDesktop: string | undefined;
}>`
  ${({ $rtl }) =>
    $rtl &&
    css`
      ${mediaBreakpointUp('sm')`
        position: relative;
      `}
    `}

  ${mediaBreakpointUp('sm')`
    display: flex;
    align-items: center;
    min-height: 11.25rem;
    overflow: inherit;
    padding: 3rem 0;
    min-height: 20.625rem;
    ${backgroundStyles}
    ${backgroundImage()}
  `}

  ${({ $backgroundImageUrl }) =>
    !$backgroundImageUrl &&
    css`
      ${mediaBreakpointUp('sm')`
        background: ${color('white')};
      `}
    `}

  ${mediaBreakpointUp('lg')`
    ${({ $bgImageForDesktop }: { $bgImageForDesktop: string | undefined }) =>
      $bgImageForDesktop
        ? css`
            ${backgroundImage($bgImageForDesktop)}
          `
        : css`
            background: ${color('white')};
          `}
  `}

  ${mediaBreakpointUp('md')`
    min-height: 25.5rem;
  `}

  ${mediaBreakpointUp('lg')`
    padding: 5rem 0;
    min-height: 32rem;
  `}
`;

export const StyledLeadText = styled.div.attrs(() => ({ className: 'lead' }))`
  &&& {
    color: ${gray(600)};
    ${mediaBreakpointOnly('xs')`
      margin-bottom: 1rem;
    `}

    ${mediaBreakpointUp('sm')`
      color: ${gray(700)};
      margin-bottom: 1.5rem;
    `}

    ${mediaBreakpointUp('lg')`
      margin-bottom: 2rem;
    `}

    p {
      ${fontStyle('lead')}
    }

    *:last-child {
      margin-bottom: 0;
    }
  }
`;
