import React from 'react';
import type { IXCMSImage } from '@whitelabel/helpers/types/xcmsTypes';
import { StyledImageWrapper, StyledMobileImageWrapper } from './styledBaseImageBlock';

export interface IBaseImageBlockProps {
  image: IXCMSImage;
  altText?: string;
  mobileImage: IXCMSImage | null;
}

const BaseImageBlock = ({ image, altText, mobileImage }: IBaseImageBlockProps) => (
  <>
    <StyledImageWrapper $hasMobileImage={!!mobileImage} src={image.file} alt={altText || image.title} loading="lazy" />
    {mobileImage && (
      <StyledMobileImageWrapper src={mobileImage.file} alt={altText || mobileImage.title} loading="lazy" />
    )}
  </>
);

export default BaseImageBlock;
