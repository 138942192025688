import { connect } from 'react-redux';
import { createIntl, IntlCache, IntlConfig, IntlProvider, IntlShape } from 'react-intl';
import { shallowEqual } from 'react-intl/src/utils';
import { renderMessageWithID } from '../../helpers/i18n';
import { ICustomIntlProviderState } from './typesCustomIntlProvider';

function getDerivedStateFromPropsOverride(
  props: IntlConfig,
  { prevConfig, cache }: { prevConfig: Record<string, any>; cache: IntlCache },
): Partial<ICustomIntlProviderState> | null {
  // Override is here. To let bidi prop pass through.
  // Original here https://github.com/formatjs/formatjs/blob/bf915ea22650ca91444dc228f4bbe7957da2924d/packages/react-intl/src/components/provider.tsx#L166
  const config = props;
  if (!shallowEqual(prevConfig, config)) {
    const MESSAGE_DISPLAY_MODE = process.env.REACT_APP_MESSAGE_DISPLAY_MODE;
    const intl = createIntl(config, cache);
    if (MESSAGE_DISPLAY_MODE) renderMessageWithID(intl);
    return {
      intl,
      prevConfig: config,
    };
  }
  return null;
}

function defaultSelector(state: { intl: IntlShape }) {
  const { intl } = state;
  IntlProvider.getDerivedStateFromProps = getDerivedStateFromPropsOverride;
  return {
    key: intl.locale,
    ...intl,
  };
}

const mapStateToProps = (state: any, { intlSelector = defaultSelector }) => intlSelector(state);

export default connect<{ locale: string }, any, any>(mapStateToProps)(IntlProvider);
