import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { zIndex, increasedZIndex } from '@whitelabel/helpers/style/zIndex';
import { fontStyle, fontWeight, fontSize, lineHeight } from '@whitelabel/helpers/style/type';
import { themeColor, transparentizeColor, color } from '@whitelabel/helpers/style/colors';
import { borderRadius, boxShadow, getLeftValue } from '@whitelabel/helpers/style/box';
import { mediaBreakpointDown, mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { multiply } from '@whitelabel/helpers/style/units';
import { getThemeProp } from '@whitelabel/helpers/style/get';
import { inputColors } from '@whitelabel/global-theme/colors';

interface IStyledSingleDatePickerInputProps {
  readonly $focused: boolean;
  readonly $invalid?: boolean;
  $rtl: boolean;
  $hasSingleDateQuestion?: boolean;
}

const selectedDateCircle = css`
  ::before {
    content: '';
    z-index: ${zIndex('behindContent')};
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    top: 0.4375rem;
    right: 0.5rem;
    border-radius: 50%;
  }
`;

export const weekHeaderFontWeight = css`
  .DayPicker_weekHeader_li {
    font-weight: 450;
  }
`;

export const calendarDayFontWeight = css`
  font-weight: 500;
`;

export const StyledSingleDatePickerInput = styled.div<IStyledSingleDatePickerInputProps>`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${mediaBreakpointUp('sm')`
    flex-direction: row;
    align-items: center;
  `}
  .DateInput {
    border-radius: ${borderRadius('base')};
    width: 100%;
  }
  .DateInput_input {
    padding: ${rem(7)} 0.75rem ${rem(7)} 0.5rem;
    font-weight: ${fontWeight('normal')};
    font-size: ${multiply(fontSize('base'), 1.125)};
    line-height: ${lineHeight('base')};
    border-bottom: none;
    cursor: pointer;
    text-align: center;
    border-radius: ${borderRadius('base')};
    height: 100%;

    ${mediaBreakpointUp('md')`
      padding-inline-end: 0.5rem;
    `}
    ${mediaBreakpointUp('lg')`
      font-size: ${fontSize('lg')};
    `}
  }
  .DayPicker__withBorder {
    box-shadow: ${boxShadow('md')};
    border: ${getThemeProp('borderStyle')};
  }

  ${weekHeaderFontWeight}
  .DayPicker_weekHeader_li {
    padding-inline-start: 0;
    ${fontStyle('h6')}
  }

  .DateInput_input__focused {
    border-bottom: none;
  }
  .CalendarDay__default {
    border-width: 0;
    color: ${color('outerSpace')};
    font-size: 1rem;
    position: relative;
    z-index: ${zIndex('content')};
    &:hover:not(.CalendarDay__blocked_out_of_range, .CalendarDay__selected) {
      background: none;
      ${selectedDateCircle}
      ::before {
        background-color: ${transparentizeColor(0.5, themeColor('primary'))};
      }
    }
    &:focus-visible {
      outline: ${inputColors.border.active} auto 1px;
    }
    ${calendarDayFontWeight}
  }
  .CalendarDay__selected {
    color: ${inputColors.value.active};
    background: none;
    border: 0;
    font-weight: ${fontWeight('bold')};
    z-index: ${zIndex('content')};
    ${selectedDateCircle}
    ::before {
      background-color: ${themeColor('primary')};
    }
  }

  .CalendarDay__blocked_out_of_range {
    color: ${inputColors.border.default};
    &:hover {
      background: none;
    }
  }

  .SingleDatePickerInput {
    display: flex;
    border: ${getThemeProp('borderWidth')} solid
      ${({ $focused, $invalid }) => {
        if ($invalid) {
          return inputColors.border.error;
        }
        if ($focused) {
          return inputColors.border.focus;
        }
        return inputColors.border.default;
      }};
    border-radius: ${borderRadius('base')};

    ${mediaBreakpointUp('sm')`
      width: 12.5rem;

    ${({ $hasSingleDateQuestion }: IStyledSingleDatePickerInputProps) =>
      $hasSingleDateQuestion ? `height: ${rem(60)};` : `height: ${rem(46)};`}

    `}

    &:hover {
      border: ${getThemeProp('borderWidth')} solid ${inputColors.border.hover};
    }
  }
  .SingleDatePickerInput__disabled {
    border: 1px solid ${inputColors.border.disabled};
    background-color: ${inputColors.background.disabled};

    .DateInput_input__disabled {
      background-color: ${inputColors.background.disabled};
    }

    &:hover {
      border: 1px solid ${inputColors.border.disabled};
    }
  }

  .SingleDatePicker {
    width: 100%;
    height: 100%;

    ${mediaBreakpointUp('sm')`
      width: auto;
    `}
  }

  .SingleDatePickerInput_calendarIcon {
    display: flex;
    align-items: center;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-inline-start: 0.75rem;
    padding-inline-end: 0;

    ${mediaBreakpointUp('md')`
      padding-top: 0;
      padding-bottom: 0;
      padding-inline-start: 1rem;
      padding-inline-end: 0;
    `}

    &:focus-visible {
      outline: none;
    }
  }
  .SingleDatePickerInput_calendarIcon_svg {
    width: 1rem;
    height: 1rem;
  }

  .SingleDatePicker_picker {
    z-index: ${zIndex('contentLayer')};
    ${getLeftValue('0')}
  }
  .DateInput_fang {
    ${getLeftValue('-16px')}
    z-index: ${increasedZIndex('contentLayer')};
    transform: translateY(-1.5px) scaleY(1.5);
    ${({ $hasSingleDateQuestion }) =>
      $hasSingleDateQuestion &&
      css`
        ${mediaBreakpointDown('xs')`
          ${getLeftValue('3.25rem')}
        `}
      `}
  }

  .DayPickerNavigation_button {
    position: relative;
  }

  .DayPickerNavigation__horizontal {
    .navSvg {
      color: ${inputColors.value.default};
      position: absolute;
      top: 1.5rem;
      width: 1.25rem;
      height: 1.25rem;
      line-height: 1.25;
    }

    .navPrev {
      left: 1.5rem;
    }

    .navNext {
      right: 1.5rem;
    }
  }
`;

export const StyledDateTimeSeparator = styled.span`
  display: none;
  ${mediaBreakpointUp('md')`
    display: block;
  `}
  &:before {
    content: '-';
  }
`;
