import { Label } from 'reactstrap';
import styled, { css } from 'styled-components';
import { fontStyle } from '@whitelabel/helpers/style/type';
import { inputColors } from '@whitelabel/global-theme/colors';

export const StyledLabel = styled(Label)<{ $disabled: boolean }>`
  ${fontStyle('formLabel')}

  & > .tooltip-icon {
    margin-inline-start: 0.25rem;
    margin-bottom: 0.25rem;
    cursor: pointer;
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${inputColors.label.disabled};
    `}
`;
