import React from 'react';
import { useIntl } from 'react-intl';
import { getButtonProps } from './helper';
import {
  StyledGridBlockButton,
  StyledGridBlockTitle,
  StyledBlockIcon,
  StyledBlockDescription,
  StyledTextContainer,
} from './styledIconCardButton';

export interface IIconCardButton {
  link?: string;
  handleClick?: () => void;
  svgIcon: any;
  title: string;
  description: string;
}

const IconCardButton = ({ handleClick, link, svgIcon, title, description }: IIconCardButton): JSX.Element => {
  const { locale } = useIntl();

  const renderContent = () => (
    <>
      <StyledBlockIcon as={svgIcon} className="svg-icon" />
      <StyledTextContainer>
        <StyledGridBlockTitle>{title}</StyledGridBlockTitle>
        <StyledBlockDescription>{description}</StyledBlockDescription>
      </StyledTextContainer>
    </>
  );
  return (
    <>
      <StyledGridBlockButton role="button" buttonType="secondary" {...getButtonProps({ link, locale, handleClick })}>
        {renderContent()}
      </StyledGridBlockButton>
    </>
  );
};

export default IconCardButton;
