import React from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as IconPlus } from '@whitelabel/media/icons/small/icon-32-circle-plus.svg';
import { ReactComponent as IconMinus } from '@whitelabel/media/icons/small/icon-32-circle-minus.svg';
import { StyledInputGroup, StyledButton } from './styledQuantityInput';
import messages from './messages';

export interface IQuantityInput {
  className?: string | null;
  name: string;
  maxLimit?: number;
  value: number;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const QuantityInput = ({ className = null, name, maxLimit, value, setFieldValue }: IQuantityInput) => {
  const intl = useIntl();
  const handleReduce = () => {
    if (+value > 0) {
      const updatedVal = +value - 1;
      setFieldValue(name, updatedVal);
    }
  };
  const handlePlus = () => {
    if (maxLimit && +value === maxLimit) {
      return;
    }
    setFieldValue(name, +value + 1);
  };

  return (
    <StyledInputGroup className={className ?? ''}>
      <StyledButton
        data-test-id="QuantityInput-decrease-button"
        aria-label={`${name} ${intl.formatMessage(messages.quantityDecreaseBtnAriaLabel)}`}
        buttonType="secondary"
        onClick={handleReduce}
        icon={IconMinus}
        disabled={+value === 0}
      />
      <span className="value">{value}</span>
      <StyledButton
        aria-label={`${name} ${intl.formatMessage(messages.quantityIncreaseBtnAriaLabel)}`}
        data-test-id="QuantityInput-increase-button"
        onClick={handlePlus}
        icon={IconPlus}
        disabled={!!maxLimit && +value === maxLimit}
      />
    </StyledInputGroup>
  );
};
export default QuantityInput;
