import styled from 'styled-components';
import { rem } from 'polished';
import { gray, themeColor } from '@whitelabel/helpers/style/colors';
import { grays } from '@whitelabel/global-theme/colors';

export const StyledToggleSliderContainer = styled.div`
  // Modifiable
  --container-width: ${rem(250)};
  --selected-circle-size: ${rem(23)};
  --inactive-circle-size: ${rem(20)};
  --line-height: ${rem(4)};
  --circle-box-shadow: 0 ${rem(4)} ${rem(8)} rgba(0, 0, 0, 0.3);
  --focus-ring: 0 0 0 ${rem(3)} rgba(0, 123, 255, 0.25);

  // Computed
  --toggle-height: var(--selected-circle-size);
  --toggle-width: calc(var(--container-width) * 0.6);
  --negative-toggle-height: calc(var(--toggle-height) * -1);

  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: var(--container-width);
  // Since we're artificially stacking rows so the hitzones
  // align with the toggle we have to pull up the margin
  margin-bottom: var(--negative-toggle-height);

  // Hidden labels to click on, covering the whole toggle container
  .hitzone-labels {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: var(--toggle-height);

    label {
      width: 50%;
      height: var(--toggle-height);
      z-index: 1;
      top: 0;
      bottom: 0;
      cursor: pointer;

      &.disabled {
        cursor: auto;
      }

      &.left {
        left: 0;
      }
      &.right {
        right: 0;
      }
    }
  }

  .toggle {
    display: inline-flex;
    align-items: center;
    position: relative;
    top: var(--negative-toggle-height);
    width: var(--toggle-width);
    height: var(--toggle-height);

    // Allow keyboard navigation while hiding default radios
    input {
      height: 0;
      width: 0;
      border: none;
      &:focus {
        outline: 0;
        box-shadow: none;

        // Apply focus styles to the adjacent circle, not the hidden default radios
        & ~ .selected-circle {
          box-shadow: var(--circle-box-shadow), var(--focus-ring);
        }
      }
      &:checked {
        background-color: transparent;
        border-color: transparent;
      }
    }

    .selected-circle {
      position: absolute;
      top: calc(0 + calc(var(--selected-circle-size) / 2));
      left: 0;
      width: var(--selected-circle-size);
      height: var(--selected-circle-size);
      background-color: ${themeColor('success')};
      box-shadow: var(--circle-box-shadow);
      border: ${rem(3)} solid white;
      border-radius: 50%;
      transition: left 0.3s ease;
      z-index: 1;

      &.disabled {
        display: none;
      }
    }
    // When the second radio is checked, move the circle to the right side
    input:nth-of-type(2):checked ~ .selected-circle {
      left: calc(var(--toggle-width) - var(--selected-circle-size));
    }

    .line {
      position: absolute;
      height: var(--line-height);
      top: calc(0 + calc(var(--line-height) / 2));
      left: 0;
      right: 0;
      background-color: ${gray(300)};
      z-index: 0;

      // Add the circles to the line's ends
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: var(--inactive-circle-size);
        height: var(--inactive-circle-size);
        background-color: inherit;
        border-radius: 50%;
      }
      &.hovered-left::before {
        background-color: ${themeColor('success')};
      }
      &.hovered-right::after {
        background-color: ${themeColor('success')};
      }
      &::before {
        left: 0;
      }
      &::after {
        right: 0;
      }
    }
  }

  // Actual labels below the toggle
  .visible-labels {
    position: relative;
    display: flex;
    top: var(--negative-toggle-height);
    width: var(--container-width);

    label {
      padding-top: 0.5rem;
      width: calc(var(--container-width) / 2);
      cursor: pointer;
      font-size: 1rem;
      color: ${grays[600]};
      font-weight: 500;
      text-align: center;

      &.selected:not(.disabled) {
        color: ${grays[700]};
        font-weight: 900;
      }

      &.disabled {
        cursor: auto;
      }
    }
  }
`;
