import { Button } from 'reactstrap';
import styled, { css } from 'styled-components';
import { rem } from 'polished';

import { gray, themeColor } from '@whitelabel/helpers/style/colors';

export const StepperButton = styled(Button)<{ $active: boolean }>`
  width: 1rem !important;
  height: 1rem;
  margin: 0 1rem;
  padding: 0;
  border-radius: 50%;

  background-color: ${({ $active }) => ($active ? themeColor('primary') : 'transparent')};
  border: 1px solid ${({ $active }) => ($active ? themeColor('primary') : gray(500))};

  ${({ $active }) =>
    $active &&
    css`
      div {
        height: ${rem('6px')};
        width: ${rem('6px')};
        margin: auto;
        border-radius: 50%;
        background-color: black;
      }
    `};

  &:focus-visible {
    outline: black solid 1px;
  }
`;

export const StepperButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
