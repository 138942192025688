import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { BOOKING_ID } from '@whitelabel/xcover-shared/helpers/constants';
import type { RootState } from '../../store';
import { hasCookieYesActioned, hasCookieYesMarketingAccepted } from '../utils';
import { updateBookingGDPR, updateCustomerGDPR } from '../../store/actions/customer';
import { useAppDispatch } from '../../store/hooks';
import { cookieYesModalActioned } from '../../store/slices/customer';

export const useCookieYesEventListener = () => {
  const user = useSelector(({ user: { data } }: RootState) => data);
  const customer = useSelector(({ customer: { data } }: RootState) => data);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleGDPRConsent = () => {
      if (!hasCookieYesActioned()) {
        return;
      }

      if (hasCookieYesMarketingAccepted()) {
        if (user && customer) {
          dispatch(updateCustomerGDPR({ id: customer.id as string, gdprConsent: true }));
        } else {
          const storedINS = localStorage.getItem(BOOKING_ID);
          if (storedINS) {
            dispatch(updateBookingGDPR({ ins: storedINS, gdprConsent: true }));
          }
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (user && customer) {
          dispatch(updateCustomerGDPR({ id: customer.id as string, gdprConsent: false }));
        } else {
          const storedINS = localStorage.getItem(BOOKING_ID);
          if (storedINS) {
            dispatch(updateBookingGDPR({ ins: storedINS, gdprConsent: false }));
          }
        }
      }
      dispatch(cookieYesModalActioned());
    };

    document.addEventListener('cookieyes_consent_update', handleGDPRConsent as unknown as EventListener);

    return () => {
      document.removeEventListener('cookieyes_consent_update', handleGDPRConsent as unknown as EventListener);
    };
  }, [customer, user, dispatch]);
};
