import React from 'react';
import styled, { css } from 'styled-components';
import { Input, FormText, FormGroup } from 'reactstrap';
import { fontSize } from '@whitelabel/helpers/style/type';
import { spacer, multiply } from '@whitelabel/helpers/style/units';
import { get } from '@whitelabel/helpers/style/get';
import { inputColors } from '@whitelabel/global-theme/colors';

export const StyledInput = styled(Input)`
  ${({ type }) =>
    type === 'textarea' &&
    css`
      && {
        height: ${multiply(spacer, 9)};
      }
    `}
  border: ${get('borderWidth')} solid ${inputColors.border.default};
  background-color: ${inputColors.background.default};
  color: ${inputColors.value.default};

  &:hover {
    border: ${get('borderWidth')} solid ${inputColors.border.hover};
    cursor: pointer;
    background-color: ${inputColors.background.hover};
    color: ${inputColors.value.hover};
  }

  &:focus {
    border: ${get('borderWidth')} solid ${inputColors.border.focus};
    cursor: pointer;
    background-color: ${inputColors.background.focus};
    color: ${inputColors.value.focus};
  }

  &:active {
    border: ${get('borderWidth')} solid ${inputColors.border.active};
    background-color: ${inputColors.background.active};
    color: ${inputColors.value.active};
  }

  &:disabled {
    border: ${get('borderWidth')} solid ${inputColors.border.disabled};
    background-color: ${inputColors.background.disabled};
    color: ${inputColors.value.disabled};
  }
`;

export const StyledFormText = styled(FormText)`
  font-size: ${fontSize('base')};
`;

// Disable eslint to keep the original logic: not pass no-unused-vars
// props down to child component.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledFormGroup = styled(({ marginBottom, marginLeft, ...props }) => <FormGroup {...props} />)`
  ${({ marginBottom }) =>
    marginBottom !== false &&
    css`
      margin-bottom: ${multiply(spacer, marginBottom)};
    `};
  ${({ marginLeft }) =>
    marginLeft &&
    css`
      margin-inline-start: ${multiply(spacer, marginLeft)};
    `};
`;

// Disable eslint to keep the original logic: not pass no-unused-vars
// props down to child component.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledSingleCheckBox = styled(({ invalid, ...props }) => <div {...props} />)`
  padding: ${spacer};
  border: ${get('borderStyle')};

  ${({ invalid }) =>
    invalid &&
    css`
      border-color: ${inputColors.border.error};
    `}

  .custom-control-input.is-invalid ~ .custom-control-label {
    color: inherit;
  }
`;
