import styled from 'styled-components';
import { fontSize, bodyColor } from '@whitelabel/helpers/style/type';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { spacer, multiply } from '@whitelabel/helpers/style/units';
import StyledCheckbox from '../../styled/StyledCheckbox';

export const StyledFieldRequirements = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${multiply(spacer, 0.75)} 0 ${spacer};
  color: ${bodyColor};
  font-size: ${fontSize('base')};
  ${mediaBreakpointUp('sm')`
    flex-direction: row;
  `}
  ${mediaBreakpointUp('md')`
    flex-direction: column;
  `}
  ${mediaBreakpointUp('xl')`
    flex-direction: row;
  `}
`;

export const StyledRequirement = styled.span`
  display: inline-flex;
  align-items: center;

  ${mediaBreakpointUp('sm')`
    margin-inline-end: ${spacer};
  `}

  &:not(:last-child) {
    margin-bottom: ${multiply(spacer, 0.5)};
    ${mediaBreakpointUp('sm')`
      margin-bottom: 0;
    `}
  }
`;

export const StyledIcon = styled(StyledCheckbox)`
  margin-inline-end: ${multiply(spacer, 0.5)};
`;
