import React from 'react';
import { HeadingTag } from '@whitelabel/helpers/types';
import { StyledIcon, StyledInfoBox, StyledInfoTitle } from './styledInfoBox';

export interface IInfoBoxProps {
  title?: string;
  titleTag?: HeadingTag;
  children: React.ReactNode;
  className?: string;
}

const InfoBox = ({ title, children, className, titleTag = 'h3' }: IInfoBoxProps): JSX.Element => (
  <StyledInfoBox className={className} $hasTitle={!!title}>
    <StyledIcon $hasTitle={!!title} />
    <div>
      {title && <StyledInfoTitle as={titleTag}>{title}</StyledInfoTitle>}
      {children}
    </div>
  </StyledInfoBox>
);
export default InfoBox;
