import styled from 'styled-components';
import { Col, Container, Row } from 'reactstrap';
import { gray } from '@whitelabel/helpers/style/colors';
import { mediaBreakpointUp, gridGutterWidth, breakpointKeys } from '@whitelabel/helpers/style/grid';
import ButtonBlock from '../ButtonBlock';

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 0;
  ${mediaBreakpointUp('lg')`
    && .content-row p {
      margin-bottom: 0;
    }
  `}
  ${({ theme }) =>
    breakpointKeys(theme).map(
      (breakpoint) =>
        mediaBreakpointUp(breakpoint)`
        align-items: ${breakpoint === 'lg' ? 'center' : 'left'};
        header {
          margin: 0;
          h2 {
              margin-bottom: ${gridGutterWidth(breakpoint)};
          }
        }
        .content-row {
            margin-bottom: ${gridGutterWidth(breakpoint)};
            p {
              margin-bottom: ${gridGutterWidth(breakpoint)};
            }
        }
    `,
    )}
`;

export const StyledContent = styled(Row)<{ alignment: 'left' | 'right' }>`
  display: flex;
  flex-direction: ${({ alignment }) => (alignment === 'left' ? `row-reverse` : `row`)};
  align-items: center;
  justify-content: center;
`;

export const StyledImage = styled(Col)`
  display: flex;
  justify-content: center;
  img {
    object-fit: cover;
  }
  ${mediaBreakpointUp('sm')`
    height: 14rem;
    `}
  ${mediaBreakpointUp('lg')`
    min-height: 17rem;
    height: 100%;
    `}
`;

export const StyledText = styled(Col)`
  h4 {
    margin-bottom: 0.5rem;
  }
  p {
    color: ${gray(600)};
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  a:first-child {
    margin-bottom: 1rem;
  }
  ${mediaBreakpointUp('sm')`
  flex-direction: row;
    a:first-child {
        margin: 0 ${gridGutterWidth('md')} 0 0;
    }
    `}
  ${mediaBreakpointUp('lg')`
    a:first-child {
        margin: 0 ${gridGutterWidth('lg')} 0 0;
    }
    `}
`;

export const StyledButtonBlock = styled(ButtonBlock)``;
