import { defineMessages } from 'react-intl';

const messages = defineMessages({
  commissionBannerSubtext: {
    id: 'commissionBanner.commissionBannerSubtext',
    defaultMessage: 'Earn <span>10% commission</span> for every policy sold',
  },
});

export default messages;
