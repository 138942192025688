import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { Label } from 'reactstrap';
import { fontStyle, fontWeight } from '@whitelabel/helpers/style/type';
import { get } from '@whitelabel/helpers/style/get';
import { inputColors } from '@whitelabel/global-theme/colors';
import { StyledSelect } from '../../styled/StyledSelect';

export const StyledDOBWrapper = styled.div<{ $invalid: boolean }>`
  display: flex;
  align-items: center;

  label:not(:last-child) {
    margin-inline-end: 1rem;
  }

  label > input {
    text-align: start;
    padding: 0.5rem 1rem;
    border: ${get('borderStyle')};
    /* TODO: add form's border color to theme */
    border-color: ${inputColors.border.default};
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      appearance: none;
      margin: 0;
    }
    &[type='number'] {
      -moz-appearance: textfield; /* stylelint-disable-line property-no-vendor-prefix */
    }
  }

  .day {
    width: 4rem;
  }

  .year {
    width: 6rem;
  }
  label > span {
    display: inline-block;
    margin-bottom: 0.5rem;
    ${fontStyle('formLabelSmall')}
  }
  ${({ $invalid }) =>
    $invalid &&
    css`
      label > span {
        color: ${inputColors.border.error};
      }

      label > input {
        border-color: ${inputColors.border.error};
      }

      .react-select__control {
        border-color: ${inputColors.border.error};
      }
    `}
`;

export const StyledMonthSelect = styled(StyledSelect)`
  width: ${rem(110)};
  font-weight: ${fontWeight('normal')};
`;

export const StyledLabel = styled(Label)<{ $disabled: boolean }>`
  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${inputColors.label.disabled};
    `}
`;
