import styled from 'styled-components';
import { FormText } from 'reactstrap';
import { fontStyle } from '@whitelabel/helpers/style/type';

export const StyledFormText = styled(FormText)`
  margin-top: 0.5rem;
  font-size: ${fontStyle('formSupport')};
`;

export const StyledDescription = styled(StyledFormText)`
  margin: 0 0 0.5rem;
`;
