import { mapValues } from 'lodash/fp';
import { internationalFontFamilies } from '@whitelabel/global-theme/fonts';
import languages from './languages.json';
import { USER_CONTINENT, USER_COUNTRY } from './constants';
import { captureExceptionWithFullStory } from './utils';
import { Language, Languages } from './types';
import { getNewURLWithLocale } from './middlewareUtils';

export const site = {
  locale: 'en',
  countries: {},
};

export function detectCookies(sentry?: { captureException: (err: unknown) => void }): boolean {
  let errorMessage;
  try {
    const test = 'test';
    localStorage.setItem(test, test);
    sessionStorage.setItem(test, test);

    if (localStorage.getItem(test) !== test) {
      errorMessage = 'localStorage is not returning the same value';
    } else if (sessionStorage.getItem(test) !== test) {
      errorMessage = 'sessionStorage is not returning the same value';
    }
    if (errorMessage) throw new Error(errorMessage);

    localStorage.removeItem(test);
    sessionStorage.removeItem(test);
    return navigator.cookieEnabled;
  } catch (err) {
    if (sentry) sentry.captureException(errorMessage ? err : new Error('Browser storage is not supported'));
    return false;
  }
}

export const getUserCountry = (): string => (detectCookies() && sessionStorage.getItem(USER_COUNTRY)) || 'AU';

export const getUserContinent = (): string | null => (detectCookies() ? sessionStorage.getItem(USER_CONTINENT) : null);

export const isUSEnglish = (locale: string) =>
  locale === 'en' && (getUserCountry() === 'US' || getUserContinent() === 'SA');

/**
 * Check if narrowSymbol is supported (for some old Safari versions)
 * Returns the unsupported locale or undefined
 * This check was not included in formatjs shouldPolyfillNumberFormat
 */
export const shouldPolyfillNumberFormatNarrowSymbol = (locale: string): string | undefined => {
  let unsupportedNumberFormatLocale: string | undefined;
  try {
    if (Intl.NumberFormat) {
      Intl.NumberFormat(locale, { currencyDisplay: 'narrowSymbol' });
    }
  } catch (error) {
    unsupportedNumberFormatLocale = locale;
  }

  return unsupportedNumberFormatLocale;
};

export function getTranslatedLanguage(
  browserLanguage: string,
  translatedLanguages: Languages,
  userCountry: string,
  userContinent: string | null,
) {
  const language = browserLanguage.split('-')[0] as Language;
  const translatedLanguage = translatedLanguages[browserLanguage as Language] || translatedLanguages[language];

  const usLanguagePresent = translatedLanguages['en-us'];
  if (translatedLanguage) {
    if (
      translatedLanguage.code.startsWith('en') &&
      usLanguagePresent &&
      (userCountry === 'US' || userContinent === 'SA')
    ) {
      return 'en-us';
    }
    if (translatedLanguage.code.startsWith('pt') && translatedLanguages['pt-br'] && userCountry === 'BR') {
      return 'pt-br';
    }
  }
  if (!translatedLanguage) {
    return site.locale;
  }
  return translatedLanguage.code;
}

function getBrowserLanguage() {
  const browserLanguage =
    (navigator.languages && navigator.languages[0]) || navigator.language || (navigator as any).userLanguage;
  switch (browserLanguage) {
    case 'zh-TW':
    case 'zh-HK':
      return 'zh-hant';
    case 'zh':
    case 'zh-CN':
      return 'zh-hans';
    default:
      return browserLanguage.toLowerCase();
  }
}

export function detectLanguage(
  translatedLanguages: Languages = languages,
  router?: { replace: (url: URL) => void },
): boolean {
  const { pathname } = window.location;
  const pathLanguage = pathname.split('/')[1]?.toLowerCase() as Language;

  const redirectToNewURL = (newPathLanguage: string) => {
    const { origin, search, hash } = window.location;
    const newURL = getNewURLWithLocale(newPathLanguage, pathname, origin, search, hash);
    if (router) {
      router.replace(newURL);
    } else {
      window.location.replace(newURL);
    }
  };

  if (!pathLanguage || !translatedLanguages[pathLanguage]) {
    const translatedLanguage = getTranslatedLanguage(
      getBrowserLanguage(),
      translatedLanguages,
      getUserCountry(),
      getUserContinent(),
    );
    redirectToNewURL(translatedLanguage);
    return false;
  }
  site.locale = pathLanguage;
  return true;
}

export const getCountryName = (countryCode: string): string => {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  if (site.countries[countryCode]) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return site.countries[countryCode].name;
  }
  captureExceptionWithFullStory(new Error(`${countryCode} country code not found in Countries object from XC.js`));
  return countryCode;
};
export const getCountryNames = (countryCodesArray: string[]): string =>
  countryCodesArray.map((code) => getCountryName(code)).join(', ');

/**
 *
 * @param userAgent
 * @returns
 */
export const detectIE = (userAgent?: string): boolean => {
  const ua = userAgent || window?.navigator?.userAgent || '';
  // MSIE: IE 10 or older
  // Trident: IE 11
  return ua.indexOf('MSIE') > -1 || ua.indexOf('Trident') > -1;
};

/**
 *
 * @param userAgent
 * @returns
 */
export const detectBlacklistedBrowser = (userAgent?: string): boolean => {
  const ua = userAgent || window?.navigator?.userAgent || '';
  // should blacklist vivo mobile phone with chrome version 87
  if (ua.indexOf('Chrome/87') > -1 && ua.indexOf('Vivo') > -1 && ua.indexOf('Mobile')) {
    return true;
  }
  return false;
};

export const isSupportedBrowser = (userAgent?: string): boolean =>
  !detectIE(userAgent) && !detectBlacklistedBrowser(userAgent);

export const detectLowerCaseINS = () => {
  const { origin, pathname, search } = window.location;
  const INSregex = /([a-z0-9]-)?([a-z0-9]{5}-){2}(ins|INS)(\/|$)/;
  if (INSregex.exec(pathname)) {
    const newPathname = pathname.replace(INSregex, (ins) => ins.toUpperCase());
    const newUrl = origin + newPathname + search;
    window.location.replace(newUrl);
  }
};

const getFontFamilyStyle = (fontFamilyElement: string, newFontFamily: string) => {
  let tempFontFamilyElement = fontFamilyElement;
  const [existingFontFamily, ...restFontFamilies] = tempFontFamilyElement.split(',');

  // If the current font family is one of the international font families
  // we need to remove it first and then add the new font family or load the default font family
  if (Object.values(internationalFontFamilies).includes(existingFontFamily.trim())) {
    tempFontFamilyElement = restFontFamilies.join(',').trim();
  }
  // add the new font to the first font of the list or load the default font
  return newFontFamily ? `${newFontFamily}, ${tempFontFamilyElement}` : tempFontFamilyElement;
};

interface IFontTheme {
  fontFamilies: Record<string, string>;
  fontStyles: Record<string, { fontFamily?: string; [key: string]: unknown }>;
}

export const updateFontFamily = <T extends IFontTheme>(locale: string | undefined, prevTheme: T) => {
  const newFontFamily = internationalFontFamilies[locale ?? ''];

  const fontFamilies = mapValues((value) => getFontFamilyStyle(value, newFontFamily), prevTheme.fontFamilies);
  const fontStyles = mapValues((value) => {
    if (value.fontFamily) {
      return {
        ...value,
        fontFamily: getFontFamilyStyle(value.fontFamily, newFontFamily),
      };
    }
    return value;
  }, prevTheme.fontStyles);

  return {
    ...prevTheme,
    fontFamilies,
    fontStyles,
  };
};
