import styled from 'styled-components';
import { fontWeight, fontSize } from '@whitelabel/helpers/style/type';
import { color } from '@whitelabel/helpers/style/colors';
import { borderRadius, boxShadow } from '@whitelabel/helpers/style/box';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { spacer, multiply } from '@whitelabel/helpers/style/units';
import Button from '../../../Button';
import SelectInput from '../../../SelectInput';

export const StyledDropdownLinksBlock = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: ${spacer};
  background: ${color('white')};
  border-radius: ${borderRadius('base')};
  box-shadow: ${boxShadow('base')};
  ${mediaBreakpointUp('sm')`
    box-shadow: ${boxShadow('md')};
  `}
  ${mediaBreakpointUp('lg')`
    flex-wrap: nowrap;
    padding: ${multiply(spacer, 1.5)};
    min-width: ${multiply(spacer, 39)};
  `}
`;

export const StyledLabel = styled.label`
  min-width: ${multiply(spacer, 16.5)};
  margin-bottom: ${multiply(spacer, 0.5)};
  font-weight: ${fontWeight('black')};
  font-size: ${fontSize('base')};
  ${mediaBreakpointUp('lg')`
    margin-inline-end: ${multiply(spacer, 1.5)};
    margin-bottom: 0;
 `}
`;

export const StyledSelect = styled(SelectInput)`
  flex: 1 0 100%;
  margin-bottom: ${spacer};
  ${mediaBreakpointUp('lg')`
    flex: 0 1 33%;
    margin-inline-end: ${multiply(spacer, 1.5)};
    margin-bottom: 0;
 `}
  .react-select__value-container {
    padding-inline-start: ${multiply(spacer, 0.5)};
  }
  .react-select__dropdown-indicator {
    padding-inline-end: ${multiply(spacer, 0.5)};
  }
`;

export const StyledButton = styled(Button)`
  flex: 1 1 100%;
`;
