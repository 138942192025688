import { xcoverAPI } from '@whitelabel/xcover-shared/store/services/xcover/index';
import { processKeys } from '@whitelabel/xcover-shared/helpers/api';
import { getAPIHost } from '@whitelabel/xcover-shared/helpers/multiRegion';
import { RTK_CACHE_LIFE } from '@whitelabel/xcover-shared/helpers/constants';
import { IPDSPolicy } from '../../../../helpers/types';

export interface IGetPDS {
  id: string;
  locale: string;
  auth?: boolean;
  search?: string;
}

export const pdsAPI = xcoverAPI.injectEndpoints({
  endpoints: (builder) => ({
    getPDS: builder.query<IPDSPolicy, IGetPDS>({
      query: ({ id, locale, auth = true, search }) => {
        const searchParams = new URLSearchParams(search);
        searchParams.append('language', locale);
        const url = `${getAPIHost(id)}/pds/${id}/?${searchParams}`;

        if (!auth) {
          return {
            url,
            headers: { auth: 'false' },
          };
        }

        return url;
      },
      transformResponse: (response: IPDSPolicy) => processKeys(response),
      keepUnusedDataFor: RTK_CACHE_LIFE,
    }),
  }),
});

export const { useGetPDSQuery } = pdsAPI;

export const clearPDS = () => pdsAPI.util.resetApiState();
