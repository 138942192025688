import React from 'react';
import { StyledIconWrapper, StyledIcon, StyledUncontrolledTooltip } from './styledIconTooltip';

export interface IIconTooltipProps {
  value: string;
  tooltipId: string;
  icon: React.FunctionComponent<React.PropsWithChildren<React.SVGProps<SVGSVGElement>>> | string;
  placement?: string;
}

const IconTooltip = ({ value, tooltipId, placement = 'right', icon }: IIconTooltipProps) => (
  <>
    <StyledIconWrapper>
      <StyledIcon id={tooltipId} as={icon} />
    </StyledIconWrapper>
    <StyledUncontrolledTooltip placement={placement} autohide={false} target={tooltipId} trigger="hover">
      <p>{value}</p>
    </StyledUncontrolledTooltip>
  </>
);

export default IconTooltip;
