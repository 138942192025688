import { css, FlattenInterpolation, ThemeProps, Theme, DefaultTheme } from 'styled-components';
import { rem } from 'polished';
import { color } from './colors';
import { getRightValue, getLeftValue, borderRadius } from './box';
import { contentColor, get } from './get';
import { zIndex } from './zIndex';

export const backgroundStyles = ({ $rtl }: { $rtl: boolean }): FlattenInterpolation<ThemeProps<Theme>> | null =>
  $rtl
    ? css`
        position: relative;
        ::before {
          position: absolute;
          z-index: ${zIndex('behindContent')};
          width: 100%;
          height: 100%;
          transform: scaleX(-1);
          content: '';
        }
      `
    : null;

export const backgroundImage =
  (backgroundImageValue?: string) =>
  ({
    $rtl,
    $backgroundImageUrl,
  }: {
    $rtl: boolean;
    $backgroundImageUrl?: string | null;
  }): FlattenInterpolation<ThemeProps<Theme>> | undefined => {
    if (backgroundImageValue || $backgroundImageUrl) {
      return $rtl
        ? css`
            ::before {
              background-image: url(${backgroundImageValue || $backgroundImageUrl});
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
            }
          `
        : css`
            background-image: url(${backgroundImageValue || $backgroundImageUrl});
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
          `;
    }
    return undefined;
  };

/**
 * This helper use css trick to generate an up-arrow for dropdown list container
 * @param top Y-axis from top
 * @param left X-axis from left
 */
export const getCSSArrow =
  (top: number, left: number, size: 'default' | 'small' = 'default', reverseSide = false) =>
  ({ $rtl = false }: { $rtl?: boolean }) =>
    css`
      &:before {
        ${reverseSide ? getRightValue(`${left - 1}px`)({ $rtl }) : getLeftValue(`${left - 1}px`)({ $rtl })}
        position: absolute;
        top: ${rem(top - 1)};
        width: 0;
        height: 0;
        z-index: ${zIndex('content')};
        border-inline-end: ${size === 'small' ? '0.5125rem' : '0.8215rem'} solid transparent;
        border-bottom: ${size === 'small' ? '0.8125rem' : '1.3125rem'} solid ${get('borderColor')};
        border-inline-start: ${size === 'small' ? '0.5125rem' : '0.8125rem'} solid transparent;
        content: '';
      }
      &:after {
        ${reverseSide ? getRightValue(`${left}px`)({ $rtl }) : getLeftValue(`${left}px`)({ $rtl })};
        position: absolute;
        top: ${rem(top)};
        width: 0;
        height: 0;
        z-index: ${zIndex('content')};
        border-inline-end: ${size === 'small' ? '0.45rem' : '0.75rem'} solid transparent;
        border-bottom: ${size === 'small' ? '0.75rem' : '1.25rem'} solid white;
        border-inline-start: ${size === 'small' ? '0.45rem' : '0.75rem'} solid transparent;
        content: '';
      }
    `;

export const focusStyle = css`
  outline: none;
  box-shadow: 0 0 0 2px ${contentColor('borderFocus')};
`;

export const focusLinkStyle = css`
  border-radius: ${borderRadius('base')};
  ${focusStyle}
`;

export const focusLinkStyleOnBlackBG = css`
  outline: none;
  border-radius: ${borderRadius('base')};
  box-shadow: 0 0 0 2px ${color('white')};
`;

export const focusVisibleMixin = (cssCode: FlattenInterpolation<ThemeProps<DefaultTheme>>) => css`
  &:focus-visible {
    ${cssCode}
  }
  @supports not selector(:focus-visible) {
    &:focus {
      /* Fallback for browsers without :focus-visible support */
      ${cssCode}
    }
  }
`;
