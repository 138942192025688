import { defineMessages } from 'react-intl';

export default defineMessages({
  instantClaimPay: {
    id: 'sellingPoints.claimPay',
    defaultMessage: `Claims paid 24/7. No mailed checks`,
  },
  easyApproval: {
    id: 'sellingPoints.easyApproval',
    defaultMessage: `No docs, no photos required`,
  },
  strongBackup: {
    id: 'sellingPoints.strongBackup',
    defaultMessage: `Backed by A rated insurance company`,
  },
  protect: {
    id: 'sellingPoints.protect',
    defaultMessage: `Protect your business &/or home`,
  },
});
