import React, { ChangeEventHandler, KeyboardEventHandler, forwardRef } from 'react';
import { ISuggest } from '@whitelabel/helpers/types';
import { filterInputAttributes } from './helper';
import { StyledGeoSuggestInput, StyledIconPin } from './styledGeoSuggest';

interface IGeoSuggestInputProps {
  value: string;
  placeholder?: string;
  id?: string;
  doNotSubmitOnEnter?: boolean;
  ignoreEnter?: boolean;
  ignoreTab?: boolean;
  autoComplete?: string;
  isSuggestsHidden: boolean;
  activeSuggest?: ISuggest;
  listId: string;
  inputType?: string;
  invalid?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onSelect: () => void;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>;
  onNext: () => void;
  onPrev: () => void;
  onEscape: () => void;
  onFocus: () => void;
  onBlur: () => void;
  displayValue?: string;
}

const GeoSuggestInput = forwardRef(
  (
    {
      activeSuggest,
      value = '',
      placeholder = 'Search',
      id,
      listId = '',
      inputType = 'text',
      doNotSubmitOnEnter,
      autoComplete = 'nope',
      isSuggestsHidden,
      onChange,
      onSelect,
      onKeyDown,
      onKeyPress,
      onEscape,
      onNext,
      onPrev,
      onBlur,
      onFocus,
      ignoreEnter,
      ignoreTab,
      invalid,
      displayValue,
      ...props
    }: IGeoSuggestInputProps,
    ref: React.ForwardedRef<null | HTMLInputElement>,
  ) => {
    const onInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
      // Call props.onKeyDown if defined
      // Gives the developer a little bit more control if needed
      if (onKeyDown) {
        onKeyDown(event);
      }

      switch (event.code) {
        case 'ArrowDown':
          if (!event.shiftKey) {
            event.preventDefault();
            onNext();
          }
          break;
        case 'ArrowUp':
          if (!event.shiftKey) {
            event.preventDefault();
            onPrev();
          }
          break;
        case 'Enter':
          if (doNotSubmitOnEnter) {
            event.preventDefault();
          }

          if (!ignoreEnter) {
            onSelect();
          }
          break;
        case 'Tab':
          if (!ignoreTab) {
            onSelect();
          }
          break;
        case 'Escape':
          onEscape();
          break;
        default:
          break;
      }
    };

    const attributes = filterInputAttributes(props);
    if (!attributes.tabIndex) {
      attributes.tabIndex = 0;
    }
    return (
      <>
        <StyledIconPin />
        <StyledGeoSuggestInput
          id={`geosuggest__input${id ? `--${id}` : ''}`}
          type={inputType}
          value={displayValue || value}
          onChange={onChange}
          onKeyDown={onInputKeyDown}
          onKeyPress={onKeyPress}
          onFocus={onFocus}
          onBlur={onBlur}
          // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
          role="combobox"
          aria-owns={listId}
          aria-expanded={!isSuggestsHidden}
          aria-activedescendant={activeSuggest ? activeSuggest.placeId : undefined}
          autoComplete={autoComplete}
          placeholder={placeholder}
          $invalid={invalid}
          ref={ref}
          {...attributes}
        />
      </>
    );
  },
);

export default React.memo(GeoSuggestInput);
