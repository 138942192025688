import styled from 'styled-components';
import { motion } from 'framer-motion';
import { rem } from 'polished';
import { colors } from '@whitelabel/global-theme/colors';
import { themeColor } from '@whitelabel/helpers/style/colors';
import { zIndex } from '@whitelabel/helpers/style/zIndex';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { focusStyle } from '@whitelabel/helpers/style/utils';
import Button from '../Button';

export const StyledSpan = styled(motion.span)`
  margin-inline-start: 0.5rem;
`;

export const StyledContactHoverButton = styled(Button)`
  width: fit-content;
  align-self: flex-end;
  border-radius: ${rem(100)};
  position: fixed;
  right: 0.5rem;
  bottom: 0.5rem;
  color: ${colors.white};
  padding: ${rem(12)};
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
  &:not(:disabled):not(.disabled):hover {
    background: ${themeColor('info')};
    color: ${colors.white};
  }
  &:not(:disabled):not(.disabled):focus {
    background: ${themeColor('info')};
    color: ${colors.white};
    ${focusStyle}
  }
  z-index: ${zIndex('content')};
  > svg {
    width: 1rem;
    height: ${rem(15)};
    margin-inline-end: 0;
  }
  ${mediaBreakpointUp('sm')`
    padding:16px;
    >svg {
    width: ${rem(23)};
    height: ${rem(22)}
    };
  `};

  ${mediaBreakpointUp('md')`
    right:1rem;
    bottom:1rem;
  `};

  ${mediaBreakpointUp('lg')`
    padding:1rem;
    > svg {
      width: ${rem(30)};
      height: ${rem(28)}
    };
    right:1.5rem;
    bottom:1.5rem;
  `};
  background: ${themeColor('info')};
`;
