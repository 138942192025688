import styled, { Theme } from 'styled-components';
import { rem } from 'polished';
import { fontStyle } from '@whitelabel/helpers/style/type';
import { mediaBreakpointUp, gridGutterWidth, breakpointKeys } from '@whitelabel/helpers/style/grid';

const StyledRichTextBlock = styled.div`
  ${fontStyle('paragraph')}
  ${(props: { theme: Theme; spacing: 'sm' | string }) =>
    breakpointKeys(props.theme).map(
      (breakpoint) => mediaBreakpointUp(breakpoint)`
      margin-bottom: ${props.spacing === 'sm' ? '1rem' : gridGutterWidth(breakpoint)};
    `,
    )}

  &:last-child,
  *:last-child {
    margin-bottom: 0;
  }

  div iframe {
    display: block;
    width: ${rem('343px')};
    height: ${rem('193px')};
    margin: 0 auto;

    ${mediaBreakpointUp('sm')`
      width: ${rem('544px')};
      height: ${rem('304px')};
    `};

    ${mediaBreakpointUp('md')`
      width: ${rem('720px')};
      height: ${rem('405px')};
    `};

    ${mediaBreakpointUp('lg')`
      width: ${rem('937px')};
      height: ${rem('527px')};
    `};
  }
`;

export default StyledRichTextBlock;
