import React from 'react';
import { useIntl } from 'react-intl';
import ReactHtmlParser from 'react-html-parser';
import { ReactComponent as IconDollar } from '@whitelabel/media/icons/small/dollar-currentcolor.svg';
import { StyledBanner } from './styled';
import messages from './message';

export interface IBanner {
  subtext:
    | string
    | {
        type: 'xcms-message';
        id: string;
      };
  hasDollarIcon?: boolean;
}

const Banner = ({ subtext, hasDollarIcon = true }: IBanner): JSX.Element => {
  const { formatMessage } = useIntl();

  const renderSubtext = () => {
    if (typeof subtext === 'string') return ReactHtmlParser(subtext);
    // @ts-expect-error can't index messages with string
    if (subtext.type === 'xcms-message') return ReactHtmlParser(formatMessage(messages[subtext.id]));

    return '';
  };

  return (
    <StyledBanner data-test-id="banner" $hasIcon={hasDollarIcon}>
      {hasDollarIcon && <IconDollar />}
      <p>{renderSubtext()}</p>
    </StyledBanner>
  );
};

export default Banner;
