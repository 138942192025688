import moment from 'moment';
import { shouldPolyfill as shouldPolyfillCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as shouldPolyfillIntlLocale } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';
import { shouldPolyfill as shouldPolyfillNumberFormat } from '@formatjs/intl-numberformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillRelativeTimeFormat } from '@formatjs/intl-relativetimeformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillDateTimeFormat } from '@formatjs/intl-datetimeformat/should-polyfill';
import { locales } from '@whitelabel/helpers/locale';
import { shouldPolyfillNumberFormatNarrowSymbol } from '@whitelabel/helpers/site';
import { importIntlNumberFormatLocaleData } from './localeData/intlNumberFormatLocaleData';
import { importIntlDateTimeFormatLocaleData } from './localeData/intlDateTimeFormatLocaleData';
import { importIntlPluralRulesLocaleData } from './localeData/intlPluralRulesLocaleData';
import { importIntlRelativeTimeFormatLocaleData } from './localeData/intlRelativeTimeFormatLocaleData';
import { importIntlDisplayNamesLocaleData } from './localeData/intlDisplayNamesLocaleData';
import { importMomentLocaleData } from './localeData/momentLocaleData';

const getLocaleCode = (locale: string) => {
  const { momentCode } = locales[locale] || {};
  return { momentCode, code: momentCode?.includes('zh') ? 'zh' : momentCode };
};

const loadIntlPolyfills = async (locale: string) => {
  const { code } = getLocaleCode(locale);

  if (typeof Intl === 'undefined') {
    await import('intl');
  }

  // 1st level import
  if (shouldPolyfillCanonicalLocales()) {
    await import(
      '@formatjs/intl-getcanonicallocales/polyfill' /* webpackChunkName: `intl-polyfill-getcanonicallocales` */
    );
  }

  // 2nd level import
  if (shouldPolyfillIntlLocale()) {
    await import('@formatjs/intl-locale/polyfill' /* webpackChunkName: `intl-polyfill-locale` */);
  }

  // 3rd level import
  const pluralRulesAndDisplayNamesLocaleData = [];

  const unsupportedPluralRulesLocale = shouldPolyfillPluralRules(code);
  if (unsupportedPluralRulesLocale) {
    await import('@formatjs/intl-pluralrules/polyfill-force' /* webpackChunkName: `intl-polyfill-pluralrules` */);

    pluralRulesAndDisplayNamesLocaleData.push(importIntlPluralRulesLocaleData(unsupportedPluralRulesLocale));
  }

  if (!Intl.DisplayNames) {
    await import('@formatjs/intl-displaynames/polyfill-force' /* webpackChunkName: `intl-polyfill-displaynames` */);
    pluralRulesAndDisplayNamesLocaleData.push(importIntlDisplayNamesLocaleData(code));
  }

  if (pluralRulesAndDisplayNamesLocaleData.length > 0) await Promise.all(pluralRulesAndDisplayNamesLocaleData);

  // 4th level import
  const unsupportedNumberFormatLocale =
    shouldPolyfillNumberFormat(code) || shouldPolyfillNumberFormatNarrowSymbol(code);
  if (unsupportedNumberFormatLocale) {
    await import('@formatjs/intl-numberformat/polyfill-force' /* webpackChunkName: `intl-polyfill-numberformat` */);
    await importIntlNumberFormatLocaleData(unsupportedNumberFormatLocale);
  }

  // 5th level import
  const timeFormatLocaleData = [];

  const unsupportedRelativeTimeFormatLocale = shouldPolyfillRelativeTimeFormat(code);
  if (unsupportedRelativeTimeFormatLocale) {
    await import(
      '@formatjs/intl-relativetimeformat/polyfill-force' /* webpackChunkName: `intl-polyfill-relativetimeformat` */
    );

    timeFormatLocaleData.push(importIntlRelativeTimeFormatLocaleData(unsupportedRelativeTimeFormatLocale));
  }

  const unsupportedDateTimeFormatLocale = shouldPolyfillDateTimeFormat(code);
  if (unsupportedDateTimeFormatLocale) {
    await import(
      '@formatjs/intl-datetimeformat/polyfill-force' /* webpackChunkName: `intl-polyfill-datetimeformat` */
    );

    timeFormatLocaleData.push(import('@formatjs/intl-datetimeformat/add-all-tz'));
    timeFormatLocaleData.push(importIntlDateTimeFormatLocaleData(unsupportedDateTimeFormatLocale));
  }

  if (timeFormatLocaleData.length > 0) await Promise.all(timeFormatLocaleData);
};

export const loadMomentLocale = async (locale: string) => {
  const { momentCode, code } = getLocaleCode(locale);

  await importMomentLocaleData(code);
  moment.locale(momentCode);
};

// Checks browser functionality and conditionally imports the required polyfills, used in conjunction with core-js
export const loadPolyfills = async (locale: string) => {
  // Load Intl polyfills in a separate async function so they don't block our other imports
  const polyfills = [loadIntlPolyfills(locale)];

  if (typeof fetch === 'undefined') {
    polyfills.push(import('whatwg-fetch'));
  }

  if (typeof document !== 'undefined' && !('scrollBehavior' in document.documentElement.style)) {
    polyfills.push(import('smoothscroll-polyfill').then((smoothscroll) => smoothscroll.default.polyfill()));
  }

  if (typeof SVGElement !== 'undefined' && !SVGElement.prototype.contains) {
    SVGElement.prototype.contains = HTMLDivElement.prototype.contains;
  }

  await Promise.all(polyfills);
};
