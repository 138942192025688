import { createContext, useContext } from 'react';

export interface IBWChatContextProps {
  isChatVisible: boolean;
}

export const BWChatContext = createContext<IBWChatContextProps>({
  isChatVisible: false,
});

export const useBWChat = () => {
  const context = useContext(BWChatContext);
  if (!context) {
    throw new Error('useBWChat must be used within a BWChatProvider');
  }
  return context;
};
