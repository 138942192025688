import React, { HTMLAttributes, useEffect, useState } from 'react';
import { Tooltip, Input } from 'reactstrap';
import { useIntl } from 'react-intl';
import { ReactComponent as IconVisible } from '@whitelabel/media/icons/forms/visible.svg';
import { ReactComponent as IconHidden } from '@whitelabel/media/icons/forms/hidden.svg';
import { TOOLTIP_TIMEOUT_DELAY } from '@whitelabel/helpers/constants';
import { StyledPasswordInput, StyledIconContainer } from './styledPasswordInput';
import messages from './messages';

export interface IPasswordInputProps extends HTMLAttributes<HTMLElement> {
  id: string;
  invalid: boolean;
  type?: string;
  className?: string;
}

const PasswordInput = ({ id, invalid, className, ...props }: IPasswordInputProps): JSX.Element => {
  const intl = useIntl();
  const [visible, setVisible] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleVisbility = () => {
    setVisible((prev) => !prev);
  };

  const toggleTooltip = () => {
    setTooltipOpen((prev) => !prev);
  };

  useEffect(() => {
    const timeoutID = window.setTimeout(() => {
      setTooltipOpen(false);
    }, TOOLTIP_TIMEOUT_DELAY);

    return () => window.clearTimeout(timeoutID);
  }, [tooltipOpen, visible]);

  const VisibilityIcon = visible ? IconVisible : IconHidden;

  return (
    <StyledPasswordInput>
      <Input
        id={id}
        {...props}
        type={visible ? 'text' : 'password'}
        invalid={invalid}
        className={className ? `${className} fs-mask` : 'fs-mask'}
      />

      <StyledIconContainer $rtl={intl.bidi} onClick={toggleVisbility} id={`icon-${id}`}>
        <VisibilityIcon />
      </StyledIconContainer>

      <Tooltip toggle={toggleTooltip} isOpen={tooltipOpen} target={`icon-${id}`}>
        {intl.formatMessage(messages.toggleVisibility)}
      </Tooltip>
    </StyledPasswordInput>
  );
};

export default PasswordInput;
