import styled from 'styled-components';
import { FilePond } from 'react-filepond';
import { alertBackgroundColor, alertBorderColor } from '@whitelabel/helpers/style/alert';
import { fontWeight, fontSize, fontFamily, lineHeight } from '@whitelabel/helpers/style/type';
import { borderRadius } from '@whitelabel/helpers/style/box';
import { color, gray } from '@whitelabel/helpers/style/colors';
import { spacer, multiply } from '@whitelabel/helpers/style/units';
import { get } from '@whitelabel/helpers/style/get';
import { inputColors } from '@whitelabel/global-theme/colors';
import { buttonVariant } from '../Button/styledButton';

// eslint-disable-next-line import/prefer-default-export
export const StyledFilePond = styled(FilePond)`
  min-height: ${multiply(spacer, 6)};

  input:focus ~ .filepond--drop-label {
    border: ${get('borderWidth')} solid ${gray(700)};
    border-radius: ${borderRadius('base')};
  }

  .filepond--drop-label {
    min-height: ${multiply(spacer, 6)};
    height: auto;
    color: ${inputColors.label.default};
    font-family: ${fontFamily('base')};

    .filepond--label-action {
      display: inline-block;
      margin-inline-start: ${multiply(spacer, 0.5)};
      padding: ${multiply(spacer, 0.5)} ${spacer};
      font-weight: ${fontWeight('bold')};
      font-size: ${fontSize('md')};
      line-height: 1;
      text-transform: uppercase;
      text-decoration: none;
      border-radius: ${borderRadius('base')};

      ${buttonVariant('primary')}
    }

    .filepost--sub-label {
      color: ${inputColors.value.default};
      font-size: ${fontSize('sm')};
      font-family: ${fontFamily('serif')};
    }

    svg {
      margin-inline-end: ${spacer};
    }
    label {
      font-size: ${multiply(fontSize('base'), 1.125)};
    }
  }

  /* the background color of the filepond drop area */
  .filepond--panel-root {
    box-sizing: border-box;
    background: ${inputColors.background.disabled};
    border: ${get('borderWidth')} dashed ${inputColors.border.default};
    border-radius: ${borderRadius('base')};
  }

  .filepond--list {
    list-style: none;
  }

  .filepond--file-wrapper {
    background-color: ${inputColors.background.disabled};
    border: ${get('borderWidth')} solid ${inputColors.border.default};
    border-radius: ${borderRadius('base')};
  }

  /* the background color of the drop circle */
  .filepond--drip-blob {
    background-color: ${color('dustyGray')};
  }

  /* the background color of the black action buttons */
  .filepond--file-action-button {
    color: ${inputColors.value.default};
    background-color: ${color('white')};
    box-shadow: 0 0 0 ${multiply(spacer, 0.125)} ${color('cadetBlue')};
  }

  .filepond--processing-complete-indicator,
  .filepond--progress-indicator,
  .filepond--process-indicator,
  .filepond--file-action-button {
    top: ${multiply(spacer, 0.875)};
  }

  /* the color of the focus ring */
  .filepond--file-action-button:hover,
  .filepond--file-action-button:focus {
    box-shadow: 0 0 0 ${multiply(spacer, 0.25)} ${inputColors.border.error};
  }

  /* the text color of the file status and info labels */
  .filepond--file {
    color: ${inputColors.value.default};
  }

  [data-filepond-item-state='processing-complete'],
  [data-filepond-item-state='idle'] {
    .filepond--file-wrapper {
      background-color: ${alertBackgroundColor('success')};
      border: ${get('borderWidth')} solid ${alertBorderColor('success')};
    }

    .filepond--file-status-main,
    .filepond--file-info-main {
      color: ${inputColors.value.success};
    }

    .filepond--file-action-button {
      color: ${inputColors.value.success};
      background-color: ${color('white')};
      box-shadow: 0 0 0 ${multiply(spacer, 0.125)} ${inputColors.border.success};
    }

    /* the color of the focus ring */
    .filepond--file-action-button:hover,
    .filepond--file-action-button:focus {
      color: ${inputColors.value};
      box-shadow: 0 0 0 ${multiply(spacer, 0.25)} ${inputColors.border.error};
    }
  }

  /* error state color */
  [data-filepond-item-state*='error'],
  [data-filepond-item-state*='invalid'] {
    .filepond--file-wrapper {
      background: ${alertBackgroundColor('danger')};
      border: ${get('borderWidth')} solid ${alertBorderColor('danger')};
    }

    .filepond--file-action-button {
      color: ${inputColors.border.error};
      background-color: ${color('white')};
      box-shadow: 0 0 0 ${multiply(spacer, 0.125)} ${inputColors.border.error};
    }

    .filepond--file-action-button:hover,
    .filepond--file-action-button:focus {
      box-shadow: 0 0 0 ${multiply(spacer, 0.25)} ${inputColors.border.error};
    }

    .filepond--file-status-main,
    .filepond--file-info-main {
      color: ${inputColors.border.error};
    }
  }

  .filepond--image-preview-overlay-failure {
    color: ${alertBackgroundColor('danger')};
  }

  .filepond--image-preview-overlay-idle {
    color: ${color('white')};
  }

  .filepond--item > .filepond--panel .filepond--panel-bottom {
    box-shadow: none;
  }

  .filepond--file-info .filepond--file-info-sub {
    font-size: ${fontSize('sm')};
    font-family: ${fontFamily('serif')};
    line-height: ${lineHeight('sm')};
    white-space: nowrap;
    transition: opacity 0.25s ease-in-out;
  }

  .filepond--file-status .filepond--file-status-main {
    font-weight: ${fontWeight('bold')};
    font-size: ${fontSize('base')};
    font-family: ${fontFamily('base')};
    line-height: ${lineHeight('sm')};
  }

  .filepond--file-info .filepond--file-info-main {
    font-weight: ${fontWeight('bold')};
    font-size: ${fontSize('base')};
    font-family: ${fontFamily('base')};
    line-height: ${lineHeight('sm')};
  }

  .filepond--progress-indicator {
    color: ${inputColors.value.default};
  }

  .filepond--credits {
    display: none;
  }
`;
