import React from 'react';
import { useIntl } from 'react-intl';
import { StyledModal } from '../../styled/StyledModal';
import ModalContent from '../ModalContent';

export interface IModalProps {
  isOpen: boolean;
  toggle: () => void;
  centered?: boolean;
  alert?: React.ReactNode;
  header?: string | React.ReactElement;
  body?: string | React.ReactElement | React.ReactElement[];
  footer?: string | React.ReactElement | false;
  backdrop?: boolean | 'static';
  size?: 'sm' | 'md' | 'lg' | 'xl';
  headingTag?: string;
  className?: string;
  returnFocusAfterClose?: boolean;
  showCloseButton?: boolean;
}

const Modal = ({
  isOpen,
  toggle,
  centered,
  header,
  body,
  footer,
  backdrop = 'static',
  size = 'md',
  headingTag,
  alert,
  className,
  returnFocusAfterClose = true,
  showCloseButton = true,
}: IModalProps): JSX.Element => {
  const intl = useIntl();
  return (
    <StyledModal
      returnFocusAfterClose={returnFocusAfterClose}
      $rtl={intl.bidi}
      isOpen={isOpen}
      toggle={toggle}
      centered={centered}
      backdrop={backdrop}
      size={size}
      data-test-id="Modal"
      className={className}
      $showCloseButton={showCloseButton}
    >
      <ModalContent
        toggle={toggle}
        header={header}
        body={body}
        footer={footer}
        headingTag={headingTag}
        alert={alert}
        showCloseButton={showCloseButton}
      />
    </StyledModal>
  );
};

export default Modal;
