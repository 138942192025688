import React, { useState, useEffect } from 'react';
import { Collapse } from 'reactstrap';
import { isOnServer, onKeyDown } from '@whitelabel/helpers/utils';
import { ReactComponent as IconChevronDown } from '@whitelabel/media/icons/small/chevron-down.svg';
import { ReactComponent as IconChevronUp } from '@whitelabel/media/icons/small/chevron-up.svg';
import { smoothScrollToAnchor, useAfterScrollHandler } from '@whitelabel/helpers/url';
import {
  StyledItemHeading,
  StyledItemInner,
  StyledAccordionItem,
  StyledItemBody,
  StyledItemHeader,
} from './styledAccordionItem';

export interface IAccordionItemProps {
  title: string;
  body: React.ReactElement | string;
  anchorId: string;
  scrollToAnchorID?: boolean;
  hasLoggedIn?: boolean;
  onTargetOrBottom?: () => void;
  onClick?: (pageSlug: string, articleTitle: string) => void;
}

const AccordionItem = ({
  body,
  title,
  anchorId,
  scrollToAnchorID,
  hasLoggedIn = false,
  onTargetOrBottom,
  onClick,
}: IAccordionItemProps): JSX.Element => {
  const hash = !isOnServer ? window.location.hash : '';
  const validHash = scrollToAnchorID || hash === `#${anchorId}`;
  const [isOpen, setIsOpen] = useState<boolean>();
  const toggle = () => {
    setIsOpen((prevState) => !prevState);
    if (onClick) onClick(anchorId, title);
  };
  const [triggerAfterScrollHandler] = useAfterScrollHandler(hash, onTargetOrBottom);

  useEffect(() => {
    if (validHash) {
      // We need to change the url hash and jump across different articles on PASH page
      setIsOpen(true);
      smoothScrollToAnchor(`#${anchorId}`)();
      if (!hasLoggedIn && onTargetOrBottom) {
        window.addEventListener('scroll', triggerAfterScrollHandler);
      }
    } else if (!hasLoggedIn && onTargetOrBottom) onTargetOrBottom();
    return () => window.removeEventListener('scroll', triggerAfterScrollHandler);
  }, [validHash, hasLoggedIn, onTargetOrBottom]);

  return (
    <StyledAccordionItem id={anchorId} data-test-id="accordion-item" tabIndex={0} onKeyDown={onKeyDown(toggle)}>
      <StyledItemHeader onClick={toggle}>
        <StyledItemHeading>
          {title}
          {isOpen ? <IconChevronUp /> : <IconChevronDown />}
        </StyledItemHeading>
      </StyledItemHeader>
      <Collapse isOpen={isOpen}>
        <StyledItemInner>
          <StyledItemBody data-test-id="AccordionItem-body">{body}</StyledItemBody>
        </StyledItemInner>
      </Collapse>
    </StyledAccordionItem>
  );
};

export default AccordionItem;
