import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BW_CHAT_BOT_ID, BW_CHAT_BUNDLE_URL, BW_CHAT_SCRIPT_ID, isBwChatEnabled } from './helpers';
import { BWChatContext } from './context';

interface IBWChatProviderProps {
  userId?: string;
}

const BWChatProvider = ({ userId, children }: PropsWithChildren<IBWChatProviderProps>) => {
  const isLoading = useRef(false);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const scriptRef = useRef<HTMLScriptElement>();
  const botRef = useRef<HTMLDivElement>();

  const clearChat = useCallback(() => {
    setIsChatVisible(false);
    if (!window.bwChatWidget) return;
    window.bwChatWidget.clearInstance();
    scriptRef.current?.remove();
  }, []);

  const setupChat = useCallback(() => {
    if (!userId || !BW_CHAT_BUNDLE_URL || isLoading.current || !!window.bwChatWidget || !isBwChatEnabled()) return;

    isLoading.current = true;
    scriptRef.current = document.createElement('script');
    botRef.current = document.createElement('div');
    botRef.current.id = BW_CHAT_BOT_ID;
    scriptRef.current.src = BW_CHAT_BUNDLE_URL;
    scriptRef.current.async = true;
    scriptRef.current.id = BW_CHAT_SCRIPT_ID;
    scriptRef.current.onload = () => {
      window.bwChatWidget?.setup(BW_CHAT_BOT_ID, {
        user_id: userId,
      });
      setIsChatVisible(true);
      isLoading.current = false;
    };
    document.body.appendChild(botRef.current);
    document.head.appendChild(scriptRef.current);
  }, [userId]);

  useEffect(() => {
    if (userId) {
      setupChat();
    } else {
      clearChat();
    }
  }, [userId, setupChat, clearChat]);

  const contextValue = useMemo(
    () => ({
      isChatVisible,
    }),
    [isChatVisible],
  );

  return <BWChatContext.Provider value={contextValue}>{children}</BWChatContext.Provider>;
};

export default BWChatProvider;
