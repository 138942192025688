import styled, { css } from 'styled-components';
import { tint } from 'polished';
import { themeColors } from '@whitelabel/global-theme/colors';
import { themeColor } from '@whitelabel/helpers/style/colors';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';

export const StyledBanner = styled.div<{ $hasIcon?: boolean }>`
  color: ${themeColor('success')};
  border: 1px solid ${themeColor('success')};
  background-color: ${tint(0.95, themeColors.success)};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 50px;
  display: flex;
  align-items: baseline;
  width: auto;
  margin-top: 1rem;

  ${mediaBreakpointUp('sm')`
    display: inline-flex;
    align-items: center;
    width: max-content;
  `}

  && svg {
    display: flex;
    position: relative;
    margin-left: 1rem;
  }

  p {
    margin: 0;
    margin-right: 1rem;
    ${({ $hasIcon }) =>
      css`
        margin-left: ${$hasIcon ? '0.5rem' : '1rem'};
      `}

    span {
      font-weight: 450;
    }
  }
`;
