import { Theme } from 'styled-components';
import { IThemeValueGetter } from '../types';

export const alertBackgroundColor =
  (key: keyof Theme['alert']['backgroundColor']): IThemeValueGetter =>
  ({ theme }) =>
    theme.alert.backgroundColor[key];
export const alertBorderColor =
  (key: keyof Theme['alert']['borderColor']): IThemeValueGetter =>
  ({ theme }) =>
    theme.alert.borderColor[key];
