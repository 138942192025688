import moment from 'moment';

export const dateDDMMYYYYtoISO = (value: moment.MomentInput) => moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD');

export const dateYYYYMMDDtoISOString = (value: moment.MomentInput, keepOffset = true) =>
  moment(value, 'YYYY-MM-DD').toISOString(keepOffset);

export const dateISOtoDDMMYYYY = (value: moment.MomentInput) => moment(value).format('DD/MM/YYYY');

export const dateISOtoDDMMMYYYY = (value: moment.MomentInput) => moment(value).format('DD-MMM-YYYY');

export const dateISOtoDMMMYYYY = (value: moment.MomentInput) => moment(value).format('D MMM YYYY');

export const dateISOtoDDSMMMSYYYY = (value: moment.MomentInput) => moment(value).format('DD MMM YYYY');

export const dateISOtoYYYYMMDD = (value: moment.MomentInput) => moment(value).format('YYYY-MM-DD');

export const dateISOtoTime = (value: moment.MomentInput) => moment(value).format('LT');

export const dateISOtoAge = (from: moment.MomentInput, to: moment.MomentInput) =>
  moment(to || undefined).diff(moment(from), 'years');

export const extractISODate = (value: any) => {
  const isoDateRegex = /^\d{4}-\d{2}-\d{2}/;
  const result = isoDateRegex.exec(value);
  return result && result[0];
};

export const checkPastDaysFromToday = (date?: string) => {
  if (!date) return undefined;
  const oneDayTime = 1000 * 60 * 60 * 24;
  const pastDate = new Date(date).getTime();
  const currentDate = new Date().getTime();

  const differenceInTime = currentDate - pastDate;
  const differenceInDays = differenceInTime / oneDayTime;
  // rounds up and returns the smaller integer greater than or equal to a given number. e.g. 0.03 => 1
  return Math.ceil(differenceInDays);
};
