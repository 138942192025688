import styled, { css } from 'styled-components';
import { fontStyle, fontWeight } from '@whitelabel/helpers/style/type';
import { getThemeProp } from '@whitelabel/helpers/style/get';
import { gray, themeColor } from '@whitelabel/helpers/style/colors';

interface IStyledSelectOptionProps {
  isSelected: boolean;
  isFocused: boolean;
  isFirstOption?: boolean;
  isLastOption?: boolean;
  isMoreOption?: boolean;
}

export const StyledSelectOption = styled.div<IStyledSelectOptionProps>`
  ${fontStyle('contentMenu')};
  cursor: pointer;
  padding: 0.5rem 0;
  display: flex;
  align-items: center;

  ${({ isFirstOption }) =>
    isFirstOption &&
    css`
      padding-top: 0;
    `}

  ${({ isLastOption }) =>
    isLastOption &&
    css`
      padding-bottom: 0;
    `}

  ${({ isMoreOption }) =>
    isMoreOption &&
    css`
      ${fontStyle('button')};
      color: ${gray(600)};
      padding-top: 2rem;
      margin: 1rem 0;
      justify-content: center;
      border-top: ${getThemeProp('borderStyle')};
    `}

  ${({ isSelected }) =>
    isSelected &&
    css`
      font-weight: ${fontWeight('bold')};
      color: ${themeColor('success')};
    `}

  ${({ isFocused }) =>
    isFocused &&
    css`
      color: ${themeColor('dark')};
    `}
`;
