import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useIntl } from 'react-intl';
import { camelCase } from 'lodash/fp';
import { IDefinition } from '@whitelabel/helpers/types';
import { transformLink } from '../../../helpers/transform';
import { StyledRow, StyledTerm, StyledDefinition } from './styledDefinitionListBlock';

interface IDefinitionListBlockProps {
  value: IDefinition[];
  className?: string;
}

const DefinitionListBlock = ({ value, className }: IDefinitionListBlockProps): JSX.Element => {
  const intl = useIntl();
  return (
    <dl className={className}>
      {value.map(({ term, definition }) => (
        <StyledRow key={camelCase(term)}>
          <StyledTerm>{term}</StyledTerm>
          <StyledDefinition>
            {ReactHtmlParser(definition, {
              transform: transformLink(intl),
            })}
          </StyledDefinition>
        </StyledRow>
      ))}
    </dl>
  );
};

export default DefinitionListBlock;
