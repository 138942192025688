import React from 'react';
import { Row, Col } from 'reactstrap';
import AccordionItem from './AccordionItem';
import { IAccordionItemProps } from './AccordionItem/AccordionItem';
import { Wrapper } from './styledAccordion';

export interface IAccordionProps {
  items: IAccordionItemProps[];
  onClick?: (pageSlug: string, articleTitle: string) => void;
}

const AccordionBlock = ({ items, onClick }: IAccordionProps): JSX.Element => (
  <Wrapper>
    {items.map((item, idx) => (
      <Row className="accordion-item-wrapper" key={item.title || `${idx}`}>
        <Col>
          <AccordionItem {...item} onClick={onClick} />
        </Col>
      </Row>
    ))}
  </Wrapper>
);

export default AccordionBlock;
