import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useIntl } from 'react-intl';
import commonMessages from '@whitelabel/helpers/messages/commonMsg';
import SafariLogo from '@whitelabel/media/logos/logo-safari.svg';
import FirefoxLogo from '@whitelabel/media/logos/logo-firefox.svg';
import ChromeLogo from '@whitelabel/media/logos/logo-chrome.svg';
import EdgeLogo from '@whitelabel/media/logos/logo-edge.svg';
import logo from '@whitelabel/media/logo.svg';
import { captureExceptionWithFullStory } from '@whitelabel/helpers/utils';
import { SITE_NAME } from '../../helpers/constants';
import messages from './messages';
import {
  BrowserLogoContainer,
  CardBodyText,
  CardHeading,
  CardSection,
  PageContainer,
  SiteLogo,
  BrowserLogo,
  BrowserLink,
  StyledRow,
} from './styledUnsupportedBrowserPage';

interface IBrowserListingProps {
  name: string;
  link: string;
  logo: string;
}

const supportedBrowsers = [
  { name: 'Chrome', link: 'https://www.google.com/chrome/', logo: ChromeLogo },
  { name: 'Safari', link: 'https://www.apple.com/safari/', logo: SafariLogo },
  { name: 'Firefox', link: 'https://www.mozilla.org/firefox/', logo: FirefoxLogo },
  { name: 'Edge', link: 'https://www.microsoft.com/edge', logo: EdgeLogo },
];

const BrowserListing = ({ name, link, logo: logoSrc }: IBrowserListingProps): JSX.Element => (
  <BrowserLogoContainer>
    <BrowserLink href={link} target="_blank" rel="noopener noreferrer">
      <BrowserLogo src={logoSrc} />
    </BrowserLink>
    <BrowserLink href={link} target="_blank" rel="noopener noreferrer">
      <h5>{name}</h5>
    </BrowserLink>
  </BrowserLogoContainer>
);

const UnsupportedBrowserPage = (): JSX.Element => {
  const { formatMessage } = useIntl();
  useEffect(() => {
    captureExceptionWithFullStory(new Error('Browser is not supported'), { userAgent: navigator.userAgent });
  }, []);
  return (
    <PageContainer>
      <Container>
        <StyledRow xs="1">
          <Col>
            <SiteLogo src={logo} alt={SITE_NAME} />
          </Col>
          <Col lg={{ size: 10 }} sm={{ size: 12 }}>
            <CardSection>
              <CardHeading>{formatMessage(messages.cardHeading)}</CardHeading>
              <CardBodyText>
                {formatMessage(messages.cardBodyText, {
                  contactUsLink: (
                    <a
                      href="mailto:support@xcover.com?subject=Unable to access the website"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {formatMessage(commonMessages.contactUsLinkText)}
                    </a>
                  ),
                })}
              </CardBodyText>
              <Row>
                {supportedBrowsers.map((browser) => (
                  <BrowserListing key={browser.link} {...browser} />
                ))}
              </Row>
            </CardSection>
          </Col>
        </StyledRow>
      </Container>
    </PageContainer>
  );
};

export default UnsupportedBrowserPage;
