import { createGlobalStyle } from 'styled-components';
import globalCardStyle from './globalCardStyle';
import globalBadgeStyle from './globalBadgeStyle';
import globalFormStyle from './globalFormStyle';
import globalGridStyle from './globalGridStyle';
import globalDropdownStyle from './globalDropdownStyle';
import globalPopoverStyle from './globalPopoverStyle';
import globalTooltipStyle from './globalTooltipStyle';
import globalNavStyle from './globalNavStyle';
import globalTypeStyle from './globalTypeStyle';
import globalModalStyle from './globalModalStyle';
import globalTableStyle from './globalTableStyle';
import globalGenericStyle from './globalGenericStyle';

const GlobalStyle = createGlobalStyle`
  ${globalCardStyle}
  ${globalBadgeStyle}
  ${globalFormStyle}
  ${globalGridStyle}
  ${globalDropdownStyle}
  ${globalCardStyle}
  ${globalPopoverStyle}
  ${globalTooltipStyle}
  ${globalNavStyle}
  ${globalTypeStyle}
  ${globalModalStyle}
  ${globalTableStyle}
  ${globalGenericStyle}
`;

export default GlobalStyle;
