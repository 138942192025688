import { defineMessages } from 'react-intl';

export default defineMessages({
  activePolicyStatus: {
    id: 'policyStatus.active',
    defaultMessage: 'Active',
  },
  more: {
    id: 'more',
    defaultMessage: 'More',
  },
  confirmedPolicyStatus: {
    id: 'policyStatus.confirmed',
    defaultMessage: 'Confirmed',
  },
  completedPolicyStatus: {
    id: 'policyStatus.completed',
    defaultMessage: 'Completed',
  },
  cancelledPolicyStatus: {
    id: 'policyStatus.cancelled',
    defaultMessage: 'Cancelled',
  },
  dateRange: {
    id: 'dateRange',
    defaultMessage: '{startDate} to {endDate}',
  },
  countRange: {
    id: 'countRange',
    defaultMessage: '{activeIndex} of {total}',
  },
  insuredLabelDefault: {
    id: 'insuredLabel.default',
    defaultMessage: '{itemCount, plural, one {Item} other {Items}} insured',
  },
  insuredLabelTravel: {
    id: 'insuredLabel.travel',
    defaultMessage: '{itemCount, plural, one {Person} other {People}} insured',
  },
  insuredLabelProduct: {
    id: 'insuredLabel.product',
    defaultMessage: '{itemCount, plural, one {Product} other {Products}} insured',
  },
  insuredLabelParcel: {
    id: 'insuredLabel.parcel',
    defaultMessage: '{itemCount, plural, one {Shipment} other {Shipments}} covered',
  },
  insuredLabelLocation: {
    id: 'insuredLabel.location',
    defaultMessage: 'Insured {itemCount, plural, one {location} other {locations}}',
  },
  insuredLabelEvent: {
    id: 'insuredLabel.event',
    defaultMessage: '{itemCount, plural, one {Event} other {Events}} covered',
  },
  insuredItemParcel: {
    id: 'insuredItem.parcel',
    defaultMessage: 'Order #',
  },
  insuredItemTracker: {
    id: 'insuredItem.tracker',
    defaultMessage: 'Tile Serial #:',
  },
  error: {
    id: 'error',
    defaultMessage: 'Sorry! Something went wrong. Please try again later.',
  },
  xclaimGlobalError: {
    id: 'xclaimGlobalError',
    defaultMessage: "There's an issue with the information provided below. Please fix the errors and try again.",
  },
  success: {
    id: 'success',
    defaultMessage: 'Your updates have been made successfully.',
  },
  NotAuthorizedException: {
    id: 'notAuthorizedException',
    defaultMessage: 'Incorrect username or password.',
  },
  paymentDateLabel: {
    id: 'bookingCard.paymentDateLabel',
    defaultMessage: 'Payment date',
  },
  coverageDatesLabel: {
    id: 'bookingCard.coverageDatesLabel',
    defaultMessage: '{policyEndDate, plural, one {Coverage date} other {Coverage dates}}',
  },
  priceLabel: {
    id: 'bookingCard.priceLabel',
    defaultMessage: 'Price/Premium',
  },
  renewalLabel: {
    id: 'bookingCard.renewalLabel',
    defaultMessage: 'Renewal',
  },
  valueMultipleDates: {
    id: 'bookingCard.valueMultipleDates',
    defaultMessage: 'Varied',
  },
  completeForm: {
    id: 'form.completeForm',
    defaultMessage: 'Please fix the errors highlighted in the fields below',
  },
  emailLabel: {
    id: 'emailLabel',
    defaultMessage: 'Email',
  },
  submitButtonText: {
    id: 'submitButtonText',
    defaultMessage: 'Submit',
  },
  continueButtonLabel: {
    id: 'continueButtonLabel',
    defaultMessage: 'Continue',
  },
  nextButtonLabel: {
    id: 'nextButtonLabel',
    defaultMessage: 'Next',
  },
  backButtonLabel: {
    id: 'backButtonLabel',
    defaultMessage: 'Back',
  },
  saveClaimButtonLabel: {
    id: 'saveClaimButtonLabel',
    defaultMessage: 'Save claim',
  },
  nextButtonText: {
    id: 'nextButtonText',
    defaultMessage: 'Next',
  },
  backButtonText: {
    id: 'backButtonText',
    defaultMessage: 'Back',
  },
  fileInputHelpText: {
    id: 'fileInputHelpText',
    defaultMessage: 'Allowed file types: {fileTypes}',
  },
  updateButtonText: {
    id: 'updateButtonText',
    defaultMessage: 'Update',
  },
  closeButtonText: {
    id: 'closeButtonText',
    defaultMessage: 'Close',
  },
  cancelButtonText: {
    id: 'cancelButtonText',
    defaultMessage: 'Cancel',
  },
  confirmButtonText: {
    id: 'confirmButtonText',
    defaultMessage: 'Confirm',
  },
  emailAddress: {
    id: 'emailAddress',
    defaultMessage: 'Email address',
  },
  password: {
    id: 'password',
    defaultMessage: 'Password',
  },

  signup: {
    id: 'signup',
    defaultMessage: 'Sign Up',
  },
  signin: {
    id: 'signin',
    defaultMessage: 'Sign In',
  },
  forgotYourPassword: {
    id: 'forgotYourPassword',
    defaultMessage: 'Forgot your password?',
  },
  download: {
    id: 'download',
    defaultMessage: 'Download',
  },
  login: {
    id: 'login',
    defaultMessage: 'Log in',
  },
  schedule: {
    id: 'schedule',
    defaultMessage: 'Schedule',
  },
  loginHeading: {
    id: 'loginHeading',
    defaultMessage: 'Login',
  },
  skipThisStep: {
    id: 'skipThisStep',
    defaultMessage: 'Skip this step',
  },
  firstName: {
    id: 'firstName',
    defaultMessage: 'First name',
  },
  lastName: {
    id: 'lastName',
    defaultMessage: 'Last name',
  },
  fullName: {
    id: 'fullName',
    defaultMessage: 'Full name',
  },
  fullNames: {
    id: 'fullNames',
    defaultMessage: 'Full name(s)',
  },
  phoneNumber: {
    id: 'phoneNumber',
    defaultMessage: 'Phone number',
  },
  phone: {
    id: 'phone',
    defaultMessage: 'Phone',
  },
  date: {
    id: 'date',
    defaultMessage: 'Date',
  },

  dateOfBirth: {
    id: 'dateOfBirth',
    defaultMessage: 'Date of birth',
  },
  contact: {
    id: 'contact',
    defaultMessage: 'contact',
  },
  address: {
    id: 'address',
    defaultMessage: 'Address',
  },
  addressLine1: {
    id: 'address1',
    defaultMessage: 'Address Line 1',
  },
  addressLine2: {
    id: 'address2',
    defaultMessage: 'Address Line 2',
  },
  city: {
    id: 'city',
    defaultMessage: 'City',
  },
  region: {
    id: 'region',
    defaultMessage: 'Region',
  },
  zipCode: {
    id: 'zipCode',
    defaultMessage: 'Zip code',
  },
  country: {
    id: 'country',
    defaultMessage: 'Country',
  },
  search: {
    id: 'search',
    defaultMessage: 'Search',
  },
  contactUsLinkText: {
    id: 'contactUsLinkText',
    defaultMessage: 'contact us',
  },
  quoteIDLabelProtection: {
    id: 'claimDetailsForm.quoteIDLabelProtection',
    defaultMessage: 'Which protection are you claiming against?',
  },
  readMore: {
    id: 'readMore',
    defaultMessage: 'Read more',
  },
  editDetails: {
    id: 'editDetails',
    defaultMessage: 'Edit details',
  },
  thisForm: {
    id: 'thisForm',
    defaultMessage: 'this form',
  },
  helpCentre: {
    id: 'helpCentre',
    defaultMessage: 'Help Centre',
  },
  pay: {
    id: 'pay',
    defaultMessage: 'Pay',
  },
  businessName: {
    id: 'businessName',
    defaultMessage: 'Business name',
  },
  businessRegistrationNumber: {
    id: 'businessRegistrationNumber',
    defaultMessage: 'Business registration number',
  },
  select: {
    id: 'select',
    defaultMessage: 'Select',
  },
  singleDatePickerPlaceholder: {
    id: 'singleDatePickerPlaceholder',
    defaultMessage: 'Add date',
  },
  singleDatePickerTimePlaceholder: {
    id: 'singleDatePickerTimePlaceholder',
    defaultMessage: 'Add time',
  },
  singleDatePickerHelperText: {
    id: 'singleDatePickerHelperText',
    defaultMessage: 'Please select time and date',
  },
  dateRangeStartPlaceholder: {
    id: 'dateRangeStartPlaceholder',
    defaultMessage: 'Add start date',
  },
  dateRangeEndPlaceholder: {
    id: 'dateRangeEndPlaceholder',
    defaultMessage: 'Add end date',
  },
  thisGuide: {
    id: 'thisGuide',
    defaultMessage: 'this guide',
  },
  sms: {
    id: 'sms',
    defaultMessage: 'SMS',
  },
  email: {
    id: 'email',
    defaultMessage: 'email',
  },
  uploadProducts: {
    id: 'uploadProducts',
    defaultMessage: 'Upload Products',
  },
  name: {
    id: 'name',
    defaultMessage: 'Name',
  },
  yourPhoneNumber: {
    id: 'yourPhoneNumber',
    defaultMessage: 'Your phone number',
  },
  pdsAction: {
    id: 'pdsAction',
    defaultMessage: 'View Wording',
  },
  clear: {
    id: 'clear',
    defaultMessage: 'Clear',
  },
  logo: {
    id: 'logo',
    defaultMessage: '{brand} logo',
  },
  delete: {
    id: 'delete',
    defaultMessage: 'Delete',
  },
  feedbackRequireText: {
    id: 'nps.feedbackRequireText',
    defaultMessage: 'Please provide your feedback above.',
  },
  feedbackPlaceholder: {
    id: 'nps.feedbackPlaceholder',
    defaultMessage: 'Provide feedback',
  },
  submitFeedbackText: {
    id: 'nps.submitFeedbackText',
    defaultMessage: 'Provide feedback',
  },
  reviewLeadText: {
    id: 'nps.reviewLeadText',
    defaultMessage:
      'It’s great to hear that you enjoyed your experience with us. We’d be very grateful if you left a review and shared your thoughts with others.',
  },
  reviewButtonLink: {
    id: 'nps.reviewButtonLink',
    defaultMessage: 'https://cg.xcvr.co/xcover-google-review',
  },
  reviewButtonText: {
    id: 'nps.reviewButtonText',
    defaultMessage: 'Leave us a review',
  },
  feedbackGiven: {
    id: 'nps.feedbackGiven',
    defaultMessage: 'Thanks for providing your feedback.',
  },
});
