// Needed to avoid error with `aria-hidden` labels
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useHover } from '../../helpers/hooks';

import { StyledToggleSliderContainer } from './styledToggleSlider';

type Option<T> = {
  id: T;
  label: string;
};

export type IToggleSlider<T> = {
  name: string;
  options: {
    left: Option<T>;
    right: Option<T>;
  };
  defaultOption: T;
  disabled: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, id: T) => void;
};

const ToggleSlider = <T extends string>({ name, options, defaultOption, disabled, onChange }: IToggleSlider<T>) => {
  const [selected, setSelected] = useState<T>(defaultOption);

  const [hoverRefHiddenLeft, isHoveredHiddenLeft] = useHover<HTMLLabelElement>();
  const [hoverRefVisibleLeft, isHoveredVisibleLeft] = useHover<HTMLLabelElement>();
  const isHoveredLeft = isHoveredHiddenLeft || isHoveredVisibleLeft;

  const [hoverRefHiddenRight, isHoveredHiddenRight] = useHover<HTMLLabelElement>();
  const [hoverRefVisibleRight, isHoveredVisibleRight] = useHover<HTMLLabelElement>();
  const isHoveredRight = isHoveredHiddenRight || isHoveredVisibleRight;

  const onChangeRadioGroup = (e: React.ChangeEvent<HTMLInputElement>) => {
    // We know for sure e.target.id is T but TS needs casting
    const id = e.target.id as T;

    setSelected(id);
    onChange(e, id);
  };

  return (
    <StyledToggleSliderContainer>
      <div className="hitzone-labels">
        <label
          ref={hoverRefHiddenLeft}
          aria-hidden
          htmlFor={options.left.id}
          className={`left ${disabled ? 'disabled' : ''}`}
        />
        <label
          ref={hoverRefHiddenRight}
          aria-hidden
          htmlFor={options.right.id}
          className={`right ${disabled ? 'disabled' : ''}`}
        />
      </div>

      <div role="radiogroup" className="toggle" onChange={onChangeRadioGroup}>
        <input
          type="radio"
          name={name}
          id={options.left.id}
          value={options.left.id}
          defaultChecked={options.left.id === defaultOption}
          disabled={disabled}
        />
        <input
          type="radio"
          name={name}
          id={options.right.id}
          value={options.right.id}
          defaultChecked={options.right.id === defaultOption}
          disabled={disabled}
        />

        <div className={`selected-circle ${disabled ? 'disabled' : ''}`} />
        <div
          className={`line ${isHoveredLeft && !disabled ? 'hovered-left' : ''} ${
            isHoveredRight && !disabled ? 'hovered-right' : ''
          } `}
        />
      </div>

      <div className={`visible-labels `}>
        <label
          ref={hoverRefVisibleLeft}
          htmlFor={options.left.id}
          className={`${selected === options.left.id ? 'selected' : ''} ${disabled ? 'disabled' : ''}`}
        >
          {options.left.label}
        </label>
        <label
          ref={hoverRefVisibleRight}
          htmlFor={options.right.id}
          className={`${selected === options.right.id ? 'selected' : ''} ${disabled ? 'disabled' : ''}`}
        >
          {options.right.label}
        </label>
      </div>
    </StyledToggleSliderContainer>
  );
};

export default ToggleSlider;
