import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';
import { Row, Container } from 'reactstrap';
import { HeadingTag, IIconFeaturesBlock } from '@whitelabel/helpers/types';
import {
  StyledIconListWrapper,
  StyledHeading,
  StyledIconTitle,
  StyledIconListBlock,
  StyledCol,
  StyledImageBlock,
} from './styledIconListBlock';

export interface IIconListBlock {
  headingTag: HeadingTag;
  headingTagIcons: HeadingTag;
  title: string;
  leadText: string;
  features: IIconFeaturesBlock[];
  className?: string;
  fullWidth?: boolean;
}

const IconListBlock = ({
  headingTag,
  headingTagIcons,
  title,
  leadText,
  features,
  className,
  fullWidth = false,
}: IIconListBlock): JSX.Element => {
  const theme = useTheme();
  const isMobile = !useMediaQuery({ minWidth: theme.gridBreakpoints.sm });
  const renderChildren = () => (
    <>
      <StyledHeading headingTag={headingTag} title={title} leadText={leadText} alignment="center" />
      <StyledIconListBlock className={className} data-test-id="IconListBlock">
        {features.map(({ image, mobileImage, title: titleValue }, index: number) => {
          const renderContent = () =>
            titleValue && <StyledIconTitle as={headingTagIcons}>{titleValue}</StyledIconTitle>;

          const renderImage = (withMargin: boolean) =>
            mobileImage && isMobile ? (
              <StyledImageBlock image={mobileImage} altText={titleValue} withMargin={withMargin} />
            ) : (
              <StyledImageBlock image={image} altText={titleValue} withMargin={withMargin} />
            );

          return (
            <StyledCol key={index}>
              {renderImage(!!titleValue)}
              {renderContent()}
            </StyledCol>
          );
        })}
      </StyledIconListBlock>
    </>
  );
  return (
    <StyledIconListWrapper as={fullWidth ? Container : Row}>
      {fullWidth ? <Row>{renderChildren()}</Row> : renderChildren()}
    </StyledIconListWrapper>
  );
};

export default IconListBlock;
