import React from 'react';
import { get } from 'lodash/fp';
import { CardBody } from 'reactstrap';
import { IBlockTypes, IContentBlock } from '@whitelabel/helpers/types';
import StyledCard from '../../../styled/StyledCard';
import BasicBlock from '../BasicBlock';

interface ICardBlockProps {
  value?: IContentBlock[];
  blockTypes: IBlockTypes | any;
  className?: string;
}

const CardBlock = ({ value = [], blockTypes, className }: ICardBlockProps): JSX.Element => (
  <StyledCard muted className={className} data-test-id="CardBlock">
    <CardBody>
      {value.map((block: any) => {
        if (get(`${block.type}.component`, blockTypes)) {
          const { component: Component, structBlock, props } = blockTypes[block.type];
          return (
            <Component
              key={block.id}
              id={block.id}
              {...(structBlock ? block.value : { value: block.value })}
              blockTypes={blockTypes}
              {...props}
            />
          );
        }

        return <BasicBlock key={block.id} {...block} blockTypes={blockTypes} />;
      })}
    </CardBody>
  </StyledCard>
);

export default CardBlock;
