import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { fontStyle } from '@whitelabel/helpers/style/type';
import { themeColor, color } from '@whitelabel/helpers/style/colors';
import { borderRadius, boxShadow } from '@whitelabel/helpers/style/box';
import { mediaBreakpointDown, mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { getThemeProp } from '@whitelabel/helpers/style/get';
import IconTick from '@whitelabel/media/icons/small/tick.svg';
import { inputColors } from '@whitelabel/global-theme/colors';

interface IRadioCheckboxExtraProps {
  large?: boolean;
  type?: string;
}

export const radioCheckboxStyles = css<IRadioCheckboxExtraProps>`
  position: relative;
  display: flex;
  margin-bottom: 1rem;
  padding: 0.5rem 0 0.375rem 0;
  background: ${color('white')};
  border: ${getThemeProp('borderStyle')};
  cursor: pointer;
  .form-check-label {
    ${fontStyle('formValue')};
    position: relative;
    width: 100%;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-inline-start: 3rem;
    padding-inline-end: 0.75rem;
    color: inherit;
    cursor: pointer;
    &::before {
      border: ${getThemeProp('borderStyle')};
      border-radius: ${({ type }) => (type === 'checkbox' ? borderRadius('base') : '50%')};
    }
    ::before,
    ::after {
      width: 1.25rem;
      height: 1.25rem;
      position: absolute;
      top: 1.4rem;
      left: 1rem;
      display: block;
      border-radius: ${({ type }) => (type === 'checkbox' ? borderRadius('base') : '50%')};
      transform: translateY(-50%);
      content: '';
    }

    ${mediaBreakpointDown('sm')`
      &::before,
      &::after {
        top: 1.1rem;
      }
    `}
  }

  .form-check-input:focus ~ .form-check-label::before,
  .form-check-input:hover:not(:disabled) ~ .form-check-label::before {
    border: 1px solid black !important;
  }

  /* Hide the radio inputs, while still allowing keyboard navigation */
  .form-check-input {
    border: none;
    height: 0;
    width: 0;

    &:focus {
      outline: 0;
      box-shadow: none;
    }

    &:checked {
      background-color: transparent;
      border-color: transparent;
    }
  }

  .form-check-input:checked ~ .form-check-label::before {
    border-color: ${themeColor('primary')};
    background-color: ${themeColor('primary')};
  }
  .form-check-input:checked ~ .form-check-label::after {
    background-image: ${`url("${IconTick}")`};
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1rem 1rem;
  }
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
  &:hover {
    z-index: 2;
    box-shadow: ${boxShadow('md')};
    transition: box-shadow 0.15s ease-in-out;
  }
  ${mediaBreakpointDown('sm')`
    margin-bottom: 0.5rem;
    .form-check-label {
      padding-top: 0.3125rem;
      padding-bottom: 0.3125rem;
      padding-inline-end: 0.5rem;
      max-width: 90%;
    }
  `}
  ${mediaBreakpointUp('md')`
    margin-bottom: 0.5rem;
    .form-check-label {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-inline-end: 0.5rem;
    }
  `}
  ${mediaBreakpointUp('lg')`
      margin-bottom: 1rem;
  `} 
  /* another variant i.e. normal checkbox/radio without card */
  ${({ large }) =>
    !large &&
    `
      width: auto;
      margin-bottom: 0;
      background: 0;
      border: 0;
      cursor: pointer;
      .form-check-label {
        cursor: pointer;
      }
      &:hover {
        box-shadow: none;
      }
    `}
`;

const StyledCustomInput = styled(({ ...props }) => <div {...props} />)`
  ${({ type, spacing }) =>
    (type === 'radio' || type === 'checkbox') &&
    css`
      ${radioCheckboxStyles}
      ${spacing === 'md' &&
      `
      margin: 0 !important;
      padding: 0;
      > div {
        margin: 0 !important;
        padding: 0;
      }
      `}
    `}

  // Needs '! important' to override Reactstrap when in error state
  label {
    color: ${inputColors.value.active} !important;
  }
`;

StyledCustomInput.propTypes = {
  type: PropTypes.string.isRequired,
};

export default StyledCustomInput;
