import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import { zIndex } from '@whitelabel/helpers/style/zIndex';
import { fontStyle, fontSize } from '@whitelabel/helpers/style/type';
import { gray } from '@whitelabel/helpers/style/colors';
import { boxShadow } from '@whitelabel/helpers/style/box';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { spacer, multiply } from '@whitelabel/helpers/style/units';
import { get } from '@whitelabel/helpers/style/get';
import { focusLinkStyle, focusVisibleMixin } from '@whitelabel/helpers/style/utils';
import ImageBlock from '../../ImageBlock';
import ButtonBlock from '../../ButtonBlock';

export const StyledAccordionItem = styled(Card)`
  && {
    border-width: ${get('borderWidth')};
    transition: all ease-in-out 0.25s;

    &:hover {
      z-index: ${zIndex('content')};
      border-color: ${gray(600)};
      box-shadow: ${boxShadow('md')};
    }
    ${focusVisibleMixin(focusLinkStyle)}
  }
`;

export const StyledItemHeader = styled(CardBody)`
  && {
    cursor: pointer;

    ${mediaBreakpointUp('md')`
      padding: ${spacer} ${multiply(spacer, 1.5)};
    `}
  }
`;

export const StyledItemHeading = styled.h3`
  && {
    ${fontStyle('accordionHeader')}
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    text-transform: none;
  }
`;

export const StyledItemInner = styled(CardBody)`
  && {
    padding: 0 ${spacer} ${spacer};
    font-size: ${multiply(fontSize('base'), 1.125)};
    line-height: ${multiply(spacer, 1.6875)};

    ${mediaBreakpointUp('md')`
      padding: 0 ${multiply(spacer, 1.5)} ${spacer};
    `}
  }
`;

// Disable eslint to keep the original logic: not pass no-unused-vars
// props down to child component.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledItemImageBlock = styled(({ withMargin, ...props }) => <ImageBlock {...props} />)`
  height: auto;

  ${({ withMargin }) =>
    withMargin &&
    css`
      margin-bottom: ${spacer};

      ${mediaBreakpointUp('md')`
        margin-bottom: ${multiply(spacer, 1.5)};
      `}

      ${mediaBreakpointUp('lg')`
        margin-bottom: ${multiply(spacer, 2)};
      `}
    `}
`;

StyledItemImageBlock.propTypes = {
  withMargin: PropTypes.bool,
};

StyledItemImageBlock.defaultProps = {
  withMargin: true,
};

export const StyledItemBody = styled.div`
  color: ${gray(600)};

  p:last-child {
    margin-bottom: 0;
  }
`;

export const StyledItemButtonBlock = styled(ButtonBlock)`
  width: auto;
  margin-top: ${multiply(spacer, 0.75)};
`;
