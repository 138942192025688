import styled from 'styled-components';
import { rem } from 'polished';
import { marginBottom, mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { gray, color } from '@whitelabel/helpers/style/colors';
import { fontWeight, fontStyle } from '@whitelabel/helpers/style/type';
import { boxShadow } from '@whitelabel/helpers/style/box';
import { get } from '@whitelabel/helpers/style/get';

export const StyledContainer = styled.div`
  ${marginBottom}
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  ${mediaBreakpointUp('md')`
    gap: 1.5rem;
  `}

  ${mediaBreakpointUp('lg')`
    flex-direction: row;
    align-items: start;
    gap: 2rem;
  `}
`;

export const StyledAnnotationWrapper = styled.div`
  width: 100%;
  border: ${get('borderStyle')};
  border-radius: ${rem(3)};
  padding: 1rem;
  ${mediaBreakpointUp('lg')`
    flex: 41.6%;
  `}
`;

export const StyledImageWrapper = styled.div`
  width: 83.3%;
  box-shadow: ${boxShadow('base')};
  > img {
    width: 100%;
  }
  ${mediaBreakpointUp('lg')`
    width: auto;
    flex: 58.4%;
  `}
`;

export const StyledList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const StyledListItem = styled.li`
  padding: 0;
  display: flex;
  margin-top: 1rem;
`;

export const StyledItemNumber = styled.span`
  width: ${rem(28)};
  min-width: ${rem(28)};
  max-width: ${rem(28)};
  height: ${rem(28)};
  border-radius: 50%;
  color: ${color('white')};
  font-weight: ${fontWeight('medium')};
  background-color: #ff39df;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledItemDiscription = styled.span`
  ${fontStyle('paragraph')}
  margin-left: ${rem(8)};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${gray(600)};
`;

export const StyledTitle = styled.h3`
  ${fontStyle('h4')}
  font-weight: ${fontWeight('medium')};
  text-transform: none;
`;
