import React, { ReactEventHandler } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, NavLink } from 'react-router-dom';
import messages from '../messages';
import { INavItem } from '../../../helpers/types';
import { getNormalOrReactRouterLinkProp } from '../../../helpers/utils';
import { StyledNavItem, StyledNavLink, StyledNavIcon } from './styledNavItem';

interface INavItemProps {
  item: INavItem;
  onClick?: ReactEventHandler;
  className?: string;
  hideOnMobileAndTablet?: boolean;
}
const NavItem = ({
  item: { messageKey, name, path, activePaths, icon, tag = NavLink },
  onClick,
  className,
  hideOnMobileAndTablet,
}: INavItemProps): JSX.Element => {
  const { locale, formatMessage } = useIntl();
  const { pathname } = useLocation();

  const renderChildren = () => (
    <>
      {icon ? <StyledNavIcon as={icon} /> : ''}
      {messageKey ? formatMessage(messages[messageKey as keyof typeof messages]) : name}
    </>
  );
  return (
    <StyledNavItem data-test-id="NavItem" className={className} $hideOnMobileAndTablet={hideOnMobileAndTablet}>
      <StyledNavLink
        tag={tag}
        active={
          activePaths && !!activePaths.find((activePath: string) => pathname.startsWith(`/${locale}${activePath}`))
        }
        data-test-id="NavItem-link"
        data-analytics={`navbar${messageKey || name}`}
        onClick={onClick}
        {...getNormalOrReactRouterLinkProp(tag, locale, path)}
      >
        {renderChildren()}
      </StyledNavLink>
    </StyledNavItem>
  );
};

export default NavItem;
