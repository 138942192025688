import styled from 'styled-components';
import { getRightValue } from '@whitelabel/helpers/style/box';

export const StyledPasswordInput = styled.div`
  position: relative;
`;

export const StyledIconContainer = styled.div<{ $rtl: boolean }>`
  ${getRightValue('1rem')}
  position: absolute;
  top: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
`;
