import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { SMALL_ICONS } from '@whitelabel/helpers/smallIcons';
import { ButtonVariant, ButtonType, SmallIconType } from '@whitelabel/helpers/types';
import { smoothScrollToAnchor } from '@whitelabel/helpers/url';
import { isOnServer } from '@whitelabel/helpers/utils';
import Button from '../../Button';
import { PackageComponentsContext } from '../../../context/PackageComponents';

const buttonTypes: Record<ButtonVariant, ButtonType> = {
  primary: 'primary',
  outline: 'secondary',
  secondary: 'secondary',
  link: 'tertiary',
};

export interface IButtonBlockProps {
  link: string;
  text: string;
  icon?: SmallIconType;
  variant: ButtonVariant;
  newWindow?: boolean;
  className?: string;
  analytics?: string;
  asLinkReferrer?: boolean;
}

interface ILinkToValue {
  pathname: string;
  state?: { referrer?: Location };
}

const ButtonBlock = ({
  link,
  text,
  icon,
  variant = 'primary',
  newWindow = false,
  className,
  analytics,
  asLinkReferrer,
  ...props
}: IButtonBlockProps): JSX.Element => {
  const intl = useIntl();
  const { location } = !isOnServer ? window : { location: undefined };
  const { xcoverShared } = useContext(PackageComponentsContext);
  const NextJSLinkTo = xcoverShared?.linkTo;
  const getLinkProps = (): {
    tag?: React.ElementType;
    href?: string;
    to?: string | ILinkToValue;
    onClick?: () => void;
  } => {
    const linkStartLetter = link.charAt(0);

    let toVal: ILinkToValue = {
      pathname: `/${intl.locale}${link}`,
    };

    const queryToVal = link;

    if (asLinkReferrer) {
      toVal = {
        ...toVal,
        state: { referrer: location },
      };
    }

    switch (linkStartLetter) {
      case '/':
        return NextJSLinkTo
          ? {
              tag: NextJSLinkTo,
              href: `/${intl.locale}${link}`,
            }
          : { tag: Link, to: toVal };
      case '?':
        return { tag: Link, to: queryToVal };
      case '#':
        return { onClick: smoothScrollToAnchor(link) };
      default:
        return { tag: 'a', href: link };
    }
  };

  return (
    <Button
      {...getLinkProps()}
      target={newWindow ? '_blank' : undefined}
      buttonType={buttonTypes[variant] || 'primary'}
      icon={icon ? SMALL_ICONS[icon as keyof typeof SMALL_ICONS] : undefined}
      className={className}
      data-analytics={analytics}
      {...props}
      data-test-id="ButtonBlock"
    >
      {text}
    </Button>
  );
};

export default ButtonBlock;
