import React from 'react';
import { StepperButtonsContainer, StepperButton } from './styledStepper';

type IStepper = {
  onClick: (i: number) => void;
  steps: number;
  activeStep: number;
};

const Stepper = ({ onClick, steps, activeStep }: IStepper) => (
  <StepperButtonsContainer className="stepper">
    {Array.from({ length: steps }, (_, index) => {
      const pageNumber = index + 1;
      return (
        <StepperButton
          key={pageNumber}
          name={`go to page number ${pageNumber}`}
          type="button"
          onClick={() => onClick(pageNumber)}
          $active={pageNumber === activeStep}
        >
          <div />
        </StepperButton>
      );
    })}
  </StepperButtonsContainer>
);

export default Stepper;
