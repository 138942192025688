import React, { createElement } from 'react';
import { useMediaQuery } from 'react-responsive';
import { withTheme, Theme } from 'styled-components';
import { Row } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import { useIntl } from 'react-intl';
import ProtectionTickIcon from '@whitelabel/media/icons/small/protection-tick.svg';
import GreenTickIcon from '@whitelabel/media/icons/small/green-tick.svg';
import XcoverLogo from '@whitelabel/media/logo.svg';
import { IImageBlock, IXCMSImage, Alignment, IButtonBlock } from '@whitelabel/helpers/types';
import { StyledTitle } from '../HeadingBlock/styledHeadingBlock';
import ButtonsBlock from '../ButtonsBlock';
import { transformLink } from '../../../helpers/transform';
import TrustPilot from '../../TrustPilot';
import Banner, { IBanner } from '../../Banner/Banner';
import {
  StyledBGContainer,
  StyledImagesBlock,
  StyledContainer,
  StyledTitleContainer,
  StyledCol,
  StyledWrapper,
  StyledLeadText,
  StyledCallout,
  StyledCalloutListItem,
  StyledCalloutLowerText,
  StyledCalloutHeading,
  StyledBgContentContainer,
} from './styledHeroModernBlock';
import messages from './messages';

interface IHeroCalloutBlock {
  type: string;
  value: {
    title: string;
    listItems: {
      listType: 'unordered' | 'ordered' | 'inclusions' | 'exclusions';
      items: string[];
    };
  };
  id: string;
}

interface IHeroBlockProps {
  title: string;
  leadText?: string;
  backgroundImage: IXCMSImage;
  supportingImages: {
    alignment: Alignment;
    images: IImageBlock[];
  };
  buttons: {
    id: string;
    type: string;
    value: {
      alignment: Alignment;
      buttons: IButtonBlock[];
    };
  }[];
  theme: Theme;
  heroCalloutOptions?: IHeroCalloutBlock[];
  trustpilotCounter?: string;
  id?: string;
  condensed: boolean;
  subtextBanner?: IBanner;
}

const HeroModernBlock = ({
  title,
  leadText = '',
  supportingImages,
  buttons,
  theme,
  id = '',
  heroCalloutOptions,
  trustpilotCounter,
  condensed,
  subtextBanner,
}: IHeroBlockProps) => {
  const intl = useIntl();
  const { formatMessage } = useIntl();
  const heroCallout = (heroCalloutOptions?.length && heroCalloutOptions[0].value) || null;
  const isMobile = !useMediaQuery({ minWidth: theme.gridBreakpoints.md });

  const renderTitle = () => (
    <StyledTitle className="desktop-title" hasBoxShadow $shouldTitleBeUppercase>
      <span>{ReactHtmlParser(title)}</span>
    </StyledTitle>
  );

  const renderLeadText = () => {
    if (!leadText) return null;
    return (
      <StyledLeadText>
        {ReactHtmlParser(leadText, {
          transform: transformLink(intl),
        })}
      </StyledLeadText>
    );
  };

  const renderButtons = () => (
    <StyledWrapper $isButtons>
      <ButtonsBlock {...buttons[0].value} />
    </StyledWrapper>
  );

  const renderImagesBlock = () => (
    <StyledImagesBlock className="images-block" $condensed={condensed} images={supportingImages.images} />
  );

  const renderTrustpilot = () => {
    if (!trustpilotCounter) return null;
    return <TrustPilot className="trustpilot-counter" trustpilotCounter={trustpilotCounter} />;
  };

  const renderBanner = () => {
    if (!subtextBanner) return null;
    return <Banner hasDollarIcon={subtextBanner.hasDollarIcon} subtext={subtextBanner.subtext} />;
  };

  const renderCallout = () => {
    if (!heroCallout) return null;
    const {
      title: titleValue,
      listItems: { listType, items },
    } = heroCallout;
    const listEl = listType === 'ordered' ? 'ol' : 'ul';
    const renderListItems = items.map((item, index) => (
      <StyledCalloutListItem key={`callout-item-${index}`}>
        <img src={GreenTickIcon} alt={formatMessage(messages.calloutGreenTick)} />
        <span>{item}</span>
      </StyledCalloutListItem>
    ));
    return (
      <StyledCallout>
        {titleValue && (
          <StyledCalloutHeading>
            <span>{titleValue}</span>
            <img src={ProtectionTickIcon} alt={formatMessage(messages.calloutProtectionIcon)} />
          </StyledCalloutHeading>
        )}
        {createElement(listEl, {}, renderListItems)}
        <StyledCalloutLowerText>
          Powered by <img src={XcoverLogo} alt={formatMessage(messages.xcoverLogoAltText)} />
        </StyledCalloutLowerText>
      </StyledCallout>
    );
  };

  const renderMobileDOM = () => (
    <div id={id}>
      <StyledBGContainer className="bg-outer" $rtl={intl.bidi} $condensed={condensed}>
        <StyledTitleContainer className="mobile-title-container" $condensed={condensed}>
          {renderImagesBlock()}
          <div className="mobile-title">{renderTitle()}</div>
        </StyledTitleContainer>
        <StyledBgContentContainer>
          {renderLeadText()}
          {!!buttons.length && renderButtons()}
          {renderTrustpilot()}
          {renderBanner()}
        </StyledBgContentContainer>
      </StyledBGContainer>
    </div>
  );

  const renderNonMobileDOM = () => (
    <div id={id}>
      <StyledBGContainer className="bg-outer" $rtl={intl.bidi} $condensed={condensed}>
        <StyledContainer className="bg-inner">
          <StyledWrapper as={Row} $isMainContainer $condensed={condensed}>
            <StyledCol className="column left" md="6" xl="7" $justifyContentCenter>
              {renderTitle()}
              {renderLeadText()}
              {!!buttons.length && renderButtons()}
              {renderTrustpilot()}
              {renderBanner()}
            </StyledCol>
            <StyledCol className="column right" sm="6" xl="5">
              {renderImagesBlock()}
              {renderCallout()}
            </StyledCol>
          </StyledWrapper>
        </StyledContainer>
      </StyledBGContainer>
    </div>
  );

  return isMobile ? renderMobileDOM() : renderNonMobileDOM();
};

export default withTheme(HeroModernBlock);
