import styled, { css } from 'styled-components';
import { ReactComponent as IconLoading } from '@whitelabel/media/icons/loading.svg';
import { themeColor } from '@whitelabel/helpers/style/colors';
import { spacer, multiply } from '@whitelabel/helpers/style/units';

export const StyledLoading = styled.div<{ $shouldBeFullPageHeight?: boolean }>`
  ${({ $shouldBeFullPageHeight }) =>
    $shouldBeFullPageHeight &&
    css`
      height: 100vh;
    `}
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  justify-self: center;
`;

export const StyledIcon = styled(IconLoading)`
  display: block;
  width: ${multiply(spacer, 3.375)};
  height: ${multiply(spacer, 4)};
  color: ${themeColor('primary')};
`;
