import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { fontStyle } from '@whitelabel/helpers/style/type';
import { themeColor } from '@whitelabel/helpers/style/colors';
import { borderRadius } from '@whitelabel/helpers/style/box';
import { getThemeProp, toggleColor } from '@whitelabel/helpers/style/get';

export const StyledToggleContainer = styled.div`
  display: flex;
  max-width: fit-content;
`;

const getWidth = (size: 'lg' | 'md' | 'sm') => {
  switch (size) {
    case 'lg':
      return rem(70);
    case 'md':
      return rem(54);
    case 'sm':
      return rem(38);
    default:
      return rem(54);
  }
};

const getHeight = (size: 'lg' | 'md' | 'sm') => {
  switch (size) {
    case 'lg':
      return rem(60);
    case 'md':
      return rem(46);
    case 'sm':
      return rem(30);
    default:
      return rem(46);
  }
};

export const StyledToggleButton = styled.button<{
  $selected: boolean;
  $size: 'lg' | 'md' | 'sm';
  $isFirst: boolean;
  $isLast: boolean;
  $isInvalid: boolean;
}>`
  ${fontStyle('button')}
  height: ${({ $size }) => getHeight($size)};
  width: ${({ $size }) => getWidth($size)};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background-color: ${({ $selected }) => ($selected ? toggleColor('bg', 'active') : toggleColor('bg', 'default'))};
  border: ${getThemeProp('borderStyle')};
  border-color: ${({ $selected }) =>
    $selected ? toggleColor('outline', 'active') : toggleColor('outline', 'default')};

  ${({ $isInvalid }) =>
    $isInvalid &&
    css`
      border-color: ${themeColor('warning')};
    `}

  ${({ $isFirst }) =>
    $isFirst &&
    css`
      border-start-start-radius: ${borderRadius('lg')};
      border-end-start-radius: ${borderRadius('lg')};
    `}

  ${({ $isLast }) =>
    $isLast &&
    css`
      border-start-end-radius: ${borderRadius('lg')};
      border-end-end-radius: ${borderRadius('lg')};
    `}

    &:hover {
    border-color: ${toggleColor('outline', 'hover')};
    background-color: ${toggleColor('bg', 'hover')};
  }
`;
