import { Input, InputGroup, InputGroupText } from 'reactstrap';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { gray, color } from '@whitelabel/helpers/style/colors';
import { borderRadius, flipForRTL } from '@whitelabel/helpers/style/box';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { spacer, multiply } from '@whitelabel/helpers/style/units';
import { get } from '@whitelabel/helpers/style/get';

export const StyledInputGroup = styled(InputGroup)`
  border: ${get('borderWidth')} solid ${gray(500)};
  border-radius: ${borderRadius('base')};
  ${mediaBreakpointUp('sm')`
    padding-inline-end: 0.1rem;
    padding-inline-start: 0.1rem;
    
    ${({ $fullWidth }: { $fullWidth?: boolean }) =>
      !$fullWidth &&
      css`
        max-width: 22.3125rem;
      `}
  `};
`;

export const StyledButton = styled('button')`
  margin: 0 ${multiply(spacer, 0.125)} 0 0;
  padding: 0;
  overflow: hidden;
  color: ${gray(600)};
  background-color: ${color('white')};
  border: 0;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${gray(300)};
    `}
`;

export const StyledInputGroupText = styled(InputGroupText)<{ $rtl: boolean }>`
  ${flipForRTL}
  padding: 0 ${multiply(spacer, 0.625)} 0 ${spacer};
  color: ${gray(600)};
  background-color: ${color('white')};
  border: none;
  line-height: 1;
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${gray(300)};
    `}
`;

export const StyledInput = styled(Input)`
  && {
    height: ${rem('46px')};
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: none;
  }
`;
