import { defineMessages } from 'react-intl';

const messages = defineMessages({
  logoAltText: {
    id: 'trustpilot.logoAltText',
    defaultMessage: 'Trustpilot logo',
  },
  starsAltText: {
    id: 'trustpilot.starsAltText',
    defaultMessage: 'Trustpilot 4.5 stars',
  },
  excellent: {
    id: 'trustpilot.excellent',
    defaultMessage: 'Excellent',
  },
  reviews: {
    id: 'trustpilot.reviews',
    defaultMessage: 'Reviews {totalReviews}',
  },
});

export default messages;
