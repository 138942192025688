import styled from 'styled-components';
import { themeColor } from '@whitelabel/helpers/style/colors';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { getThemeProp } from '@whitelabel/helpers/style/get';

export const StyledValue = styled.span`
  border-bottom: ${getThemeProp('borderWidth')} dashed ${themeColor('info')};
  cursor: pointer;

  ${mediaBreakpointUp('md')`
    justify-content: flex-start;
  `}
`;
