import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { modifyChargeWidget } from './helper';
import { StyledModalWithChargeWidgetSupport } from './styledModalWithChargeWidgetSupport';

export interface IModalWithChargeWidgetSupportProps {
  chargeWidgetReady: boolean;
  isOpen: boolean;
  centered: boolean;
  toggle: () => void;
  children: React.ReactNode;
  showChargeWidget?: boolean;
}

const ModalWithChargeWidgetSupport = ({
  chargeWidgetReady,
  isOpen,
  centered,
  toggle,
  children,
  showChargeWidget = false,
}: IModalWithChargeWidgetSupportProps): JSX.Element => {
  const { bidi } = useIntl();

  useEffect(() => {
    if (chargeWidgetReady) {
      modifyChargeWidget();
    }
  }, [chargeWidgetReady]);

  useEffect(() => {
    if (showChargeWidget) {
      const footer = document.querySelector<HTMLElement>('.modal-footer');
      if (footer) {
        footer.style.display = 'none';
      }
    }
  }, [showChargeWidget]);

  return (
    <StyledModalWithChargeWidgetSupport
      $chargeWidgetReady={chargeWidgetReady}
      $rtl={bidi}
      isOpen={isOpen}
      centered={centered}
      toggle={toggle}
      backdrop="static"
      size="md"
      data-test-id="ModifyModal"
    >
      {children}
    </StyledModalWithChargeWidgetSupport>
  );
};

export default ModalWithChargeWidgetSupport;
