import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useIntl } from 'react-intl';
import { IButtonBlock, IXCMSImage } from '@whitelabel/helpers/types';
import { transformLink } from '../../../helpers/transform';
import HeadingBlock from '../HeadingBlock';
import {
  StyledWrapper,
  StyledContainer,
  StyledContent,
  StyledImage,
  StyledText,
  StyledButtonBlock,
  StyledButtonContainer,
} from './styledContentImageBlock';

interface IContentImageBlock {
  title: string;
  contentImages: {
    alignment: 'left' | 'right';
    body: string;
    image: IXCMSImage;
    title: string;
  }[];
  button: {
    id: string;
    type: string;
    value: IButtonBlock;
  }[];
}

const ContentImageBlock = ({ title, contentImages, button }: IContentImageBlock): JSX.Element => {
  const intl = useIntl();

  return (
    <StyledWrapper>
      <StyledContainer>
        <HeadingBlock headingTag="h2" title={title} shouldParse />
        {contentImages.map(({ alignment, body, image, title: titleValue }) => (
          <StyledContent className="content-row" key={titleValue} alignment={alignment}>
            <StyledText md="12" lg="6">
              <h4>{titleValue}</h4>
              {ReactHtmlParser(body, { transform: transformLink(intl) })}
            </StyledText>
            <StyledImage xs="10" sm="auto" lg="6">
              <img src={image.file} alt={image.title} />
            </StyledImage>
          </StyledContent>
        ))}
        <StyledButtonContainer>
          {button && !!button.length && button.map(({ value }) => <StyledButtonBlock key={value.text} {...value} />)}
        </StyledButtonContainer>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default ContentImageBlock;
