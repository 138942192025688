const calculateColumnWidth = (gridColumns: any, gridColumnsOffset: any) => {
  const columnWidth = 12 / gridColumns;
  if (gridColumns === 1) {
    // centers the single column where possible
    const columnGridWidth = Math.ceil(columnWidth - gridColumnsOffset * 2);
    return columnGridWidth >= 1 ? columnGridWidth : 1;
  }
  return Math.floor(columnWidth);
};

export default calculateColumnWidth;
