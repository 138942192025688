import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { NavItem, NavLink as BSNavLink } from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';
import { fontSize } from '@whitelabel/helpers/style/type';
import { themeColor, color } from '@whitelabel/helpers/style/colors';
import { mediaBreakpointUp, breakpointKeys, breakpointNext, breakpointInfix } from '@whitelabel/helpers/style/grid';

export const StyledNavItem = styled(NavItem)`
  padding-inline-end: 0;
  font-size: ${fontSize('lg')};

  &:not(:last-child) {
    margin-bottom: 1rem;

    ${mediaBreakpointUp('lg')`
      margin-bottom: 0;
    `}
  }
`;

export const StyledNavLink = styled(BSNavLink).attrs(() => ({ tag: RRNavLink }))`
  padding: 0;
  text-decoration: none;

  :focus-visible {
    outline-width: ${rem(1)};
    outline-offset: ${rem(5)};
    outline-color: ${color('white')};
    outline-style: solid;
  }

  ${({ theme }) =>
    breakpointKeys(theme).map((breakpoint) => {
      const next = breakpointNext(breakpoint)({ theme });

      if (next) {
        const infix = breakpointInfix(next)({ theme });

        return css`
          ${mediaBreakpointUp(next)`
            .navbar-expand${infix} && {
              padding-right: 0;
              padding-inline-start: 0;
            }
          `}
        `;
      }

      return null;
    })}

  ${mediaBreakpointUp('md')`
    margin: 0.5rem;
    margin-inline-start: 0;
  `}

  ${mediaBreakpointUp('lg')`
    margin: 0 0.75rem;
    font-size: 1.125rem;
    padding-top: 0.1875rem;
  `}

  .navbar-dark &&&:not(.active) {
    color: ${color('white')};

    ${mediaBreakpointUp('lg')`
      color: ${themeColor('header')};
    `}
  }

  .navbar-dark &&&.active {
    color: ${color('white')};
    ${mediaBreakpointUp('lg')`
      color: ${themeColor('header')};
    `}
  }
`;

export const StyledNavIcon = styled.svg`
  display: block;
  margin-inline-end: 0.5rem;
  color: ${color('white')};
`;
