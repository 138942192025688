import styled from 'styled-components';
import { gray } from '@whitelabel/helpers/style/colors';
import { spacer, multiply } from '@whitelabel/helpers/style/units';
import { ReactComponent as IconLoading } from '@whitelabel/media/icons/loading.svg';

export const StyledSelectLoadingIcon = styled(IconLoading)`
  position: absolute;
  top: ${multiply(spacer, 1.34375)};
  left: ${spacer};
  display: block;
  width: ${multiply(spacer, 1.25)};
  height: ${multiply(spacer, 1.0625)};
  color: ${gray(600)};
`;
