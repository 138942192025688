// .badge class is not in use
import { css } from 'styled-components';
import { fontSize, lineHeight } from '@whitelabel/helpers/style/type';
import { borderRadius } from '@whitelabel/helpers/style/box';
import { spacer, multiply } from '@whitelabel/helpers/style/units';

const globalBadgeStyle = css`
  h1,
  .h1 {
    .badge {
      margin-top: ${multiply(spacer, 0.875)};
      padding: ${multiply(spacer, 0.5)} ${multiply(spacer, 2)};
      font-size: ${fontSize('lg')};
      line-height: ${lineHeight('base')};
    }
  }

  .badge {
    border-radius: ${borderRadius('base')};
  }
`;

export default globalBadgeStyle;
