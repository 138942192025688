export default function getStylesBasedOnTotalImages(totalImages: number): {
  alignment: string;
  imageBoxMaxWidth: number;
  imageBoxMaxWidthSm: number;
  imageBoxMaxWidthMd: number;
  imageBoxMaxWidthLg?: number;
  addMarginTopAfter?: number;
  addMarginTopAfterSm?: number;
  addMarginTopAfterMd?: number;
  imageMaxWidth?: string;
  imageMaxWidthSm?: string;
  imageMaxWidthMd?: string;
} {
  const stylesCommon = {
    alignment: 'space-around',
  };
  if (totalImages >= 5) {
    return {
      ...stylesCommon,
      imageBoxMaxWidth: 100 / 3,
      imageBoxMaxWidthSm: 100 / 3,
      imageBoxMaxWidthMd: 100 / 3,
      imageBoxMaxWidthLg: 100 / totalImages,
      addMarginTopAfter: 3,
      addMarginTopAfterSm: 3,
      addMarginTopAfterMd: 3,
      imageMaxWidthSm: '70%',
      imageMaxWidthMd: '70%',
    };
  }
  if (totalImages === 4) {
    return {
      ...stylesCommon,
      imageBoxMaxWidth: 40,
      imageBoxMaxWidthSm: 25,
      imageBoxMaxWidthMd: 25,
      addMarginTopAfter: 2,
    };
  }

  if (totalImages === 1) {
    return {
      ...stylesCommon,
      imageBoxMaxWidth: 100,
      imageBoxMaxWidthSm: 100,
      imageBoxMaxWidthMd: 100,
      imageBoxMaxWidthLg: 75,
    };
  }

  return {
    ...stylesCommon,
    imageBoxMaxWidth: 100,
    imageBoxMaxWidthSm: 100 / 3,
    imageBoxMaxWidthMd: 25,
    addMarginTopAfter: 1,
    imageMaxWidth: '12.5rem',
  };
}
