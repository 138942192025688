import { rem } from 'polished';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { ButtonType } from '@whitelabel/helpers/types';
import { fontStyle, fontFamily, resetLinkStyle } from '@whitelabel/helpers/style/type';
import { boxShadow, flipForRTL, flipFlexForRTL } from '@whitelabel/helpers/style/box';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { spacer, multiply } from '@whitelabel/helpers/style/units';
import { getThemeProp, button, buttonColor, contentColor } from '@whitelabel/helpers/style/get';
import { focusVisibleMixin } from '@whitelabel/helpers/style/utils';
import { IButtonProps } from './types';

export const StyledIcon = styled.svg.attrs(() => ({ 'aria-hidden': true }))<{
  $rtl: boolean;
  size: IButtonProps['size'];
  $iconPosition: IButtonProps['iconPosition'];
}>`
  ${flipForRTL}
  ${({ $rtl, size, $iconPosition }) => {
    switch ($iconPosition) {
      case 'right':
        return css`
          margin-inline-${$rtl ? 'end' : 'start'}: ${size === 'xs' || size === 'sm' ? '0.375rem' : '0.5rem'};
        `;
      case 'top':
        return css`
          margin: 0 0 0.125rem 0;
        `;
      default:
        return css`
        margin-inline-${$rtl ? 'start' : 'end'}: ${size === 'xs' || size === 'sm' ? '0.375rem' : '0.5rem'};
      `;
    }
  }}
  transition: color 0.25s ease-in-out;
`;

const notDisabledAndActive = `:not(:disabled):not(.disabled):active, :not(:disabled):not(.disabled).active`;
const notDisabledAndFocus = `:not(:disabled):not(.disabled):focus, :not(:disabled):not(.disabled).focus`;

export const buttonVariant = (buttonType: ButtonType = 'primary') => css`
  padding: ${buttonType === 'tertiary' && 0};
  color: ${buttonColor(buttonType, 'label')};
  background: ${buttonColor(buttonType, 'bg')};
  border: ${buttonType === 'tertiary' ? 0 : getThemeProp('borderWidth')} solid ${buttonColor(buttonType, 'outline')};
  svg {
    color: ${buttonColor(buttonType, 'icon')};
  }

  &.disabled,
  &:disabled {
    color: ${buttonColor(buttonType, 'label', 'disabled')};
    background: ${buttonColor(buttonType, 'bg', 'disabled')};
    border-color: ${buttonColor(buttonType, 'outline', 'disabled')};

    svg {
      color: ${buttonColor(buttonType, 'icon', 'disabled')};
    }
  }

  &${notDisabledAndFocus} {
    color: ${buttonColor(buttonType, 'label', 'focus')};
    background: ${buttonColor(buttonType, 'bg', 'focus')};
    filter: none;
    svg {
      color: ${buttonColor(buttonType, 'icon', 'focus')};
    }
  }

  @media not print {
    &:not(:disabled):not(.disabled):hover {
      color: ${buttonColor(buttonType, 'label', 'hover')};
      background: ${buttonColor(buttonType, 'bg', 'hover')};
      border-color: ${buttonColor(buttonType, 'outline', 'hover')};
      box-shadow: ${buttonType === 'tertiary' ? 'none' : boxShadow('md')};
      filter: brightness(1.07);

      svg {
        color: ${buttonColor(buttonType, 'icon', 'hover')};
      }
    }
  }

  &:not(:disabled):not(.disabled) {
    ${focusVisibleMixin(css`
      color: ${buttonColor(buttonType, 'label', 'focusVisible')};
      background: ${buttonColor(buttonType, 'bg', 'focusVisible')};
      border-color: ${contentColor('borderFocus')};
      filter: none;
      outline: none;
      box-shadow: 0 0 0 ${buttonType === 'tertiary' ? '2px' : '1px'} ${contentColor('borderFocus')};

      svg {
        color: ${buttonColor(buttonType, 'icon', 'focusVisible')};
      }
    `)}
  }

  &${notDisabledAndActive}, .show > &.dropdown-toggle {
    color: ${buttonColor(buttonType, 'label', 'active')};
    background: ${buttonColor(buttonType, 'bg', 'active')};
    border-color: ${buttonColor(buttonType, 'outline', 'active')};
    ${buttonType === 'secondary'
      ? css`
          box-shadow: 0 0 0 1px ${contentColor('borderFocus')};
        `
      : css`
          box-shadow: none;
        `}
    filter: none;

    svg {
      color: ${buttonColor(buttonType, 'icon', 'active')};
    }
  }
`;

export const StyledButton = styled(Button).withConfig<{
  $rtl: boolean;
  buttonType: ButtonType;
  size: IButtonProps['size'];
  $iconPosition: IButtonProps['iconPosition'];
  $icon: IButtonProps['icon'];
}>({
  shouldForwardProp(prop: any) {
    return !['buttonType'].includes(prop);
  },
})`
  ${resetLinkStyle}
  ${flipFlexForRTL}
  ${({ $rtl, $iconPosition }) => {
    switch ($iconPosition) {
      case 'right':
        return css`
          flex-direction: ${$rtl ? 'row' : 'row-reverse'};
        `;
      case 'top':
        return css`
          flex-direction: column;
        `;
      default:
        return css`
          flex-direction: ${$rtl ? 'row-reverse' : 'row'};
        `;
    }
  }};
  direction: initial;
  display: ${({ $block }) => ($block ? 'flex' : 'inline-flex')};
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: ${button('fontWeight')};
  font-family: ${fontFamily('headings')};
  white-space: normal;
  text-align: center;
  text-transform: ${button('textTransform')};
  text-decoration: none;
  vertical-align: middle;
  user-select: none;

  ${mediaBreakpointUp('sm')`
    width: ${button('width')};
    white-space: nowrap;
  `};

  &:hover,
  &:focus,
  &.focus {
    text-decoration: none;
    outline: 0;
  }

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    box-shadow: none;
    pointer-events: none;
  }

  &:not(:disabled):not(.disabled) {
    cursor: pointer;
    transition: all ease-in-out 0.25s;
  }

  ${({ $block }) =>
    $block &&
    css`
      width: 100%;

      + & {
        margin-top: ${multiply(spacer, 0.5)};
      }

      input[type='submit'] &,
      input[type='reset'] &,
      input[type='button'] & {
        width: 100%;
      }
    `}

  ${({ size, theme, buttonType, $icon }) => {
    switch (size) {
      case 'xs': {
        return css`
          ${fontStyle('buttonExtraSmall')};
        `;
      }

      case 'sm': {
        return css`
          ${fontStyle('buttonSmall')};
          font-size: ${buttonType === 'tertiary' ? theme.fontSizes.sm : theme.fontSizes.md};
          padding: ${$icon ? rem(7) : '0.5rem'} 0.75rem;
          border-radius: ${theme.button.borderRadii.base};
        `;
      }

      case 'lg': {
        return css`
          ${fontStyle('button')};
          padding: ${$icon ? rem(15) : '1rem'} 6.5rem;
          border-radius: ${theme.button.borderRadii.base};
        `;
      }

      case 'md':
      default: {
        return css`
          ${fontStyle('button')};
          padding: ${$icon ? rem(15) : '1rem'} 1rem;
          border-radius: ${theme.button.borderRadii.base};
          ${mediaBreakpointUp('sm')`
             padding: ${$icon ? rem(15) : '1rem'} 2rem;
          `}
        `;
      }
    }
  }}

  ${({ buttonType }) => buttonVariant(buttonType)}
`;

StyledButton.propTypes = {
  $block: PropTypes.bool,
};

StyledButton.defaultProps = {
  $block: undefined,
};
