import { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { smoothScrollToAnchor } from '@whitelabel/helpers/url';
import { PackageComponentsContext } from '../../../context/PackageComponents';

interface IScrollToAnchorProps {
  anchor?: string;
  gap?: number;
}

const ScrollToAnchor = ({ anchor, gap }: IScrollToAnchorProps): null => {
  const { hash } = useLocation();
  useEffect(() => {
    if (anchor && hash === `#${anchor}`) {
      smoothScrollToAnchor(`#${anchor}`, gap)();
    }
  }, [anchor, gap, hash]);
  return null;
};

const Component = ({ anchor, gap }: IScrollToAnchorProps): null => {
  const packageComponents = useContext(PackageComponentsContext);

  /**
   * If the custom ScrollToAnchor function is defined in the packageComponents context, use it.
   * Otherwise, use the default ScrollToAnchor function.
   * */
  if (packageComponents.xcoverShared?.scrollToAnchor) {
    return packageComponents.xcoverShared.scrollToAnchor({ anchor, gap });
  }

  if (packageComponents.qbf?.scrollToAnchor) {
    return packageComponents.qbf.scrollToAnchor({ anchor, gap });
  }

  return ScrollToAnchor({ anchor, gap });
};

export default Component;
