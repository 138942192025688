import styled, { css } from 'styled-components';
import { fontStyle } from '@whitelabel/helpers/style/type';
import { mediaBreakpointUp, marginBottom } from '@whitelabel/helpers/style/grid';

interface IStyledManualAddressInput {
  $hideAddressLine1: boolean;
  $hideAddressLine2: boolean;
}

export const StyledManualAddressInput = styled.div<IStyledManualAddressInput>`
  label {
    margin-bottom: 0.5rem;
    ${fontStyle('formLabel')}
  }

  .input-wrapper {
    display: inline-block;
    width: 100%;
    &:not(:last-child) {
      ${marginBottom}
    }
  }

  ${({ $hideAddressLine1 }) =>
    $hideAddressLine1 &&
    css`
      > .input-wrapper:first-child {
        margin-top: 1rem;

        ${mediaBreakpointUp('md')`
          margin-top: 1.5rem;
        `}

        ${mediaBreakpointUp('lg')`
          margin-top: 2rem;
        `}
      }
    `}

  .input-wrapper.half-width {
    ${mediaBreakpointUp('md')`
      width: calc(50% - 1rem);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      > .form-text {
        flex-grow: 1;
      }

      > .invalid-feedback{
        height: 1.5rem;
      }

      > input {
        margin-top: auto;
      }

      &:nth-child(2n+1){
        margin-inline-end: 2rem;
      }

      &:nth-last-child(-n+2){
        margin-bottom: 0;
      }
    `}
  }

  > .input-wrapper-container {
    &:not(:last-child) {
      ${marginBottom}
    }

    ${mediaBreakpointUp('md')`
      display: flex;
    `}
  }

  ${({ $hideAddressLine2 }) =>
    $hideAddressLine2 &&
    css`
      > .input-wrapper-container {
        margin-top: 1rem;

        ${mediaBreakpointUp('md')`
          margin-top: 1.5rem;
        `}

        ${mediaBreakpointUp('lg')`
          margin-top: 2rem;
        `}
      }
    `}
`;
