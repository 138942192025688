import React from 'react';
import { useIntl } from 'react-intl';
import { INavItem } from '@whitelabel/helpers/types';
import { isOnServer } from '@whitelabel/helpers/utils';
import messages from '../messages';
import { IGetStyledDropdownItemProps } from '../../../helpers/types';
import {
  StyledDropdown,
  StyledDropdownToggle,
  StyledDropdownMenu,
  StyledDropdownItem,
  StyledNavIcon,
  StyledDropdownHeaderItem,
  StyledDropdownColumn,
} from './styled';

interface IMenuDropdownProps {
  items?: INavItem[];
  item: INavItem;
  className?: string;
  getStyledDropdownItemProps: IGetStyledDropdownItemProps;
}

const MenuDropdown = function ({
  items = [],
  item: { icon, messageKey: itemMessageKey },
  className,
  getStyledDropdownItemProps,
}: IMenuDropdownProps): JSX.Element {
  const intl = useIntl();

  function isActive(path: string) {
    if (!isOnServer && window.location.pathname === `/${intl.locale}${path}`) {
      return 'active';
    }
    return undefined;
  }
  const renderItem = (key: string, messageKey: any, children: any, path: string, tag?: React.ElementType) => {
    if (children) {
      return (
        <StyledDropdownHeaderItem
          header
          data-test-id="MenuDropdown-item-header"
          data-analytics={`navbarDropdown${messageKey}`}
        >
          {intl.formatMessage(messages[messageKey as keyof typeof messages])}
        </StyledDropdownHeaderItem>
      );
    }
    return (
      <StyledDropdownItem
        key={key}
        data-test-id="MenuDropdown-item"
        data-analytics={`navbarDropdown${messageKey}`}
        className={isActive(path)}
        {...getStyledDropdownItemProps(intl.locale, path, tag)}
      >
        {intl.formatMessage(messages[messageKey as keyof typeof messages])}
      </StyledDropdownItem>
    );
  };
  const itemsHaveChildren = items?.find((item) => item.children);

  const renderChildItems = (children: INavItem[]) =>
    children.map(({ key, messageKey, path, tag }) => (
      <StyledDropdownItem
        key={key}
        data-test-id="MenuDropdown-item"
        data-analytics={`navbarDropdown${messageKey}`}
        className={isActive(path)}
        {...getStyledDropdownItemProps(intl.locale, path, tag)}
      >
        {intl.formatMessage(messages[messageKey as keyof typeof messages])}
      </StyledDropdownItem>
    ));
  return (
    <StyledDropdown nav inNavbar data-test-id="MenuDropdown" className={className}>
      <StyledDropdownToggle nav tag="button" data-test-id="MenuDropdown-toggle">
        {intl.formatMessage(messages[itemMessageKey as keyof typeof messages])}
        <StyledNavIcon as={icon} />
      </StyledDropdownToggle>

      <StyledDropdownMenu end={!intl.bidi} $multiColumn={itemsHaveChildren} data-test-id="MenuDropdown-menu">
        <div>
          {items?.map(({ key, messageKey, children, path, tag }) => (
            <StyledDropdownColumn key={key}>
              {renderItem(key, messageKey, children, path, tag)}
              {children && renderChildItems(children)}
            </StyledDropdownColumn>
          ))}
        </div>
      </StyledDropdownMenu>
    </StyledDropdown>
  );
};

export default MenuDropdown;
