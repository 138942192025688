import { css } from 'styled-components';
import { gray } from '@whitelabel/helpers/style/colors';

const globalTableStyle = css`
  .border,
  .border-top,
  .border-right,
  .border-bottom,
  .border-left {
    border-color: ${gray(400)};
  }
  .table {
    tbody {
      border-color: ${gray(400)};
      td {
        border-color: ${gray(400)};
      }
    }
    th {
      padding: 1rem 0.5rem;
      border-bottom: 2px solid ${gray(400)};
    }
  }
  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: inherit;
  }
`;

export default globalTableStyle;
