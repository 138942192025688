import React, { useEffect, useRef, useState } from 'react';
import { useField } from 'formik';
import { InputProps } from 'reactstrap';
import { useIntl } from 'react-intl';
import { useClientRect } from '@whitelabel/helpers/hooks';
import { getCurrencySymbolOrName } from '@whitelabel/helpers/utils';
import CurrencySelect from '../CurrencySelect';
import { StyledCurrencyAmountInputBox, StyledAmountInput, StyledCurrencySymbol } from './styledCurrencyAmountInput';

export interface ICurrencyAmountInputProps extends InputProps {
  id: string;
  invalid: boolean;
  bundledCurrencyFieldName?: string;
  showCurrencyInput?: boolean;
  disabled?: boolean;
  name: string;
  [inputAttr: string]: any;
}

const CurrencyAmountInput = ({
  id,
  invalid,
  showCurrencyInput = true,
  disabled = false,
  name,
  bundledCurrencyFieldName,
  ...props
}: ICurrencyAmountInputProps): JSX.Element => {
  const { locale, bidi } = useIntl();
  const currencyFieldName = name.substring(0, name.lastIndexOf('.')).concat(`.${bundledCurrencyFieldName}`);
  const [field, { initialValue }, { setValue: setCurrency }] = useField<string>(currencyFieldName);
  const { value: currency } = field;
  const [currencySymbol, setCurrencySymbol] = useState(getCurrencySymbolOrName(locale, currency ?? initialValue));
  const currencySymbolRef = useRef<HTMLElement>(null);
  const currencySymbolRect = useClientRect(currencySymbolRef);

  useEffect(() => {
    setCurrencySymbol(getCurrencySymbolOrName(locale, currency));
  }, [currency, setCurrencySymbol, locale]);

  return (
    <StyledCurrencyAmountInputBox>
      <StyledAmountInput
        id={id}
        invalid={invalid}
        name={name}
        $currencySymbolWidth={currencySymbolRect?.width ?? 0}
        step="0.01"
        {...props}
        type="number"
        disabled={disabled}
      />
      <StyledCurrencySymbol ref={currencySymbolRef} $rtl={bidi}>
        {currencySymbol}
      </StyledCurrencySymbol>
      {/* There are cases where currency select is not required */}
      {showCurrencyInput && (
        <CurrencySelect
          disabled={disabled}
          invalid={invalid}
          id={`${id}_${bundledCurrencyFieldName}`}
          setCurrency={setCurrency}
          field={field}
        />
      )}
    </StyledCurrencyAmountInputBox>
  );
};

export default CurrencyAmountInput;
