import React from 'react';
import { HeadingTag } from '@whitelabel/helpers/types';
import { StyledWrapper, StyledImageWrapper, StyledTitle } from './styledFeatureImage';

export interface IFeatureImageProps {
  image: {
    src: string;
    alt: string;
  };
  title: string;
  headingTag?: HeadingTag;
  description: string;
}

const FeatureImage = ({ image: { src, alt }, title, headingTag = 'h4', description }: IFeatureImageProps) => (
  <StyledWrapper className="feature-image-wrapper">
    <StyledImageWrapper className="image-wrapper">
      <img src={src} alt={alt} />
    </StyledImageWrapper>
    <StyledTitle as={headingTag}>{title}</StyledTitle>
    <div className="feature-image-description">{description}</div>
  </StyledWrapper>
);

export default FeatureImage;
