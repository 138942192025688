import React from 'react';
import { HeadingTag } from '@whitelabel/helpers/types';
import BaseImageBlock from '../BaseImageBlock';
import type { IBaseImageBlockProps } from '../BaseImageBlock/BaseImageBlock';
import {
  StyledContainer,
  StyledAnnotationWrapper,
  StyledImageWrapper,
  StyledItemNumber,
  StyledList,
  StyledListItem,
  StyledItemDiscription,
  StyledTitle,
} from './styledImageAnnotationBlock';

export interface IImageAnnotationBlockProps {
  title: string;
  headingTag?: HeadingTag;
  items: string[];
  image: IBaseImageBlockProps;
}

const ImageAnnotationBlock = ({ title, headingTag, items, image }: IImageAnnotationBlockProps) => (
  <StyledContainer>
    <StyledAnnotationWrapper>
      <StyledTitle as={headingTag}>{title}</StyledTitle>
      <StyledList>
        {items.map((item, idx) => (
          <StyledListItem key={item}>
            <StyledItemNumber>{idx + 1}</StyledItemNumber>
            <StyledItemDiscription>{item}</StyledItemDiscription>
          </StyledListItem>
        ))}
      </StyledList>
    </StyledAnnotationWrapper>
    <StyledImageWrapper>
      <BaseImageBlock {...image} />
    </StyledImageWrapper>
  </StyledContainer>
);

export default ImageAnnotationBlock;
