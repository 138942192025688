import styled from 'styled-components';
import { fontStyle, fontWeight } from '@whitelabel/helpers/style/type';
import { gray } from '@whitelabel/helpers/style/colors';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { spacer, multiply } from '@whitelabel/helpers/style/units';

const thumbnailMaxWidth = multiply(spacer, 10.5);
const thumbnailWidthPlusGap = multiply(spacer, 11.5);
export const StyledReview = styled.div<{ alignment: 'left' | 'right' | undefined }>`
  display: flex;
  flex-direction: ${({ alignment }) => (alignment === 'left' ? `row-reverse` : `row`)};
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  ${mediaBreakpointUp('sm')`
    justify-content: space-between;
  `}

  :last-child {
    figure {
      margin-bottom: 0;
    }
    p {
      ${mediaBreakpointUp('sm')`
        margin-bottom: 0;
      `}
    }
  }
`;

export const StyledWords = styled.p`
  ${fontStyle('paragraph')}
  margin-bottom: ${spacer};
  color: ${gray(600)};

  ${mediaBreakpointUp('sm')`
    width: calc(100% - ${thumbnailWidthPlusGap})
  `}
`;

export const StyledThumbnail = styled.figure`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: ${thumbnailMaxWidth};
`;

export const StyledImage = styled.img`
  width: ${multiply(spacer, 4)};
  margin-bottom: ${spacer};
`;

export const StyledCaption = styled.figcaption`
  font-weight: ${fontWeight('bold')};
  font-size: ${multiply(spacer, 1.25)};
  text-align: center;

  ${mediaBreakpointUp('md')`
    font-size: ${multiply(spacer, 1.5)};
  `}
`;
