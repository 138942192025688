import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'noCookiesErrorPage.title',
    defaultMessage: 'Cookies are disabled',
  },
  description: {
    id: 'noCookiesErrorPage.description',
    defaultMessage: 'Your browser has cookies disabled. Make sure that your cookies are enabled and try again.',
  },
  linkText: {
    id: 'noCookiesErrorPage.linkText',
    defaultMessage: 'Learn more',
  },
  buttonText: {
    id: 'noCookiesErrorPage.buttonText',
    defaultMessage: 'Try again',
  },
});
