import React from 'react';
import { lower } from '@whitelabel/helpers/forms/normalize';
import InputLabel from '../../InputLabel';
import FormInputWrapper from '../../FormInputWrapper';
import { InputProps } from '../types';
import { getProps } from '../helper';
import { StyledInput } from '../styledFormikInputs';

const FormikEmailInput = (props: InputProps) => {
  const { inputProps, wrapperProps, labelProps, form } = getProps(props);
  const { label, ...restLabelProps } = labelProps;
  const { name } = inputProps;
  const { setFieldValue } = form;
  return (
    <FormInputWrapper {...wrapperProps}>
      {label && <InputLabel {...restLabelProps} label={label} />}
      <StyledInput
        {...inputProps}
        onChange={(event: any) => {
          setFieldValue(name, lower(event.target.value));
        }}
      />
    </FormInputWrapper>
  );
};

export default FormikEmailInput;
