import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { captureExceptionWithFullStory } from '@whitelabel/helpers/utils';
import backgroundImg from '@whitelabel/media/images/404-background.jpg';
import { PUBLIC_NAV_ITEMS } from '@whitelabel/xcover-shared/helpers/constants';
import { IError } from '@whitelabel/xcover-shared/helpers/types';
import NavBar from '../../containers/layout/NavBar';
import messages from './messages';
import { StyledLayout, StyledHeroBlock } from './styledErrorPage';

const AUTH_ERROR = 'auth_error';
const FNOL_PATH_KEY = '/account/claims/fnol';
interface IErrorPageProps {
  title?: string;
  leadText?: string;
  button?: {
    label?: string;
    link?: string;
    onClick?: () => void;
  };
  isNoMatch?: boolean;
  error?: IError;
  showGlobalNav?: boolean;
}
const ErrorPage = ({
  title: titleProp,
  leadText: leadTextProp,
  button,
  isNoMatch = false,
  error,
  showGlobalNav = true,
}: IErrorPageProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const title = titleProp || formatMessage(messages.generalErrorTitle);
  const unMatchedBookingOnFNOL = (error as IError)?.name === AUTH_ERROR && location.pathname.includes(FNOL_PATH_KEY);
  const leadText =
    leadTextProp ||
    (unMatchedBookingOnFNOL ? formatMessage(messages.unMatchedBookingText) : formatMessage(messages.generalErrorText));

  useEffect(() => {
    if (isNoMatch) {
      const err = new Error();
      err.name = 'URL Not Found';
      err.message = `URL: ${location.pathname} cannot be found`;
      captureExceptionWithFullStory(err);
    }
  }, [isNoMatch, location.pathname]);

  const singleButtonBlock = button
    ? [
        {
          type: 'button',
          value: {
            buttons: [
              {
                text: button.label || formatMessage(messages.backButtonText),
                link: button.link || '#',
                onClick: button.onClick,
              },
            ],
          },
        },
      ]
    : [
        {
          type: 'buttons',
          value: {
            alignment: 'left',
            buttons: [
              {
                link: '/',
                text: formatMessage(messages.backButtonText),
                variant: 'primary',
              },
            ],
          },
          id: '3c8ce8e9-0ff5-4e88-beb5-1ef737dbceb3',
        },
      ];

  return (
    <StyledLayout
      navBar={<NavBar items={showGlobalNav ? PUBLIC_NAV_ITEMS : []} size="lg" hideGlobalNav={!showGlobalNav} />}
      hideFooterNav={!showGlobalNav}
      spacing="lg"
    >
      <StyledHeroBlock
        id="errorPageHeroBlock"
        title={title}
        leadText={leadText}
        backgroundImage={{ file: backgroundImg }}
        ctaOptions={singleButtonBlock}
      />
    </StyledLayout>
  );
};

export default ErrorPage;
