import { IThemeAsProp, IThemeValueGetter } from '../types';
import { multiply as multiplyUnit } from '../utils';

export const spacer: IThemeValueGetter = ({ theme }) => theme.spacer;

export const multiply =
  (base: string | IThemeValueGetter = '1rem', multiplier: number | IThemeValueGetter = 1) =>
  ({ theme }: IThemeAsProp): string | 0 => {
    const parsedBase = (typeof base === 'function' ? base({ theme }) : base) as string;
    const parsedMultiplier = (typeof multiplier === 'function' ? multiplier({ theme }) : multiplier) as number;
    return multiplyUnit(parsedBase, parsedMultiplier);
  };
