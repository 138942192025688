import { retry } from '@whitelabel/helpers/utils';

export function importMomentLocaleData(locale: string) {
  switch (locale) {
    case 'de':
      // @ts-ignore
      return retry(() => import('moment/locale/de' /* webpackChunkName: 'moment-locale-de' */));
    case 'ja':
      // @ts-ignore
      return retry(() => import('moment/locale/ja' /* webpackChunkName: 'moment-locale-ja' */));
    case 'es':
      // @ts-ignore
      return retry(() => import('moment/locale/es' /* webpackChunkName: 'moment-locale-es' */));
    case 'es-mx':
      // @ts-ignore
      return retry(() => import('moment/locale/es-mx' /* webpackChunkName: 'moment-locale-es-mx' */));
    case 'id':
      // @ts-ignore
      return retry(() => import('moment/locale/id' /* webpackChunkName: 'moment-locale-id' */));
    case 'ko':
      // @ts-ignore
      return retry(() => import('moment/locale/ko' /* webpackChunkName: 'moment-locale-ko' */));
    case 'ms':
      // @ts-ignore
      return retry(() => import('moment/locale/ms' /* webpackChunkName: 'moment-locale-ms' */));
    case 'ru':
      // @ts-ignore
      return retry(() => import('moment/locale/ru' /* webpackChunkName: 'moment-locale-ru' */));
    case 'th':
      // @ts-ignore
      return retry(() => import('moment/locale/th' /* webpackChunkName: 'moment-locale-th' */));
    case 'tr':
      // @ts-ignore
      return retry(() => import('moment/locale/tr' /* webpackChunkName: 'moment-locale-tr' */));
    case 'uk':
      // @ts-ignore
      return retry(() => import('moment/locale/uk' /* webpackChunkName: 'moment-locale-uk' */));
    case 'vi':
      // @ts-ignore
      return retry(() => import('moment/locale/vi' /* webpackChunkName: 'moment-locale-vi' */));
    case 'da':
      // @ts-ignore
      return retry(() => import('moment/locale/da' /* webpackChunkName: 'moment-locale-da' */));
    case 'fr':
      // @ts-ignore
      return retry(() => import('moment/locale/fr' /* webpackChunkName: 'moment-locale-fr' */));
    case 'it':
      // @ts-ignore
      return retry(() => import('moment/locale/it' /* webpackChunkName: 'moment-locale-it' */));
    case 'nl':
      // @ts-ignore
      return retry(() => import('moment/locale/nl' /* webpackChunkName: 'moment-locale-nl' */));
    case 'pl':
      // @ts-ignore
      return retry(() => import('moment/locale/pl' /* webpackChunkName: 'moment-locale-pl' */));
    case 'pt':
      // @ts-ignore
      return retry(() => import('moment/locale/pt' /* webpackChunkName: 'moment-locale-pt' */));
    case 'pt-br':
      // @ts-ignore
      return retry(() => import('moment/locale/pt-br' /* webpackChunkName: 'moment-locale-pt-br' */));
    case 'sv':
      // @ts-ignore
      return retry(() => import('moment/locale/sv' /* webpackChunkName: 'moment-locale-sv' */));
    case 'fi':
      // @ts-ignore
      return retry(() => import('moment/locale/fi' /* webpackChunkName: 'moment-locale-fi' */));
    case 'cs':
      // @ts-ignore
      return retry(() => import('moment/locale/cs' /* webpackChunkName: 'moment-locale-cs' */));
    case 'bg':
      // @ts-ignore
      return retry(() => import('moment/locale/bg' /* webpackChunkName: 'moment-locale-bg' */));
    case 'ca':
      // @ts-ignore
      return retry(() => import('moment/locale/ca' /* webpackChunkName: 'moment-locale-ca' */));
    case 'hr':
      // @ts-ignore
      return retry(() => import('moment/locale/hr' /* webpackChunkName: 'moment-locale-hr' */));
    case 'et':
      // @ts-ignore
      return retry(() => import('moment/locale/et' /* webpackChunkName: 'moment-locale-et' */));
    case 'el':
      // @ts-ignore
      return retry(() => import('moment/locale/el' /* webpackChunkName: 'moment-locale-el' */));
    case 'hu':
      // @ts-ignore
      return retry(() => import('moment/locale/hu' /* webpackChunkName: 'moment-locale-hu' */));
    case 'lv':
      // @ts-ignore
      return retry(() => import('moment/locale/lv' /* webpackChunkName: 'moment-locale-lv' */));
    case 'lt':
      // @ts-ignore
      return retry(() => import('moment/locale/lt' /* webpackChunkName: 'moment-locale-lt' */));
    case 'ro':
      // @ts-ignore
      return retry(() => import('moment/locale/ro' /* webpackChunkName: 'moment-locale-ro' */));
    case 'sr':
      // @ts-ignore
      return retry(() => import('moment/locale/sr' /* webpackChunkName: 'moment-locale-sr' */));
    case 'sk':
      // @ts-ignore
      return retry(() => import('moment/locale/sk' /* webpackChunkName: 'moment-locale-sk' */));
    case 'sl':
      // @ts-ignore
      return retry(() => import('moment/locale/sl' /* webpackChunkName: 'moment-locale-sl' */));
    case 'sw':
      // @ts-ignore
      return retry(() => import('moment/locale/sw' /* webpackChunkName: 'moment-locale-sw' */));
    case 'is':
      // @ts-ignore
      return retry(() => import('moment/locale/is' /* webpackChunkName: 'moment-locale-is' */));
    case 'fil':
      // @ts-ignore
      return retry(() => import('moment/locale/fil' /* webpackChunkName: 'moment-locale-fil' */));
    case 'ar':
      // @ts-ignore
      return retry(() => import('moment/locale/ar' /* webpackChunkName: 'moment-locale-ar' */));
    case 'he':
      // @ts-ignore
      return retry(() => import('moment/locale/he' /* webpackChunkName: 'moment-locale-he' */));
    case 'mt':
      // @ts-ignore
      return retry(() => import('moment/locale/mt' /* webpackChunkName: 'moment-locale-mt' */));
    case 'az':
      // @ts-ignore
      return retry(() => import('moment/locale/az' /* webpackChunkName: 'moment-locale-az' */));
    case 'ka':
      // @ts-ignore
      return retry(() => import('moment/locale/ka' /* webpackChunkName: 'moment-locale-ka' */));
    case 'gl':
      // @ts-ignore
      return retry(() => import('moment/locale/gl' /* webpackChunkName: 'moment-locale-gl' */));
    case 'eu':
      // @ts-ignore
      return retry(() => import('moment/locale/eu' /* webpackChunkName: 'moment-locale-eu' */));
    case 'fr-ca':
      // @ts-ignore
      return retry(() => import('moment/locale/fr-ca' /* webpackChunkName: 'moment-locale-fr-ca' */));
    case 'zh-hant':
      // @ts-ignore
      return retry(() => import('moment/locale/zh-hk' /* webpackChunkName: 'moment-locale-zh-hk' */));
    case 'zh-hans':
      // @ts-ignore
      return retry(() => import('moment/locale/zh-cn' /* webpackChunkName: 'moment-locale-zh-cn' */));
    case 'no':
      // Norwegian bokmål (nb) is the dominant language in Norway
      // @ts-ignore
      return retry(() => import('moment/locale/nb' /* webpackChunkName: 'moment-locale-nb' */));
    default:
      // @ts-ignore
      return retry(() => import('moment/locale/en-au' /* webpackChunkName: 'moment-locale-en-au' */));
  }
}
