const card = {
  columnsCount: {
    xs: 1,
    sm: 1,
    md: 2,
    lg: 3,
    xl: 4,
  },
};

export default card;
