import React from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as IconLoading } from '@whitelabel/media/icons/loading.svg';
import { bwClickTracking } from '@whitelabel/helpers/analytics';
import { StyledButton, StyledIcon } from './styledButton';
import { IButtonProps } from './types';

const Button = ({
  loading,
  disabled,
  icon: Icon,
  iconPosition = 'left',
  children,
  size = 'md',
  buttonType = 'primary',
  block = false,
  onClick,
  tag = 'button',
  bwCustomVariables,
  ...props
}: IButtonProps): JSX.Element => {
  const intl = useIntl();
  const handleButtonClick = (e: Event) => {
    bwClickTracking(e, bwCustomVariables);
    if (onClick) {
      onClick(e);
    }
  };
  return (
    <StyledButton
      $rtl={intl.bidi}
      disabled={disabled || loading}
      buttonType={buttonType}
      onClick={handleButtonClick}
      $block={block}
      size={size}
      tag={tag}
      $icon={Icon}
      $iconPosition={iconPosition}
      {...props}
    >
      {loading ? (
        <IconLoading title="Loading" />
      ) : (
        <>
          {Icon && <StyledIcon size={size} $rtl={intl.bidi} as={Icon} $iconPosition={iconPosition} />}
          {children}
        </>
      )}
    </StyledButton>
  );
};

export default Button;
