import React, { useState } from 'react';
import { flushSync } from 'react-dom';

import { extractCity } from '@whitelabel/helpers/utils';
import { IAddressInputFields } from '@whitelabel/helpers/types';

import ManualAddressInput from '../ManualAddressInput';
import { StyledGeoSuggest } from '../../styled/StyledGeoSuggest';
import { manualAddressInitialValues, manualToGeoSuggestValue } from './helper';
import { IAddressInputProps, IAddress } from './types';
import { StyledLabel } from './StyledLabel';

function AddressInput({
  className,
  label,
  name,
  value,
  placeholder,
  form: { setFieldTouched, setFieldValue, setFieldError },
  setGlobalError,
  disabled = false,
  disableManualInput = false,
  inputSize = 'base',
  useManualInput = false,
  manualAddressFieldsOverride,
  country,
  invalid,
  onSwitchToManualInput,
  'aria-errormessage': ariaErrorMessage,
}: IAddressInputProps): JSX.Element {
  const [showManualInput, setShowManualInput] = useState(false);
  function onChange(text: any) {
    setFieldError(name, '');
    if (value?.country) {
      setFieldValue(name, { description: text });
      if (setGlobalError) {
        setGlobalError(null);
      }
    }
  }
  function onBlur() {
    setFieldTouched(name, true);
  }

  function onSuggestSelect(suggest: any) {
    if (suggest) {
      setFieldError(name, '');
      if (setGlobalError) {
        setGlobalError(null);
      }
      const {
        description,
        gmaps: { formatted_address: formattedAddress, address_components: addressComponents },
        location: { lat, lng },
      } = suggest;
      const address: IAddress = {
        description,
        formattedAddress,
        lat,
        lng,
      };
      addressComponents.forEach(({ short_name: shortName, long_name: longName, types }: any) => {
        if (types.includes('street_number')) {
          address.streetNumber = shortName;
        } else if (types.includes('route')) {
          address.streetName = longName;
        } else if (types.includes('administrative_area_level_1')) {
          address.region = shortName;
        } else if (types.includes('country')) {
          address.country = shortName;
        } else if (types.includes('postal_code')) {
          address.postcode = shortName;
        } else if (types.includes('subpremise')) {
          address.unitNumber = shortName;
        }
      });
      address.city = extractCity(addressComponents, description);
      if (!address.region) {
        address.region = address.city;
      }

      // Avoid react 18 event batching
      // This is done because otherwise when `onBlur` validation is triggered by
      // setFieldTouched the state is not yet updated and validation fails
      flushSync(() => {
        setFieldValue(name, address);
      });

      setFieldTouched(name, true);
    }
  }

  function skipSuggest(suggest: google.maps.places.AutocompletePrediction): boolean {
    // On very rare occasions, suggest.types is undefined (detected by Sentry)
    if (!suggest.types) {
      return true;
    }
    return !(
      suggest.types.includes('street_address') ||
      suggest.types.includes('premise') ||
      suggest.types.includes('route') ||
      suggest.types.includes('subpremise')
    );
  }

  function switchToManualInput() {
    setShowManualInput(true);
    setFieldValue(name, {
      ...manualAddressInitialValues,
      country: manualAddressFieldsOverride?.country?.value?.value || '',
    });
    setFieldError(name, ''); // clear errors on switch

    if (onSwitchToManualInput) {
      onSwitchToManualInput();
    }
  }

  function onGoogleAPIFailed() {
    setShowManualInput(true);
  }

  if (useManualInput || showManualInput) {
    return (
      <ManualAddressInput
        className={className}
        name={name}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        value={value as IAddressInputFields}
        fieldsOverride={manualAddressFieldsOverride}
      />
    );
  }

  return (
    <div className={className}>
      {label && (
        <StyledLabel $disabled={disabled} className={invalid ? 'text-danger form-label' : 'form-label'}>
          {label}
        </StyledLabel>
      )}
      <StyledGeoSuggest
        name={name}
        invalid={invalid}
        initialValue={
          (value as IAddress | undefined)?.description ||
          manualToGeoSuggestValue(value as IAddressInputFields | undefined) ||
          ''
        }
        placeDetailFields={['address_components', 'formatted_address']}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        onSuggestSelect={onSuggestSelect}
        switchToManualInput={switchToManualInput}
        disableManualInput={disableManualInput}
        autoComplete="off"
        skipSuggest={skipSuggest}
        disabled={disabled}
        inputSize={inputSize}
        country={country}
        onGoogleAPIFailed={onGoogleAPIFailed}
        aria-invalid={invalid ? 'true' : undefined}
        aria-errormessage={invalid ? ariaErrorMessage : undefined}
      />
    </div>
  );
}

export default AddressInput;
