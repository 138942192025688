import React from 'react';
import InfoBox from '../../InfoBox';
import { StyledInfo } from './styledInfoBannerBlock';

interface IInfoBannerBlock {
  text: string;
}

const InfoBannerBlock = ({ text }: IInfoBannerBlock) => (
  <InfoBox>
    <StyledInfo>{text}</StyledInfo>
  </InfoBox>
);

export default InfoBannerBlock;
