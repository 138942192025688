import { intlReducer as intl } from 'react-intl-redux';
import locales from '@whitelabel/reducers/locales';
import navs from './navs';

const appReducers = {
  intl,
  locales,
  navs,
};

export default appReducers;
