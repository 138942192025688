import { defineMessages } from 'react-intl';

export default defineMessages({
  statusComingSoon: {
    id: 'logoSlideBlock.statusComingSoon',
    defaultMessage: 'Coming soon',
  },
  statusDemo: {
    id: 'logoSlideBlock.statusDemo',
    defaultMessage: 'Demo',
  },
  statusGetApp: {
    id: 'logoSlideBlock.getTheApp',
    defaultMessage: 'Get The App',
  },
});
