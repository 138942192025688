import { defineMessages } from 'react-intl';

const messages = defineMessages({
  copy: {
    id: 'codeBlock.copy',
    defaultMessage: 'Copy',
  },
  copied: {
    id: 'codeBlock.copied',
    defaultMessage: 'Copied',
  },
  copyButtonAriaLabel: {
    id: 'codeBlock.copyButtonAriaLabel',
    defaultMessage: 'Copy to clipboard',
  },
});

export default messages;
