import React from 'react';
import { Row, Col } from 'reactstrap';
import { useIntl } from 'react-intl';
import ReactHtmlParser from 'react-html-parser';
import { IImageBlock, IUSPListItem, IButtonBlock } from '@whitelabel/helpers/types';
import { transformLink } from '../../../helpers/transform';
import {
  StyledHeading,
  StyledUspItem,
  StyledUspIcon,
  StyledUspHeading,
  StyledUspContent,
  StyledButtonBlock,
  StyledImageBlock,
  StyledCol,
} from './styledSellingBlock';

export interface ISellingBlockProps {
  uspList: IUSPListItem[];
  image: IImageBlock;
  title: string;
  button: {
    value: IButtonBlock;
  }[];
  leftWidth: number;
  imageAlignment: string;
}

const SellingBlock = ({
  image,
  imageAlignment,
  leftWidth,
  title,
  uspList,
  button,
}: ISellingBlockProps): JSX.Element => {
  const alignment = imageAlignment === 'right' ? 1 : 0;
  const intl = useIntl();

  return (
    <Row className="selling-block">
      <StyledCol md={{ size: 10, offset: 1 }} lg={{ size: leftWidth, offset: 0, order: alignment }}>
        <StyledImageBlock image={image} />
      </StyledCol>
      <Col lg={12 - leftWidth}>
        {title && <StyledHeading title={title} headingTag="h2" shouldParse />}
        {uspList.map((usp) => (
          <StyledUspItem key={usp.title}>
            <StyledUspIcon src={usp.icon?.file} alt={usp.icon?.title} />
            <div>
              <StyledUspHeading>{ReactHtmlParser(usp.title, { transform: transformLink(intl) })}</StyledUspHeading>
              <StyledUspContent>{ReactHtmlParser(usp.text, { transform: transformLink(intl) })}</StyledUspContent>
            </div>
          </StyledUspItem>
        ))}
        {button && !!button.length && button.map(({ value }) => <StyledButtonBlock key={value.text} {...value} />)}
      </Col>
    </Row>
  );
};

export default SellingBlock;
