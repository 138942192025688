import styled, { css } from 'styled-components';
import { Input } from 'reactstrap';
import { fontSize } from '@whitelabel/helpers/style/type';
import { gray } from '@whitelabel/helpers/style/colors';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { get } from '@whitelabel/helpers/style/get';

export const StyledPriceInputBox = styled.div`
  display: flex;
  position: relative;
`;
export const StyledPriceInput = styled(Input)<{ $currencyCodeWidth: number; $currencySymbolWidth: number }>`
  && {
    padding: 0.5rem ${({ $currencyCodeWidth }) => `${(20 + $currencyCodeWidth) / 16}rem`} 0.5rem
      ${({ $currencySymbolWidth }) => `${(20 + $currencySymbolWidth) / 16}rem`};
    appearance: textfield;

    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      margin: 0;
      appearance: none;
    }

    ${mediaBreakpointUp('md')`
      padding: 0.5rem
        ${({ $currencyCodeWidth }: { $currencyCodeWidth: number }) => `${(24 + $currencyCodeWidth) / 16}rem`} 0.5rem
        ${({ $currencySymbolWidth }: { $currencySymbolWidth: number }) => `${(24 + $currencySymbolWidth) / 16}rem`};
    `}
    :hover {
      border: ${get('borderWidth')} solid ${gray(700)};
      cursor: pointer;
    }
  }
`;

const commonCurrencyStyles = css`
  position: absolute;
  top: 0.5rem;
  color: ${gray(600)};
  font-size: 1.125rem;

  ${mediaBreakpointUp('lg')`
      font-size: ${fontSize('lg')};
    `}
`;

export const StyledCurrencySymbol = styled.span`
  ${commonCurrencyStyles}
  left: 0.75rem;

  ${mediaBreakpointUp('md')`
      left: 1rem;
    `}
`;

export const StyledCurrencyCode = styled.span`
  ${commonCurrencyStyles}
  right: 0.75rem;

  ${mediaBreakpointUp('md')`
      right: 1rem;
    `}
`;
