import React from 'react';
import { useIntl } from 'react-intl';
import { isJSON } from '@whitelabel/helpers/objects';
import { StyledFieldFeedback } from './styledFieldFeedback';

export interface IFieldFeedbackProps {
  error?:
    | string
    | {
        [key: string]: any;
      }
    | (string | JSX.Element)[];
  className?: string;
  id?: string;
}

const FieldFeedback = ({ error = {}, className, id }: IFieldFeedbackProps): JSX.Element => {
  const intl = useIntl();
  let errorMessage = error;

  if (typeof errorMessage === 'object' && !Array.isArray(errorMessage)) {
    [errorMessage] = Object.values(error);
  }
  if (typeof errorMessage === 'string' && isJSON(errorMessage)) {
    const { descriptor, value } = JSON.parse(errorMessage);
    errorMessage = intl.formatMessage(descriptor, value);
  }

  return (
    <StyledFieldFeedback className={className} id={id}>
      {errorMessage}
    </StyledFieldFeedback>
  );
};

export default FieldFeedback;
