import React, { useState, useCallback, PropsWithChildren, useEffect } from 'react';
import { IntlShape } from 'react-intl';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { FieldProps, useField } from 'formik';
import { SingleDatePicker, SingleDatePickerShape } from 'react-dates';
import moment from 'moment';
import commonMessages from '@whitelabel/helpers/messages/commonMsg';
import { ReactComponent as IconCalendar } from '@whitelabel/media/icons/small/calendar.svg';
import { ReactComponent as IconChevronLeft } from '@whitelabel/media/icons/small/chevron-left.svg';
import { ReactComponent as IconChevronRight } from '@whitelabel/media/icons/small/chevron-right.svg';
import TimePicker from './TimePicker';
import { StyledSingleDatePickerInput, StyledDateTimeSeparator } from './styledSingleDatePickerInput';

export interface ISingleDatePickerProps {
  id: string;
  className?: string;
  name: string;
  value: string;
  placeholder?: string;
  format?: string;
  minDate?: string;
  maxDate?: string;
  onChange: (...args: any[]) => any;
  onBlur: (...args: any[]) => any;
  form: FieldProps['form'];
  intl: IntlShape;
  invalid?: boolean;
  hasSingleDateQuestion?: boolean;
  showTimePicker?: boolean;
  dateDisplayFormat?: string;
}

const SingleDatePickerInput = ({
  id,
  name,
  value: dateValue,
  className,
  placeholder,
  format = 'YYYY-MM-DD',
  minDate,
  maxDate,
  onChange,
  onBlur,
  form: { setFieldTouched, setFieldValue },
  intl,
  invalid,
  hasSingleDateQuestion,
  showTimePicker = false,
  dateDisplayFormat,
  ...props
}: PropsWithChildren<ISingleDatePickerProps>): JSX.Element => {
  const { formatMessage, bidi } = intl;
  const [focused, setFocused] = useState<boolean>(false);
  const [{ value: timeValue }] = useField<string>(`${name}_time`);
  const [{ value: timeTypeValue }] = useField<string>(`${name}_timeType`);

  const onFocusChange = useCallback(
    ({ focused: isFocused }: { focused: boolean }) => {
      setFocused(isFocused);
      if (!isFocused && onBlur && dateValue) {
        setFieldTouched(name, true);
        onBlur(dateValue);
      }
    },
    [onBlur, dateValue, name, setFieldTouched, setFocused],
  );

  const onDateChange: SingleDatePickerShape['onDateChange'] = async (date) => {
    if (date) {
      // Modify selected date plus the current time
      const currentTime = moment().format('HH:mm:ss');
      const selectedDateAndTime = moment(`${date.format('YYYY-MM-DD')} ${currentTime}`).format(format);
      // await here is to fix stale state issue
      // because values in validate function or validateSchema in formik are one state behind
      await setFieldValue(name, selectedDateAndTime);
      onChange?.(selectedDateAndTime);
      // trigger validation when both time and timeType are set
      await setFieldTouched(name, !!(timeValue && timeTypeValue));
    } else {
      await setFieldValue(name, '');
    }
  };

  const isOutsideRange = useCallback<NonNullable<SingleDatePickerShape['isOutsideRange']>>(
    (date) => {
      if (minDate && maxDate) {
        return !date.isBetween(minDate, maxDate, null, '[]');
      }

      if (minDate) return date.isBefore(minDate);
      if (maxDate) return date.isAfter(maxDate);
      return false;
    },
    [minDate, maxDate],
  );

  useEffect(() => {
    // calendar icon is a button element which needs to manually disable tab selection
    const calendarIcon = document.querySelector('.SingleDatePickerInput_calendarIcon') as HTMLElement;
    calendarIcon.tabIndex = -1;
  }, []);

  return (
    <StyledSingleDatePickerInput
      $rtl={bidi}
      className={className}
      $focused={focused}
      $invalid={invalid}
      $hasSingleDateQuestion={hasSingleDateQuestion}
    >
      <SingleDatePicker
        {...props}
        id={id}
        date={dateValue ? moment(dateValue) : null}
        placeholder={placeholder || formatMessage(commonMessages.singleDatePickerPlaceholder)}
        focused={focused}
        onDateChange={onDateChange}
        onFocusChange={onFocusChange}
        isOutsideRange={isOutsideRange}
        numberOfMonths={1}
        isRTL={bidi}
        hideKeyboardShortcutsPanel
        showDefaultInputIcon
        displayFormat={dateDisplayFormat || 'll'}
        readOnly
        customInputIcon={<IconCalendar />}
        navPrev={
          <div className="navSvg navPrev">
            <IconChevronLeft />
          </div>
        }
        navNext={
          <div className="navSvg navNext">
            <IconChevronRight />
          </div>
        }
      />
      {showTimePicker && (
        <>
          <StyledDateTimeSeparator />
          <TimePicker rtl={bidi} invalid={invalid} name={name} hasSingleDateQuestion={hasSingleDateQuestion} />
        </>
      )}
    </StyledSingleDatePickerInput>
  );
};

export default SingleDatePickerInput;
