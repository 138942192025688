import styled, { css } from 'styled-components';
import { fontStyle, fontWeight, fontSize, fontFamily, lineHeight } from '@whitelabel/helpers/style/type';
import { gray, themeColor, color } from '@whitelabel/helpers/style/colors';
import { borderRadius, boxShadow } from '@whitelabel/helpers/style/box';
import {
  mediaBreakpointDown,
  mediaBreakpointUp,
  gridGutterWidth,
  breakpointKeys,
  marginBottom,
} from '@whitelabel/helpers/style/grid';
import { getThemeProp } from '@whitelabel/helpers/style/get';
import { focusLinkStyle, focusVisibleMixin } from '@whitelabel/helpers/style/utils';
import { StyledAlert } from '../Alert/styledAlert';

export const StyledWidgetBox = styled.div<{ $loading?: boolean; $renderWhiteBox: boolean; $renderInAModal?: boolean }>`
  position: relative;
  text-align: start;

  ${({ $loading }) =>
    $loading &&
    css`
      min-height: 27rem;
      display: flex;
      justify-content: center;

      #xpay-charge-widget {
        display: none;
      }
    `}

  ${({ theme }) =>
    breakpointKeys(theme).map(
      (breakpoint) => mediaBreakpointUp(breakpoint)`
        margin-bottom: ${gridGutterWidth(breakpoint)};
      `,
    )}

  ${({ $renderWhiteBox }) =>
    $renderWhiteBox
      ? css`
          .adyen-checkout__card-input {
            border: ${getThemeProp('borderStyle')};
            ${({ theme }) =>
              breakpointKeys(theme).map(
                (breakpoint) => mediaBreakpointUp(breakpoint)`
            padding: ${gridGutterWidth(breakpoint)};
            margin-bottom: ${gridGutterWidth(breakpoint)};
            `,
              )}
          }
        `
      : css`
          .card {
            background-color: transparent;
          }
        `}

  .message {
    display: none;
  }

  #card {
    padding-bottom: 0;
    ${mediaBreakpointUp('md')`
      padding-bottom: 2rem;
    `}
  }

  .card {
    margin-bottom: 0;
    padding-bottom: 0 !important;
  }

  .card-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .adyen-checkout__field {
    width: 100%;
  }

  .adyen-checkout__label,
  .adyen-checkout__field.adyen-checkout__card__holderName,
  .adyen-checkout__input-wrapper {
    width: 100%;
  }

  .adyen-checkout__input {
    display: block;
    width: 100%;
    height: calc(2.875rem + 0.0125rem);
    padding: 0.6875rem 0.9375rem;
    color: ${themeColor('header')};
    font-weight: ${fontWeight('normal')};
    font-size: ${fontSize('lg')};
    line-height: ${lineHeight('base')};
    background-color: ${color('white')};
    background-clip: padding-box;
    border: ${getThemeProp('borderWidth')} solid ${gray(500)};
    border-radius: ${borderRadius('base')};
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:placeholder-shown,
    &::placeholder {
      /* color defined by the 3rd party iframe field */
      color: rgb(185, 196, 201);
      -webkit-font-smoothing: auto;
    }

    &:focus,
    &:focus:hover,
    &.adyen-checkout__input--focus {
      border-color: ${themeColor('header')};
      outline: 0;
      box-shadow: none;
    }
  }

  .adyen-checkout__label--focused {
    .adyen-checkout__label__text {
      color: inherit;
    }
  }
  .adyen-checkout__label__text {
    ${fontStyle('formLabel')}
    margin-bottom: 0.5rem;
    line-height: ${lineHeight('sm')};
    ${mediaBreakpointUp('md')`
      line-height: ${lineHeight('base')};
    `};
  }

  .adyen-checkout__card__exp-cvc {
    .adyen-checkout__field {
      width: 50%;
      margin-inline-end: 0;
    }

    .adyen-checkout__field--expiryDate {
      margin-inline-end: 2.125rem;
    }
  }

  .adyen-checkout-input__inline-validation {
    display: none;
  }

  .adyen-checkout__icon svg {
    display: block;
  }

  .adyen-checkout__field--error {
    .adyen-checkout__label__text {
      color: ${themeColor('danger')};
    }

    .adyen-checkout__input--error,
    .adyen-checkout__input--invalid {
      border-color: ${themeColor('danger')};

      &:focus,
      &.adyen-checkout__input--focus {
        border-color: ${themeColor('danger')};
        outline: 0;
        box-shadow: none;
      }
    }

    .adyen-checkout__error-text {
      color: ${themeColor('danger')};
      font-size: ${fontSize('base')};
    }
  }

  .xpay-contract__pay-button {
    padding: calc(0.75rem - ${getThemeProp('borderWidth')}) calc(6rem - ${getThemeProp('borderWidth')});
    color: ${gray(700)};
    font-weight: ${fontWeight('bold')};
    font-family: ${fontFamily('headings')};
    white-space: nowrap;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    vertical-align: middle;
    background: ${themeColor('primary')};
    border: ${getThemeProp('borderWidth')} solid transparent;
    border-radius: ${borderRadius('base')};
    outline: none;
    user-select: none;

    ${mediaBreakpointDown('sm')`
        width: 100%;
        margin-top: 1rem;
      `};

    &.float-right {
      float: none;
    }

    &:hover {
      border-color: ${themeColor('primary')};
      box-shadow: ${boxShadow('md')};
    }

    ${focusVisibleMixin(focusLinkStyle)}
  }

  .adyen-checkout__card__holderName,
  .adyen-checkout__field--cardNumber {
    margin-top: 0;
    margin-bottom: 1rem !important;

    ${mediaBreakpointUp('md')`
        margin-bottom: 2rem !important;
    `}
  }

  ${({ $renderInAModal }) =>
    $renderInAModal &&
    css`
      && {
        margin-bottom: 0;
      }
      .adyen-checkout__card-input {
        ${mediaBreakpointUp('sm')`
          padding: 1.5rem;
          border: ${getThemeProp('borderStyle')};
        `}
      }
      .xpay-contract__card-form-container {
        display: block;
      }

      .xpay-contract__pay-button {
        padding: 1rem 2rem;
      }
      .adyen-checkout__card__exp-cvc {
        ${mediaBreakpointDown('sm')`
          flex-direction: column;
        `}
      }
      .adyen-checkout__card__exp-cvc .adyen-checkout__field {
        ${mediaBreakpointDown('sm')`
          width: 100%;
        `}
      }
      .adyen-checkout__field-wrapper > .adyen-checkout__field:nth-child(2) {
        ${mediaBreakpointDown('sm')`
          margin-inline-start: 0;
        `}
      }
      .adyen-checkout__field-wrapper:last-of-type > .adyen-checkout__field {
        ${mediaBreakpointDown('sm')`
          margin-bottom: 1rem;
        `}
      }
      .adyen-checkout__card__holderName,
      .adyen-checkout__field--cardNumber {
        ${mediaBreakpointUp('sm')`
          margin-bottom: 1rem !important;
        `}
      }

      .adyen-checkout__field--expiryDate {
        ${mediaBreakpointUp('md')`
          margin-bottom: 0 !important;
        `}
      }

      .adyen-checkout__field--securityCode {
        margin-bottom: 0 !important;
      }
      .adyen-checkout__label__text {
        ${fontStyle('formLabel')};
        padding-bottom: 0;
        margin-bottom: 0.5rem;
      }
      .modal-footer {
        ${mediaBreakpointDown('sm')`
          padding-top: 0;
        `}
      }
    `}
`;

export const StyledWidgetErrorAlert = styled(StyledAlert)`
  ${marginBottom}
`;
