import { css, FlattenInterpolation, Theme, ThemeProps } from 'styled-components';
import { IThemePropertyGetter, IThemeValueGetter } from '../types';
import { mediaBreakpointUp } from './grid';

export const bodyBackground: IThemeValueGetter = ({ theme }) => theme.bodyBackground;
export const bodyColor: IThemeValueGetter = ({ theme }) => theme.bodyColor;

export const fontFamily: IThemePropertyGetter<keyof Theme['fontFamilies']> =
  (variant = 'base') =>
  ({ theme }) =>
    theme.fontFamilies[variant];
export const fontSize: IThemePropertyGetter<keyof Theme['fontSizes']> =
  (size = 'base') =>
  ({ theme }) =>
    theme.fontSizes[size];
export const fontWeight =
  (weight: keyof Theme['fontWeights'] = 'base'): IThemeValueGetter =>
  ({ theme }) =>
    theme.fontWeights[weight];
export const lineHeight: IThemePropertyGetter<keyof Theme['lineHeights']> =
  (size = 'base') =>
  ({ theme }) =>
    theme.lineHeights[size];

export const resetLinkStyle = (): FlattenInterpolation<ThemeProps<Theme>> => css`
  color: initial;
  font-weight: ${fontWeight('normal')};
  box-shadow: none;

  &:hover {
    color: initial;
    box-shadow: none;
  }
`;

export const headingTextTransform: IThemeValueGetter = ({ theme }) => theme.headingTextTransform;

/**
 * Get a preset font style
 * @param {Key of fontStyles} fontStyleKey
 */
export const fontStyle =
  (fontStyleKey: keyof Theme['fontStyles']) =>
  ({ theme }: any) => {
    const currentFontStyle = theme.fontStyles[fontStyleKey];

    if (!currentFontStyle) {
      throw Error(`cannot find key ${fontStyleKey} in fontStyles`);
    }

    return css`
      ${currentFontStyle.fontWeight && `font-weight: ${currentFontStyle.fontWeight}`};
      ${currentFontStyle.fontSizes.xs && `font-size: ${currentFontStyle.fontSizes.xs}`};
      ${currentFontStyle.fontFamily && `font-family: ${currentFontStyle.fontFamily};`}
      ${currentFontStyle.lineHeight && `line-height: ${currentFontStyle.lineHeight}`};
      ${currentFontStyle.fontSizes.sm &&
      mediaBreakpointUp('sm')`
          font-size: ${currentFontStyle.fontSizes.sm};
        `}
      ${currentFontStyle.fontSizes.md &&
      mediaBreakpointUp('md')`
          font-size: ${currentFontStyle.fontSizes.md};
        `}
    ${currentFontStyle.fontSizes.lg &&
      mediaBreakpointUp('lg')`
          font-size: ${currentFontStyle.fontSizes.lg};
        `}
    `;
  };
