import { useIntl } from 'react-intl';
import React, { MouseEventHandler, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { ReactComponent as TooltipIcon } from '@whitelabel/media/icons/small/tooltip.svg';
import { decodedTooltip } from '@whitelabel/helpers/utils';
import { transformLink } from '../../helpers/transform';
import Modal from '../Modal';
import { StyledLabel } from './styledInputLabel';

interface IInputLabelProps {
  invalid: boolean;
  tooltipContent?: string;
  label: string;
  id: string;
  disabled?: boolean;
  isLabelHtml?: boolean;
}

const InputLabel = ({
  invalid,
  tooltipContent,
  label,
  id,
  disabled = false,
  isLabelHtml = false,
}: IInputLabelProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const intl = useIntl();
  const toggle = () => setIsModalOpen((prev) => !prev);
  const handleTooltipClick: MouseEventHandler<SVGSVGElement> = (e) => {
    e.preventDefault();
    toggle();
  };

  return (
    <>
      {tooltipContent && (
        <Modal
          isOpen={isModalOpen}
          body={ReactHtmlParser(decodedTooltip(tooltipContent), {
            transform: transformLink(intl),
          })}
          toggle={toggle}
          centered
        />
      )}
      <StyledLabel $disabled={disabled} for={id} className={invalid ? 'text-danger form-label' : 'form-label'}>
        {isLabelHtml ? ReactHtmlParser(label) : label}
        {tooltipContent && <TooltipIcon className="tooltip-icon" onClick={handleTooltipClick} />}
      </StyledLabel>
    </>
  );
};

export default InputLabel;
