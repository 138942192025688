import React from 'react';
import { useIntl } from 'react-intl';
import { getLinkProps } from '../../helpers/button';
import { StyledBox } from './styledImageCardButton';

export interface IImageCardButtonProps {
  title: string;
  image?: string;
  link: string;
  alt?: string;
}

const ImageCardButton = ({ title, image, link, alt }: IImageCardButtonProps): JSX.Element => {
  const { locale } = useIntl();
  return (
    <StyledBox role="button" buttonType="secondary" {...getLinkProps(link, locale)}>
      {image && (
        <div className="image-wrapper">
          <img src={image} alt={alt || title} className="image-box-image" />
        </div>
      )}
      <h3 className="image-box-title">{title}</h3>
    </StyledBox>
  );
};

export default ImageCardButton;
