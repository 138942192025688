import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';
import { useField } from 'formik';
import { Props as SelectProps } from 'react-select';
import { TIME_TYPE_OPTIONS } from '@whitelabel/helpers/constants';
import { StyledTimePickerSelect, StyledWrapper, StyledTimeSeparator, StyledToggleInput } from './styledTimePicker';
import { hourOptions, minuteOptions } from './helper';

interface ITimePickerProps {
  name: string;
  rtl?: boolean;
  invalid?: boolean;
  hasSingleDateQuestion?: boolean;
}

const TimePicker = ({ invalid, rtl, name, hasSingleDateQuestion }: ITimePickerProps): JSX.Element => {
  const theme = useTheme();
  const isMobile = !useMediaQuery({ minWidth: theme.gridBreakpoints.sm });
  const [{ value: dateValue }, , { setTouched: setDateTouched }] = useField<string>(name);
  const [
    { value: timeValue },
    { initialValue: initialTimeValue, touched: isTimeValueTouched },
    { setValue: setTimeValue, setTouched: setTimeValueTouched },
  ] = useField<string>(`${name}_time`);
  const [{ value: timeTypeValue }] = useField<string>(`${name}_timeType`);
  // eslint-disable-next-line no-unsafe-optional-chaining
  const [hours, minutes] = (timeValue ?? initialTimeValue ?? '')?.split(':');

  const isInvalid = invalid || (isTimeValueTouched && !timeTypeValue);

  const onChangeTime = async (value: string) => {
    // await here is to fix stale state issue
    // because values in validate function or validateSchema in formik are one state behind
    await setTimeValue(value);
    await setTimeValueTouched(true);
    // trigger validation when all fields have values
    await setDateTouched(!!(dateValue && timeTypeValue));
  };

  const handleHourSelectChange: SelectProps['onChange'] = async (option) => {
    const minuteValue = minutes || minuteOptions[0].value;
    const hourValue = option?.value || hourOptions[0].value;
    await onChangeTime(`${hourValue}:${minuteValue}`);
  };

  const handleMinuteSelectChange: SelectProps['onChange'] = async (option) => {
    const hourValue = hours || hourOptions[0].value;
    const minuteValue = option?.value || minuteOptions[0].value;
    await onChangeTime(`${hourValue}:${minuteValue}`);
  };

  const getSelectValue = (value: string, options: SelectProps['options']) =>
    options?.find((option) => option.value === value);

  return (
    <StyledWrapper $rtl={rtl} $hasSingleDateQuestion={hasSingleDateQuestion}>
      <StyledTimePickerSelect
        invalid={isInvalid}
        options={hourOptions}
        placeholder="hh"
        value={getSelectValue(hours, hourOptions)}
        onChange={handleHourSelectChange}
      />
      <StyledTimeSeparator />
      <StyledTimePickerSelect
        invalid={isInvalid}
        options={minuteOptions}
        placeholder="mm"
        value={getSelectValue(minutes, minuteOptions)}
        onChange={handleMinuteSelectChange}
      />
      <StyledToggleInput
        name={`${name}_timeType`}
        id={`${name}_timeType`}
        options={TIME_TYPE_OPTIONS}
        onChange={() => setDateTouched(!!(dateValue && timeValue))}
        size={hasSingleDateQuestion && !isMobile ? 'lg' : 'md'}
      />
    </StyledWrapper>
  );
};

export default TimePicker;
