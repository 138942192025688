import React from 'react';
import { useIntl } from 'react-intl';
import { components, ValueContainerProps } from 'react-select';
import { ISelectOption, ISelectInput } from '@whitelabel/helpers/types';
import { getCurrencySymbolOrName } from '@whitelabel/helpers/utils';
import { StyledSearchIcon, StyledSelectInput } from './styledCurrencyInput';

export interface ICurrencyInputProps {
  input: ISelectInput;
  id: string;
  options?: ISelectOption[];
  invalid?: boolean;
}

const CurrencyInput = ({ input, id, options, ...props }: ICurrencyInputProps): JSX.Element => {
  const { bidi, locale } = useIntl();

  const ValueContainer = ({ children, ...restProps }: ValueContainerProps<ISelectOption, false>) => (
    <components.ValueContainer {...restProps}>
      <StyledSearchIcon $rtl={bidi} />
      {children}
    </components.ValueContainer>
  );

  const customOptionLabel = (customOptions: { value: string; label: string }) =>
    `${customOptions.value} (${getCurrencySymbolOrName(locale, customOptions.value)}) - ${getCurrencySymbolOrName(
      locale,
      customOptions.value,
      false,
    )}`;

  return (
    <StyledSelectInput
      input={input}
      id={id}
      options={options}
      customComponents={{ ValueContainer }}
      customOptionLabel={customOptionLabel}
      useSingleValue={false}
      {...props}
    />
  );
};

export default CurrencyInput;
