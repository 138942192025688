import React from 'react';
import { get } from 'lodash/fp';
import { IBlockTypes, IContentBlock } from '@whitelabel/helpers/types';
import BasicBlock from '../BasicBlock';
import { StyledContainer } from './styledContainerBlock';

interface IContainerBlockProps {
  value?: IContentBlock[];
  blockTypes: IBlockTypes | any;
  className?: string;
}

const ContainerBlock = ({ value = [], blockTypes, className }: IContainerBlockProps): JSX.Element => (
  <StyledContainer className={className} data-test-id="ContainerBlock">
    {value.map((block: any) => {
      if (get(`${block.type}.component`, blockTypes)) {
        const { component: Component, structBlock, props } = blockTypes[block.type];
        return (
          <Component
            key={block.id}
            id={block.id}
            {...(structBlock ? block.value : { value: block.value })}
            blockTypes={blockTypes}
            {...props}
          />
        );
      }

      return <BasicBlock key={block.id} {...block} blockTypes={blockTypes} />;
    })}
  </StyledContainer>
);

export default ContainerBlock;
