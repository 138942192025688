import { createContext } from 'react';
import { PackageComponents } from './types';

/**
 * PackageComponentsContext is a context that is used to share functions between
 * components that are part of the same package. It is used to avoid circular
 * dependencies between packages.
 * */
export const PackageComponentsContext = createContext<PackageComponents>({});
export const PackageComponentsProvider = PackageComponentsContext.Provider;
