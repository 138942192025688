import React from 'react';
import { Col } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import { useIntl } from 'react-intl';
import { camelCase } from 'lodash/fp';
import { HeadingTag, IFeatureBlock, BlockComponentSpacing } from '@whitelabel/helpers/types';
import { transformLink } from '../../../helpers/transform';
import ImageBlock from '../ImageBlock';
import {
  StyledFeatureListBlock,
  StyledFeatureRow,
  StyledHeading,
  StyledLeadText,
  StyledBody,
} from './styledFeatureListBlock';

export interface IFeatureListBlockProps {
  headingTag: HeadingTag;
  features: IFeatureBlock[];
  className?: string;
  spacing: BlockComponentSpacing;
}

const FeatureListBlock = ({
  headingTag,
  features,
  className,
  spacing = 'md',
}: IFeatureListBlockProps): JSX.Element => {
  const hasImages = !!features.filter(({ image }) => image).length;
  const intl = useIntl();

  return (
    <StyledFeatureListBlock className={className} data-test-id="FeatureListBlock">
      {features.map(({ image, title, leadText, body }) => (
        <StyledFeatureRow key={camelCase(title || (image && image.title))} spacing={spacing}>
          {hasImages && (
            <Col xs={3} md={2} className="image">
              {image && <ImageBlock image={image} altText={title} />}
            </Col>
          )}
          <Col xs={hasImages ? 9 : 12} md={hasImages ? 10 : 12} className="content">
            {title && <StyledHeading as={headingTag}>{title}</StyledHeading>}

            {leadText && leadText !== '<p></p>' && (
              <StyledLeadText data-test-id="FeatureListBlock-leadText">
                {ReactHtmlParser(leadText, { transform: transformLink(intl) })}
              </StyledLeadText>
            )}

            {body && body !== '<p></p>' && (
              <StyledBody data-test-id="FeatureListBlock-body">
                {ReactHtmlParser(body, { transform: transformLink(intl) })}
              </StyledBody>
            )}
          </Col>
        </StyledFeatureRow>
      ))}
    </StyledFeatureListBlock>
  );
};

export default FeatureListBlock;
