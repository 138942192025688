import { isXCoverNextJSLive } from '@whitelabel/xcover-shared/helpers/utils';

const initialState = {
  data: {
    public: [
      {
        key: 'about',
        messageKey: 'aboutLink',
        name: 'About',
        path: '/about',
        tag: isXCoverNextJSLive() ? 'a' : undefined,
      },
    ],
    account: [
      {
        key: 'account',
        messageKey: 'accountLink',
        name: 'Your account',
        path: '/account',
        activePaths: ['/coi/', '/modify/', '/pds/'],
      },
      {
        key: 'help',
        messageKey: 'makeClaimLink',
        name: 'Make a claim',
        path: '/claims/',
      },
    ],
  },
  loading: false,
  error: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    default:
      return state;
  }
};
