import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import { fontStyle, fontWeight, fontFamily, lineHeight } from '@whitelabel/helpers/style/type';
import { spacer } from '@whitelabel/helpers/style/units';
import { getThemeProp } from '@whitelabel/helpers/style/get';

export const StyledRow = styled(Row)`
  margin-bottom: ${spacer};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledTerm = styled(Col).attrs(() => ({ tag: 'dt', md: 4 }))`
  ${fontStyle('paragraph')}
  color: ${getThemeProp('bodyColor')};
  font-weight: ${fontWeight('headings')};
  font-family: ${fontFamily('base')};
  line-height: ${lineHeight('base')};
`;

export const StyledDefinition = styled(Col).attrs(() => ({ tag: 'dd', md: 8 }))`
  margin-bottom: 0;

  *:last-child {
    margin-bottom: 0;
  }
`;
