import { defineMessages } from 'react-intl';

const messages = defineMessages({
  calloutProtectionIcon: {
    id: 'heroBlock.callout.calloutProtectionIcon',
    defaultMessage: 'protection icon',
  },
  calloutGreenTick: {
    id: 'heroBlock.callout.greenTickIcon',
    defaultMessage: 'green tick',
  },
  xcoverLogoAltText: {
    id: 'heroBlock.callout.xcoverLogoAltText',
    defaultMessage: 'XCover Logo',
  },
});

export default messages;
