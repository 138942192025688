import { multiply } from './units';
import { grays, colors } from './colors';
import { fontFamilies } from './fonts';

export const bodyBackground = colors.white;
export const bodyColor = grays[700];

export const fontSizeBase = '1rem';
export const lineHeightBase = '1.5';

export const fontSizes = {
  base: fontSizeBase,
  xs: multiply(fontSizeBase, 0.625),
  sm: multiply(fontSizeBase, 0.75),
  md: multiply(fontSizeBase, 0.875),
  lg: multiply(fontSizeBase, 1.25),
};

export const fontWeights = {
  base: 400,
  headings: 700,
  light: 300,
  normal: 400,
  medium: 500,
  bold: 700,
  black: 900,
};

export const lineHeights = {
  base: lineHeightBase,
  headings: 1.25,
  headingsLg: 1.625,
  sm: 1.25,
  lg: 2,
};

export const headingFontSizes = {
  h1: {
    xs: multiply(fontSizeBase, 1.5),
    md: multiply(fontSizeBase, 3),
    lg: multiply(fontSizeBase, 3.5),
  },
  h2: {
    xs: multiply(fontSizeBase, 1.5),
    md: multiply(fontSizeBase, 2.5),
    lg: multiply(fontSizeBase, 3),
  },
  h3: {
    xs: multiply(fontSizeBase, 1.25),
    md: multiply(fontSizeBase, 1.75),
    lg: multiply(fontSizeBase, 2),
  },
  h4: {
    xs: multiply(fontSizeBase, 1.25),
    md: multiply(fontSizeBase, 1.5),
    lg: multiply(fontSizeBase, 1.5),
  },
  h5: {
    xs: multiply(fontSizeBase, 1.125),
    md: multiply(fontSizeBase, 1.25),
    lg: multiply(fontSizeBase, 1.25),
  },
  h6: {
    xs: fontSizeBase,
    md: multiply(fontSizeBase, 1.125),
    lg: multiply(fontSizeBase, 1.125),
  },
};

export const inputDisabledBG = grays[300];

const base = {
  fontWeight: fontWeights.base,
  fontFamily: fontFamilies.base,
  lineHeight: lineHeights.base,
  fontSizes: {
    xs: '1rem',
  },
};

const baseHeader = {
  fontWeight: fontWeights.black,
  fontFamily: fontFamilies.base,
  lineHeight: lineHeights.headings,
  fontSizes: {
    xs: '1.5rem',
    md: '2.5rem',
    lg: '3rem',
  },
};

const paragraph = {
  fontFamily: fontFamilies.base,
  fontSizes: {
    xs: '1.125rem',
  },
};

const paragraphSmall = {
  fontWeight: fontWeights.base,
  lineHeight: lineHeights.base,
  fontSizes: {
    xs: '1rem',
  },
};

const lead = {
  fontWeight: fontWeights.base,
  fontFamily: fontFamilies.serif,
  fontSizes: {
    xs: '1.25rem',
  },
};

const h2 = {
  fontWeight: fontWeights.black,
  lineHeight: lineHeights.headings,
  fontSizes: {
    xs: '1.5rem',
    md: '2rem',
    lg: '2.5rem',
  },
};

const h3 = {
  fontWeight: fontWeights.black,
  lineHeight: lineHeights.headings,
  fontSizes: {
    xs: '1.25rem',
    md: '1.5rem',
    lg: '1.75rem',
  },
};

const h4 = {
  fontWeight: fontWeights.bold,
  lineHeight: lineHeights.headings,
  fontSizes: {
    xs: '1.125rem',
    md: '1.25rem',
    lg: '1.5rem',
  },
};

const h5 = {
  fontWeight: fontWeights.bold,
  lineHeight: lineHeights.headings,
  fontSizes: {
    xs: '1.125rem',
    lg: '1.25rem',
  },
};

const h6 = {
  fontWeight: fontWeights.bold,
  lineHeight: lineHeights.sm,
  fontSizes: {
    xs: '0.875rem',
    lg: '1rem',
  },
};

const subtext = {
  fontSizes: {
    xs: '1rem',
  },
};

const formLabel = {
  fontWeight: fontWeights.bold,
  fontSizes: {
    xs: '1.125rem',
    lg: '1.25rem',
  },
};

const formLabelSmall = {
  fontWeight: fontWeights.bold,
  lineHeight: lineHeights.sm,
  fontSizes: {
    xs: '1rem',
  },
};

const formValue = {
  fontWeight: fontWeights.normal,
  fontSizes: {
    xs: '1.125rem',
    lg: '1.25rem',
  },
};

const formSupport = {
  fontSizes: {
    xs: '1rem',
  },
};

const button = {
  fontWeight: fontWeights.black,
  lineHeight: 1,
  fontSizes: {
    xs: '0.875rem',
  },
};

const buttonSmall = {
  fontWeight: fontWeights.black,
  lineHeight: 1,
  fontSizes: {
    xs: '0.875rem',
  },
};

const navigationLink = {
  lineHeight: 1.5,
  fontWeight: 700,
  fontSizes: {
    xs: '0.75rem',
  },
};

const listLabel = {
  fontFamily: fontFamilies.serif,
  lineHeight: lineHeights.base,
  fontSizes: {
    xs: '1rem',
  },
};

const listValue = {
  fontSizes: {
    xs: '1rem',
  },
};

const buttonExtraSmall = {
  fontWeight: fontWeights.black,
  lineHeight: lineHeights.sm,
  fontSizes: {
    xs: '.625rem',
  },
};

const accordionHeader = {
  fontWeight: fontWeights.base,
  fontSizes: {
    xs: '1.125rem',
  },
};

const messaging = {
  fontSizes: {
    xs: '1rem',
    lg: '1.125rem',
  },
};

const tableHeader = {
  fontFamily: fontFamilies.serif,
  fontWeight: fontWeights.base,
  lineHeight: lineHeights.base,
  fontSizes: {
    xs: '1rem',
    md: '1rem',
    lg: '1rem',
  },
};

const tableData = {
  fontSizes: {
    xs: '1rem',
  },
};

const bigValue = {
  fontWeight: fontWeights.black,
  lineHeight: lineHeights.sm,
  fontSizes: {
    xs: '1.25rem',
    lg: '1.5rem',
  },
};

const status = {
  fontWeight: fontWeights.bold,
  lineHeight: lineHeights.sm,
  fontSizes: {
    xs: '.75rem',
  },
};

const disclaimer = {
  fontSizes: {
    xs: '.875rem',
  },
};

const contentMenu = {
  lineHeight: lineHeights.base,
  fontSizes: {
    xs: '1rem',
  },
};

const contentMenuActive = {
  lineHeight: lineHeights.base,
  fontWeight: fontWeights.bold,
  fontSizes: {
    xs: '1rem',
  },
};

const tableDataSubtext = {
  fontSizes: {
    xs: '0.875rem',
    lg: '0.75rem',
  },
};

const secondaryH1 = {
  fontWeight: fontWeights.medium,
  fontSizes: {
    xs: '1.5rem',
    md: '2rem',
    lg: '2.5rem',
  },
};

const secondaryH2 = {
  fontWeight: fontWeights.medium,
  fontSizes: {
    xs: '1.25rem',
    md: '1.5rem',
    lg: '1.75rem',
  },
};

const secondaryH3 = {
  fontWeight: fontWeights.medium,
  fontSizes: {
    xs: '1.125rem',
    md: '1.25rem',
    lg: '1.5rem',
  },
};

const secondaryH4 = {
  fontWeight: fontWeights.medium,
  fontSizes: {
    xs: '1rem',
    md: '1.125rem',
    lg: '1.25rem',
  },
};

export const fontStyles = {
  base,
  baseHeader,
  paragraph,
  paragraphSmall,
  lead,
  h2,
  h3,
  h4,
  h5,
  h6,
  secondaryH1,
  secondaryH2,
  secondaryH3,
  secondaryH4,
  subtext,
  formLabel,
  formLabelSmall,
  formValue,
  formSupport,
  button,
  buttonSmall,
  navigationLink,
  buttonExtraSmall,
  listLabel,
  listValue,
  accordionHeader,
  messaging,
  tableHeader,
  tableData,
  tableDataSubtext,
  bigValue,
  disclaimer,
  status,
  contentMenu,
  contentMenuActive,
};
