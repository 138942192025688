import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useIntl } from 'react-intl';
import { BlockComponentSpacing } from '@whitelabel/helpers/types';
import { transform } from '../../../helpers/reactHtmlParserUtils';
import StyledRichTextBlock from './styledRichTextBlock';

export interface IRichTextBlockProps {
  value: string;
  className?: string;
  spacing?: BlockComponentSpacing;
}

const RichTextBlock = ({ value, className, spacing = 'md' }: IRichTextBlockProps): JSX.Element => {
  const intl = useIntl();
  return (
    <StyledRichTextBlock key={value} className={`${className} rich-text-block`} spacing={spacing}>
      {ReactHtmlParser(value, {
        transform: transform(intl),
      })}
    </StyledRichTextBlock>
  );
};

export default RichTextBlock;
