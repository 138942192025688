// @ts-nocheck
import React from 'react';
import { useField } from 'formik';
import { StyledPercentageInputBox, StyledInput, StyledPercentageSymbol } from './styledPercentageInput';

export interface IPercentageInputProps {
  id: string;
  invalid: boolean;
  name: string;
  'aria-errormessage'?: string;
}

const PercentageInput = ({
  id,
  invalid,
  name,
  'aria-errormessage': ariaErrorMessage,
}: IPercentageInputProps): JSX.Element => {
  const [, { value: metaValue }, { setValue, setTouched }] = useField(name);

  return (
    <StyledPercentageInputBox>
      <StyledInput
        $isInvalid={invalid}
        invalid={invalid}
        id={id}
        name={name}
        value={metaValue}
        type="number"
        onBlur={() => setTouched(true)}
        onChange={({ target: { value } }) => {
          const numberValue = Number(value);
          if (numberValue <= 0) {
            setValue(0);
            return;
          }
          if (numberValue >= 100) {
            setValue(100);
            return;
          }
          setValue(numberValue);
        }}
        aria-errormessage={invalid ? ariaErrorMessage : undefined}
      />
      <StyledPercentageSymbol>%</StyledPercentageSymbol>
    </StyledPercentageInputBox>
  );
};

export default PercentageInput;
