import React from 'react';
import { locales } from '@whitelabel/helpers/locale';
import { StyledNavIconWrapper, StyledNavIcon } from './styledNavBar';

export const getGlobalNavItems = ({ NavItem, globalNavHelp, closeNav, toggleLocaleModal, locale }: any) => (
  <>
    <NavItem item={globalNavHelp} onClick={closeNav} />
    <li>
      <button onClick={toggleLocaleModal} type="button">
        <StyledNavIconWrapper className="nav-icon-wrapper">
          <StyledNavIcon as={locales[locale].Flag} />
        </StyledNavIconWrapper>
        {locales[locale].nameLocal}
      </button>
    </li>
  </>
);
