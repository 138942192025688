import styled, { css } from 'styled-components';
import { rem } from 'polished';
import CurrencyInput from 'react-currency-input-field';
import { borderRadius } from '@whitelabel/helpers/style/box';
import { get } from '@whitelabel/helpers/style/get';
import { fontSize } from '@whitelabel/helpers/style/type';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { inputColors } from '@whitelabel/global-theme/colors';

export const StyledInputContainer = styled.div`
  position: relative;

  .symbol,
  .code {
    position: absolute;
    top: 0.6rem;
    color: ${inputColors.icon.default};
    font-size: 1.125rem;

    ${mediaBreakpointUp('lg')`
      top: 0.5rem;
      font-size: ${fontSize('lg')};
    `}
  }

  .symbol {
    left: 1rem;
  }
  .code {
    right: 1rem;
    text-transform: uppercase;
  }
`;

export const StyledInput = styled(CurrencyInput)<{ $isInvalid: boolean }>`
  width: 100%;
  height: ${rem('46px')};
  padding: 0.5rem 3.5rem 0.5rem 2rem;
  border-radius: ${borderRadius('base')};
  border: ${get('borderWidth')} solid ${inputColors.border.default};
  background-color: ${inputColors.background.default};
  color: ${inputColors.value.default};

  &:hover {
    border: ${get('borderWidth')} solid ${inputColors.border.hover};
    cursor: pointer;
    background-color: ${inputColors.background.hover};
    color: ${inputColors.value.hover};
  }

  &:focus {
    cursor: pointer;
    border: ${get('borderWidth')} solid ${inputColors.border.focus};
    background-color: ${inputColors.background.focus};
    color: ${inputColors.value.focus};
  }
  &:focus-visible {
    outline: none;
  }

  &:active {
    border: ${get('borderWidth')} solid ${inputColors.border.active};
    box-shadow: none;
    background-color: ${inputColors.background.active};
    color: ${inputColors.value.active};
  }

  &:disabled {
    border: ${get('borderWidth')} solid ${inputColors.border.disabled};
    background-color: ${inputColors.background.disabled};
    color: ${inputColors.value.disabled};
  }

  ${({ $isInvalid }) =>
    $isInvalid &&
    css`
      && {
        border: ${get('borderWidth')} solid ${inputColors.border.error};
        background-color: ${inputColors.background.error};
        color: ${inputColors.value.error};
      }
    `};
`;
