import styled from 'styled-components';
import { fontWeight, fontSize, fontFamily, lineHeight } from '@whitelabel/helpers/style/type';
import { themeColor } from '@whitelabel/helpers/style/colors';
import { spacer, multiply } from '@whitelabel/helpers/style/units';

export const StyledPrice = styled.p`
  display: inline-flex;
  align-items: center;
  margin-top: ${multiply(spacer, 0.5)};
  margin-bottom: ${multiply(spacer, 0.25)};
  color: ${themeColor('header')};
  font-weight: ${fontWeight('black')};
  font-size: ${multiply(fontSize('base'), 3)};
  line-height: ${lineHeight('sm')};
  sub {
    color: ${themeColor('body')};
    margin-inline-start: ${multiply(spacer, 0.25)};
    font-weight: ${fontWeight('base')};
    font-size: ${fontSize('md')};
    line-height: ${lineHeight('sm')};
    text-align: start;
  }
`;

export const StyledAnnualCost = styled.p`
  margin-bottom: ${spacer};
  font-size: ${fontSize('md')};
  font-family: ${fontFamily('serif')};
  text-align: center;
`;
