import styled from 'styled-components';
import { fontStyle } from '@whitelabel/helpers/style/type';
import { themeColor } from '@whitelabel/helpers/style/colors';
import { mediaBreakpointDown, mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import Button from '../Button';

const StyledBlockIcon = styled.svg`
  margin-inline-end: 1rem;
  flex-shrink: 0;
  width: 2.5rem;

  ${mediaBreakpointUp('md')`
    margin-inline-end: initial;
    margin-bottom: 1rem;
    width: 3rem;
  `}
`;
const StyledGridBlockButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  white-space: break-spaces;
  &:hover {
    background: transparent;
    border-color: transparent;
  }

  ${mediaBreakpointUp('md')`
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `}

  ${mediaBreakpointUp('lg')`
    padding: 1.5rem;
  `}
`;

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${mediaBreakpointDown('sm')`
    text-align: start;
  `}
`;

const StyledGridBlockTitle = styled.h3`
  ${fontStyle('button')}
  margin-bottom: 0.25rem;

  ${mediaBreakpointUp('md')`
    margin-bottom: 0.5rem;
  `}
`;

const StyledBlockDescription = styled.p`
  ${fontStyle('paragraphSmall')};
  margin-bottom: 0;
  color: ${themeColor('body')};
  text-transform: initial;
`;

export { StyledGridBlockButton, StyledGridBlockTitle, StyledBlockIcon, StyledBlockDescription, StyledTextContainer };
