import { createContext, useContext } from 'react';

interface IZendeskChatContextProps {
  isZendeskChatOpen: boolean;
  openChat: (partnerSlug: string) => Promise<void>;
}

export const ZendeskChatContext = createContext<IZendeskChatContextProps>({
  isZendeskChatOpen: false,
  openChat: () => Promise.resolve(),
});

export const useZendeskChat = () => {
  const context = useContext(ZendeskChatContext);
  if (!context) {
    throw new Error('useZendeskChat must be used within a ZendeskChatContextProvider');
  }
  return context;
};
