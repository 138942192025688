import React from 'react';
import 'react-phone-number-input/style.css';
import { useIntl } from 'react-intl';
import { getDescriptionID, getHelpTextID } from '@whitelabel/helpers/utils';
import FieldFeedback from '../FieldFeedback';
import FieldRequirements from '../FieldRequirements';
import { StyledFormText, StyledFormGroup } from './styledFormInputWrapper';
import { FormInputWrapperProps } from './types';

const FormInputWrapper = ({
  children,
  field,
  helpText,
  description,
  requirements,
  formGroupProps,
  className,
  invalid,
  error,
  errorId,
}: FormInputWrapperProps): JSX.Element => {
  const intl = useIntl();

  return (
    <StyledFormGroup {...formGroupProps} className={`${className} form-group`} $rtl={intl.bidi}>
      {children}
      {requirements && <FieldRequirements value={field.value} requirements={requirements} />}
      {helpText && <StyledFormText id={getHelpTextID(field.name)}>{helpText}</StyledFormText>}
      {description && <StyledFormText id={getDescriptionID(field.name)}>{description}</StyledFormText>}
      {invalid && error && <FieldFeedback error={error} id={errorId} />}
    </StyledFormGroup>
  );
};

export default FormInputWrapper;
