import { css } from 'styled-components';
import { gray } from '@whitelabel/helpers/style/colors';
import { borderRadius } from '@whitelabel/helpers/style/box';
import { setAllGridGutterWidths } from '@whitelabel/helpers/style/grid';
import { spacer, multiply } from '@whitelabel/helpers/style/units';

const globalCardStyle = css`
  .card {
    ${setAllGridGutterWidths(['margin-bottom'])}
    border: 0 solid ${gray(400)};
    border-radius: ${borderRadius('base')};

    > .list-group:first-child {
      .list-group-item:first-child {
        border-top-left-radius: ${borderRadius('base')};
        border-top-right-radius: ${borderRadius('base')};
      }
    }

    > .list-group:last-child {
      .list-group-item:last-child {
        border-bottom-right-radius: ${borderRadius('base')};
        border-bottom-left-radius: ${borderRadius('base')};
      }
    }
  }

  .card-header {
    padding: ${spacer} ${spacer};
    background-color: ${gray(100)};
    border-bottom: 0;

    &:first-child {
      border-radius: ${borderRadius('base')} ${borderRadius('base')} 0 0;
    }
  }

  .card-footer {
    padding: ${spacer} ${spacer};
    background-color: ${gray(100)};
    border-top: 0;

    &:last-child {
      border-radius: 0 0 ${borderRadius('base')} ${borderRadius('base')};
    }
  }

  .card-body {
    padding: ${spacer};
  }

  .card-link {
    + .card-link {
      margin-inline-start: ${spacer};
    }
  }

  .card-header-tabs {
    margin-inline-end: -${multiply(spacer, 0.5)};
    margin-bottom: -${spacer};
    margin-inline-start: -${multiply(spacer, 0.5)};
  }

  .card-header-pills {
    margin-inline-end: -${multiply(spacer, 0.5)};
    margin-inline-start: -${multiply(spacer, 0.5)};
  }

  .card-header,
  .card-footer,
  .card-body {
    ${setAllGridGutterWidths(['padding'])}
  }

  .card-title {
    margin-bottom: ${spacer};
    text-transform: none;
  }

  .card-subtitle {
    margin-top: -${multiply(spacer, 0.75)};
    margin-bottom: ${spacer};
    color: ${gray(600)};
  }

  .card-img-overlay {
    ${setAllGridGutterWidths(['padding'])}
  }

  .card-group > .card {
    ${setAllGridGutterWidths(['margin-bottom'])};
  }

  .card-img,
  .card-img-top {
    border-top-left-radius: ${borderRadius('base')};
    border-top-right-radius: ${borderRadius('base')};
  }

  .card-img,
  .card-img-bottom {
    border-bottom-right-radius: ${borderRadius('base')};
    border-bottom-left-radius: ${borderRadius('base')};
  }

  .accordion {
    > .card {
      > .card-header {
        margin-bottom: 0;
      }
    }
  }
`;

export default globalCardStyle;
