import { defineMessages } from 'react-intl';

export default defineMessages({
  activePolicyStatus: {
    id: 'policyStatus.active',
    defaultMessage: 'Active',
  },
  modifyPolicyholder: {
    id: 'modifyPolicyHolder',
    defaultMessage: 'Modify Policyholder',
  },
  confirmedPolicyStatus: {
    id: 'policyStatus.confirmed',
    defaultMessage: 'Confirmed',
  },
  completedPolicyStatus: {
    id: 'policyStatus.completed',
    defaultMessage: 'Completed',
  },
  cancelledPolicyStatus: {
    id: 'policyStatus.cancelled',
    defaultMessage: 'Cancelled',
  },
  claimStartedPolicyStatus: {
    id: 'policyStatus.claimStarted',
    defaultMessage: 'Claim Started',
  },
  receivedPolicyStatus: {
    id: 'policyStatus.received',
    defaultMessage: 'Quote',
  },
  pendingPaymentStatus: {
    id: 'policyStatus.pendingPayment',
    defaultMessage: 'Pending Title Payment',
  },
  dateRange: {
    id: 'dateRange',
    defaultMessage: '{startDate} to {endDate}',
  },
  insuredLabelDefault: {
    id: 'insuredLabel.default',
    defaultMessage: '{itemCount, plural, one {Item} other {Items}} insured',
  },
  insuredLabelTravel: {
    id: 'insuredLabel.travel',
    defaultMessage: '{itemCount, plural, one {Person} other {People}} insured',
  },
  insuredLabelProduct: {
    id: 'insuredLabel.product',
    defaultMessage: '{itemCount, plural, one {Product} other {Products}} insured',
  },
  insuredLabelParcel: {
    id: 'insuredLabel.parcel',
    defaultMessage: '{itemCount, plural, one {Shipment} other {Shipments}} covered',
  },
  insuredLabelLocation: {
    id: 'insuredLabel.location',
    defaultMessage: 'Insured {itemCount, plural, one {location} other {locations}}',
  },
  insuredLabelEvent: {
    id: 'insuredLabel.event',
    defaultMessage: '{itemCount, plural, one {Event} other {Events}} covered',
  },
  insuredItemParcel: {
    id: 'insuredItem.parcel',
    defaultMessage: 'Order #',
  },
  insuredItemTracker: {
    id: 'insuredItem.tracker',
    defaultMessage: 'Tile Serial #:',
  },
  insuredLabelShopee: {
    id: 'insuredLabel.shopee',
    defaultMessage: '{itemCount, plural, one {Product} other {Products}} covered',
  },
  insuredLabelCompany: {
    id: 'insuredLabel.company',
    defaultMessage: 'Insured {itemCount, plural, one {Company} other {Companies}}',
  },
  error: {
    id: 'error',
    defaultMessage: 'Sorry! Something went wrong. Please try again later.',
  },
  NotAuthorizedException: {
    id: 'notAuthorizedException',
    defaultMessage: 'Incorrect username or password.',
  },
  paymentDateLabel: {
    id: 'bookingCard.paymentDateLabel',
    defaultMessage: 'Payment date',
  },
  referenceNo: {
    id: 'bookingDetailsCard.referenceNo',
    defaultMessage: 'Reference No',
  },
  bookingTitleLabel: {
    id: 'bookingCard.bookingTitleLabel',
    defaultMessage: 'Your trip from {pickupAddress}',
  },
  coverageDatesLabel: {
    id: 'bookingCard.coverageDatesLabel',
    defaultMessage: '{policyEndDate, plural, one {Coverage date} other {Coverage dates}}',
  },
  policyPeriodLabel: {
    id: 'bookingCard.policyPeriodLabel',
    defaultMessage: 'Policy period',
  },
  priceLabel: {
    id: 'bookingCard.priceLabel',
    defaultMessage: 'Price/Premium',
  },
  priceOnlyLabel: {
    id: 'bookingCard.priceOnlyLabel',
    defaultMessage: 'Price',
  },
  renewalLabel: {
    id: 'bookingCard.renewalLabel',
    defaultMessage: 'Renewal',
  },
  valueMultipleDates: {
    id: 'bookingCard.valueMultipleDates',
    defaultMessage: 'Varied',
  },
  completeForm: {
    id: 'form.completeForm',
    defaultMessage: 'Please fix the errors highlighted in the fields below',
  },
  emailLabel: {
    id: 'emailLabel',
    defaultMessage: 'Email',
  },
  submitButtonText: {
    id: 'submitButtonText',
    defaultMessage: 'Submit',
  },
  phoneVerified: {
    id: 'phoneVerified',
    defaultMessage: 'Your phone number has been verified and can be used to login.',
  },
  emailVerified: {
    id: 'emailVerified',
    defaultMessage: 'Your email has been verified and can be used to login.',
  },
  email: {
    id: 'email',
    defaultMessage: 'email',
  },
  SMS: {
    id: 'sms',
    defaultMessage: 'SMS',
  },
  policyLengthInYears: {
    id: 'policyLengthInYears',
    defaultMessage: '{coverageLength, number} {coverageLength, plural, one {year} other {years}}',
  },
  customer: {
    id: 'customer',
    defaultMessage: 'Customer',
  },
  insuredLabelPet: {
    id: 'insuredLabel.pet',
    defaultMessage: 'Pet Protection',
  },
  insuredLabelCyberProtection: {
    id: 'insuredLabel.cyberProtection',
    defaultMessage: 'Cyber Protection',
  },
  ssAsiaLimit: {
    id: 'ssAsiaLimit',
    defaultMessage: '90% of the unused flight fares',
  },
  includedLimit: {
    id: 'benefitsTable.limitDescription.includedLimit',
    defaultMessage: 'Included in limit above',
  },
  wantToChangeYourProtection: {
    id: 'help.wantToChangeYourProtection',
    defaultMessage: 'Want to change or cancel your protection?',
  },
  manageYourProtection: {
    id: 'help.manageYourProtection',
    defaultMessage: 'Manage your protection',
  },
  cantFindAnAnswer: {
    id: 'help.cantFindAnAnswer',
    defaultMessage: "Can't find an answer",
  },
  contactUsViaForm: {
    id: 'help.contactUsViaForm',
    defaultMessage: 'Contact our friendly XCover team.',
  },
  loginForMore: {
    id: 'help.loginForMore',
    defaultMessage: 'Login for more articles or to contact us.',
  },
  contactXCoverText: {
    id: 'contactXCoverText',
    defaultMessage: 'Contact {branchName}',
  },
  helpCenter: {
    id: 'help.helpCenter',
    defaultMessage: 'Help centre',
  },
  laterButton: {
    id: 'starRatings.laterButton',
    defaultMessage: 'Maybe later',
  },
  claimDetails: {
    id: 'fnol.FNOLClaimDetailsInitialForm.claimDetails',
    defaultMessage: 'Claim Details',
  },
  modifyButtonTextVisitPartner: {
    id: 'modifyBookingModal.modifyButtonTextVisitPartner',
    defaultMessage: 'Visit {partnerName}',
  },
  partnerManagedModalBody: {
    id: 'modifyBookingModal.body',
    defaultMessage:
      'To make changes to your XCover Protection or to manage your related purchase, please visit {title}.',
  },
  helpfulArticle: {
    id: 'helpfulArticle',
    defaultMessage: 'This helpful article',
  },
  contactSupportTeam: {
    id: 'contactSupportTeam',
    defaultMessage: 'contact our friendly Support Team',
  },
});
