export default {
  en: 'English',
  de: 'German',
  fr: 'French',
  nl: 'Dutch',
  es: 'Spanish',
  'es-mx': 'Mexican Spanish',
  it: 'Italian',
  zh: 'Chinese',
  'zh-hans': 'Simplified Chinese',
  'zh-hant': 'Traditional Chinese',
  'pt-br': 'Brazilian Portuguese',
  pl: 'Polish',
  he: 'Hebrew',
  pt: 'Portuguese',
  sv: 'Swedish',
  ja: 'Japanese',
  ko: 'Korean',
  da: 'Danish',
  no: 'Norwegian',
  fi: 'Finnish',
  fil: 'Filipino',
  ar: 'Arabic',
  tr: 'Turkish',
  cs: 'Czech',
  ru: 'Russian',
  th: 'Thai',
  bg: 'Bulgarian',
  ca: 'Catalan',
  hr: 'Croatian',
  et: 'Estonian',
  el: 'Greek',
  hu: 'Hungarian',
  is: 'Iceland',
  id: 'Indonesian',
  lv: 'Latvian',
  lt: 'Lithuanian',
  ms: 'Malay',
  ro: 'Romanian',
  sr: 'Serbian',
  sk: 'Slovak',
  sl: 'Slovenian',
  sw: 'Swahili',
  uk: 'Ukrainian',
  vi: 'Vietnamese',
  'en-us': 'English (US)',
  be: 'Belarusian',
  mt: 'Maltese',
  az: 'Azerbaijani',
  ka: 'Georgian',
  gl: 'Galician',
  eu: 'Basque',
  'fr-ca': 'Canadian French',
};
