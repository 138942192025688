import { css } from 'styled-components';
import { gray, themeColor, rgbaColor } from '@whitelabel/helpers/style/colors';
import { borderRadius } from '@whitelabel/helpers/style/box';
import { mediaBreakpointDown, setAllGridGutterWidths } from '@whitelabel/helpers/style/grid';
import { spacer, multiply } from '@whitelabel/helpers/style/units';
import { getThemeProp, nav } from '@whitelabel/helpers/style/get';

const globalNavStyle = css`
  .nav-link {
    &.disabled {
      color: ${gray(500)};
    }
  }

  .nav-tabs {
    align-items: flex-end;
    border-bottom: ${getThemeProp('borderWidth')} solid ${nav('tabs', 'borderColor')};

    .nav-item {
      margin-bottom: 0;
      padding-inline-start: 0;

      ${setAllGridGutterWidths(['margin-inline-end'])}

      &:last-child {
        margin-inline-end: 0;
      }
    }

    .nav-link {
      padding: 0 0 ${multiply(spacer, 0.5)};
      text-transform: uppercase;
      background-image: none;
      border: 0;
      border-bottom: ${nav('tabs', 'borderWidth')} solid transparent;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      &:hover,
      &:focus {
        background-image: none;
        border-color: ${themeColor('secondary')};
      }

      &.disabled {
        color: ${gray(500)};
      }
    }

    .nav-link.active,
    .nav-item.show .nav-link {
      color: ${gray(700)};
      background-color: transparent;
      border-color: ${themeColor('secondary')};
    }

    .dropdown-menu {
      margin-top: -${multiply(spacer, 0.25)};
    }
  }

  .navbar-toggler {
    padding: calc(${multiply(spacer, 0.625)} - ${getThemeProp('borderWidth')})
      calc(${multiply(spacer, 0.5)} - ${getThemeProp('borderWidth')});
    border-radius: ${borderRadius('base')};
    ${mediaBreakpointDown('sm')`
      &:focus {
        box-shadow: none;
      }
    `}
  }

  .navbar-dark {
    .navbar-brand {
      color: ${themeColor('primary')};

      &:hover,
      &:focus {
        color: ${themeColor('primary')};
      }
    }

    .navbar-nav {
      .show > .nav-link,
      .active > .nav-link,
      .nav-link.show,
      .nav-link.active {
        color: ${themeColor('primary')};
      }
    }

    .navbar-toggler {
      color: $navbar-dark-color;
      border-color: ${gray(400)};
    }

    .navbar-text {
      a {
        color: ${themeColor('primary')};

        &:hover,
        &:focus {
          color: ${themeColor('primary')};
        }
      }
    }
  }

  .navbar-light {
    .navbar-brand {
      color: ${themeColor('header')};

      &:hover,
      &:focus {
        color: ${themeColor('header')};
      }
    }

    .navbar-nav {
      .nav-link {
        color: ${themeColor('header')};

        &:hover,
        &:focus {
          color: ${themeColor('header')};
        }

        /* stylelint-disable max-nesting-depth */
        &.disabled {
          color: ${rgbaColor(0.3, themeColor('header'))};
        }

        /* stylelint-enable max-nesting-depth */
      }

      .show > .nav-link,
      .active > .nav-link,
      .nav-link.show,
      .nav-link.active {
        color: ${themeColor('header')};
      }
    }

    .navbar-toggler {
      color: ${themeColor('header')};
      border-color: ${gray(400)};
    }

    .navbar-text {
      color: ${themeColor('header')};
      a {
        color: ${themeColor('header')};

        &:hover,
        &:focus {
          color: ${themeColor('header')};
        }
      }
    }
  }
`;

export default globalNavStyle;
