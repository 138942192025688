import React from 'react';
import styled, { css } from 'styled-components';
import { mediaBreakpointUp, gridGutterWidth, justifyContent } from '@whitelabel/helpers/style/grid';
import { spacer, multiply } from '@whitelabel/helpers/style/units';
import { StyledButton } from '../../Button/styledButton';

// Disable eslint to keep the original logic: not pass no-unused-vars
// props down to child component.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledButtonBox = styled(({ alignment, ...props }) => <div {...props} />)`
  position: relative;
  display: flex;
  flex-direction: column;
  ${justifyContent}

  ${mediaBreakpointUp('sm')`
    flex-direction: row;
  `}

  .accordion-item-wrapper + && {
    margin-top: ${spacer};

    ${mediaBreakpointUp('sm')`
      margin-top: ${multiply(spacer, 3)};
    `}
  }

  .selling-block + && {
    margin-top: 1rem;

    ${mediaBreakpointUp('md')`
      margin-top: 1.5rem;
    `}

    ${mediaBreakpointUp('lg')`
      margin-top: 2rem;
    `}
  }

  ${StyledButton} {
    &:not(:first-child) {
      margin-top: ${gridGutterWidth('sm')};

      ${mediaBreakpointUp('sm')`
        margin-top: 0;
      `}

      ${({ alignment }) =>
        alignment !== 'space-evenly' &&
        alignment !== 'space-around' &&
        css`
          ${mediaBreakpointUp('sm')`
          margin-inline-start: ${gridGutterWidth('sm')};
        `}

          ${mediaBreakpointUp('md')`
          margin-inline-start: ${gridGutterWidth('md')};
        `}

        ${mediaBreakpointUp('lg')`
          margin-inline-start: ${gridGutterWidth('lg')};
        `}
        `}
    }
  }
`;

StyledButtonBox.defaultProps = {
  alignment: 'left',
};
