import { Label } from 'reactstrap';
import styled, { css } from 'styled-components';
import { inputColors } from '@whitelabel/global-theme/colors';

export const StyledLabel = styled(Label)<{ $disabled: boolean }>`
  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${inputColors.label.disabled};
    `}
`;
