import { IBooking } from '@whitelabel/helpers/types';
import { KW_AGENT_INFO, KW_ROLE_AGENT } from './constants';

export const storeKWAgentInfo = (booking: IBooking) => {
  /**
   * Save agent data in localStorage.
   * This will be used to send events to HubSpot
   */
  const bookingAttributes = booking.policyTypes?.home_warranty_cover?.quotes[0]?.attributes;
  if (bookingAttributes?.role) {
    const tcOrAgentEmail =
      bookingAttributes.role === KW_ROLE_AGENT ? bookingAttributes.agentEmail : bookingAttributes.tcEmail;
    const bookingID = booking.id;
    const kwAgentInfo = {
      kw_quote_id: bookingID,
      email: tcOrAgentEmail,
    };
    localStorage.setItem(KW_AGENT_INFO, JSON.stringify(kwAgentInfo));
  }
};
