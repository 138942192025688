import React from 'react';
import { hiddenLocaleCodes } from '@whitelabel/xcover-shared/helpers/constants';
import { locales } from '@whitelabel/helpers/locale';
import { ILocales } from '@whitelabel/helpers/types';
import { StyledLocaleListItem, StyledLocaleLink, StyledGrid, StyledFlagWrapper } from './styledLocaleModal';

export const getLocalsToDisplay = () =>
  Object.values<ILocales>(locales)
    .filter((locale) => !hiddenLocaleCodes.includes(locale.code))
    .sort((a, b) => {
      const nameLocalSortMap = {
        česky: 'cesky',
        Ελληνικά: 'ezzzzzzz',
        Íslenska: 'islenska',
      };
      let aNameLocal = a.nameLocal.toLowerCase();
      let bNameLocal = b.nameLocal.toLowerCase();
      if (nameLocalSortMap[a.nameLocal as keyof typeof nameLocalSortMap]) {
        aNameLocal = nameLocalSortMap[a.nameLocal as keyof typeof nameLocalSortMap];
      }
      if (nameLocalSortMap[b.nameLocal as keyof typeof nameLocalSortMap]) {
        bNameLocal = nameLocalSortMap[b.nameLocal as keyof typeof nameLocalSortMap];
      }
      return aNameLocal > bNameLocal ? 1 : -1;
    });

export const getBodyContent = (
  localsToDisplay: ILocales[],
  intlLocale: string,
  handleLocaleChange: (code: string) => () => void,
) => (
  <StyledGrid $localsToDisplay={localsToDisplay}>
    {localsToDisplay.map(({ code, nameLocal, Flag }) => {
      const isActive = code === intlLocale || (intlLocale === 'en-us' && code === 'en');
      return (
        <StyledLocaleListItem key={code}>
          <StyledLocaleLink
            $active={isActive}
            data-test-id="LocaleModal-item"
            data-analytics={`locale${nameLocal}`}
            onClick={handleLocaleChange(code)}
            tabIndex={0}
          >
            {!isActive && <StyledFlagWrapper>{Flag && <Flag />}</StyledFlagWrapper>}
            <div className="name-local">{nameLocal}</div>
          </StyledLocaleLink>
        </StyledLocaleListItem>
      );
    })}
  </StyledGrid>
);
