import React, { HTMLAttributes } from 'react';
import PhoneInputComponent from 'react-phone-number-input';
import { FieldProps } from 'formik';
import { useIntl } from 'react-intl';
import { getUserCountry } from '@whitelabel/helpers/site';
import { StyledPhoneInputContainer } from './styledPhoneInput';
import getLabels from './helper';

export interface IPhoneInputProps extends HTMLAttributes<HTMLElement> {
  name: string;
  value: string;
  form: FieldProps['form'];
  id: string;
  type: string;
  defaultCountry?: string;
  disabled?: boolean;
}

const PhoneInput = ({
  value,
  id,
  type,
  name,
  disabled = false,
  form: { setFieldValue, setFieldTouched, errors, touched },
  defaultCountry,
  ...props
}: IPhoneInputProps): JSX.Element => {
  const { bidi } = useIntl();
  const userCountry = getUserCountry();
  const error = (Boolean(errors[name]) && Boolean(touched[name])) || false;

  return (
    <StyledPhoneInputContainer $rtl={bidi} $error={error} $disabled={disabled}>
      <PhoneInputComponent
        id={id}
        type={type}
        labels={getLabels()}
        onChange={(val: any) => {
          setFieldValue(name, val ?? '');
        }}
        name={name}
        onBlur={() => setFieldTouched(name, true)}
        value={value || ''}
        disabled={disabled}
        defaultCountry={defaultCountry || userCountry}
        international
        countryCallingCodeEditable={false}
        aria-invalid={error ? 'true' : undefined}
        aria-errormessage={error ? props['aria-errormessage'] : undefined}
        aria-describedby={props['aria-describedby']}
      />
    </StyledPhoneInputContainer>
  );
};

export default PhoneInput;
