import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Button from '../../../Button';
import { StyledDropdownLinksBlock, StyledLabel, StyledSelect } from './styledDropdownLinksBlock';

export interface IDropdownLinkBlockProps {
  leadText?: string;
  hintText?: string;
  links?: {
    text: string;
    url: string;
  }[];
  buttonText: string;
  defaultLink?: string;
}

export default function DropdownLinkBlock({
  leadText = '',
  hintText = '',
  links = [],
  buttonText,
  defaultLink = '/',
}: IDropdownLinkBlockProps): JSX.Element {
  const intl = useIntl();
  const [redirectTo, setRedirectTo] = useState(defaultLink);
  const options = links.map(({ text, url }) => ({ label: text, value: url }));
  const handleSelectChange = (option: any) => {
    setRedirectTo(option);
  };

  const handleButtonClick = () => {
    window.location.href = `/${intl.locale}${redirectTo}`;
  };

  return (
    <StyledDropdownLinksBlock>
      <StyledLabel htmlFor="select-insurance-line">{leadText}</StyledLabel>
      <StyledSelect
        inputId="select-insurance-line"
        instanceId="select-insurance-line"
        input={{
          name: 'select-insurance-line',
          value: redirectTo,
          onChange: handleSelectChange,
          placeholder: hintText,
        }}
        options={options}
      />
      <Button onClick={handleButtonClick}>{buttonText}</Button>
    </StyledDropdownLinksBlock>
  );
}
