import { ReactComponent as IconArrowDown } from '@whitelabel/media/icons/small/arrow-down.svg';
import { ReactComponent as IconArrowLeft } from '@whitelabel/media/icons/small/arrow-left.svg';
import { ReactComponent as IconArrowRight } from '@whitelabel/media/icons/small/arrow-right.svg';
import { ReactComponent as IconArrowUp } from '@whitelabel/media/icons/small/arrow-up.svg';
import { ReactComponent as IconBin } from '@whitelabel/media/icons/small/bin.svg';
import { ReactComponent as IconCalendar } from '@whitelabel/media/icons/small/calendar.svg';
import { ReactComponent as IconChevronDown } from '@whitelabel/media/icons/small/chevron-down.svg';
import { ReactComponent as IconChevronLeft } from '@whitelabel/media/icons/small/chevron-left.svg';
import { ReactComponent as IconChevronRight } from '@whitelabel/media/icons/small/chevron-right.svg';
import { ReactComponent as IconChevronUp } from '@whitelabel/media/icons/small/chevron-up.svg';
import { ReactComponent as IconCross } from '@whitelabel/media/icons/small/cross.svg';
import { ReactComponent as IconDanger } from '@whitelabel/media/icons/small/danger.svg';
import { ReactComponent as IconDocument } from '@whitelabel/media/icons/small/document.svg';
import { ReactComponent as IconDollar } from '@whitelabel/media/icons/small/dollar-currentcolor.svg';
import { ReactComponent as IconDownload } from '@whitelabel/media/icons/small/download.svg';
import { ReactComponent as IconDownloadAlt } from '@whitelabel/media/icons/small/download-alt.svg';
import { ReactComponent as IconEdit } from '@whitelabel/media/icons/small/edit.svg';
import { ReactComponent as IconEllipsis } from '@whitelabel/media/icons/small/ellipsis.svg';
import { ReactComponent as IconEmail } from '@whitelabel/media/icons/small/email.svg';
import { ReactComponent as IconHelp } from '@whitelabel/media/icons/small/help.svg';
import { ReactComponent as IconInfo } from '@whitelabel/media/icons/small/info.svg';
import { ReactComponent as IconPhone } from '@whitelabel/media/icons/small/phone.svg';
import { ReactComponent as IconPlus } from '@whitelabel/media/icons/small/plus.svg';
import { ReactComponent as IconPower } from '@whitelabel/media/icons/small/power.svg';
import { ReactComponent as IconProfile } from '@whitelabel/media/icons/small/profile.svg';
import { ReactComponent as IconSave } from '@whitelabel/media/icons/small/save.svg';
import { ReactComponent as IconSearch } from '@whitelabel/media/icons/small/search.svg';
import { ReactComponent as IconTick } from '@whitelabel/media/icons/small/tick.svg';
import { ReactComponent as IconWarning } from '@whitelabel/media/icons/small/warning.svg';
import { ReactComponent as IconCash } from '@whitelabel/media/icons/small/cash-note.svg';
import { ReactComponent as IconGuard } from '@whitelabel/media/icons/small/guard.svg';
import { ReactComponent as IconUploadAlt } from '@whitelabel/media/icons/small/upload-alt.svg';
import { ReactComponent as IconExternalLink } from '@whitelabel/media/icons/small/externallink.svg';

export const SMALL_ICONS = {
  'arrow-down': IconArrowDown,
  'arrow-left': IconArrowLeft,
  'arrow-right': IconArrowRight,
  'arrow-up': IconArrowUp,
  bin: IconBin,
  cash: IconCash,
  calendar: IconCalendar,
  'chevron-down': IconChevronDown,
  'chevron-left': IconChevronLeft,
  'chevron-right': IconChevronRight,
  'chevron-up': IconChevronUp,
  cross: IconCross,
  danger: IconDanger,
  document: IconDocument,
  dollar: IconDollar,
  download: IconDownload,
  'download-alt': IconDownloadAlt,
  edit: IconEdit,
  ellipsis: IconEllipsis,
  email: IconEmail,
  guard: IconGuard,
  help: IconHelp,
  info: IconInfo,
  phone: IconPhone,
  plus: IconPlus,
  power: IconPower,
  profile: IconProfile,
  save: IconSave,
  search: IconSearch,
  tick: IconTick,
  'upload-alt': IconUploadAlt,
  warning: IconWarning,
  externallink: IconExternalLink,
};
