import { xcoverAPI } from '@whitelabel/xcover-shared/store/services/xcover/index';
import { processKeys } from '@whitelabel/xcover-shared/helpers/api';
import { getAPIHost } from '@whitelabel/xcover-shared/helpers/multiRegion';
import { IHelpArticle } from '@whitelabel/helpers/types';
import { RTK_CACHE_LIFE } from '@whitelabel/xcover-shared/helpers/constants';
import { IHelpArticleListsResponse } from '../../../../helpers/types';

interface IGetHelpArticleListsProps {
  helpType: string;
  slug: string;
  locale: string;
  auth: boolean;
}

interface IGetHelpArticleListsResponse {
  helpPageContent: IHelpArticleListsResponse;
  partner: {
    slug: string;
    title: string;
    logo: string;
    signupMethodOnXcover: string | null;
  };
}

interface IGetHelpArticleProps {
  partner: string | null;
  slug: string;
  locale: string;
  revisionId: string | null;
  auth: boolean;
}

export const helpAPI = xcoverAPI.injectEndpoints({
  endpoints: (builder) => ({
    getHelpArticleListsContent: builder.query<IGetHelpArticleListsResponse, IGetHelpArticleListsProps>({
      query: ({ helpType, slug, locale, auth }) => {
        const searchParams = new URLSearchParams({
          language: locale,
          isLoggedIn: auth.toString(),
        });

        const url = `${getAPIHost(undefined, 'v2')}/help/${helpType}/${slug}/?${searchParams}`;

        if (!auth) {
          return {
            url,
            headers: { auth: 'false' },
          };
        }

        return url;
      },
      transformResponse: (response: any) => processKeys(response),
      keepUnusedDataFor: RTK_CACHE_LIFE,
    }),
    getHelpArticle: builder.query<IHelpArticle, IGetHelpArticleProps>({
      query: ({ slug, partner, locale, revisionId, auth }) => {
        const searchParams = new URLSearchParams({
          language: locale,
          isLoggedIn: auth.toString(),
          ...(partner && { partner }),
          ...(revisionId && { revision_id: revisionId }),
        });

        const url = `${getAPIHost()}/help/articles/${slug}/?${searchParams}`;

        if (!auth) {
          return {
            url,
            headers: { auth: 'false' },
          };
        }

        return url;
      },
      transformResponse: (response: any) => processKeys(response),
      keepUnusedDataFor: RTK_CACHE_LIFE,
    }),
  }),
});

export const { useGetHelpArticleListsContentQuery, useGetHelpArticleQuery } = helpAPI;

export const clearHelpArticle = () => helpAPI.util.resetApiState();
