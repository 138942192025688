import React from 'react';
import InputLabel from '../../InputLabel';
import PasswordInput from '../../PasswordInput';
import FormInputWrapper from '../../FormInputWrapper';
import { InputProps } from '../types';
import { getProps } from '../helper';

const FormikPasswordInput = (props: InputProps) => {
  const { inputProps, wrapperProps, labelProps } = getProps(props);
  const { label, ...restLabelProps } = labelProps;

  return (
    <FormInputWrapper {...wrapperProps}>
      {label && <InputLabel {...restLabelProps} label={label} />}
      <PasswordInput {...inputProps} />
    </FormInputWrapper>
  );
};

export default FormikPasswordInput;
