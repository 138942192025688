import styled, { css } from 'styled-components';
import { Row } from 'reactstrap';
import { fontStyle, fontWeight } from '@whitelabel/helpers/style/type';
import { gray } from '@whitelabel/helpers/style/colors';
import { boxShadow } from '@whitelabel/helpers/style/box';
import { mediaBreakpointUp, gridGutterWidth } from '@whitelabel/helpers/style/grid';
import { spacer, multiply } from '@whitelabel/helpers/style/units';
import { ReactComponent as Tick } from '@whitelabel/media/icons/tick.svg';

export const StyledRow = styled(Row)`
  margin-bottom: -${gridGutterWidth('sm')};

  ${mediaBreakpointUp('lg')`
    margin-bottom: -${gridGutterWidth('lg')};
  `}
`;

export const StyledCard = styled.button.attrs(() => ({ type: 'button' }))<{ $selected: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: ${gridGutterWidth('sm')};
  padding: ${spacer};
  background: transparent;
  border: solid ${multiply(spacer, 0.125)} ${gray(400)};

  ${mediaBreakpointUp('lg')`
    margin-bottom: ${gridGutterWidth('lg')};
  `}

  ${({ $selected }) =>
    $selected &&
    css`
      border: solid ${multiply(spacer, 0.1251)} ${gray(700)};
    `};

  &:hover,
  &:focus {
    outline: none;
    box-shadow: ${boxShadow('md')};
  }
`;

export const StyledCardImage = styled.img`
  padding-bottom: ${multiply(spacer, 1.5)};
`;

export const StyledCardTitle = styled.span`
  ${fontStyle('button')}
  font-weight: ${fontWeight('black')};
  text-transform: uppercase;
`;

export const StyledTickIcon = styled(Tick)`
  position: absolute;
  right: ${multiply(spacer, 0.5)};

  ${mediaBreakpointUp('sm')`
    right: ${multiply(spacer, 0.25)};
  `}

  ${mediaBreakpointUp('lg')`
    right: ${spacer};
  `}

  .tick {
    stroke: ${gray(700)};
  }
`;
