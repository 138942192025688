import React from 'react';
import { useIntl } from 'react-intl';
import logo from '@whitelabel/media/logo.svg';
import { SITE_NAME } from '@whitelabel/xcover-shared/helpers/constants';
import messages from '../messages';
import { StyledBrandLogo } from './styledPoweredByBrand';

const PoweredByBrand = (): JSX.Element => {
  const intl = useIntl();
  return (
    <div>
      {intl.formatMessage(messages.poweredBy)}
      <StyledBrandLogo src={logo} alt={SITE_NAME} />
    </div>
  );
};

export default PoweredByBrand;
