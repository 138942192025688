import React from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { updateLocale as updateLocaleAction } from '@whitelabel/actions/locales';
import { Modal } from '@whitelabel/component-library';
import { StyledHeading } from './styledLocaleModal';
import messages from './messages';
import { getBodyContent, getLocalsToDisplay } from './helpers';

interface ILocaleModalProps {
  isOpen: boolean;
  toggle: () => void;
  updateLocale: (...args: any[]) => any;
}

const LocaleModal = ({ isOpen, toggle, updateLocale }: ILocaleModalProps) => {
  const intl = useIntl();
  const handleLocaleChange = (code: any) => () => updateLocale(code);
  const localsToDisplay = getLocalsToDisplay();
  const bodyContent = getBodyContent(localsToDisplay, intl.locale, handleLocaleChange);

  return (
    <Modal
      isOpen={isOpen}
      header={<StyledHeading>{intl.formatMessage(messages.header)}</StyledHeading>}
      body={bodyContent}
      toggle={toggle}
      backdrop
    />
  );
};

const mapDispatchToProps = {
  updateLocale: updateLocaleAction,
};

export default connect(null, mapDispatchToProps)(LocaleModal);
