import { createAction } from '@reduxjs/toolkit';
import { IUser } from '@whitelabel/helpers/types';

export const resetError = createAction('user/resetError');
export const impersonateLoginFailure = createAction('user/impersonateLoginFailure');
export const impersonateLoginFulfill = createAction('user/impersonateLoginFulfill');
export const impersonateLoginRequest = createAction('user/impersonateLoginRequest');
export const impersonateLoginSuccess = createAction<IUser>('user/impersonateLoginSuccess');
export const confirmSignUpFulfill = createAction('user/confirmSignUpFulfill');
export const confirmSignUpRequest = createAction('user/confirmSignUpRequest');
export const confirmSignUpSuccess = createAction<IUser>('user/confirmSignUpSuccess');
export const loginFailure = createAction('user/loginFailure');
export const loginFulfill = createAction('user/loginFulfill');
export const loginRequest = createAction('user/loginRequest');
export const loginSuccess = createAction<IUser>('user/loginSuccess');
export const logoutFailure = createAction<any>('user/logoutFailure');
export const logoutRequest = createAction('user/logoutRequest');
export const logoutSuccess = createAction('user/logoutSuccess');
