import React from 'react';
import { useIntl } from 'react-intl';
import commonMsg from '@whitelabel/helpers/messages/commonMsg';
import { ReactComponent as ArrowRight } from '@whitelabel/media/icons/small/arrow-right.svg';
import { ReactComponent as SearchIcon } from '@whitelabel/media/icons/small/search.svg';
import { ReactComponent as ClearIcon } from '@whitelabel/media/icons/small/cross.svg';
import { StyledInputGroup, StyledInputGroupText, StyledInput, StyledButton } from './styledSearchInput';

export interface ISearchInputProps {
  id: string;
  placeholder?: string;
  disabled?: boolean;
  hasResults?: boolean;
  inlineSubmit?: boolean;
  value?: string;
  onClear?: (e: any) => void;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  name: string;
  $rtl: boolean;
  fullWidth?: boolean;
}

const SearchInput = ({
  id,
  placeholder = '',
  disabled = false,
  hasResults = false,
  inlineSubmit = true,
  onClear,
  value = '',
  onChange,
  name,
  $rtl,
  fullWidth = false,
}: ISearchInputProps): JSX.Element => {
  const { formatMessage } = useIntl();
  return (
    <StyledInputGroup id={id} $fullWidth={fullWidth}>
      <StyledInputGroupText $rtl={$rtl} disabled={disabled}>
        <SearchIcon />
      </StyledInputGroupText>
      <StyledInput
        type="text"
        value={value}
        disabled={disabled}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
      />
      <StyledInputGroupText $rtl={$rtl} disabled={disabled}>
        {hasResults && value ? (
          <StyledButton
            disabled={disabled}
            type="button"
            onClick={onClear}
            aria-label={formatMessage(commonMsg.clear)}
          >
            <ClearIcon />
          </StyledButton>
        ) : (
          inlineSubmit && (
            <StyledButton disabled={disabled} type="submit" aria-label={formatMessage(commonMsg.search)}>
              <ArrowRight />
            </StyledButton>
          )
        )}
      </StyledInputGroupText>
    </StyledInputGroup>
  );
};

export default SearchInput;
