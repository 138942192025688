import React from 'react';
import { useIntl } from 'react-intl';
import { ISuggest } from '@whitelabel/helpers/types';
import { StyledDivider, StyledSuggestItem, StyledSuggestList, StyledSuggestListWrapper } from './styledGeoSuggest';
import SuggestItem from './SuggestItem';
import messages from './messages';

interface ISuggestListProps {
  isHidden: boolean;
  suggests: ISuggest[];
  activeSuggest?: ISuggest;
  userInput: string;
  isHighlightMatch: boolean;
  listId: string;
  onSuggestSelect: (suggest: ISuggest) => void;
  onSuggestMouseDown: (event: React.MouseEvent) => void;
  onSuggestMouseOut: (event: React.MouseEvent) => void;
  switchToManualInput?: () => void;
  disableManualInput: boolean;
  manualIsActive?: boolean;
}

const SuggestList = ({
  listId,
  suggests,
  isHidden,
  activeSuggest,
  userInput,
  isHighlightMatch,
  onSuggestMouseDown,
  onSuggestMouseOut,
  onSuggestSelect,
  switchToManualInput,
  disableManualInput,
  manualIsActive,
}: ISuggestListProps): JSX.Element | null => {
  const { formatMessage } = useIntl();

  if (isHidden || !userInput.length || !suggests.length) {
    return null;
  }
  return (
    <StyledSuggestListWrapper>
      <StyledSuggestList id={listId} role="listbox">
        {suggests.map((suggest) => {
          const isActive = (activeSuggest && suggest.placeId === activeSuggest.placeId) || false;
          return (
            <SuggestItem
              key={suggest.placeId}
              className={suggest.className || ''}
              userInput={userInput}
              isHighlightMatch={isHighlightMatch}
              suggest={suggest}
              isActive={isActive}
              onMouseDown={onSuggestMouseDown}
              onMouseOut={onSuggestMouseOut}
              onSelect={onSuggestSelect}
            />
          );
        })}
        {!disableManualInput && (
          <>
            <StyledDivider />
            <StyledSuggestItem
              role="option"
              onClick={switchToManualInput}
              onMouseDown={onSuggestMouseDown}
              onMouseOut={onSuggestMouseOut}
              aria-selected={manualIsActive}
              $isActive={manualIsActive}
            >
              {formatMessage(messages.switchToManualInput)}
            </StyledSuggestItem>
          </>
        )}
      </StyledSuggestList>
    </StyledSuggestListWrapper>
  );
};

export default React.memo(SuggestList);
