import { multiply } from './units';
import { spacer } from './grid';
import { grays } from './colors';

const modalFontSizeBase = '1.25rem';

const modal = {
  fontSizeBase: modalFontSizeBase,
  sizes: {
    sm: '485px',
    md: '728px',
  },
  backdrop: {
    bg: grays[700],
    opacity: 0.8,
  },
  headingsFontSizes: {
    h1: {
      xs: multiply(modalFontSizeBase, 1.2),
      md: multiply(modalFontSizeBase, 2),
      lg: multiply(modalFontSizeBase, 2),
    },
    h2: {
      xs: multiply(modalFontSizeBase, 1.2),
      md: multiply(modalFontSizeBase, 1.4),
      lg: multiply(modalFontSizeBase, 1.6),
    },
    h3: {
      xs: modalFontSizeBase,
      md: multiply(modalFontSizeBase, 1.2),
      lg: multiply(modalFontSizeBase, 1.2),
    },
    h4: {
      xs: multiply(modalFontSizeBase, 0.8),
      md: modalFontSizeBase,
      lg: modalFontSizeBase,
    },
    h5: {
      xs: multiply(modalFontSizeBase, 0.7),
      md: multiply(modalFontSizeBase, 0.8),
      lg: multiply(modalFontSizeBase, 0.8),
    },
    h6: {
      xs: multiply(modalFontSizeBase, 0.7),
      md: multiply(modalFontSizeBase, 0.7),
      lg: multiply(modalFontSizeBase, 0.7),
    },
  },
  headingsLineHeights: {
    base: 1.5,
    sm: 1.25,
  },
  headingsMarginBottoms: {
    h1: {
      xs: multiply(spacer, 0.5),
      md: multiply(spacer, 0.5),
      lg: multiply(spacer, 0.5),
    },
    h2: {
      xs: spacer,
      md: multiply(spacer, 1.5),
      lg: multiply(spacer, 1.5),
    },
    h3: {
      xs: spacer,
      md: spacer,
      lg: spacer,
    },
    h4: {
      xs: spacer,
      md: spacer,
      lg: spacer,
    },
    h5: {
      xs: spacer,
      md: spacer,
      lg: spacer,
    },
    h6: {
      xs: 0,
      md: 0,
      lg: 0,
    },
  },
};

export default modal;
