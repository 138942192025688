import styled from 'styled-components';
import { get } from '@whitelabel/helpers/style/get';

const StyledDivider = styled.hr<{ $gap?: string }>`
  margin: ${({ $gap }) => $gap} 0;
  width: 100%;
  border-top: ${get('borderStyle')};
`;

export default StyledDivider;
