import React from 'react';
import { useMediaQuery } from 'react-responsive';
import ReactHtmlParser from 'react-html-parser';
import { useIntl } from 'react-intl';
import { decodedTooltip } from '@whitelabel/helpers/utils';
import { transformLink } from '../../helpers/transform';
import { StyledUncontrolledTooltip } from '../../styled/StyledUncontrolledTooltip';
import { StyledValue } from './styledTooltip';

export type Placement =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end';

export interface ITooltipProps {
  backgroundColor?: string;
  placement?: Placement;
  tooltip: string;
  tooltipId: string;
  value?: string;
  hasMaxWidth?: boolean;
  hasMinimalPadding?: boolean;
  className?: string;
}

const Tooltip = ({
  backgroundColor,
  placement,
  tooltip,
  tooltipId,
  value,
  hasMaxWidth = true,
  hasMinimalPadding = false,
  className = '',
}: ITooltipProps): JSX.Element => {
  const intl = useIntl();
  const isMobile = useMediaQuery({ maxWidth: 400 });
  const defaultPlacement = isMobile ? 'top' : 'right';

  return (
    <>
      <StyledValue>
        <span id={tooltipId}>{value}</span>
      </StyledValue>
      <StyledUncontrolledTooltip
        backgroundColor={backgroundColor}
        placement={placement || defaultPlacement}
        autohide={false}
        target={tooltipId}
        trigger="hover"
        hasMaxWidth={hasMaxWidth}
        hasMinimalPadding={hasMinimalPadding}
        className={className}
      >
        <p>
          {ReactHtmlParser(decodedTooltip(tooltip), {
            transform: transformLink(intl),
          })}
        </p>
      </StyledUncontrolledTooltip>
    </>
  );
};

export default Tooltip;
