import styled from 'styled-components';
import { Card, CardBody } from 'reactstrap';
import { zIndex } from '@whitelabel/helpers/style/zIndex';
import { fontStyle } from '@whitelabel/helpers/style/type';
import { gray } from '@whitelabel/helpers/style/colors';
import { boxShadow } from '@whitelabel/helpers/style/box';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { get } from '@whitelabel/helpers/style/get';
import { focusLinkStyle, focusVisibleMixin } from '@whitelabel/helpers/style/utils';

export const StyledAccordionItem = styled(Card)`
  && {
    border-width: ${get('borderWidth')};
    transition: all ease-in-out 0.25s;

    &:hover {
      z-index: ${zIndex('content')};
      border-color: ${gray(600)};
      box-shadow: ${boxShadow('md')};
    }
    ${focusVisibleMixin(focusLinkStyle)}
  }
`;

export const StyledItemHeader = styled(CardBody)`
  && {
    cursor: pointer;

    ${mediaBreakpointUp('md')`
      padding: 1rem 1.5rem;
    `}
  }
`;

export const StyledItemHeading = styled.h3`
  && {
    ${fontStyle('accordionHeader')}
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    text-transform: none;

    & > *:last-child {
      flex-shrink: 0;
      margin-inline-start: 1rem;
    }
  }
`;

export const StyledItemInner = styled(CardBody)`
  && {
    padding: 0 1rem 1rem;
    font-size: 1.125rem;
    line-height: 1.6875rem;

    ${mediaBreakpointUp('md')`
      padding: 0 1.5rem 1rem;
    `}
  }
`;

export const StyledItemBody = styled.div`
  color: ${gray(600)};

  p:last-child,
  .section-block:last-child,
  > div:last-child {
    margin-bottom: 0;
  }
`;
