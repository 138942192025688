import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useIntl } from 'react-intl';
import { transformLink } from '../../../helpers/transform';
import {
  StyledResourceContainer,
  StyledRichText,
  StyledTitle,
  StyledSubtitle,
  StyledResourceWrapper,
} from './styledResourceBlock';

export interface IResourceBlockProps {
  title: string;
  resourceItems: {
    content: string;
    subtitle: string;
  }[];
}

const ResourceBlock = ({ title, resourceItems }: IResourceBlockProps): JSX.Element => {
  const intl = useIntl();

  return (
    <StyledResourceWrapper>
      <StyledTitle>{title}</StyledTitle>
      <StyledResourceContainer>
        {resourceItems.map(({ content, subtitle }) => (
          <StyledRichText key={content}>
            <StyledSubtitle>{subtitle}</StyledSubtitle>
            {ReactHtmlParser(content, { transform: transformLink(intl) })}
          </StyledRichText>
        ))}
      </StyledResourceContainer>
    </StyledResourceWrapper>
  );
};
export default ResourceBlock;
