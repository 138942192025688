import { css } from 'styled-components';
import { borderRadius } from '@whitelabel/helpers/style/box';
import { tooltip } from '@whitelabel/helpers/style/get';

const globalTooltipStyle = css`
  .tooltip {
    .arrow {
      width: ${tooltip('arrowWidth')};
      height: ${tooltip('arrowHeight')};
    }
  }
  .bs-tooltip-top,
  .bs-tooltip-auto[x-placement^='top'] {
    padding: calc(${tooltip('arrowHeight')} + 2px) 0;

    .arrow {
      bottom: 2px;

      &::before {
        border-width: ${tooltip('arrowHeight')} calc(${tooltip('arrowWidth')} / 2) 0;
      }
    }
  }

  .bs-tooltip-right,
  .bs-tooltip-auto[x-placement^='right'] {
    padding: 0 calc(${tooltip('arrowHeight')} + 2px);

    .arrow {
      left: 2px;
      width: ${tooltip('arrowHeight')};
      height: ${tooltip('arrowWidth')};

      &::before {
        border-width: calc(${tooltip('arrowWidth')} / 2) ${tooltip('arrowHeight')} calc(${tooltip('arrowWidth')} / 2) 0;
      }
    }
  }

  .bs-tooltip-bottom,
  .bs-tooltip-auto[x-placement^='bottom'] {
    padding: calc(${tooltip('arrowHeight')} + 2px) 0;

    .arrow {
      top: 2px;

      &::before {
        border-width: 0 calc(${tooltip('arrowWidth')} / 2) ${tooltip('arrowHeight')};
      }
    }
  }

  .bs-tooltip-left,
  .bs-tooltip-auto[x-placement^='left'] {
    padding: 0 calc(${tooltip('arrowHeight')} + 2px);

    .arrow {
      right: 2px;
      width: ${tooltip('arrowHeight')};
      height: ${tooltip('arrowWidth')};

      &::before {
        border-width: calc(${tooltip('arrowWidth')} / 2) 0 calc(${tooltip('arrowWidth')} / 2) ${tooltip('arrowHeight')};
      }
    }
  }

  .tooltip-inner {
    padding: ${tooltip('paddingY')} ${tooltip('paddingX')};
    border-radius: ${borderRadius('base')};
  }
`;

export default globalTooltipStyle;
