import { css, FlattenSimpleInterpolation, Theme } from 'styled-components';
import { IThemePropertyGetter, IThemeValueGetter } from '../types';

export const borderRadius =
  (size: keyof Theme['borderRadii'] = 'base'): IThemeValueGetter =>
  ({ theme }) =>
    theme.borderRadii[size] || theme.borderRadii.base;
export const boxShadow: IThemePropertyGetter<keyof Theme['boxShadows']> =
  (size = 'base') =>
  ({ theme }) =>
    theme.boxShadows[size] || theme.boxShadows.base;

export const clearFix = css`
  &::after {
    display: table;
    clear: both;
    content: '';
  }
`;

export const flipForRTL = ({ $rtl }: { $rtl: boolean }): FlattenSimpleInterpolation =>
  $rtl
    ? css`
        transform: scaleX(-1);
      `
    : css``;

export const flipFlexForRTL = ({ $rtl }: { $rtl: boolean }): FlattenSimpleInterpolation =>
  $rtl
    ? css`
        flex-direction: row-reverse;
      `
    : css`
        flex-direction: row;
      `;

// This shouldn't be needed, but inset-inline properties are not supported by safari
export const getRightValue =
  (val: string) =>
  ({ $rtl }: { $rtl: boolean }): FlattenSimpleInterpolation =>
    $rtl
      ? css`
          left: ${val};
        `
      : css`
          right: ${val};
        `;

export const getLeftValue =
  (val: string) =>
  ({ $rtl = false }: { $rtl?: boolean }): FlattenSimpleInterpolation =>
    $rtl
      ? css`
          right: ${val};
        `
      : css`
          left: ${val};
        `;

export const getMarginLeft =
  (val: string) =>
  ({ $rtl = false }: { $rtl?: boolean }): FlattenSimpleInterpolation =>
    $rtl
      ? css`
          margin-right: ${val};
          margin-left: 0;
        `
      : css`
          margin-left: ${val};
        `;

export const avoidBreakInside = css`
  break-inside: avoid;
  &::after {
    display: table;
    clear: both;
    visibility: hidden;
    content: '';
  }
`;
