import { multiply } from './units';
import { spacer } from './grid';

const tooltip = {
  arrowHeight: '1.375rem',
  arrowWidth: '1.375rem',
  paddingY: multiply(spacer, 0.5),
  paddingX: spacer,
};

export default tooltip;
