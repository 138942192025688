import { get } from 'lodash/fp';
import { getAriaDescribedBy } from '@whitelabel/helpers/utils';
import { InputProps } from './types';

export const getProps = ({
  form,
  id,
  label,
  disabled,
  className,
  isLabelHtml,
  tooltipContent,
  showLabel,
  field,
  helpText,
  description,
  requirements,
  formGroupProps,
  showInitialErrors,
  ...rest
}: InputProps) => {
  const { touched, errors: formErrors, initialErrors } = form;

  const initialError = showInitialErrors ? get(field.name, initialErrors) : '';
  const isTouched = get(field.name, touched);
  const fieldError = get(field.name, formErrors);
  const isInValid = (isTouched && !!fieldError) || !!initialError;
  const errorId = isInValid ? `error-${(Math.random() + 1).toString(36).substring(7)}` : undefined;

  const inputProps = { ...field, ...rest, id, label, disabled, invalid: isInValid, 'aria-errormessage': errorId };

  const ariaDescribedBy = getAriaDescribedBy(field.name, helpText, description, requirements);

  if (ariaDescribedBy) {
    inputProps['aria-describedby'] = ariaDescribedBy;
  }

  const wrapperProps = {
    field,
    helpText,
    description,
    requirements,
    formGroupProps,
    className,
    invalid: isInValid,
    error: fieldError || initialError,
    errorId,
  };

  const labelProps = { invalid: isInValid, tooltipContent, label, id, disabled, isLabelHtml };

  return { inputProps, wrapperProps, labelProps, form };
};
