import styled from 'styled-components';
import { gray, themeColor } from '@whitelabel/helpers/style/colors';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import Button from '../../Button';

export const StyledBlockHeader = styled.h2`
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;

  ${mediaBreakpointUp('md')`
    margin-top: 3rem;
  `}
`;

export const StyledBlockSubTitle = styled.p`
  margin-bottom: 1rem;
  color: ${themeColor('body')};

  ${mediaBreakpointUp('md')`
    margin-bottom: 1.5rem;
  `}
`;

export const StyledShowAllButton = styled(Button)`
  grid-column: 1 / -1;
`;

export const StyledDivider = styled.hr`
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 1rem;
  color: ${gray(400)};
  ${mediaBreakpointUp('lg')`
      margin-bottom: 1.5rem;
      margin-top: 1.5rem;
  `}
`;
