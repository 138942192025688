import styled from 'styled-components';
import { mediaBreakpointUp, publicMarginBottom } from '@whitelabel/helpers/style/grid';

export const StyledResponseSection = styled.section`
  ${publicMarginBottom};
  margin-top: 1.5rem;
  .section-header {
    margin-bottom: 0.5rem;
    ${mediaBreakpointUp('md')`
         margin-bottom: 1rem;
         margin-top: 2rem;
      `}
    ${mediaBreakpointUp('lg')`
         margin-bottom: 1.5rem;
         margin-top: 3rem;
      `}
  }
`;
