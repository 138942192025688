import { ReactComponent as AUFlag } from '@whitelabel/media/icons/flags/AU.svg';
import { ReactComponent as EUFLag } from '@whitelabel/media/icons/flags/EU.svg';
import { ReactComponent as USFlag } from '@whitelabel/media/icons/flags/US.svg';
import { ReactComponent as GBFlag } from '@whitelabel/media/icons/flags/GB.svg';
import { ReactComponent as AEFlag } from '@whitelabel/media/icons/flags/AE.svg';
import { ReactComponent as ARFlag } from '@whitelabel/media/icons/flags/AR.svg';
import { ReactComponent as BGFlag } from '@whitelabel/media/icons/flags/BG.svg';
import { ReactComponent as BOFlag } from '@whitelabel/media/icons/flags/BO.svg';
import { ReactComponent as BRFlag } from '@whitelabel/media/icons/flags/BR.svg';
import { ReactComponent as BSFlag } from '@whitelabel/media/icons/flags/BS.svg';
import { ReactComponent as CAFlag } from '@whitelabel/media/icons/flags/CA.svg';
import { ReactComponent as CHFlag } from '@whitelabel/media/icons/flags/CH.svg';
import { ReactComponent as CLFlag } from '@whitelabel/media/icons/flags/CL.svg';
import { ReactComponent as CNFlag } from '@whitelabel/media/icons/flags/CN.svg';
import { ReactComponent as COFlag } from '@whitelabel/media/icons/flags/CO.svg';
import { ReactComponent as CZFlag } from '@whitelabel/media/icons/flags/CZ.svg';
import { ReactComponent as DKFlag } from '@whitelabel/media/icons/flags/DK.svg';
import { ReactComponent as DOFlag } from '@whitelabel/media/icons/flags/DO.svg';
import { ReactComponent as EGFlag } from '@whitelabel/media/icons/flags/EG.svg';
import { ReactComponent as HKFlag } from '@whitelabel/media/icons/flags/HK.svg';
import { ReactComponent as HUFlag } from '@whitelabel/media/icons/flags/HU.svg';
import { ReactComponent as HRFlag } from '@whitelabel/media/icons/flags/HR.svg';
import { ReactComponent as ISFlag } from '@whitelabel/media/icons/flags/IS.svg';
import { ReactComponent as INFlag } from '@whitelabel/media/icons/flags/IN.svg';
import { ReactComponent as IDFlag } from '@whitelabel/media/icons/flags/ID.svg';
import { ReactComponent as ILFlag } from '@whitelabel/media/icons/flags/IL.svg';
import { ReactComponent as JPFlag } from '@whitelabel/media/icons/flags/JP.svg';
import { ReactComponent as KRFlag } from '@whitelabel/media/icons/flags/KR.svg';
import { ReactComponent as KZFlag } from '@whitelabel/media/icons/flags/KZ.svg';
import { ReactComponent as MAFlag } from '@whitelabel/media/icons/flags/MA.svg';
import { ReactComponent as MUFlag } from '@whitelabel/media/icons/flags/MU.svg';
import { ReactComponent as MYFlag } from '@whitelabel/media/icons/flags/MY.svg';
import { ReactComponent as MXFlag } from '@whitelabel/media/icons/flags/MX.svg';
import { ReactComponent as NOFlag } from '@whitelabel/media/icons/flags/NO.svg';
import { ReactComponent as NZFlag } from '@whitelabel/media/icons/flags/NZ.svg';
import { ReactComponent as PYFlag } from '@whitelabel/media/icons/flags/PY.svg';
import { ReactComponent as PEFlag } from '@whitelabel/media/icons/flags/PE.svg';
import { ReactComponent as PHFlag } from '@whitelabel/media/icons/flags/PH.svg';
import { ReactComponent as PLFlag } from '@whitelabel/media/icons/flags/PL.svg';
import { ReactComponent as QAFlag } from '@whitelabel/media/icons/flags/QA.svg';
import { ReactComponent as ROFlag } from '@whitelabel/media/icons/flags/RO.svg';
import { ReactComponent as RSFlag } from '@whitelabel/media/icons/flags/RS.svg';
import { ReactComponent as RUFlag } from '@whitelabel/media/icons/flags/RU.svg';
import { ReactComponent as SAFlag } from '@whitelabel/media/icons/flags/SA.svg';
import { ReactComponent as SEFlag } from '@whitelabel/media/icons/flags/SE.svg';
import { ReactComponent as SGFlag } from '@whitelabel/media/icons/flags/SG.svg';
import { ReactComponent as THFlag } from '@whitelabel/media/icons/flags/TH.svg';
import { ReactComponent as TRFlag } from '@whitelabel/media/icons/flags/TR.svg';
import { ReactComponent as TWFlag } from '@whitelabel/media/icons/flags/TW.svg';
import { ReactComponent as UAFlag } from '@whitelabel/media/icons/flags/UA.svg';
import { ReactComponent as UYFlag } from '@whitelabel/media/icons/flags/UY.svg';
import { ReactComponent as VEFlag } from '@whitelabel/media/icons/flags/VE.svg';
import { ReactComponent as VNFlag } from '@whitelabel/media/icons/flags/VN.svg';
import { ReactComponent as ZAFlag } from '@whitelabel/media/icons/flags/ZA.svg';
import { ICurrencies } from './types';

const currencies: ICurrencies[] = [
  { label: 'AUD', value: 'AUD', icon: AUFlag },
  { label: 'EUR', value: 'EUR', icon: EUFLag },
  { label: 'USD', value: 'USD', icon: USFlag },
  { label: 'GBP', value: 'GBP', icon: GBFlag },
  { label: 'AED', value: 'AED', icon: AEFlag, hiddenFromList: true },
  { label: 'ARS', value: 'ARS', icon: ARFlag, hiddenFromList: true },
  { label: 'BGN', value: 'BGN', icon: BGFlag, hiddenFromList: true },
  { label: 'BOB', value: 'BOB', icon: BOFlag, hiddenFromList: true },
  { label: 'BRL', value: 'BRL', icon: BRFlag, hiddenFromList: true },
  { label: 'BSD', value: 'BSD', icon: BSFlag, hiddenFromList: true },
  { label: 'CAD', value: 'CAD', icon: CAFlag, hiddenFromList: true },
  { label: 'CHF', value: 'CHF', icon: CHFlag, hiddenFromList: true },
  { label: 'CLP', value: 'CLP', icon: CLFlag, hiddenFromList: true },
  { label: 'CNY', value: 'CNY', icon: CNFlag, hiddenFromList: true },
  { label: 'COP', value: 'COP', icon: COFlag, hiddenFromList: true },
  { label: 'CZK', value: 'CZK', icon: CZFlag, hiddenFromList: true },
  { label: 'DKK', value: 'DKK', icon: DKFlag, hiddenFromList: true },
  { label: 'DOP', value: 'DOP', icon: DOFlag, hiddenFromList: true },
  { label: 'EGP', value: 'EGP', icon: EGFlag, hiddenFromList: true },
  { label: 'HKD', value: 'HKD', icon: HKFlag, hiddenFromList: true },
  { label: 'HUF', value: 'HUF', icon: HUFlag, hiddenFromList: true },
  { label: 'HRK', value: 'HRK', icon: HRFlag, hiddenFromList: true },
  { label: 'ISK', value: 'ISK', icon: ISFlag, hiddenFromList: true },
  { label: 'INR', value: 'INR', icon: INFlag, hiddenFromList: true },
  { label: 'IDR', value: 'IDR', icon: IDFlag, hiddenFromList: true },
  { label: 'ILS', value: 'ILS', icon: ILFlag, hiddenFromList: true },
  { label: 'JPY', value: 'JPY', icon: JPFlag, hiddenFromList: true },
  { label: 'KRW', value: 'KRW', icon: KRFlag, hiddenFromList: true },
  { label: 'KZT', value: 'KZT', icon: KZFlag, hiddenFromList: true },
  { label: 'MAD', value: 'MAD', icon: MAFlag, hiddenFromList: true },
  { label: 'MUR', value: 'MUR', icon: MUFlag, hiddenFromList: true },
  { label: 'MYR', value: 'MYR', icon: MYFlag, hiddenFromList: true },
  { label: 'MXN', value: 'MXN', icon: MXFlag, hiddenFromList: true },
  { label: 'NOK', value: 'NOK', icon: NOFlag, hiddenFromList: true },
  { label: 'NZD', value: 'NZD', icon: NZFlag, hiddenFromList: true },
  { label: 'PYG', value: 'PYG', icon: PYFlag, hiddenFromList: true },
  { label: 'PEN', value: 'PEN', icon: PEFlag, hiddenFromList: true },
  { label: 'PHP', value: 'PHP', icon: PHFlag, hiddenFromList: true },
  { label: 'PLN', value: 'PLN', icon: PLFlag, hiddenFromList: true },
  { label: 'QAR', value: 'QAR', icon: QAFlag, hiddenFromList: true },
  { label: 'RON', value: 'RON', icon: ROFlag, hiddenFromList: true },
  { label: 'RSD', value: 'RSD', icon: RSFlag, hiddenFromList: true },
  { label: 'RUB', value: 'RUB', icon: RUFlag, hiddenFromList: true },
  { label: 'SAR', value: 'SAR', icon: SAFlag, hiddenFromList: true },
  { label: 'SEK', value: 'SEK', icon: SEFlag, hiddenFromList: true },
  { label: 'SGD', value: 'SGD', icon: SGFlag, hiddenFromList: true },
  { label: 'THB', value: 'THB', icon: THFlag, hiddenFromList: true },
  { label: 'TRY', value: 'TRY', icon: TRFlag, hiddenFromList: true },
  { label: 'TWD', value: 'TWD', icon: TWFlag, hiddenFromList: true },
  { label: 'UAH', value: 'UAH', icon: UAFlag, hiddenFromList: true },
  { label: 'UYU', value: 'UYU', icon: UYFlag, hiddenFromList: true },
  { label: 'VEF', value: 'VEF', icon: VEFlag, hiddenFromList: true },
  { label: 'VND', value: 'VND', icon: VNFlag, hiddenFromList: true },
  { label: 'ZAR', value: 'ZAR', icon: ZAFlag, hiddenFromList: true },
];

export const sortedCurrencies = [...currencies].sort((a, b) => a.label.localeCompare(b.label));

export default currencies;
