import { flagMap } from './flagMap';
import { isUSEnglish } from './site';
import { ILocales } from './types';
import languages from './languages.json';

const momentCodes = {
  en: 'en-au',
  'zh-hans': 'zh-cn',
  'zh-hant': 'zh-hk',
};

export const locales = Object.keys(languages).reduce<Record<string, ILocales>>((availableLocales, locale) => {
  const { code, name, nameLocal, bidi: isRTL } = languages[locale as keyof typeof languages];
  const flagCode = isUSEnglish(locale) ? 'en-us' : locale;
  const Flag = flagMap[flagCode as keyof typeof flagMap];

  availableLocales[locale] = {
    code,
    momentCode: momentCodes[locale as keyof typeof momentCodes] || code,
    name,
    nameLocal,
    isRTL,
    Flag,
  };

  return availableLocales;
}, {});
