import styled, { css } from 'styled-components';
import { spacer, multiply } from '@whitelabel/helpers/style/units';
import IconTick from '@whitelabel/media/icons/tick.svg';
import IconCross from '@whitelabel/media/icons/cross.svg';
import { BlockComponentSpacing, ListType } from '@whitelabel/helpers/types';

export const StyledIconList = styled.ul<{ spacing: BlockComponentSpacing }>`
  margin-bottom: ${({ spacing }) => (spacing === 'sm' ? '1rem' : '2rem')};
  padding-inline-start: 0;
  line-height: ${multiply(spacer, 2)};
  list-style: none;
`;
interface IStyledIconListItem {
  $listType: ListType;
  $rtl: boolean;
  size?: 'small' | 'large';
}
export const StyledIconListItem = styled.li<IStyledIconListItem>`
  ${({ size, $listType, $rtl }: IStyledIconListItem) => {
    const bgInlinePosition = $rtl ? 'right' : 'left';

    let overWritten = null;
    if (size === 'small') {
      overWritten = css`
        background-position: ${bgInlinePosition} center;
        background-size: 1rem 1.6875rem;
        padding-inline-start: 1.5rem;
        margin-bottom: 0.5rem;
        font-size: 1.125rem;
        line-height: 1.5;
      `;
    }

    return css`
      position: relative;
      margin-bottom: 1rem;
      padding-inline-start: ${multiply(spacer, 3)};
      background: url(${$listType === 'inclusions' ? IconTick : IconCross}) ${bgInlinePosition} top no-repeat;
      ${overWritten}
    `;
  }}
`;
