// @ts-nocheck
import React from 'react';
import { useField } from 'formik';
import { useIntl } from 'react-intl';
import { StyledInputContainer, StyledInput } from './styledCurrencyFormattedInput';

export interface ICurrencyFormattedInputProps {
  id: string;
  invalid: boolean;
  disabled: boolean;
  name: string;
  currency: string;
  'aria-errormessage'?: string;
}

const CurrencyFormattedInput = ({
  id,
  invalid,
  disabled,
  name,
  currency: currencyCode,
  'aria-errormessage': ariaErrorMessage,
}: ICurrencyFormattedInputProps): JSX.Element => {
  const { locale } = useIntl();

  const [, { value: metaValue }, { setValue, setTouched }] = useField(name);

  // Get currency symbol by formatting a random number
  const [{ value: currencySymbol }] = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  }).formatToParts(1);

  const handleOnBlur = () => setTouched(name, true);
  const handleOnValueChange = (value) => setValue(value);

  return (
    <StyledInputContainer>
      <span className="symbol">{currencySymbol}</span>
      <StyledInput
        intlConfig={{ locale, currency: currencyCode }}
        $isInvalid={invalid}
        disabled={disabled}
        id={id}
        name={name}
        // Hack to remove the prefix:
        // Adding prefix overrides the currency one but
        // the library doesn't allow empty string ("") prefix
        // so I'm using unicode's invisible character
        prefix="‎"
        value={metaValue}
        onBlur={handleOnBlur}
        onValueChange={handleOnValueChange}
        aria-invalid={invalid ? 'true' : undefined}
        aria-errormessage={invalid ? ariaErrorMessage : undefined}
      />
      <span className="code">{currencyCode}</span>
    </StyledInputContainer>
  );
};

export default CurrencyFormattedInput;
