import { Auth } from '@aws-amplify/auth';
import * as FullStory from '@fullstory/browser';
import { decamelizeKeys } from 'humps';
import { push } from 'connected-react-router';
import { IMPERSONATOR_TOKEN, BOOKING_REFERENCE_FILED_ID } from '@whitelabel/helpers/constants';
import { captureExceptionWithFullStory } from '@whitelabel/helpers/utils';
import { IBooking } from '@whitelabel/helpers/types';
import api, {
  handleFormikAWSFormError,
  handleFormikFormError,
  ZENDESK_API_URL,
} from '@whitelabel/xcover-shared/helpers/api';
import {
  LANGUAGE_FILED_ID,
  CUSTOMER_RATING_FIELD_ID,
  MEDIA_TYPE,
  MAX_BOOKINGS_PER_REVIEW,
  WRONG_USERNAME_OR_PASSWORD,
  CUSTOMER_REGION,
  EU_CENTRAL_1,
} from '@whitelabel/xcover-shared/helpers/constants';
import { configureCognito, getAPIHost } from '@whitelabel/xcover-shared/helpers/multiRegion';
import { createAppAsyncThunk } from '../createAppAsyncThunk';
import { ILocationState } from '../../helpers/types';
import { impersonateLogin } from './impersonateLogin';

interface IUpdateCustomerGDPRProp {
  gdprConsent: boolean;
  id: string;
}

interface IUpdateBookingGDPRProp {
  gdprConsent: boolean;
  ins: string;
}

export const cognitoSignIn = async ({
  cognitoUserID,
  password,
  getState,
}: {
  cognitoUserID: string;
  password: string;
  getState: () => { intl: { messages: { error: string } } };
}) => {
  try {
    const cognitoUser = await Auth.signIn(cognitoUserID, password);
    return cognitoUser;
  } catch (error) {
    // @ts-expect-error TS2571: Object is of type 'unknown'.
    if (error.message !== WRONG_USERNAME_OR_PASSWORD) {
      // @ts-expect-error TS2571: Object is of type 'unknown'.
      error.message = `Cognito error: ${error.message}`;
      // @ts-expect-error TS2571: Object is of type 'unknown'.
      captureExceptionWithFullStory(error);
      const generalErrorMessage = getState().intl.messages.error;
      throw new Error(generalErrorMessage);
    }

    throw error;
  }
};

const recordRatingOnFS = (email: string, rating: number, feedback: string) => {
  if (FullStory.isInitialized()) {
    FullStory.setUserVars({
      email,
    });
    FullStory.event('Review Added', {
      rating,
      comment: feedback,
    });
  }
};

const createZDTicket = async (
  firstName: string,
  lastName: string,
  email: string,
  locale: string,
  rating: number,
  feedback: string,
  bookingID = '',
  languageName: string,
  bookings = [],
  isFromEmail: boolean,
) => {
  const first20BookingIds = bookings.slice(0, MAX_BOOKINGS_PER_REVIEW).map((booking: IBooking) => booking.id);
  // ZD requires BCP 47 compliant language tags
  const langTag = ['zh-hans', 'zh-hant'].includes(locale) ? 'zh-cn' : locale;
  const body = JSON.stringify({
    request: {
      requester: { name: `${firstName} ${lastName}`, email, locale: langTag },
      subject: 'Xcover.com - New Customer Review added',
      comment: {
        body: `
        FS Session URL: ${
          !window.isHeadless && FullStory.isInitialized() ? FullStory.getCurrentSessionURL(true) : 'NA'
        }
        Customer Rating: ${rating}.
        Customer Feedback: ${feedback},
        Customer Email: ${email},
        ${
          isFromEmail
            ? `Booking ID: ${bookingID},`
            : `Booking Ids (Maximum ${MAX_BOOKINGS_PER_REVIEW}): ${first20BookingIds},`
        }
        `,
      },
      tags: isFromEmail ? ['xc-email-feedback'] : ['xc-widget-feedback'],
      custom_fields: [
        { id: LANGUAGE_FILED_ID, value: languageName || '' },
        { id: BOOKING_REFERENCE_FILED_ID, value: bookingID },
        { id: CUSTOMER_RATING_FIELD_ID, value: rating },
      ],
    },
  });
  await api.post(`${ZENDESK_API_URL}/requests`, false, { body });
};

export const checkCustomer = createAppAsyncThunk(
  'customer/checkCustomer',
  async (
    { requestValue, isEmail, setSubmitting, isFallback = false, suppressError = false, ignoreError = false }: any,
    { rejectWithValue, getState },
  ) => {
    try {
      const { locale } = getState().intl;
      const searchParams = new URLSearchParams({ language: locale });
      const mediaType = isEmail ? MEDIA_TYPE.EMAIL : MEDIA_TYPE.PHONE;
      const body = JSON.stringify({ [mediaType]: requestValue });
      if (isFallback) {
        searchParams.append('fallback', isFallback);
      }
      const customer = await api.post(`${getAPIHost()}/customers/lookup/?${searchParams}`, false, { body });
      if (customer && isFallback) {
        localStorage.setItem(CUSTOMER_REGION, EU_CENTRAL_1);
        configureCognito();
      }
      return { ...customer, [mediaType]: requestValue };
    } catch (error) {
      if (ignoreError) {
        // silent error, can be used for signup flow
        return rejectWithValue(null);
      }
      if (suppressError) {
        // Disable eslint to keep the original logic: not pass no-unused-vars
        // props down to child component.
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        // @ts-expect-error TS2339: Property 'message' does not exist on type 'unknown'.
        const { message, ...supressedError } = error;
        return rejectWithValue(supressedError);
      }
      return rejectWithValue(handleFormikFormError(error));
    } finally {
      if (setSubmitting) setSubmitting(false);
    }
  },
);

export const getCustomerEmail = createAppAsyncThunk(
  'customer/getCustomerEmail',
  async (id: string, { rejectWithValue, getState }) => {
    try {
      const { locale } = getState().intl;
      const searchParams = new URLSearchParams({ language: locale });
      const headers = { 'X-Id': id };
      const customer = await api.get(`${getAPIHost()}/customers/id/?${searchParams}`, false, { headers });
      return customer;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const sendSignUpVerification = createAppAsyncThunk(
  'customer/sendSignUpVerification',
  async ({ requestValue, isEmail, setSubmitting }: any, { rejectWithValue, getState }) => {
    try {
      const mediaType = isEmail ? MEDIA_TYPE.EMAIL : MEDIA_TYPE.PHONE;
      const { locale } = getState().intl;
      const referrer = (getState().router.location.state as ILocationState)?.referrer;
      const redirect = new URLSearchParams(window.location.search).get('redirect');
      let redirectObject;
      if (redirect) {
        const [pathname, rawSearch] = redirect.split('?');
        const search = rawSearch ? `&${rawSearch}` : '';
        redirectObject = { pathname, search: `redirect=true${search}` };
      }
      const body = JSON.stringify({ [mediaType]: requestValue, referrer: redirectObject || referrer });
      const searchParams = new URLSearchParams({ language: locale });

      await api.post(`${getAPIHost()}/customers/signup/?${searchParams}`, false, { body });
      return mediaType;
    } catch (error) {
      return rejectWithValue(handleFormikFormError(error));
    } finally {
      setSubmitting(false);
    }
  },
);

export const validateSignUpToken = createAppAsyncThunk(
  'customer/validateSignUpToken ',
  async (
    { id, token, source, onError, onSuccess, suppressExistingCustomerError = false }: any,
    { rejectWithValue, getState, dispatch },
  ) => {
    try {
      const { locale } = getState().intl;
      const searchParams = new URLSearchParams({ language: locale });
      const body = JSON.stringify({ token, source });
      const customer = await api.post(`${getAPIHost()}/customers/${id}/validate_token/?${searchParams}`, false, {
        body,
      });
      if (onSuccess) {
        onSuccess();
      }
      return { ...customer, id, token };
    } catch (error) {
      // @ts-expect-error TS2339: Property 'message' does not exist on type 'unknown'.
      if (error.status === 422 && suppressExistingCustomerError) {
        // Disable eslint to keep the original logic: not pass no-unused-vars
        // props down to child component.
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        // @ts-expect-error TS2339: Property 'message' does not exist on type 'unknown'.
        const { message, ...suppressedError } = error;
        return rejectWithValue(suppressedError);
      }
      // @ts-expect-error TS2339: Property 'message' does not exist on type 'unknown'.
      if (error.status === 404) {
        const { locale } = getState().intl;
        dispatch(push(`/${locale}/login`));
      }

      if (onError) {
        onError(error);
      }
      return rejectWithValue(error);
    }
  },
);

export const getCustomer = createAppAsyncThunk(
  'customer/getCustomer',
  async (
    { id, getXpayCustomerId }: { id: string; getXpayCustomerId?: boolean },
    { rejectWithValue, getState, dispatch },
  ) => {
    try {
      const { locale } = getState().intl;
      const searchParams = new URLSearchParams({
        language: locale as string,
        ...(getXpayCustomerId && { get_xpay_customer_id: 'true' }),
      });
      const customer: any = await api.get(`${getAPIHost()}/customers/${id}/?${searchParams}`, true);
      const impersonate = sessionStorage.getItem(IMPERSONATOR_TOKEN);
      if (impersonate) {
        dispatch(impersonateLogin({ username: customer.id, email: customer.email }));
      }

      return customer;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateCustomer = createAppAsyncThunk(
  'customer/updateCustomer',
  async (payload: any, { rejectWithValue, getState }) => {
    try {
      const { locale } = getState().intl;
      const searchParams = new URLSearchParams({ language: locale });
      const body = JSON.stringify(decamelizeKeys(payload));

      const customer = await api.patch(`${getAPIHost()}/customers/${payload.id}/?${searchParams}`, true, {
        body,
      });
      return customer;
    } catch (error) {
      return rejectWithValue(handleFormikFormError(error));
    }
  },
);

export const processCustomerFeedback = createAppAsyncThunk(
  'customer/processCustomerFeedback',
  async (payload: any, { rejectWithValue, getState }) => {
    const { rating, feedback = '', languageName, bookings } = payload;
    try {
      const { locale } = getState().intl;
      const customer = getState().customer.data;

      const { email, firstName, lastName, id } = customer!;
      const searchParams = new URLSearchParams({ language: locale });
      if (!window.isHeadless) {
        recordRatingOnFS(email!, rating, feedback);
      }

      if (ZENDESK_API_URL) {
        createZDTicket(firstName!, lastName!, email!, locale, rating, feedback, '', languageName, bookings, false);
      }
      await api.patch(`${getAPIHost()}/customers/${id}/feedback/?${searchParams}`, true);
      return null;
    } catch (error) {
      return rejectWithValue(handleFormikFormError(error));
    }
  },
);

export const updateCustomerGDPR = createAppAsyncThunk(
  'customer/updateCustomerGDPR',
  async ({ gdprConsent, id }: IUpdateCustomerGDPRProp, { rejectWithValue, getState }) => {
    try {
      const { locale } = getState().intl;
      const searchParams = new URLSearchParams({ language: locale });
      const body = JSON.stringify({ gdpr_consent: gdprConsent });
      await api.post(`${getAPIHost()}/customers/${id}/gdpr/?${searchParams}`, true, { body });
      return {
        gdprConsent,
        id,
      };
    } catch (error) {
      return rejectWithValue(handleFormikFormError(error));
    }
  },
);

export const updateBookingGDPR = createAppAsyncThunk(
  'customer/updateBookingGDPR',
  // eslint-disable-next-line consistent-return
  async ({ gdprConsent, ins }: IUpdateBookingGDPRProp, { getState }) => {
    try {
      const { locale } = getState().intl;
      const searchParams = new URLSearchParams({ language: locale });
      const body = JSON.stringify({ gdpr_consent: gdprConsent });
      await api.patch(`${getAPIHost(ins)}/quotes/${ins}/gdpr/?${searchParams}`, false, { body });
      return { gdprConsent, ins };
    } catch (error: any) {
      // don't need to do anything
    }
  },
);
export const getEmailUpdates = createAppAsyncThunk(
  'customer/getEmailUpdates',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { id } = getState().customer.data!;
      const { locale } = getState().intl;
      const searchParams = new URLSearchParams({ language: locale });
      const { email } = await api.get(`${getAPIHost()}/customers/${id}/email/?${searchParams}`, true);
      return email;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateEmail = createAppAsyncThunk(
  'customer/updateEmail',
  // eslint-disable-next-line consistent-return
  async ({ id, newEmail, password }: any, { rejectWithValue, getState, dispatch }) => {
    try {
      const { locale } = getState().intl;
      const searchParams = new URLSearchParams({ language: locale });
      const body = JSON.stringify({ email: newEmail });
      await cognitoSignIn({ cognitoUserID: id, password, getState });
      await api.post(`${getAPIHost()}/customers/${id}/email/?${searchParams}`, true, { body });
      dispatch(getEmailUpdates());
    } catch (error) {
      // @ts-expect-error TS2339: Property 'message' does not exist on type 'unknown'.
      if (error.code === 'NotAuthorizedException') {
        return rejectWithValue(handleFormikAWSFormError(error));
      }
      return rejectWithValue(handleFormikFormError(error));
    }
  },
);

export const transferBookings = createAppAsyncThunk(
  'customer/transferBookings',
  async ({ targetCustomerID, customerID, securityToken }: any, { rejectWithValue, getState }) => {
    try {
      const { locale } = getState().intl;
      const searchParams = new URLSearchParams({ language: locale });
      const body = JSON.stringify({ customer_id: customerID, security_token: securityToken });

      await api.post(`${getAPIHost()}/customers/${targetCustomerID}/transfer_bookings/?${searchParams}`, true, {
        body,
      });
      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const resendVerification = createAppAsyncThunk(
  'customer/resendVerification',
  async ({ id, email }: any, { rejectWithValue, getState }) => {
    try {
      const { locale } = getState().intl;
      const searchParams = new URLSearchParams({ language: locale });
      const body = JSON.stringify({ email });
      await api.post(`${getAPIHost()}/customers/${id}/email/?${searchParams}`, true, { body });
      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const validateEmail = createAppAsyncThunk(
  'customer/validateEmail',
  async (token: string, { rejectWithValue, getState }) => {
    try {
      const { locale } = getState().intl;
      const searchParams = new URLSearchParams({ language: locale });
      const body = JSON.stringify({ token });
      const { acknowledged: emailUpdated } = await api.patch(
        `${getAPIHost()}/customers/email/update/?${searchParams}`,
        false,
        { body },
      );
      return emailUpdated;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
