import { createRoutine } from 'redux-saga-routines';
import { push } from 'connected-react-router';
import { takeLatest, put, select } from 'redux-saga/effects';
import { get } from 'lodash/fp';
import { getUserContinent, getUserCountry } from '@whitelabel/helpers/site';
import { localizeLocation } from '@whitelabel/helpers/i18n';
import { CURRENT_LOCALE } from '@whitelabel/helpers/constants';

export const updateLocale = createRoutine('locales/UPDATE_LOCALE');

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'locale' implicitly has an 'any' t... Remove this comment to see the full error message
function* handleUpdateLocale({ payload: locale }: ReturnType<{ type: typeof updateLocale.TRIGGER; payload: string }>) {
  try {
    const prevLocale: string = yield select(get('intl.locale'));
    const location: Location = yield select(get('router.location'));

    if (locale !== prevLocale) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const newLocale =
        locale === 'en' && (getUserCountry() === 'US' || getUserContinent() === 'SA') ? 'en-us' : locale;
      sessionStorage.setItem(CURRENT_LOCALE, newLocale);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { pathname, search, hash, state } = localizeLocation(location, newLocale);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      yield put(push(`${pathname}${search}${hash}`, state));
      window.location.reload();
    }
  } catch (error) {
    yield put(updateLocale.failure(error));
  }
}
export function* localesSaga() {
  yield takeLatest(updateLocale.TRIGGER, handleUpdateLocale);
}
