import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { fontStyle, fontWeight } from '@whitelabel/helpers/style/type';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { themeColor } from '@whitelabel/helpers/style/colors';
import { focusLinkStyle, focusVisibleMixin } from '@whitelabel/helpers/style/utils';
import SelectInput from '../SelectInput';

export const StyledSelectInput = styled(SelectInput)`
  flex: 1 0 33%;
  max-width: ${rem(110)};

  > .react-select__control {
    border-start-start-radius: 0;
    border-end-start-radius: 0;
  }

  && .react-select__option {
    padding: 0;
  }

  && .react-select__option--is-selected,
  && .react-select__option--is-focused {
    background-color: transparent;
    font-weight: ${fontWeight('bold')};
  }

  && .react-select__menu-list {
    padding-top: 0;
    padding-bottom: 0;
  }

  && .react-select__menu {
    inset-inline-start: ${rem(-59)};
    width: ${rem(168)};
    padding: 1rem;
  }
`;

export const StyledIcon = styled.svg`
  margin-inline-end: 0.5rem;
`;

export const StyledModalBodyWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 2rem;

  ${mediaBreakpointUp('sm')`
    grid-template-columns: repeat(3, 1fr);
  `}
`;

export const StyledModalOption = styled.span<{ isSelected: boolean }>`
  ${fontStyle('contentMenu')};
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    color: ${themeColor('success')};
  }

  ${focusVisibleMixin(focusLinkStyle)}

  ${({ isSelected }) =>
    isSelected &&
    css`
      font-weight: ${fontWeight('bold')};
      color: ${themeColor('success')};
    `}
`;
