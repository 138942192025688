import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAccessToken } from '@whitelabel/helpers/api';
import { IMPERSONATOR_TOKEN } from '@whitelabel/helpers/constants';

type RootState = { user: { data: any } };
// Define a service using a base URL and expected endpoints
export const xcoverAPI = createApi({
  reducerPath: 'xcoverApi',
  baseQuery: fetchBaseQuery({
    prepareHeaders: async (headers, api) => {
      const auth = headers.get('auth');

      if (auth === 'false') {
        headers.delete('auth');
        return headers;
      }
      const user = (api.getState() as RootState).user.data;
      if (user) {
        const impersonatorToken = sessionStorage.getItem(IMPERSONATOR_TOKEN);
        const token = impersonatorToken ? `Impersonator ${impersonatorToken}` : `Bearer ${await getAccessToken(true)}`;
        headers.set('Authorization', token);
        return headers;
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
  tagTypes: ['Bookings', 'Quote'],
});
