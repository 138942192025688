import { updateLocale } from '@whitelabel/actions/locales';

const initialState = {
  loading: false,
  error: null,
};

// Technically, we can remove REQUEST and FULFIL case. Just keeping it as we might use it in near future.
export default (state = initialState, action: any) => {
  switch (action.type) {
    case updateLocale.REQUEST:
      return { ...state, loading: true, error: null };

    case updateLocale.FAILURE:
      return { ...state, error: action.payload };

    case updateLocale.FULFILL:
      return { ...state, loading: false };
    default:
      return state;
  }
};
