import React from 'react';
import styled, { css } from 'styled-components';
import { Row } from 'reactstrap';
import { rem } from 'polished';
import { fontStyle, headingTextTransform, lineHeight, fontWeight } from '@whitelabel/helpers/style/type';
import { zIndex } from '@whitelabel/helpers/style/zIndex';
import { mediaBreakpointUp, gridGutterWidth } from '@whitelabel/helpers/style/grid';
import { spacer } from '@whitelabel/helpers/style/units';
import { Alignment, BlockComponentSpacing, IImageBlock } from '@whitelabel/helpers/types';
import { headingBlockColor } from '@whitelabel/helpers/style/get';
import { themeColor } from '@whitelabel/helpers/style/colors';
import ImageBlock from '../ImageBlock';

interface IStyledHeadingProp {
  name: string;
  alignment: string;
  spacing?: BlockComponentSpacing;
}
export const StyledHeading = styled.header<IStyledHeadingProp>`
  position: relative;
  z-index: ${zIndex('content')};
  margin-bottom: ${(props: IStyledHeadingProp) => (props.spacing === 'sm' ? '1rem' : '1.5rem')};

  ${mediaBreakpointUp('md')`
    margin-bottom:  ${(props: IStyledHeadingProp) => (props.spacing === 'sm' ? '1rem' : '2rem')};
  `}

  ${({ alignment }) => {
    switch (alignment) {
      case 'start':
      case 'left':
        return css`
          text-align: start;
        `;
      case 'end':
      case 'right':
        return css`
          text-align: end;
        `;
      default:
        return css`
          text-align: center;
        `;
    }
  }}
`;

export const StyledTitle = styled.h1<{
  hasBoxShadow?: boolean;
  $shouldTitleBeUppercase?: boolean;
  headingTextColor?: string;
}>`
  margin-bottom: ${spacer};
  ${({ $shouldTitleBeUppercase }) =>
    css`
      text-transform: ${$shouldTitleBeUppercase ? headingTextTransform : 'none'};
    `}
  overflow-wrap: break-word;
  hyphens: auto;

  sup {
    top: 0;
    font-weight: ${fontWeight('normal')};
    font-size: ${fontStyle('base')};
    vertical-align: super;

    ${mediaBreakpointUp('md')`
      top: -${rem(8)};
    `}
  }
  span {
    color: ${(props) => (props.headingTextColor ? props.headingTextColor : headingBlockColor('headerText'))};
  }
  ${({ hasBoxShadow }) =>
    hasBoxShadow &&
    css`
      line-height: ${lineHeight('headingsLg')};
      margin-inline-end: 0.5rem;
      margin-inline-start: 0.5rem;

      ${mediaBreakpointUp('md')`
        margin-inline-end: ${spacer};
        margin-inline-start: ${spacer};
      `}

      span {
        box-decoration-break: clone;
        -webkit-box-decoration-break: clone;
        background: ${themeColor('primary')};
        box-shadow: 0.5rem 0 0 ${themeColor('primary')}, -0.5rem 0 0 ${themeColor('primary')};

        ${mediaBreakpointUp('md')`
        box-shadow: ${spacer} 0 0 ${themeColor('primary')}, -${spacer} 0 0 ${themeColor('primary')};
      `};
      }
    `}
`;

export interface IImageBlockProps {
  $rtl: boolean;
  image:
    | {
        file: string;
        title: string;
      }
    | IImageBlock;
  altText: string;
  className?: string;
  alignment?: Alignment;
  spacing?: BlockComponentSpacing;
}

// Disable eslint to keep the original logic: not pass no-unused-vars
// props down to child component.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledImage = styled(({ alignment = 'left', ...props }: IImageBlockProps) => <ImageBlock {...props} />)`
  display: block;
  max-width: 100%;
  margin: 0 auto ${(props) => (props.spacing === 'sm' ? '1rem' : gridGutterWidth('sm'))};

  ${mediaBreakpointUp('md')`
    margin-bottom: ${(props: IImageBlockProps) => (props.spacing === 'sm' ? '1rem' : gridGutterWidth('md'))};
  `}

  ${mediaBreakpointUp('lg')`
    margin-bottom:  ${(props: IImageBlockProps) => (props.spacing === 'sm' ? '1rem' : gridGutterWidth('lg'))};
  `}

  ${({ alignment }) => {
    switch (alignment) {
      case 'left':
        return css`
          ${mediaBreakpointUp('sm')`
            ${({ $rtl }: { $rtl: boolean }) => ($rtl ? 'float: left;' : 'float: right;')}
            max-width: 33%;
            margin: 0 0 0 ${gridGutterWidth('sm')};
          `}

          ${mediaBreakpointUp('md')`
            max-width: 50%;
            margin: 0 0 0 ${gridGutterWidth('md')};
          `}

          ${mediaBreakpointUp('lg')`
            margin: 0 0 0 ${gridGutterWidth('lg')};
          `}
        `;

      case 'right':
        return css`
          ${mediaBreakpointUp('sm')`
            ${({ $rtl }: { $rtl: boolean }) => ($rtl ? 'float: right;' : 'float: left;')}
            max-width: 33%;
            margin: 0 ${gridGutterWidth('sm')} 0 0;
          `}

          ${mediaBreakpointUp('md')`
            max-width: 50%;
            margin: 0 ${gridGutterWidth('md')} 0 0;
          `}

          ${mediaBreakpointUp('lg')`
            margin: 0 ${gridGutterWidth('lg')} 0 0;
          `}
        `;

      default:
        return null;
    }
  }}
`;

export const StyledLeadText = styled(Row).attrs(() => ({ className: 'lead' }))`
  ${fontStyle('lead')}
  *:last-child {
    margin-bottom: 0;
  }
  display: block;
`;
