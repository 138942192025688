import styled, { css } from 'styled-components';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { HeroBlock } from '@whitelabel/component-library';
import Layout from '../Layout';
import { StyledMain } from '../Layout/styledLayout';
import { StyledSectionNav } from '../Footer/FooterLarge/styledFooterLarge';

export const StyledLayout = styled(Layout)<{ $hideFooterNav?: boolean }>`
  ${({ $hideFooterNav }) =>
    $hideFooterNav
      ? css`
          padding-bottom: 0;
        `
      : css`
          ${StyledMain} {
            margin-bottom: 0;
          }

          ${StyledSectionNav} {
            border-top: 0;
          }
        `}
`;

export const StyledHeroBlock = styled(HeroBlock)`
  margin-top: -1.5rem;

  ${mediaBreakpointUp('sm')`
    margin-top: -2rem;
  `}
  ${mediaBreakpointUp('lg')`
    margin-top: -3rem;
`}
`;
