import {
  grays,
  colors,
  themeColors,
  buttonColors,
  contentColors,
  toggleColors,
  headingBlockColors,
  inputColors,
} from './colors';
import {
  bodyBackground,
  bodyColor,
  fontSizes,
  fontWeights,
  lineHeights,
  inputDisabledBG,
  headingFontSizes,
  fontStyles,
} from './type';
import { spacer, gridBreakpoints, gridGutterWidths } from './grid';
import { borderWidth, borderColor, borderStyle, borderRadii, boxShadows } from './box';
import { fontFamilies } from './fonts';
import modal from './modal';
import alert from './alert';
import zIndex from './zIndex';
import card from './card';
import dropdown from './dropdown';
import popover from './popover';
import tooltip from './tooltip';
import nav from './nav';
import button from './button';

const theme = {
  grays,
  colors,
  themeColors,
  toggleColors,
  buttonColors,
  headingBlockColors,
  bodyBackground,
  bodyColor,
  contentColors,
  inputColors,
  spacer,
  gridBreakpoints,
  gridGutterWidths,
  borderWidth,
  borderColor,
  borderStyle,
  borderRadii,
  boxShadows,
  transitions: {
    base: 'all 0.2s ease-in-out',
    fade: 'opacity 0.15s linear',
    collapse: 'height 0.35s ease',
    duration: '250ms',
  },
  table: {
    borderWidth: '1px',
    borderColor: grays[400],
  },
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  headingFontSizes,
  inputDisabledBG,
  fontStyles,
  modal,
  alert,
  zIndex,
  card,
  dropdown,
  popover,
  tooltip,
  nav,
  button,
  headingTextTransform: 'uppercase',
};

export default theme;
