import React, { ChangeEvent, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Input } from 'reactstrap';
import { Props as SelectProps } from 'react-select';
import { FieldProps } from 'formik';
import moment from 'moment';
import { IDOB } from '@whitelabel/helpers/types';
import commonMessages from '@whitelabel/helpers/messages/commonMsg';
import messages from './messages';
import { StyledDOBWrapper, StyledMonthSelect, StyledLabel } from './styledDOBInput';

export interface IDOBInputProps {
  name: string;
  value?: IDOB;
  form: FieldProps['form'];
  className?: string;
  id?: string;
  invalid: boolean;
  disabled?: boolean;
  'aria-errormessage'?: string;
}

const DOBInput = ({
  name,
  value,
  form: { setFieldValue, setFieldTouched },
  className,
  id,
  invalid,
  disabled = false,
  'aria-errormessage': ariaErrorMessage,
}: IDOBInputProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const { dobDay, dobMonth, dobYear } = value || {};

  const FULL_MONTH_NAMES = useMemo(
    () =>
      Array.from(Array(12), (_, index) => ({
        label: moment().month(index).format('MMM'),
        value: String(index + 1).padStart(2, '0'),
      })),
    [],
  );

  const handleInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const { name: prefixedDOBFieldName, value: dobFieldValue } = event.target;
    const dobFieldName = prefixedDOBFieldName.split('-')[1];
    let isInputValid = false;
    switch (dobFieldName) {
      case 'dobDay':
        isInputValid = dobFieldValue.length <= 2;
        break;
      case 'dobYear':
        isInputValid = dobFieldValue.length <= 4;
        break;
      default:
    }

    if (isInputValid) {
      const newValue = { ...value, [dobFieldName]: dobFieldValue };
      // await here is to fix stale state issue
      // because values in validate function or validateSchema in formik are one state behind
      await setFieldValue(name, newValue);
      // only validate when all day, month and year are filled
      const shouldValidate = !!(newValue?.dobDay && newValue?.dobMonth && newValue?.dobYear?.length === 4);
      await setFieldTouched(name, shouldValidate);
    }
  };

  const handleSelectChange: SelectProps['onChange'] = async (selectedMonth) => {
    const newValue = { ...value, dobMonth: selectedMonth?.value };
    await setFieldValue(name, newValue);
    const shouldValidate = !!(newValue?.dobDay && newValue?.dobMonth && newValue?.dobYear);
    await setFieldTouched(name, shouldValidate);
  };

  return (
    <StyledDOBWrapper id={id} className={className} $invalid={invalid}>
      <StyledLabel $disabled={disabled}>
        <span>{formatMessage(messages.dayLabel)}</span>
        <Input
          className="day"
          name={`${name}-dobDay`}
          type="number"
          onChange={handleInputChange}
          value={dobDay}
          placeholder="DD"
          disabled={disabled}
          invalid={invalid}
          aria-errormessage={invalid ? ariaErrorMessage : undefined}
        />
      </StyledLabel>
      <StyledLabel $disabled={disabled}>
        <span>{formatMessage(messages.monthLabel)}</span>
        <StyledMonthSelect
          name={`${name}-dobMonth`}
          options={FULL_MONTH_NAMES}
          onChange={handleSelectChange}
          placeholder={formatMessage(commonMessages.select)}
          value={FULL_MONTH_NAMES.filter((el) => el.value === dobMonth)}
          isDisabled={disabled}
        />
      </StyledLabel>
      <StyledLabel $disabled={disabled}>
        <span>{formatMessage(messages.yearLabel)}</span>
        <Input
          className="year"
          name={`${name}-dobYear`}
          type="number"
          onChange={handleInputChange}
          value={dobYear}
          placeholder="YYYY"
          disabled={disabled}
          invalid={invalid}
          aria-errormessage={invalid ? ariaErrorMessage : undefined}
        />
      </StyledLabel>
    </StyledDOBWrapper>
  );
};

export default DOBInput;
