import { ILabelValuePair } from '../types';

export const countriesToSelectOptions = (
  countries: Record<string, { code: string; name: string }>,
  locale?: string | string[],
): ILabelValuePair[] =>
  Object.values(countries)
    .map(({ code, name }) => ({ value: code, label: name }))
    .sort((a, b) => a.label.localeCompare(b.label, locale));

export const convertObjectNullToString = (values: any) => {
  Object.keys(values).forEach((key) => {
    if (values[key] === null) {
      values[key] = '';
    }
  });
  return values;
};

export const convertEmptyStringToNull = (values: any) => {
  Object.keys(values).forEach((key) => {
    if (values[key] === '') {
      values[key] = null;
    }
  });
  return values;
};
