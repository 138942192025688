import React from 'react';
import { NavLink } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { isXCoverNextJSLive } from '@whitelabel/xcover-shared/helpers/utils';
import commonMessages from '@whitelabel/helpers/messages/commonMsg';
import { INavBrandProps } from '../../../helpers/types';
import { StyledBrand, StyledBrandLogo, StyledBrandTitle } from './styledBrand';

const Brand = ({ title, logo, size, link = true, locale, hasLoggedIn }: INavBrandProps): JSX.Element => {
  const intl = useIntl();
  const homepageLinkProps = isXCoverNextJSLive()
    ? { href: `/${locale}` }
    : { tag: NavLink, to: `/${locale}`, exact: true };
  return (
    <StyledBrand
      {...(link && (hasLoggedIn ? { tag: NavLink, to: `/${locale}/account`, exact: true } : homepageLinkProps))}
      data-test-id="NavBarBrand"
    >
      <StyledBrandLogo
        src={logo}
        alt={intl.formatMessage(commonMessages.logo, { brand: title })}
        size={size}
        data-test-id="NavBarBrand-logo"
      />
      <StyledBrandTitle data-test-id="NavBarBrand-title">{title}</StyledBrandTitle>
    </StyledBrand>
  );
};

export default Brand;
