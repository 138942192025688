import React from 'react';
import styled from 'styled-components';
import { MessageDescriptor } from 'react-intl';

export const formatCurrency = (intl: any, value: any, currency: any, trimCents = false) => {
  if (!value) return value;
  const formattedValue = intl.formatNumber(value, { style: 'currency', currency, narrowSymbol: false });

  if (trimCents && formattedValue.endsWith('.00')) {
    return formattedValue.substring(0, formattedValue.indexOf('.00'));
  }

  return formattedValue;
};

const StyledMessageContainerSpan = styled.span`
  position: relative;
`;
const StyledMessageIDSpan = styled.span`
  z-index: 100;
  top: -1.5em;
  position: absolute;
  display: inline-block;
  visibility: hidden;
  padding: 0.25rem;
  background: white;
  border-radius: 0.2rem;
  cursor: pointer;
`;

export const renderMessageWithID = (intl: any) => {
  let storedID: string | number | undefined = '';
  document.addEventListener('keydown', (e) => {
    if (e.shiftKey) {
      navigator.clipboard.writeText(storedID as string);
    }
  });

  const handleMouseOver = (id: MessageDescriptor['id']) => {
    const idEl = document.getElementById(`message-${id}`);
    if (!idEl) return;
    storedID = id;
    idEl.style.visibility = 'visible';
    setTimeout(() => {
      idEl.style.visibility = 'hidden';
    }, 2000);
  };

  const old = intl.formatMessage;

  intl.formatMessage = ({ id, defaultMessage }: MessageDescriptor) => (
    <StyledMessageContainerSpan onMouseOver={() => handleMouseOver(id)}>
      <StyledMessageIDSpan id={`message-${id}`}>{id} </StyledMessageIDSpan>
      {old({ id, defaultMessage })}
    </StyledMessageContainerSpan>
  );
};
