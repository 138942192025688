import { MutableRefObject, useEffect, useRef, useState } from 'react';

/*
 ** This is not as robust because it is not considering
 ** the differences between `mouseover`, `pointerover`, `touchstart`
 ** https://github.com/adobe/react-spectrum/blob/main/packages/%40react-aria/interactions/src/useHover.ts
 */
export function useHover<T extends HTMLElement>(): [MutableRefObject<T | null>, boolean] {
  const [value, setValue] = useState<boolean>(false);
  const ref = useRef<T | null>(null);

  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  useEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);
      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
      };
    }
    return undefined;
  }, []);

  return [ref, value];
}
