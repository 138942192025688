import { Auth } from '@aws-amplify/auth';
import { camelizeKeys } from 'humps';
import { POLICY_TYPES, USER_GEO } from './constants';
import { captureExceptionWithFullStory } from './utils';

export const [
  API_HOST,
  CMS_API_HOST,
  XPAY_API_HOST,
  XPAY_CHARGE_PROVIDER_KEY,
  XPAY_CHARGE_BUNDLE_URL,
  GOOGLE_PLACES_API_KEY,
  GTM_AUTH,
  GTM_PREVIEW,
  GTM_CONTAINER_ID,
  BRIGHTWRITE_VERSION,
  BRIGHTWRITE_APP_ID,
  BRIGHTWRITE_APP_VERSION,
  BRIGHTWRITE_APP_VERSION1,
  BRIGHTWRITE_APP_VERSION2,
  BRIGHTWRITE_APP_VERSION3,
  BRIGHTWRITE_PUBLIC_KEY,
] = [
  process.env.REACT_APP_API_HOST || process.env.NEXT_PUBLIC_API_HOST,
  process.env.REACT_APP_CMS_API_HOST || process.env.NEXT_PUBLIC_CMS_API_HOST,
  process.env.REACT_APP_XPAY_API_HOST || process.env.NEXT_PUBLIC_XPAY_API_HOST,
  process.env.REACT_APP_XPAY_CHARGE_PROVIDER_KEY || process.env.NEXT_PUBLIC_XPAY_CHARGE_PROVIDER_KEY,
  process.env.REACT_APP_XPAY_CHARGE_BUNDLE_URL || process.env.NEXT_PUBLIC_XPAY_CHARGE_BUNDLE_URL,
  process.env.REACT_APP_GOOGLE_PLACES_API_KEY || process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY,
  process.env.REACT_APP_GTM_AUTH || process.env.NEXT_PUBLIC_GTM_AUTH,
  process.env.REACT_APP_GTM_PREVIEW || process.env.NEXT_PUBLIC_GTM_PREVIEW,
  process.env.REACT_APP_GTM_CONTAINER_ID || process.env.NEXT_PUBLIC_GTM_CONTAINER_ID,
  process.env.REACT_APP_BRIGHTWRITE_VERSION || process.env.NEXT_PUBLIC_BRIGHTWRITE_VERSION,
  process.env.REACT_APP_BRIGHTWRITE_APP_ID || process.env.NEXT_PUBLIC_BRIGHTWRITE_APP_ID,
  process.env.REACT_APP_BRIGHTWRITE_APP_VERSION || process.env.NEXT_PUBLIC_BRIGHTWRITE_APP_VERSION,
  process.env.REACT_APP_BRIGHTWRITE_APP_VERSION1 || process.env.NEXT_PUBLIC_BRIGHTWRITE_APP_VERSION1,
  process.env.REACT_APP_BRIGHTWRITE_APP_VERSION2 || process.env.NEXT_PUBLIC_BRIGHTWRITE_APP_VERSION2,
  process.env.REACT_APP_BRIGHTWRITE_APP_VERSION3 || process.env.NEXT_PUBLIC_BRIGHTWRITE_APP_VERSION3,
  process.env.REACT_APP_BRIGHTWRITE_PUBLIC_KEY || process.env.NEXT_PUBLIC_BRIGHTWRITE_PUBLIC_KEY,
];

export const getAccessToken = (auth: any) =>
  auth && typeof auth !== 'string'
    ? Auth.currentSession().then((currentSession) => (currentSession as any).accessToken.jwtToken)
    : auth;

const createHeaders = async (auth = false, config: any) =>
  new Headers({
    'Content-Type': 'application/json',
    ...(auth && { Authorization: `Bearer ${await getAccessToken(auth)}` }),
    ...config,
  });

const captureError = (error: any, url = '') => {
  if (url) {
    const validURL = url.startsWith('http') ? url : window.location.origin + url;
    const { pathname } = new URL(validURL);
    if (
      ((pathname.endsWith('/customers/email/') ||
        pathname.startsWith('/api/v1/staticpages/') ||
        // todo: remove this check (pathname.startsWith('/api/v2/staticpages/')
        // when all api endpoints of static pages pointing to xcover backend
        // currently, some applications (namely: qb, cp) are fetch static pages from xcms
        pathname.startsWith('/api/v2/staticpages/') ||
        pathname.endsWith('/customers/forgot_password/')) &&
        error.status === 404) ||
      (pathname.endsWith('/validateToken/') && error.status === 400) ||
      (pathname.startsWith('/api/v1/payout/') && error.status === 403)
    ) {
      return;
    }
  }
  captureExceptionWithFullStory(error, { url });
};

const createError = (status: any, response: any, url: any) => {
  const allowedAttributes = ['requestId', 'type', 'code', 'href', 'errors'];
  const errorAttributes = Object.keys(response).filter((attribute) => allowedAttributes.includes(attribute));
  const error = new Error(response.message || response.detail);
  errorAttributes.reduce((accumulator, attribute) => {
    if (attribute === 'type') {
      accumulator.name = response[attribute];
    } else {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      accumulator[attribute] = response[attribute];
    }
    return accumulator;
  }, error);
  (error as any).status = status;
  captureError(error, url);
  return error;
};

const processResponse = async (response: any) => {
  try {
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      const responseJSON = await response.json();
      const data = camelizeKeys(responseJSON, (key: any, convert: any, options: any) => {
        if (POLICY_TYPES.includes(key) || key === '_error' || key.includes('sectionFields')) return key;
        return convert(key, options);
      });
      return data;
    }
    return response.text();
  } catch (error) {
    const responseText = await response.text();
    captureExceptionWithFullStory(error as Error, {
      response: {
        url: response.url,
        status: response.status,
        statusText: response.statusText,
        responseText,
      },
    });
    throw error;
  }
};
// TODO: delete this method since each app has its own API implementation
const api = async (url = '', auth = false, config = {}) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'headers' does not exist on type '{}'.
  const { headers, ...init } = config;
  let newURL = url;
  if (newURL.includes('language=')) {
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const languageParam = /language=[^&]+/.exec(newURL)[0];
    const language = languageParam.split('=')[1];
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    const userGeo = JSON.parse(sessionStorage.getItem(USER_GEO));
    const userCountry = userGeo && userGeo.countryCode;
    if (language === 'en' && userCountry && userCountry.toLowerCase() === 'us') {
      newURL = newURL.replace(languageParam, 'language=en-us');
    }
  }
  const response = await fetch(newURL, {
    mode: 'cors',
    headers: await createHeaders(auth, headers),
    ...init,
  });
  const data = await processResponse(response);
  if (!response.ok) throw createError(response.status, data, newURL);
  return data;
};
const get = (url: any, auth: any, config: any) => api(url, auth, { ...config, method: 'GET' });
const head = (url: any, auth: any, config: any) => api(url, auth, { ...config, method: 'HEAD' });
const post = (url: any, auth: any, config: any) => api(url, auth, { ...config, method: 'POST' });
const put = (url: any, auth: any, config: any) => api(url, auth, { ...config, method: 'PUT' });
const patch = (url: any, auth: any, config: any) => api(url, auth, { ...config, method: 'PATCH' });
const del = (url: any, auth: any, config: any) => api(url, auth, { ...config, method: 'DELETE' });
api.get = get;
api.head = head;
api.post = post;
api.put = put;
api.patch = patch;
api.del = del;
export default api;
