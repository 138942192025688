import {
  css,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  Theme,
  ThemeProps,
  CSSObject,
} from 'styled-components';
import { getValueAndUnit } from 'polished';
import { IAlignmentAsProp, IThemeAsProp, IThemePropertyGetter, BreakPoint } from '../types';
import { spacer, multiply } from './units';

type TypeCSS = CSSObject | TemplateStringsArray | string | any;
type TypeCssArgs = [TypeCSS, ...TypeCSS[]];

export const gridGutterWidth: IThemePropertyGetter<keyof Theme['gridBreakpoints']> =
  (breakpoint) =>
  ({ theme }) =>
    theme.gridGutterWidths[breakpoint];
export const gridBreakpoint: IThemePropertyGetter<keyof Theme['gridBreakpoints']> =
  (breakpoint) =>
  ({ theme }) =>
    theme.gridBreakpoints[breakpoint];
export const breakpointKeys = (theme: Theme): Array<keyof Theme['gridBreakpoints']> =>
  Object.keys(theme.gridBreakpoints) as Array<keyof Theme['gridBreakpoints']>;

export const justifyContent = ({ alignment }: IAlignmentAsProp): FlattenSimpleInterpolation | null => {
  switch (alignment) {
    case 'center':
      return css`
        justify-content: center;
      `;

    case 'left':
      return css`
        justify-content: flex-start;
      `;

    case 'right':
      return css`
        justify-content: flex-end;
      `;

    case 'full-width':
      return css`
        justify-content: stretch;
      `;

    case 'space-between':
      return css`
        justify-content: space-between;
      `;

    case 'space-around':
      return css`
        justify-content: space-around;
      `;

    case 'space-evenly':
      return css`
        justify-content: space-evenly;
      `;

    default:
      return null;
  }
};

export const breakpointNext =
  (breakpoint: BreakPoint) =>
  ({ theme }: IThemeAsProp): null | BreakPoint => {
    const breakpointNames = breakpointKeys(theme);
    const index = breakpointNames.indexOf(breakpoint);
    return index !== -1 && index < breakpointNames.length - 1 ? breakpointNames[index + 1] : null;
  };

export const breakpointMax =
  (breakpoint: BreakPoint) =>
  ({ theme }: IThemeAsProp): null | string => {
    const next = breakpointNext(breakpoint)({ theme });

    if (next) {
      const [width, unit] = getValueAndUnit(theme.gridBreakpoints[next]);
      return `${width - 0.02}${unit}`;
    }

    return null;
  };

export const breakpointInfix =
  (breakpoint: BreakPoint) =>
  ({ theme }: IThemeAsProp): string | null =>
    theme.gridBreakpoints[breakpoint] ? `-${breakpoint}` : null;

export const mediaBreakpointUp =
  (breakpoint: BreakPoint) =>
  (...args: TypeCssArgs) =>
  ({ theme }: IThemeAsProp): FlattenSimpleInterpolation => {
    const min = theme.gridBreakpoints[breakpoint];

    if (min) {
      return css`
        @media (min-width: ${min}) {
          ${css(...args)};
        }
      `;
    }

    return css(...args);
  };

export const mediaBreakpointDown =
  (breakpoint: BreakPoint) =>
  (...args: TypeCssArgs) =>
  ({ theme }: IThemeAsProp): FlattenSimpleInterpolation => {
    const max = breakpointMax(breakpoint)({ theme });
    if (max) {
      return css`
        @media (max-width: ${max}) {
          ${css(...args)};
        }
      `;
    }

    return css(...args);
  };

export const mediaBreakpointBetween =
  (lowerBreakpoint: BreakPoint, upperBreakpoint: BreakPoint) =>
  (...args: TypeCssArgs) =>
  ({ theme }: IThemeAsProp): FlattenSimpleInterpolation | null => {
    const min = theme.gridBreakpoints[lowerBreakpoint];
    const max = breakpointMax(upperBreakpoint)({ theme });

    if (min && max) {
      return css`
        @media (min-width: ${min}) and (max-width: ${max}) {
          ${css(...args)};
        }
      `;
    }

    if (!max) {
      return mediaBreakpointUp(lowerBreakpoint)(...args)({ theme });
    }

    if (!min) {
      return mediaBreakpointDown(upperBreakpoint)(...args)({ theme });
    }

    return null;
  };
export const mediaBreakpointOnly =
  (breakpoint: BreakPoint) =>
  (...args: TypeCssArgs) =>
  ({ theme }: IThemeAsProp): FlattenSimpleInterpolation | null => {
    const min = theme.gridBreakpoints[breakpoint];
    const max = breakpointMax(breakpoint)({ theme });

    if (min && max) {
      return css`
        @media (min-width: ${min}) and (max-width: ${max}) {
          ${css(...args)};
        }
      `;
    }

    if (!max) {
      return mediaBreakpointUp(breakpoint)(...args)({ theme });
    }

    if (!min) {
      return mediaBreakpointDown(breakpoint)(...args)({ theme });
    }

    return null;
  };

export const marginBottom = css`
  margin-bottom: 1rem;

  ${mediaBreakpointUp('md')`
    margin-bottom: 1.5rem;
  `}

  ${mediaBreakpointUp('lg')`
    margin-bottom: 2rem;
  `}
`;

export const publicMarginBottom = css`
  margin-bottom: 1.5rem;

  ${mediaBreakpointUp('md')`
    margin-bottom: 2rem;
  `}

  ${mediaBreakpointUp('lg')`
    margin-bottom: 3rem;
  `}
`;

export const singleQuestionMarginBottom = css`
  margin-bottom: ${multiply(spacer, 1.5)};

  ${mediaBreakpointUp('md')`
    margin-bottom: ${multiply(spacer, 5)};
  `}

  ${mediaBreakpointUp('lg')`
    margin-bottom: ${multiply(spacer, 8)};
  `}
`;

/**
 * @param {string[]} cssProperties an array of applied cssProperty
 * @param {number} multiplier a multiplier to apply on gutter width
 */
export const setAllGridGutterWidths =
  (cssProperties: string[], multiplier = 1) =>
  ({ theme }: IThemeAsProp): Array<(prop: IThemeAsProp) => FlattenSimpleInterpolation> =>
    breakpointKeys(theme).map((breakpointKey) => {
      const cssContent: FlattenInterpolation<ThemeProps<Theme>>[] = [];
      cssProperties.forEach((cssProperty: string) => {
        cssContent.push(css`
          ${cssProperty}: ${multiply(gridGutterWidth(breakpointKey), multiplier)};
        `);
      });
      return mediaBreakpointUp(breakpointKey)`
      ${cssContent}
    `;
    });
