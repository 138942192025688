import { createMedia } from '@artsy/fresnel';
import { breakpoints } from '@whitelabel/global-theme/grid';

const AppMedia = createMedia({
  breakpoints,
});

// Make styles for injection into the header of the page
export const mediaStyles = AppMedia.createMediaStyle();

export const { Media, MediaContextProvider } = AppMedia;
