import { xcoverAPI } from '@whitelabel/xcover-shared/store/services/xcover/index';
import { STATUS_CONFIRMED, STATUS_CANCELLED } from '@whitelabel/helpers/constants';
import { SEARCH_TYPE, BOOKINGS_PER_PAGE, RTK_CACHE_LIFE } from '@whitelabel/xcover-shared/helpers/constants';
import { processKeys } from '@whitelabel/xcover-shared/helpers/api';
import { IBooking } from '@whitelabel/helpers/types';
import { getAPIHost } from '@whitelabel/xcover-shared/helpers/multiRegion';

export interface IGetCustomerBookings {
  id: string;
  locale: string;
  status?: string[];
  url?: string;
}

export interface IFilterCustomerBookings {
  id: string;
  searchTerm: string;
  searchType: string;
}

export interface IGetBooking {
  id: string;
  locale: string;
  securityToken?: string;
}

interface IBookingsData {
  all: string[];
  bookings: Record<string, IBooking>;
  next: string;
  filtered?: boolean;
}

const processBookingsResponse = (response: any) => {
  const { results, next } = processKeys(response);
  const all: string[] = [];
  const bookings = results.reduce((allBookings: any, booking: any) => {
    all.push(booking.id);
    return { ...allBookings, [booking.id]: booking };
  }, {});

  return {
    bookings,
    all,
    next,
  };
};

export const bookingsAPI = xcoverAPI.injectEndpoints({
  endpoints: (builder) => ({
    getCustomerBookings: builder.query<IBookingsData, IGetCustomerBookings>({
      query: ({ id, locale, status = [STATUS_CONFIRMED, STATUS_CANCELLED], url = '' }) => {
        const searchParams = new URLSearchParams({
          language: locale,
          status: status.join('|'),
          limit: BOOKINGS_PER_PAGE.toString(),
        });
        return url || `${getAPIHost()}/customers/${id}/bookings/?${searchParams}`;
      },
      transformResponse: (response) => ({ filtered: false, ...processBookingsResponse(response) }),
      keepUnusedDataFor: RTK_CACHE_LIFE,
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (cache, newData) => {
        cache.all = [...cache.all, ...newData.all];
        cache.next = newData.next;
        Object.assign(cache.bookings, newData.bookings);
      },
      // No forceRefetch function since we don't need to refetch data when jump across different pages.
    }),
    filterCustomerBookings: builder.query<IBookingsData, IFilterCustomerBookings>({
      query: ({ id, searchTerm, searchType }) => {
        const queryParam = {
          [searchType === SEARCH_TYPE.REF_NUMBER ? 'id' : 'attributes__tracking_reference']: searchTerm,
        };
        const searchParams = new URLSearchParams(queryParam);
        return `${getAPIHost()}/customers/${id}/bookings/?${searchParams}`;
      },
      transformResponse: (response) => processBookingsResponse(response),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        dispatch(
          bookingsAPI.util.updateQueryData(
            'getCustomerBookings',
            undefined as unknown as IGetCustomerBookings,
            (draft) => {
              draft.filtered = false;
            },
          ),
        );
        try {
          const { data } = await queryFulfilled;
          dispatch(
            bookingsAPI.util.updateQueryData(
              'getCustomerBookings',
              undefined as unknown as IGetCustomerBookings,
              () => ({ filtered: true, ...data }),
            ),
          );
        } catch (e) {
          // Ignore
        }
      },
    }),
    getBooking: builder.query<IBooking, IGetBooking>({
      query: ({ id, locale, securityToken }) => {
        const searchParamsObject = (() => {
          if (securityToken) {
            return {
              language: locale,
              security_token: securityToken,
            };
          }

          return {
            language: locale,
          };
        })();
        const searchParams = new URLSearchParams(searchParamsObject as any);

        return `${getAPIHost(id)}/bookings/${id}/?${searchParams}`;
      },
      transformResponse: (response: any) => processKeys(response),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        dispatch(
          bookingsAPI.util.updateQueryData(
            'getCustomerBookings',
            undefined as unknown as IGetCustomerBookings,
            (draft) => {
              if (draft) draft.filtered = false;
            },
          ),
        );
        try {
          const { data } = await queryFulfilled;
          dispatch(
            bookingsAPI.util.updateQueryData(
              'getCustomerBookings',
              undefined as unknown as IGetCustomerBookings,
              (draft) => {
                if (draft) {
                  draft.all = Array.from(new Set([...draft.all, id]));
                  draft.bookings[id] = data;
                }
              },
            ),
          );
        } catch (e) {
          // Ignore
        }
      },
    }),
  }),
});

export const {
  useGetCustomerBookingsQuery,
  useLazyGetCustomerBookingsQuery,
  useLazyFilterCustomerBookingsQuery,
  useLazyGetBookingQuery,
  useGetBookingQuery,
} = bookingsAPI;

export const clearBookings = () => bookingsAPI.util.resetApiState();
