import { Col } from 'reactstrap';
import styled, { css, ThemeProps } from 'styled-components';
import { rem } from 'polished';
import { themeColor, color } from '@whitelabel/helpers/style/colors';
import { borderRadius } from '@whitelabel/helpers/style/box';
import { mediaBreakpointDown } from '@whitelabel/helpers/style/grid';
import { spacer, multiply } from '@whitelabel/helpers/style/units';
import ButtonBlock from '../ButtonBlock';
import HeadingBlock from '../HeadingBlock';
import { IImageContentCTABlockProps } from './types';

const StyledContainer = styled.div<
  ThemeProps<any> & Pick<IImageContentCTABlockProps, 'backgroundColor' | 'imageAlignment'>
>`
  margin-bottom: 3rem;
  ${mediaBreakpointDown('md')`
    display: none;
  `}

  ${({
    theme,
    backgroundColor,
    imageAlignment,
  }: ThemeProps<any> & Pick<IImageContentCTABlockProps, 'backgroundColor' | 'imageAlignment'>) => {
    let bgColor;
    if (['primary', 'secondary'].includes(backgroundColor)) {
      bgColor = theme.themeColors[backgroundColor];
    }

    const spacer1dot5 = multiply(spacer, 1.5)({ theme });
    const spacer2dot5 = multiply(spacer, 2.5)({ theme });
    let paddingInline;
    if (imageAlignment === 'right') {
      paddingInline = `
        padding-inline-start: ${spacer2dot5};
        padding-inline-end: ${spacer1dot5};
      `;
    } else {
      paddingInline = `
        padding-inline-start: ${spacer1dot5};
        padding-inline-end: ${spacer2dot5};
      `;
    }

    return css`
      padding-top: ${spacer1dot5};
      padding-bottom: ${spacer1dot5};
      border-radius: ${borderRadius('base')};
      ${bgColor ? `background-color: ${bgColor};` : ''}
      ${paddingInline}
    `;
  }};
`;

const StyledImageCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  ${mediaBreakpointDown('md')`
      ${({ variant }: { variant: string }) => (variant === 'secondary' ? `display: none` : `display: block`)}        
    `}
`;

const StyledContentCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: ${themeColor('body')};
  background-color: ${color('white')};
  border-radius: ${borderRadius('base')};
  .list-block li {
    background-position: left top;
  }
  &&& {
    padding-top: ${multiply(spacer, 2)};
    padding-bottom: ${multiply(spacer, 2)};
    padding-inline-start: ${multiply(spacer, 2)};
    padding-inline-end: ${multiply(spacer, 2)};
  }
`;

const StyledHeading = styled(HeadingBlock)`
  margin-bottom: 0.5rem;
  color: ${themeColor('header')};
  > h3 {
    margin-bottom: 0;
  }
`;

const StyledParagraph = styled.p`
  margin-bottom: ${multiply(spacer, 1.5)};
  line-height: ${rem(27)};
  color: ${themeColor('body')};
`;

const StyledButtonBlock = styled(ButtonBlock)`
  margin-top: ${multiply(spacer, -0.5)};
`;

export { StyledContainer, StyledButtonBlock, StyledParagraph, StyledContentCol, StyledImageCol, StyledHeading };
