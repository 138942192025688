import React from 'react';
import { useIntl } from 'react-intl';
import { smoothScrollToAnchor } from '@whitelabel/helpers/url';
import { StyledLink } from './styledLinkWrapper';

export interface ILinkWrapperProps {
  link: string;
  children: React.ReactNode;
}

const LinkWrapper = ({ link, children }: ILinkWrapperProps): JSX.Element => {
  const intl = useIntl();
  const linkStartLetter = link.charAt(0);
  switch (linkStartLetter) {
    case '/':
      return <StyledLink to={`/${intl.locale}${link}`}>{children}</StyledLink>;
    case '#':
      return (
        <button onClick={smoothScrollToAnchor(link)} type="button">
          {children}
        </button>
      );
    default:
      return (
        <StyledLink href={link} as="a">
          {children}
        </StyledLink>
      );
  }
};

export default LinkWrapper;
