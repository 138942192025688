import styled, { css } from 'styled-components';
import { gray } from '@whitelabel/helpers/style/colors';
import { buttonColor, getThemeProp } from '@whitelabel/helpers/style/get';
import { borderRadius } from '@whitelabel/helpers/style/box';
import Button from '../Button';

export const StyledInputContainer = styled.div<{ $invalid: boolean }>`
  display: flex;
  align-items: center;

  > .value {
    max-width: 5.5rem;
    margin: 0 1rem;
    padding: 0.5rem 1.5rem;
    text-align: center;
    border: ${getThemeProp('borderStyle')};
    border-radius: ${borderRadius('base')};

    /* Remove Arrows/Spinners */
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield; /* stylelint-disable-line property-no-vendor-prefix */
    }

    ${({ $invalid }) =>
      $invalid &&
      css`
        :not(:focus) {
          border-color: red;
        }
      `}
  }
`;

export const StyledButton = styled(Button)`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  padding: 0;

  > svg {
    margin: 0;
  }

  &:disabled {
    > svg {
      > path {
        stroke: ${gray(500)};
      }
    }
  }

  &.btn-secondary:not(.disabled):active,
  &.btn-secondary:not(.disabled):visited,
  &.btn-secondary:not(.disabled):focus {
    border-color: ${buttonColor('secondary', 'outline')};
  }
  &.btn-secondary:not(.disabled):hover {
    box-shadow: none;
    border-color: ${gray(700)};
  }
`;
