import { lazy } from 'react';
import { retry } from '../helpers/utils';
import { localePrefix } from './routeHelpers';

export const ConnectedHome = lazy(() =>
  retry(() => import('../containers/pages/Home' /* webpackChunkName: "Home" */)),
);
export const PDS = lazy(() => retry(() => import('../containers/pages/PDS' /* webpackChunkName: "PDS" */)));
export const StaticPage = lazy(() =>
  retry(() => import('../containers/pages/StaticPage' /* webpackChunkName: "Static" */)),
);
export const Help = lazy(() => retry(() => import('../containers/pages/Help' /* webpackChunkName: "Help" */)));
export const ConnectedCOI = lazy(() => retry(() => import('../containers/pages/COI' /* webpackChunkName: "COI" */)));
export const ConnectedNotify = lazy(() =>
  retry(() => import('../containers/pages/Notify' /* webpackChunkName: "Notify" */)),
);
export const Login = lazy(() => retry(() => import('../containers/pages/Login' /* webpackChunkName: "Login" */)));
export const ConfirmSignUp = lazy(() =>
  retry(() => import('../containers/pages/ConfirmSignUp' /* webpackChunkName: "ConfirmSignUp" */)),
);
export const ForgotPassword = lazy(() =>
  retry(() => import('../containers/pages/ForgotPassword' /* webpackChunkName: "ForgotPassword" */)),
);
export const ForgotPasswordSubmit = lazy(() =>
  retry(() => import('../containers/pages/ForgotPasswordSubmit' /* webpackChunkName: "ForgotPasswordSubmit" */)),
);
export const Account = lazy(() =>
  retry(() => import('../containers/pages/Account' /* webpackChunkName: "Account" */)),
);
export const ConnectedProfile = lazy(() =>
  retry(() => import('../containers/pages/Profile' /* webpackChunkName: "Profile" */)),
);
export const ConnectedPayout = lazy(() =>
  retry(() => import('../containers/pages/Payout' /* webpackChunkName: "Payout" */)),
);
export const Logout = lazy(() => retry(() => import('../containers/pages/Logout' /* webpackChunkName: "Logout" */)));
export const FNOLRedirect = lazy(() =>
  retry(() => import('../containers/pages/FNOLRedirect' /* webpackChunkName: "FNOLRedirect" */)),
);
export const ClaimsPortalRedirect = lazy(() =>
  retry(() => import('../containers/pages/ClaimsPortalRedirect' /* webpackChunkName: "ClaimsPortalRedirect" */)),
);

export const Invoice = lazy(() =>
  retry(() => import('../containers/pages/Invoice' /* webpackChunkName: "Invoice" */)),
);
export const PayoutSubmitted = lazy(() =>
  retry(() => import('../containers/pages/PayoutSubmitted' /* webpackChunkName: "PayoutSubmitted" */)),
);
export const Quote = lazy(() => retry(() => import('../containers/pages/Quote' /* webpackChunkName: "Quote" */)));
export const QuotePolicyholder = lazy(() =>
  retry(() => import('../containers/pages/Quote/QuotePolicyholder' /* webpackChunkName: "QuotePolicyholder" */)),
);
export const QuotePaymentPage = lazy(() =>
  retry(() => import('../containers/pages/Quote/QuotePaymentPage' /* webpackChunkName: "QuotePaymentPage" */)),
);
export const QuoteConfirmed = lazy(() =>
  retry(() => import('../containers/pages/Quote/QuoteConfirmed' /* webpackChunkName: "QuoteConfirmed" */)),
);
export const HelpLanding = lazy(() =>
  retry(() => import('../containers/pages/HelpLanding' /* webpackChunkName: "HelpLanding" */)),
);
export const HelpArticleLists = lazy(() =>
  retry(() => import('../containers/pages/HelpArticleLists' /* webpackChunkName: "HelpArticleLists" */)),
);
export const ConnectedModifyBooking = lazy(() =>
  retry(() => import('../containers/pages/ModifyBooking' /* webpackChunkName: "ModifyBooking" */)),
);
export const PaypalInterstitial = lazy(() =>
  retry(() => import('../containers/pages/PaypalInterstitial' /* webpackChunkName: "PaypalInterstitial" */)),
);
export const Impersonate = lazy(() =>
  retry(() => import('../containers/pages/Impersonate' /* webpackChunkName: "Impersonate" */)),
);
export const AwsQuestionnaire = lazy(() =>
  retry(() => import('../containers/pages/AwsQuestionnaire' /* webpackChunkName: "AwsQuestionnaire" */)),
);
export const QuoteUnqualified = lazy(() =>
  retry(() => import('../containers/pages/Quote/QuoteUnqualified' /* webpackChunkName: "QuoteUnqualified" */)),
);
export const Summary = lazy(() =>
  retry(() => import('../containers/pages/Summary' /* webpackChunkName: "Summary" */)),
);
export const Upsell = lazy(() => retry(() => import('../containers/pages/Upsell' /* webpackChunkName: "Upsell" */)));
export const KWRoleSelection = lazy(() =>
  retry(() => import('../containers/pages/KWRoleSelection' /* webpackChunkName: "KwRoleSelection" */)),
);
export const KWQuestionnaire = lazy(() =>
  retry(() => import('../containers/pages/KWQuestionnaire' /* webpackChunkName: "KWQuestionnaire" */)),
);
export const KWQuoteConfirmed = lazy(() =>
  retry(() => import('../containers/pages/KWQuoteConfirmed' /* webpackChunkName: "KWQuoteConfirmed" */)),
);
export const KWRoleRedirect = lazy(() =>
  retry(() => import('../containers/pages/KWRoleRedirect' /* webpackChunkName: "KWRoleRedirect" */)),
);
export const KWQuestionnaireRedirect = lazy(() =>
  retry(() => import('../containers/pages/KWQuestionnaireRedirect' /* webpackChunkName: "KWQuestionnaireRedirect" */)),
);
export const KWAgentsRedirect = lazy(() =>
  retry(() => import('../containers/pages/KWAgentsRedirect' /* webpackChunkName: "KWAgentsRedirect" */)),
);
export const QuoteRedirect = lazy(() =>
  retry(() => import('../containers/pages/Quote/QuoteRedirect' /* webpackChunkName: "QuoteRedirect" */)),
);

export const routes = [
  {
    key: 'home',
    path: `${localePrefix}/`,
    exact: true,
    component: ConnectedHome,
  },
  {
    key: 'pds',
    path: `${localePrefix}/pds/:id`,
    exact: true,
    component: PDS,
  },
  {
    key: 'coi',
    path: `${localePrefix}/coi/:id`,
    exact: true,
    component: ConnectedCOI,
  },
  {
    key: 'notify',
    path: `${localePrefix}/notify/:id`,
    exact: true,
    component: ConnectedNotify,
  },
  {
    key: 'invoice',
    path: `${localePrefix}/invoice/:id`,
    exact: true,
    component: Invoice,
  },
  {
    key: 'help',
    path: `${localePrefix}/help/:slug`,
    exact: true,
    component: Help,
  },
  {
    key: 'helpLanding',
    path: [`${localePrefix}/help`, `${localePrefix}/help#:hashId`],
    exact: true,
    component: HelpLanding,
  },
  {
    key: 'helpArticleLists',
    path: `${localePrefix}/help/:type(partners|categories)/:slug`,
    exact: true,
    component: HelpArticleLists,
  },
  {
    key: 'quoteLanding',
    path: `${localePrefix}/get-quote/:insuranceLine`,
    component: QuoteRedirect,
  },
  {
    key: 'awsQuestionnaire',
    path: `${localePrefix}/aws/questionnaire`,
    component: AwsQuestionnaire,
  },
  {
    key: 'KWQuestionnaire',
    path: `${localePrefix}/home/keller-williams/questionnaire`,
    component: KWQuestionnaire,
  },
  {
    key: 'kwRoleSelection',
    path: `${localePrefix}/home/keller-williams/agents`,
    component: KWRoleSelection,
  },
  {
    key: 'KWQuoteConfirmed',
    path: `${localePrefix}/kw/order-confirmation`,
    component: KWQuoteConfirmed,
  },
  {
    key: 'old-KWQuestionnaire',
    path: `${localePrefix}/kw/questionnaire`,
    component: KWQuestionnaireRedirect,
  },
  {
    key: 'old-kwRoleSelection',
    path: `${localePrefix}/kw/role`,
    component: KWRoleRedirect,
  },
  {
    key: 'old-kwRoleSelectionTwo',
    path: `${localePrefix}/kellercoveredhomewarranty`,
    component: KWAgentsRedirect,
  },
  {
    key: 'KWAgents',
    path: `${localePrefix}/home/agents`,
    component: KWRoleRedirect,
  },
  {
    key: 'quoteUnqualified',
    path: `${localePrefix}/quote/unable-to-proceed`,
    component: QuoteUnqualified,
  },
  {
    key: 'quote',
    path: `${localePrefix}/quote/:quotePackageID`,
    exact: true,
    component: Quote,
  },
  {
    key: 'quotePolicyholder',
    path: `${localePrefix}/quote/:quotePackageID/policyholder`,
    exact: true,
    component: QuotePolicyholder,
  },
  {
    key: 'quotePayment',
    path: `${localePrefix}/quote/:quotePackageID/payment`,
    exact: true,
    component: QuotePaymentPage,
  },
  {
    key: 'quoteConfirmed',
    path: `${localePrefix}/quote/:bookingID/confirmed`,
    exact: true,
    component: QuoteConfirmed,
  },
  {
    key: 'fnol',
    path: `${localePrefix}/account/claims/fnol`,
    exact: true,
    component: FNOLRedirect,
  },
  {
    key: 'claims',
    path: `${localePrefix}/claims`,
    component: ClaimsPortalRedirect,
  },
  {
    key: 'impersonate',
    path: `${localePrefix}/impersonate`,
    exact: true,
    component: Impersonate,
  },
  // Keller William development(WIP):
  // Commenting out this router is because we don't release this in phase 1.
  // This page will be further developed in phase 2.
  // {
  //   key: 'Upsell',
  //   path: `${localePrefix}/upsell`,
  //   exact: true,
  //   component: Upsell,
  // },
  {
    key: 'logout',
    path: `${localePrefix}/logout`,
    exact: true,
    component: Logout,
  },
  {
    key: 'page',
    path: `${localePrefix}/:slug*`,
    exact: true,
    component: StaticPage,
  },
];

export const unauthRoutes = [
  {
    key: 'login',
    path: `${localePrefix}/login`,
    exact: true,
    component: Login,
  },
  {
    key: 'confirmSignUp',
    path: `${localePrefix}/signup/confirm`,
    exact: true,
    component: ConfirmSignUp,
  },
  {
    key: 'forgotPassword',
    path: `${localePrefix}/forgot-password`,
    exact: true,
    component: ForgotPassword,
  },
  {
    key: 'forgotPasswordSubmit',
    path: `${localePrefix}/forgot-password/confirm`,
    exact: true,
    component: ForgotPasswordSubmit,
  },
];

export const componentsMap = {
  account: Account,
  profile: ConnectedProfile,
  payoutSubmitted: PayoutSubmitted,
  payout: ConnectedPayout,
  paypalInterstitial: PaypalInterstitial,
  modifyBooking: ConnectedModifyBooking,
  summary: Summary,
  authenticatedStaticPages: StaticPage,
};
