import styled from 'styled-components';
import { Row } from 'reactstrap';
import { fontSize, lineHeight } from '@whitelabel/helpers/style/type';
import { mediaBreakpointUp, gridGutterWidth } from '@whitelabel/helpers/style/grid';
import { spacer, multiply } from '@whitelabel/helpers/style/units';
import { BlockComponentSpacing } from '@whitelabel/helpers/types';

export const StyledFeatureListBlock = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const StyledFeatureRow = styled(Row).attrs(() => ({ tag: 'li' }))`
  display: flex;
  align-items: start;
  margin-bottom: ${gridGutterWidth('xs')};
  padding: 0;

  ${mediaBreakpointUp('md')`
    margin-bottom: ${(props: { spacing: BlockComponentSpacing }) =>
      props.spacing === 'sm' ? '1rem' : gridGutterWidth('md')};
  `}

  ${mediaBreakpointUp('lg')`
    margin-bottom: ${(props: { spacing: BlockComponentSpacing }) =>
      props.spacing === 'sm' ? '1rem' : gridGutterWidth('lg')};
  `}
`;

export const StyledHeading = styled.h3`
  && {
    margin-bottom: ${spacer};
    font-size: ${multiply(fontSize('base'), 1.5)};
    line-height: ${lineHeight('headings')};
    text-transform: none;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledLeadText = styled.div.attrs({ className: 'lead' })`
  margin-bottom: ${spacer};
  font-size: ${fontSize('base')};

  ${StyledHeading} + & {
    margin-top: ${multiply(spacer, -0.5)};
  }

  &:last-child,
  > :last-child {
    margin-bottom: 0;
  }
`;

export const StyledBody = styled.div`
  &:last-child,
  > :last-child {
    margin-bottom: 0;
  }
`;
