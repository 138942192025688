const NUMBER_OF_TIME_SELECTION = 12;

export const hourOptions = Array.from(Array(NUMBER_OF_TIME_SELECTION), (_, index) => ({
  label: String(index + 1).padStart(2, '0'),
  value: String(index + 1).padStart(2, '0'),
}));

export const minuteOptions = Array.from(Array(NUMBER_OF_TIME_SELECTION), (_, index) => ({
  label: String(index * 5).padStart(2, '0'),
  value: String(index * 5).padStart(2, '0'),
}));
