import React from 'react';
import styled, { css } from 'styled-components';
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalHeaderProps } from 'reactstrap';
import { fontWeight, fontSize } from '@whitelabel/helpers/style/type';
import { borderRadius } from '@whitelabel/helpers/style/box';
import {
  mediaBreakpointDown,
  mediaBreakpointUp,
  gridGutterWidth,
  breakpointKeys,
} from '@whitelabel/helpers/style/grid';
import { spacer, multiply } from '@whitelabel/helpers/style/units';
import { getThemeProp, modal } from '@whitelabel/helpers/style/get';
import { BreakPoint } from '@whitelabel/helpers/types';
import { zIndex } from '@whitelabel/helpers/style/zIndex';
import { focusLinkStyle, focusVisibleMixin } from '@whitelabel/helpers/style/utils';

export const StyledModal = styled(Modal)<{ $rtl: boolean; $showCloseButton: boolean }>`
  direction: ${({ $rtl }) => ($rtl ? 'rtl' : 'ltr')};
  ${mediaBreakpointUp('sm')`
    max-width: ${modal('sizes', 'md')};
  `}

  .modal-content {
    padding: 2rem 1rem 1rem 1rem;
    padding-top: ${({ $showCloseButton = true }) => ($showCloseButton ? '2rem' : '1rem')};
    border: 0;
    border-radius: ${borderRadius('base')};

    ${mediaBreakpointUp('md')`
      padding: ${multiply(spacer, 4)};
    `}

    > :last-child {
      margin-bottom: 0;
    }
  }
`;

/**
 * bootstrap 5 uses 'btn-close' class while reactstrap uses close 'class'.
 * The CloseButton component is created and passed on as value to prop 'close' for 'ModalHeader'.
 * @see https://github.com/reactstrap/reactstrap/blob/master/src/ModalHeader.js
 *  */
const CloseButton = ({
  modalHeaderProps: { toggle, closeAriaLabel, charCode = 215 },
}: {
  modalHeaderProps: ModalHeaderProps;
}) => {
  const closeIcon = typeof charCode === 'number' ? String.fromCharCode(charCode) : charCode;
  return (
    <button type="button" onClick={toggle} className="btn-close" aria-label={closeAriaLabel}>
      <span aria-hidden="true">{closeIcon}</span>
    </button>
  );
};

const ModalHeaderWrapper = (props: ModalHeaderProps) => (
  <ModalHeader close={<CloseButton modalHeaderProps={props} />} {...props} />
);

export const StyledModalHeader = styled(({ $showCloseButton, ...props }) => <ModalHeaderWrapper {...props} />)`
  padding: 0;
  border-bottom: 0;

  ${({ theme, $header }) =>
    $header
      ? breakpointKeys(theme).map(
          (breakpoint) => mediaBreakpointUp(breakpoint)`
        margin-bottom: ${gridGutterWidth(breakpoint)};
      `,
        )
      : `
        margin-bottom: 0;
      `}

  .modal-title {
    position: relative;
    width: 100%;
    color: inherit;
    font-size: ${multiply(fontSize('base'), 1.5)};
    line-height: 1.25;

    ${mediaBreakpointUp('md')`
      font-size: ${multiply(fontSize('base'), 2)};
    `}

    ${mediaBreakpointUp('lg')`
      font-size: ${multiply(fontSize('base'), 2.5)};
    `}
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    height: auto;
    margin: 0;
    padding: ${spacer};
    font-weight: ${fontWeight('headings')};
    font-size: ${multiply(fontSize('base'), 1.5)};
    line-height: 0.5;
    background: none;
    display: ${({ $showCloseButton = true }) => ($showCloseButton ? 'block' : 'none')};
    z-index: ${zIndex('button')};

    :focus {
      ${focusLinkStyle}
    }
    ${focusVisibleMixin(focusLinkStyle)}

    ${mediaBreakpointUp('md')`
      top: ${spacer};
      right: ${spacer};
    `}
  }
`;

export const StyledModalAlert = styled.div`
  ${mediaBreakpointDown('xs')`
      margin-top: ${spacer};
    `}
`;

export const StyledModalBody = styled(ModalBody)`
  padding: 0;
  font-size: ${multiply(fontSize('base'), 1.125)};

  ${mediaBreakpointUp('md')`
    font-size: ${fontSize('lg')};
  `}

  ${({ theme }) =>
    breakpointKeys(theme).map(
      (breakpoint) => mediaBreakpointUp(breakpoint)`
      margin-bottom: ${gridGutterWidth(breakpoint)};
    `,
    )}

  ${({
    theme: {
      modal: { headingsFontSizes, headingsMarginBottoms },
    },
  }) =>
    (Object.keys(headingsFontSizes) as Array<keyof typeof headingsFontSizes>).map(
      (heading) => css`
        ${String(heading)} {
          ${(Object.keys(headingsFontSizes[heading]) as Array<keyof (typeof headingsFontSizes)[typeof heading]>).map(
            (breakpoint) => mediaBreakpointUp(breakpoint as BreakPoint)`
                    font-size: ${headingsFontSizes[heading][breakpoint]};
                    margin-bottom: ${headingsMarginBottoms[heading][breakpoint]};
                  `,
          )}
        }
      `,
    )}

  h1,
  h2 {
    line-height: ${modal('headingsLineHeights', 'sm')};
  }

  h3 {
    line-height: ${modal('headingsLineHeights', 'base')};

    ${mediaBreakpointUp('md')`
      line-height: ${modal('headingsLineHeights', 'base')};
    `}
  }

  h4,
  h5,
  h6 {
    line-height: ${modal('headingsLineHeights', 'base')};
  }
`;

export const StyledModalFooter = styled(ModalFooter)`
  display: flex;
  flex-direction: column;
  padding: ${spacer} 0 0 0;
  border-top: ${getThemeProp('borderStyle')};

  ${mediaBreakpointUp('md')`
    padding-top: ${multiply(spacer, 1.5)};
    flex-direction: row;
    justify-content: start;
    gap: .75rem 1rem;
  `}

  ${mediaBreakpointUp('lg')`
    padding-top: ${multiply(spacer, 2)};
    gap: 1rem;
  `}

  > :not(:first-child) {
    margin-top: ${spacer};
    margin-inline-start: 0;

    ${mediaBreakpointUp('md')`
      margin-top: 0;
      margin-inline-start: 0;
    `}
  }

  > :not(:last-child) {
    margin-inline-end: 0;

    ${mediaBreakpointUp('md')`
      margin-inline-end: ${multiply(spacer, 0.75)};
    `}

    ${mediaBreakpointUp('lg')`
      margin-inline-end: ${spacer};
    `}
  }

  button {
    width: 100%;
    ${mediaBreakpointUp('md')`
      width: auto;
    `}
  }
`;
