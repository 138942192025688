import React, { useState } from 'react';
import { Col } from 'reactstrap';
import { FieldProps } from 'formik';
import { ISelectOption } from '@whitelabel/helpers/types';
import { StyledRow, StyledCard, StyledCardImage, StyledCardTitle, StyledTickIcon } from './styledCardOptions';

export interface ICardOptionsProps {
  name: string;
  className?: string;
  options?: ISelectOption[];
  form: FieldProps['form'];
  id?: string;
}

const CardOptions = ({
  name,
  options,
  className,
  form: { setFieldValue, setFieldTouched, values },
  id,
}: ICardOptionsProps): JSX.Element => {
  const [cardSelected, setCardSelected] = useState(values[name]);

  const onCardSelect = (cardID: any) => () => {
    setCardSelected(cardID);
    setFieldValue(name, cardID, false);
    setFieldTouched(name, true, false);
  };

  return (
    <StyledRow id={id}>
      {options?.map(({ label, value, icon }: any) => (
        <Col key={value} xs={6} sm={4}>
          <StyledCard
            id={value}
            className={className}
            onClick={onCardSelect(value)}
            $selected={value === cardSelected}
          >
            {value === cardSelected && <StyledTickIcon />}
            {icon && <StyledCardImage src={icon} alt={label} />}
            <StyledCardTitle>{label}</StyledCardTitle>
          </StyledCard>
        </Col>
      ))}
    </StyledRow>
  );
};

export default CardOptions;
