import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { fontStyle } from '@whitelabel/helpers/style/type';
import { themeColor, color } from '@whitelabel/helpers/style/colors';
import { getThemeProp } from '@whitelabel/helpers/style/get';
import { borderRadius } from '@whitelabel/helpers/style/box';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';

export const StyledBannerContainer = styled.div<{ $image: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;
  min-height: ${rem(80)};
  background-color: ${themeColor('primary')};
  border-radius: ${borderRadius('lg')};

  body.rentalcover-theme & {
    background-color: ${color('apricot')};
    border: ${getThemeProp('borderWidth')} solid ${themeColor('primary')};
  }

  .banner-image {
    display: none;

    ${mediaBreakpointUp('sm')`
      display: block;
      object-fit: cover;
      max-width: ${rem(100)};
      max-height: ${rem(100)};
      margin-right: 1.5rem;
      margin-left: 0;
    `}
  }

  .banner-content {
    width: 100%;
  }

  .banner-title {
    ${fontStyle('h5')}
    margin-bottom: 0;
  }

  .banner-paragraph {
    ${fontStyle('paragraph')}
    margin-bottom: 1rem;

    ${mediaBreakpointUp('sm')`
      margin-bottom: 0;
    `}

    a {
      color: ${themeColor('dark')};
      text-decoration: underline;
      text-decoration-skip-ink: none;
      &:hover {
        box-shadow: none;
      }
    }
  }

  .banner-button {
    ${mediaBreakpointUp('sm')`
      margin-right: 0;
      margin-left: 1.5rem;
    `}
  }

  ${mediaBreakpointUp('sm')`
    flex-direction: row;
    align-items: center;
    min-height: ${rem(96)};
    padding: 1.5rem;
    margin-bottom: 1.5rem;

    ${({ $image }: { $image: boolean }) =>
      css`
        padding: ${$image ? '0.5rem 1.5rem' : '1.5rem'};
      `}
  `}

  ${mediaBreakpointUp('lg')`
    margin-bottom: 3rem;
  `}
`;
