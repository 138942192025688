import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'nps.title',
    defaultMessage: 'How likely are you to recommend XCover to a friend or colleague?',
  },
  negativeText: {
    id: 'nps.negativeText',
    defaultMessage: 'Not likely',
  },
  positiveText: {
    id: 'nps.positiveText',
    defaultMessage: 'Very likely',
  },
  feedbackLeadText: {
    id: 'nps.feedbackLeadText',
    defaultMessage: `We’re sorry we didn’t meet your expectations. Would you like to share your feedback to help us improve?`,
  },
});
