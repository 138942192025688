import styled, { css, createGlobalStyle } from 'styled-components';
import { Nav, Navbar, NavbarToggler, Container } from 'reactstrap';
import { zIndex } from '@whitelabel/helpers/style/zIndex';
import { fontStyle, fontWeight, fontSize, fontFamily } from '@whitelabel/helpers/style/type';
import { gray, themeColor, color } from '@whitelabel/helpers/style/colors';
import { borderRadius, flipForRTL, flipFlexForRTL, getRightValue } from '@whitelabel/helpers/style/box';
import { mediaBreakpointDown, mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { getThemeProp, button, transitions } from '@whitelabel/helpers/style/get';
import Button from '../components/Button';

const mobileNavHeight = '4rem';
const hamburgerIcon = (strokeColor: string) =>
  // eslint-disable-next-line max-len
  `url("data:image/svg+xml;charset=utf8,%3Csvg width='24px' height='20px' viewBox='0 0 24 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='${strokeColor.replace(
    '#',
    '%23',
  )}' stroke-width='4' stroke-linecap='round' d='M2,2 L22,2 M2,10 L22,10 M2,18 L22,18'/%3E%3C/svg%3E")`;

export const MenuGlobalStyle = createGlobalStyle<{ $fixed: boolean }>`
  body {
    position: ${({ $fixed }) => ($fixed ? 'fixed' : 'static')};
    width: 100%;
    text-align: start;
  }
`;

export const StyledNavBar = styled(Navbar).attrs((props) => ({
  light: !props.dark,
  expand: 'lg',
}))`
  z-index: ${zIndex('contentLayer')};
  background-color: ${color('white')};
  padding: 0;
  margin: 0;

  ${mediaBreakpointUp('md')`
    min-height: 2rem;
  `}

  .container-fluid {
    min-height: ${mobileNavHeight};
    padding: 0;
    margin: 0;

    ${mediaBreakpointUp('lg')`
      margin: 1rem auto;
    `}
  }
`;

export const StyledNavBarToggler = styled(NavbarToggler)`
  ${({ $active, theme }) =>
    $active
      ? css`
          background: ${themeColor('primary')};

          .navbar-light &&,
          .navbar-dark && {
            border-color: ${themeColor('primary')};
            .navbar-toggler-icon {
              background-image: ${hamburgerIcon(theme.nav.togglerIconColor.active)};
            }
          }
        `
      : css`
          .navbar-light &&,
          .navbar-dark && {
            .navbar-toggler-icon {
              background-image: ${hamburgerIcon(theme.nav.togglerIconColor.inactive)};
            }
          }
        `}

  padding: calc(0.625rem - ${getThemeProp('borderWidth')})
    calc(0.5rem - ${getThemeProp('borderWidth')});
  font-size: ${fontSize('base')};
  border-radius: ${borderRadius('base')};

  &:focus {
    box-shadow: none;
  }

  .navbar-toggler-icon {
    display: block;
    width: 1.5rem;
    height: 1.25rem;
  }
`;

export const StyledNavButton = styled(Button)`
  && {
    width: 100%;
    max-width: inherit;
    height: 2.5rem;
    padding-inline-end: 1rem;
    padding-inline-start: 1rem;
    font-weight: ${button('fontWeight')};
    font-size: ${fontSize('md')};

    ${mediaBreakpointDown('md')`
      margin-bottom: 1rem;
    `}

    ${mediaBreakpointUp('lg')`
      width: auto;
      margin-inline-start: 0.75rem;
    `}
  }
`;

export const StyledNav = styled(Nav)`
  position: relative;
  width: 100vw;
  height: calc(100vh - ${mobileNavHeight});
  /* arbitrary padding-bottom to handle Chrome UI hiding the logout link */
  padding: 1rem 1rem 3.5rem;
  overflow-y: auto;
  background: ${themeColor('header')};

  @media (min-height: 622px) {
    overflow-y: ${({ $secondary }) => ($secondary ? 'auto' : 'inherit')};
  }

  ${mediaBreakpointUp('md')`
    padding: 1.5rem 1.5rem 3.5rem;
  `}

  ${mediaBreakpointUp('lg')`
    width: auto;
    height: auto;
    margin: 0 0 0 auto;
    padding: 0;
    background: none;
    align-items: center;
    overflow-y: inherit;
  `}
`;

export const StyledMenu = styled.div<{ $isOpen: boolean; $isSecondaryNavOpen: boolean; $rtl: boolean }>`
  ${mediaBreakpointDown('md')`
      position: fixed;
      top: 4rem;
      width: 100vw;
      ${getRightValue('-100vw')}
      opacity: 0;
      transition: opacity 0.35s;

      ${({ $isOpen }: { $isOpen: boolean }) =>
        $isOpen &&
        css`
          ${getRightValue('0vw')}
          opacity: 1;
        `};

      .StyledMenu-container {
        position: absolute;
        ${getRightValue('-100vw')}
        display: flex;
        width: 200vw;
        transition: right 0.35s, left 0.35s;

        ${({ $isOpen, $isSecondaryNavOpen }: { $isOpen: boolean; $isSecondaryNavOpen: boolean }) =>
          $isOpen &&
          $isSecondaryNavOpen &&
          css`
            ${getRightValue('0vw')}
          `};
        }
  `}
`;

export const StyledNavSeparator = styled.hr`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-top: ${getThemeProp('borderWidth')} solid ${gray(600)};

  ${mediaBreakpointUp('lg')`
    display: none;
  `}
`;

export const StyledPrimaryNavItems = styled.div<{ $customer?: any; $secondary?: boolean; $rtl: boolean }>`
  display: flex;
  flex-direction: column;
  border-bottom: ${({ $customer }) => (!$customer ? '0' : `${getThemeProp('borderStyle')}`)};

  && {
    li > a,
    li > button,
    div > button {
      margin: 0;
      padding: 0;
      font-weight: ${fontWeight('bold')};
      font-size: 0.75rem;
      text-transform: uppercase;
      svg {
        ${flipForRTL}
      }
      &:focus {
        outline: none;
      }
    }
  }

  ${mediaBreakpointDown('md')`
    li {
       padding: 0;
    }
    li:not(:last-child) {
      margin-bottom: 1rem;
    }

    button:not(.btn-primary) {
      display: flex;
      justify-content: space-between;
      width: 100%;
      color: ${color('white')};
      background: none;
      border: 0;

      svg {
        margin: 0;
      }
    }
  `};

  ${mediaBreakpointUp('lg')`
  ${flipFlexForRTL}
    display: flex;
    margin: 0;
    border: 0;

    &&& {
      li > a,
      li > button,
      div > button {
        margin-inline-start: 0.75rem;
        margin-inline-end: 0.75rem;
        font-size: 0.75rem;
        transition: color ${transitions('duration')} ease-in-out;

        svg path, svg circle {
          transition: stroke ${transitions('duration')} ease-in-out;
        }

        &:hover {
          color: ${themeColor('secondary')};

          svg path, svg circle {
            stroke: ${themeColor('secondary')};
          }
        }
      }

      li.show div > button {
        color: ${themeColor('secondary')};

        svg path, svg circle {
          stroke: ${themeColor('secondary')};
        }
      }
    }
  `}
`;

export const StyledMenuGlobalNavItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  li {
    margin-bottom: 1rem;
    padding: 0;
    &:last-child a {
      margin-inline-end: 0;
    }
  }

  li > a,
  li button {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin: 0;
    padding: 0;
    color: ${color('white')};
    font-weight: ${fontWeight('bold')};
    font-size: 0.75rem;
    text-transform: uppercase;
    background: none;
    border: 0;

    &:hover {
      color: ${themeColor('primary')};
      background: none;
    }

    &:focus {
      outline: none;
    }

    &.active {
      background: none;
    }

    svg {
      min-width: 1rem;
      margin-top: 0;
      margin-inline-end: 0.6875rem;
      margin-bottom: 0.25rem;
      margin-inline-start: 0;
    }
  }
`;

export const StyledNavButtonWrapper = styled(Container)`
  ${({ $fixed }) =>
    $fixed &&
    css`
      position: fixed;
      bottom: 0;
      left: 0;
    `};
`;

export const StyledNavCustomer = styled.div`
  display: none;
  ${mediaBreakpointDown('md')`
      display: flex;
      flex-direction: column;
  `}
`;

export const StyledNavCustomerDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledNavCustomerDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledNavCustomerIcon = styled.svg<{ $small?: boolean }>`
  width: ${({ $small }) => ($small ? '1rem' : '2.54rem')};
  height: ${({ $small }) => ($small ? '1rem' : '2.54rem')};
  margin-inline-end: 0.5rem;
`;

export const StyledNavCustomerName = styled.span`
  text-transform: capitalize;
  color: ${themeColor('primary')};
  ${fontStyle('paragraph')}
  ${mediaBreakpointUp('lg')`
    margin-inline-start: 0.5rem;
  `}
`;

export const StyledNavCustomerEmail = styled.span`
  color: ${color('white')};
  ${fontStyle('subtext')};
  font-family: ${fontFamily('serif')};
  ${mediaBreakpointUp('lg')`
    margin-inline-start: 0.5rem;
  `}
`;

export const StyledNavCustomerItems = styled.div`
  padding-top: 1rem;

  li > a,
  li button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: ${color('white')};
    font-weight: ${fontWeight('bold')};
    font-size: 0.75rem;
    text-transform: uppercase;

    svg {
      min-width: 1rem;
      margin-top: 0;
      margin-inline-end: 0.6875rem;
      margin-bottom: 0;
      margin-inline-start: 0;

      path {
        fill: ${color('white')};
      }
    }
  }
`;
