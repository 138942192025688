import React, { useState, useEffect, useRef, ChangeEventHandler, forwardRef } from 'react';
import { ISuggest, ILocation } from '@whitelabel/helpers/types';
import SuggestList from './SuggestList';
import { filterInputAttributes, geocodeSuggest } from './helper';
import { StyledGeoSuggest, StyledInputWrapper } from './styledGeoSuggest';
import GeoSuggestInput from './GeoSuggestInput';

interface IGeoSuggestProps {
  initialValue?: string;
  placeholder?: string;
  disabled?: boolean;
  id?: string;
  className?: string;
  name?: string;
  inputClassName?: string;
  suggestItemActiveClassName?: string;
  location?: google.maps.LatLng;
  radius?: string | number;
  bounds?: google.maps.LatLngBounds | google.maps.LatLngBoundsLiteral;
  country?: string;
  types?: string[] | null;
  highlightMatch?: boolean;
  onSuggestSelect?: (suggest?: ILocation) => void;
  onFocus?: () => void;
  onBlur?: (userInput?: string) => void;
  onChange?: (value: string) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  onKeyPress?: (event: React.KeyboardEvent) => void;
  onUpdateSuggests?: (suggests: ISuggest[], activeSuggest: ISuggest | undefined) => void;
  onActivateSuggest?: (suggest: ISuggest | undefined) => void;
  skipSuggest?: (suggest: google.maps.places.AutocompletePrediction) => boolean;
  getSuggestLabel?: (suggest: google.maps.places.AutocompletePrediction) => string;
  autoActivateFirstSuggest?: boolean;
  ignoreTab?: boolean;
  ignoreEnter?: boolean;
  autoComplete?: string;
  minLength?: number;
  placeDetailFields?: string[] | null;
  inputType?: string;
  switchToManualInput?: () => void;
  disableManualInput?: boolean;
  inputSize?: 'base' | 'large';
  onGoogleAPIFailed?: () => void;
  enableFNOLUserInput?: boolean;
  invalid?: boolean;
  'aria-invalid'?: string;
  'aria-errormessage'?: string;
  displayValue?: string;
  forceHideSuggestions?: boolean;
}

const GeoSuggest = forwardRef(
  (
    {
      className,
      id,
      onChange,
      onBlur,
      onSuggestSelect,
      onUpdateSuggests,
      skipSuggest,
      getSuggestLabel = (suggest) => suggest.description,
      placeDetailFields,
      initialValue = '',
      highlightMatch = true,
      minLength = 1,
      location,
      radius,
      bounds,
      types,
      country,
      ignoreTab,
      ignoreEnter,
      onFocus,
      onKeyDown,
      onKeyPress,
      inputType,
      onActivateSuggest,
      placeholder,
      autoComplete,
      switchToManualInput,
      disableManualInput = false,
      inputSize = 'base',
      onGoogleAPIFailed,
      enableFNOLUserInput = false,
      invalid,
      displayValue,
      forceHideSuggestions = false,
      ...props
    }: IGeoSuggestProps,
    ref: React.ForwardedRef<null | HTMLInputElement>,
  ): JSX.Element => {
    const googleMaps = useRef<typeof google.maps>();
    const autocompleteService = useRef<google.maps.places.AutocompleteService>();
    const placesService = useRef<google.maps.places.PlacesService>();
    const sessionToken = useRef<google.maps.places.AutocompleteSessionToken>();
    const geocoder = useRef<google.maps.Geocoder>();
    const [userInput, setUserInput] = useState(initialValue || '');

    const [activeSuggest, setActiveSuggest] = useState<ISuggest>();
    const [ignoreBlur, setIgnoreBlur] = useState(false);
    const [isSuggestsHidden, setIsSuggestsHidden] = useState(true);
    const [suggests, setSuggests] = useState<ISuggest[]>([]);
    const [manualIsActive, setManualIsActive] = useState(false);
    const listId = `geosuggest__list${id ? `--${id}` : ''}`;

    useEffect(() => {
      if (typeof window === 'undefined') {
        return;
      }
      const googleMapsInstance = (window as any).google?.maps;
      if (!googleMapsInstance) {
        console.error('Google maps API was not found in the page.');
        if (onGoogleAPIFailed) {
          onGoogleAPIFailed();
        }
        return;
      }
      googleMaps.current = googleMapsInstance;
      autocompleteService.current = new googleMapsInstance.places.AutocompleteService();
      placesService.current = new googleMapsInstance.places.PlacesService(document.createElement('div'));
      sessionToken.current = new googleMapsInstance.places.AutocompleteSessionToken();
      geocoder.current = new googleMapsInstance.Geocoder();
    }, []);

    /**
     * Return the new activeSuggest object after suggests have been updated
     */
    const updateActiveSuggest = (suggestsValue: ISuggest[] = []): ISuggest | undefined => {
      let newSuggest;

      if (activeSuggest) {
        [newSuggest] = suggestsValue.filter(
          (listedSuggest) => activeSuggest && activeSuggest.placeId === listedSuggest.placeId,
        );
      }

      return newSuggest;
    };

    const updateSuggests = (suggestsGoogle: google.maps.places.AutocompletePrediction[] = []) => {
      const suggestsValue: ISuggest[] = [];

      suggestsGoogle.forEach((suggest) => {
        if (skipSuggest && !skipSuggest(suggest)) {
          suggestsValue.push({
            description: suggest.description,
            label: getSuggestLabel ? getSuggestLabel(suggest) : '',
            matchedSubstrings: suggest.matched_substrings[0],
            placeId: suggest.place_id,
            mainText: suggest.structured_formatting.main_text,
          });
        }
      });

      const activeSuggestValue = updateActiveSuggest(suggestsValue);

      if (onUpdateSuggests) {
        onUpdateSuggests(suggestsValue, activeSuggestValue);
      }
      setSuggests(suggestsValue);
      setActiveSuggest(activeSuggestValue);
    };

    const selectSuggest = (suggestToSelect: ISuggest | undefined): void => {
      const suggest: ISuggest = suggestToSelect || { label: userInput, placeId: userInput };

      setIsSuggestsHidden(true);

      if (suggest.location) {
        setIgnoreBlur(false);
        if (onSuggestSelect) {
          onSuggestSelect(suggest as ILocation);
        }
        return;
      }

      geocodeSuggest({
        suggestToGeocode: suggest,
        geocoder,
        placesService,
        sessionToken,
        googleMaps,
        placeDetailFields,
        bounds,
        country,
        location,
        enableFNOLUserInput,
        setUserInput,
        onSuggestSelect,
      });
      updateSuggests();
    };

    const searchSuggests = () => {
      if (!userInput) {
        updateSuggests();
        return;
      }
      const options: google.maps.places.AutocompletionRequest = {
        input: userInput,
        sessionToken: sessionToken.current,
      };

      const isShorterThanMinLength = minLength && userInput.length < minLength;
      if (isShorterThanMinLength) {
        updateSuggests();
        return;
      }
      if (location) options.location = location;
      if (radius) options.radius = Number(radius);
      if (bounds) options.bounds = bounds;
      if (types) options.types = types;
      if (country) options.componentRestrictions = { country };

      if (autocompleteService.current) {
        autocompleteService.current.getPlacePredictions(options, (suggestsGoogle) => {
          updateSuggests(suggestsGoogle || []);
        });
      }
    };

    const showSuggests = () => {
      searchSuggests();
      setIsSuggestsHidden(false);
    };

    const onInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
      const { value } = event.target;
      setUserInput(value);
      showSuggests();
      onChange?.(value);

      if (!value) {
        if (onSuggestSelect) {
          onSuggestSelect();
        }
      }
    };

    /**
     * Activate a new suggest, used for keyboard navigation
     */
    const activateSuggest = (direction: 'next' | 'prev') => {
      if (isSuggestsHidden) {
        showSuggests();
        return;
      }

      const suggestsCount = suggests.length - 1;
      const next = direction === 'next';
      let newActiveSuggest;
      let newIndex = 0;

      for (let i = 0; i <= suggestsCount; i++) {
        if (suggests[i] === activeSuggest) {
          newIndex = next ? i + 1 : i - 1;
        }
      }

      if (!activeSuggest) {
        newIndex = next ? 0 : suggestsCount;
      }

      if (newIndex >= 0 && newIndex <= suggestsCount) {
        newActiveSuggest = suggests[newIndex];
        setManualIsActive(false);
      }

      if ((newIndex > suggestsCount || newIndex < 0) && !disableManualInput) {
        setManualIsActive(true);
      }

      if (onActivateSuggest) {
        onActivateSuggest(newActiveSuggest);
      }

      setActiveSuggest(newActiveSuggest);
    };

    const hideSuggests = () => {
      if (onBlur) {
        onBlur(userInput);
      }
      setActiveSuggest(undefined);
      setIsSuggestsHidden(true);
    };

    const onInputBlur = () => {
      if (!ignoreBlur) {
        hideSuggests();
      }
    };

    const onSuggestMouseDown = () => {
      setIgnoreBlur(true);
    };

    const onSuggestMouseOut = () => {
      setIgnoreBlur(false);
    };

    const onInputFocus = () => {
      if (onFocus) {
        onFocus();
      }
      showSuggests();
    };

    const onNext = () => {
      activateSuggest('next');
    };

    const onPrev = () => {
      activateSuggest('prev');
    };

    const onSelect = () => {
      if (manualIsActive) {
        switchToManualInput?.();
        return;
      }
      selectSuggest(activeSuggest);
    };

    const attributes = filterInputAttributes(props);
    return (
      <StyledGeoSuggest className={className} id={id} $size={inputSize}>
        <StyledInputWrapper>
          <GeoSuggestInput
            {...attributes}
            ref={ref}
            id={id}
            value={userInput}
            onChange={onInputChange}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
            onKeyDown={onKeyDown}
            onKeyPress={onKeyPress}
            listId={listId}
            inputType={inputType}
            onNext={onNext}
            onPrev={onPrev}
            onSelect={onSelect}
            onEscape={hideSuggests}
            isSuggestsHidden={isSuggestsHidden}
            activeSuggest={activeSuggest}
            doNotSubmitOnEnter={!isSuggestsHidden}
            ignoreEnter={ignoreEnter}
            ignoreTab={ignoreTab}
            placeholder={placeholder}
            autoComplete={autoComplete}
            invalid={invalid}
            displayValue={displayValue}
          />
        </StyledInputWrapper>
        <SuggestList
          isHidden={isSuggestsHidden || forceHideSuggestions}
          userInput={userInput}
          isHighlightMatch={Boolean(highlightMatch)}
          suggests={suggests}
          activeSuggest={activeSuggest}
          onSuggestMouseDown={onSuggestMouseDown}
          onSuggestMouseOut={onSuggestMouseOut}
          onSuggestSelect={selectSuggest}
          listId={listId}
          switchToManualInput={switchToManualInput}
          disableManualInput={disableManualInput}
          manualIsActive={manualIsActive}
        />
      </StyledGeoSuggest>
    );
  },
);

GeoSuggest.displayName = 'GeoSuggest';

export default GeoSuggest;
