import { Auth } from '@aws-amplify/auth';
import { captureExceptionWithFullStory } from '@whitelabel/helpers/utils';
import { getLocalStorage } from '@whitelabel/helpers/storageUtils';
import { AP_SOUTH_1, EU_CENTRAL_1, CUSTOMER_REGION, US_EAST_1 } from './constants';
import { IError } from './types';
import { API_HOST } from './api';

// This is from: https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
export async function hash(text: string) {
  const msgUint8 = new TextEncoder().encode(text); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  return hashHex;
}

export async function fetchAndStoreCustomerRegion(emailOrPhone: string) {
  try {
    const hashedEmailOrPhone = await hash(emailOrPhone.trim().toLocaleLowerCase());
    const response = await fetch(`${API_HOST}/v1/customers/region/${hashedEmailOrPhone}/`);
    const data = (await response.json()) as { region?: string };

    if (response.ok) {
      localStorage.setItem(CUSTOMER_REGION, data.region!);
    } else {
      const apiError = data as IError;
      const requestId = response.headers.get('x-request-id');
      const error: IError = new Error(apiError.message);
      error.requestId = requestId;
      error.status = response.status;
      error.code = apiError.errors?.code;
      throw error;
    }
  } catch (e) {
    if ((e as IError).status !== 404) {
      captureExceptionWithFullStory(e as IError, {
        emailOrPhone,
        error: JSON.stringify(e),
        url: `/api/v1/customers/region/`,
      });
    }
    throw e;
  }
}

export function getStoredCustomerRegion() {
  return getLocalStorage(CUSTOMER_REGION);
}

export function configureCognito() {
  const region = getStoredCustomerRegion();
  if (region) {
    let cognitoRegion = process.env.REACT_APP_COGNITO_REGION || process.env.NEXT_PUBLIC_COGNITO_REGION;
    let cognitoUserPoolID = process.env.REACT_APP_COGNITO_USER_POOL_ID || process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID;
    let cognitoAppClientID =
      process.env.REACT_APP_COGNITO_APP_CLIENT_ID || process.env.NEXT_PUBLIC_COGNITO_APP_CLIENT_ID;

    if (region === AP_SOUTH_1) {
      cognitoRegion =
        process.env.REACT_APP_COGNITO_AP_SOUTH_1_REGION || process.env.NEXT_PUBLIC_COGNITO_AP_SOUTH_1_REGION;
      cognitoUserPoolID =
        process.env.REACT_APP_COGNITO_AP_SOUTH_1_USER_POOL_ID ||
        process.env.NEXT_PUBLIC_COGNITO_AP_SOUTH_1_USER_POOL_ID;
      cognitoAppClientID =
        process.env.REACT_APP_COGNITO_AP_SOUTH_1_APP_CLIENT_ID ||
        process.env.NEXT_PUBLIC_COGNITO_AP_SOUTH_1_APP_CLIENT_ID;
    } else if (region === US_EAST_1) {
      cognitoRegion =
        process.env.REACT_APP_COGNITO_US_EAST_1_REGION || process.env.NEXT_PUBLIC_COGNITO_US_EAST_1_REGION;
      cognitoUserPoolID =
        process.env.REACT_APP_COGNITO_US_EAST_1_USER_POOL_ID || process.env.NEXT_PUBLIC_COGNITO_US_EAST_1_USER_POOL_ID;
      cognitoAppClientID =
        process.env.REACT_APP_COGNITO_US_EAST_1_APP_CLIENT_ID ||
        process.env.NEXT_PUBLIC_COGNITO_US_EAST_1_APP_CLIENT_ID;
    }
    Auth.configure({
      mandatorySignIn: true,
      region: cognitoRegion,
      userPoolId: cognitoUserPoolID,
      userPoolWebClientId: cognitoAppClientID,
    });
  }
}

function getSelectedAPIHost(euCentral1APIHost: string, usEast1APIHost: string, apSouth1APIHost: string, ins?: string) {
  if (ins) {
    if (ins.startsWith('I-')) {
      return apSouth1APIHost;
    }
    if (ins.startsWith('U-')) {
      return usEast1APIHost;
    }
    return euCentral1APIHost;
  }

  const region = getStoredCustomerRegion();
  if (region === AP_SOUTH_1) {
    return apSouth1APIHost;
  }
  if (region === US_EAST_1) {
    return usEast1APIHost;
  }
  return euCentral1APIHost;
}

export function getAPIHost(ins?: string, version: 'v1' | 'v2' = 'v1') {
  const addVersion = (url: string) => `${url}/${version}`;

  const apSouth1APIHost = addVersion(
    (process.env.REACT_APP_AP_SOUTH_1_API_HOST || process.env.NEXT_PUBLIC_AP_SOUTH_1_API_HOST)!,
  );
  const usEast1APIHost = addVersion(
    (process.env.REACT_APP_US_EAST_1_API_HOST || process.env.NEXT_PUBLIC_US_EAST_1_API_HOST)!,
  );
  const euCentral1APIHost = addVersion(API_HOST || '/api');

  return getSelectedAPIHost(euCentral1APIHost, usEast1APIHost, apSouth1APIHost, ins);
}

export function getNestAPIHost(ins?: string, version: 'v1' | 'v2' = 'v1') {
  const addVersion = (url: string) => `${url}/${version}`;

  const apSouth1APIHost = addVersion(
    (process.env.REACT_APP_NEST_AP_SOUTH_1_API_HOST || process.env.NEXT_PUBLIC_NEST_AP_SOUTH_1_API_HOST)!,
  );
  const usEast1APIHost = addVersion(
    (process.env.REACT_APP_NEST_US_EAST_1_API_HOST || process.env.NEXT_PUBLIC_NEST_US_EAST_1_API_HOST)!,
  );
  const euCentral1APIHost = addVersion(
    (process.env.REACT_APP_NEST_API_HOST || process.env.NEXT_PUBLIC_NEST_API_HOST)!,
  );

  return getSelectedAPIHost(euCentral1APIHost, usEast1APIHost, apSouth1APIHost, ins);
}

export function storeCustomerRegionFromURL(search?: string) {
  const urlParams = new URLSearchParams(search || window.location.search);
  const region = urlParams.get('region');
  /**
   * If region is available in the URL, force update the region.
   * Else check if customerRegion already exist in LS.
   ** If there's an existing LS, honor the existing customerRegion and no changes.
   ** Else default the customerRegion to EU_CENTRAL_1.
   */
  localStorage.setItem(CUSTOMER_REGION, region || localStorage.getItem(CUSTOMER_REGION) || EU_CENTRAL_1);
}
