import { tint } from 'polished';
import { themeColors } from './colors';

const { success, danger, info, warning } = themeColors;

const alert = {
  backgroundColor: {
    success: tint(0.95, success),
    danger: tint(0.95, danger),
    info: tint(0.95, info),
    warning: tint(0.95, warning),
  },
  borderColor: {
    success: tint(0.1, success),
    danger: tint(0.1, danger),
    info: tint(0.1, info),
    warning: tint(0.1, warning),
  },
};

export default alert;
