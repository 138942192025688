import * as FullStory from '@fullstory/browser';
import { IMPERSONATE } from '@whitelabel/xcover-shared/helpers/constants';
import { handleFormikAWSFormError } from '@whitelabel/xcover-shared/helpers/api';
import {
  impersonateLoginFailure,
  impersonateLoginFulfill,
  impersonateLoginRequest,
  impersonateLoginSuccess,
} from '../slices/createActions';
import type { AppDispatch } from '..';

const tagFullStory = (username: string) => {
  const adminUsername = sessionStorage.getItem(IMPERSONATE.ADMIN_USERNAME);
  const customerEmail = sessionStorage.getItem(IMPERSONATE.CUSTOMER_EMAIL);
  FullStory.identify(username, {
    displayName: `Admin: ${adminUsername} as ${customerEmail}`,
  });
};

export const impersonateLogin =
  ({ username, email }: { username: string; email: string }) =>
  (dispatch: AppDispatch) => {
    try {
      dispatch(impersonateLoginRequest());
      const user: any = {
        username,
        email,
      };
      sessionStorage.setItem(IMPERSONATE.CUSTOMER_EMAIL, email);
      if (!window.isHeadless && FullStory.isInitialized()) {
        tagFullStory(username);
      }
      dispatch(impersonateLoginSuccess(user));
    } catch (error) {
      dispatch(impersonateLoginFailure(handleFormikAWSFormError(error)));
    } finally {
      dispatch(impersonateLoginFulfill());
    }
  };
