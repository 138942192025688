import { css } from 'styled-components';
import { gray } from '@whitelabel/helpers/style/colors';
import { modal } from '@whitelabel/helpers/style/get';

const globalModalStyle = css`
  .modal-backdrop {
    background-color: ${modal('backdrop', 'bg')};

    &.show {
      opacity: ${modal('backdrop', 'opacity')};
    }
  }
  .modal-footer,
  .modal-header {
    border-color: ${gray(400)};
  }
  body.modal-open {
    padding-right: 0px !important;
  }
  .modal-footer > * {
    margin: 0;
  }
`;

export default globalModalStyle;
