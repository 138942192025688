import React from 'react';
import { useIntl } from 'react-intl';
import TrustpilotStars from '@whitelabel/media/images/trustpilot-4-and-half-stars.svg';
import TrustpilotLogo from '@whitelabel/media/images/trustpilot-logo.svg';
import messages from './messages';
import { StyledTrustpilotBlock } from './styledTrustPilot';

export interface ITrustPilotProps {
  trustpilotCounter: string;
  className?: string;
}

const TrustPilot = ({ trustpilotCounter, className }: ITrustPilotProps): JSX.Element => {
  const { formatMessage, locale } = useIntl();
  const totalReviews = `${new Intl.NumberFormat(locale).format(+trustpilotCounter.replace(/[,.]/g, ''))}+`;

  return (
    <StyledTrustpilotBlock className={className}>
      <div className="logo">
        <img src={TrustpilotLogo} alt={formatMessage(messages.logoAltText)} />
      </div>
      <div className="stars">
        <img src={TrustpilotStars} alt={formatMessage(messages.starsAltText)} />
      </div>
      <span>
        {formatMessage(messages.reviews, { totalReviews })} <span className="separator">•</span>{' '}
        <span className="excellent">{formatMessage(messages.excellent)}</span>
      </span>
    </StyledTrustpilotBlock>
  );
};

export default TrustPilot;
