import * as FullStory from '@fullstory/browser';
import { getWindowSessionReplayURL } from './utils';

export const track = (argument: any) => {
  if (typeof window.dataLayer !== 'object') {
    return;
  }
  if (typeof argument === 'string') {
    window.dataLayer.push({
      event: argument,
    });
  } else {
    window.dataLayer.push({
      ...argument,
    });
  }
};

export const bwClickTracking = (event: any, customVariables = {}) => {
  if (window.bwtag) {
    const { dataset } = event.target;
    window.bwtag('record', 'click', { analyticsID: dataset?.analytics, ...customVariables }, event);
  }
};

export const bwNPSScoreTracking = ({
  score,
  source,
  bookingID,
  feedback,
  partnerID,
  partnerName,
  subsidiaryID,
  subsidiaryName,
  policyTypeGroupName,
  claimReference,
}: {
  score: string;
  source: string;
  bookingID?: string;
  feedback?: string;
  partnerID?: string;
  partnerName?: string;
  subsidiaryID?: string;
  subsidiaryName?: string;
  policyTypeGroupName?: string;
  claimReference?: string;
}) => {
  if (window.isHeadless || !window.bwtag) return;

  window.bwtag('record', 'nps_widget:score', {
    score,
    feedback: feedback ?? null,
    source,
    bookingID: bookingID ?? null,
    ins: bookingID,
    fsSession: !window.isHeadless && FullStory.isInitialized() ? FullStory.getCurrentSessionURL(true) : 'NA',
    bwSession: getWindowSessionReplayURL() || 'NA',
    partnerID,
    partnerName,
    subsidiaryID,
    subsidiaryName,
    policyTypeGroupName,
    claimReference,
    claim_cla: claimReference,
  });
};

interface IBWContactTrackingArgs {
  source: string;
  contactReason?: { id: number; value: string }[];
  bookingID?: string;
  referer?: string | null;
  claimReference?: string | null;
  request?: string;
  partnerID?: string;
  partnerName?: string;
  subsidiaryID?: string;
  subsidiaryName?: string;
}

export const bwContactTracking = ({
  source,
  contactReason,
  bookingID,
  referer,
  claimReference,
  partnerID,
  partnerName,
  subsidiaryID,
  subsidiaryName,
  request,
}: IBWContactTrackingArgs) => {
  if (window.isHeadless || !window.bwtag) return;

  window.bwtag('record', 'contact-us', {
    source,
    contactReason: contactReason ?? null,
    bookingReference: bookingID ?? null,
    ins: bookingID ?? null,
    referer: referer ?? null,
    claimReference: claimReference ?? null,
    claim_cla: claimReference ?? null,
    request: request ?? null,
    partnerID,
    partnerName,
    subsidiaryID,
    subsidiaryName,
  });
};

export async function getBWVariant(experimentAPIName: string): Promise<{ apiName: string } | undefined> {
  return new Promise((resolve) => {
    window.bwtag('retrieveExperiment', experimentAPIName, (experiment: { getVariant: () => void }) => {
      const variant: any = experiment?.getVariant();
      return resolve(variant);
    });
  });
}
