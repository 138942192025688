import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { mediaBreakpointDown, mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { StyledModal } from '../../styled/StyledModal';

export const StyledModalWithChargeWidgetSupport = styled(StyledModal)`
  .modal-body {
    ${({ $chargeWidgetReady }) => css`
      ${$chargeWidgetReady && `margin-bottom:0`}
    `}
  }
  #card {
    padding-bottom: 1rem;
    ${mediaBreakpointUp('md')`
     padding-bottom: 2rem;
    `}
  }

  > .modal-content {
    ${mediaBreakpointDown('sm')`
     padding-top: ${rem(40)};
    `}
  }

  .btn-close {
    ${mediaBreakpointDown('sm')`
      padding: ${rem(28)};
    `}
  }
`;
