import styled from 'styled-components';
import { rem } from 'polished';
import { fontFamily } from '@whitelabel/helpers/style/type';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';

export const StyledTrustpilotBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  padding: ${rem(3)} ${rem(6)};

  .logo {
    width: ${rem(76)};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .stars {
    width: ${rem(96)};
    margin: 0 0 0 ${rem(24)};
    display: flex;
    align-items: center;
    justify-content: center;

    ${mediaBreakpointUp('sm')`
      margin: 0 ${rem(12)};
    `}

    ${mediaBreakpointUp('lg')`
      margin: 0 ${rem(12)};
    `}
  }

  span {
    flex-basis: 100%;
    margin-top: 0.5rem;
    font-size: ${rem(14)};
    font-family: ${fontFamily('base')};
    line-height: ${rem(18)};
    text-align: center;
    ${mediaBreakpointUp('sm')`
    flex-basis: content;
    margin-top: 0;
  `}
  }

  ${mediaBreakpointUp('sm')`
    justify-content: start;
  `}

  ${mediaBreakpointUp('md')`
    margin-top: 1.5rem;
  `}

  ${mediaBreakpointUp('lg')`
    margin-top: 2rem;
  `}
`;
