import { get as ldGet, LodashGet11x1 } from 'lodash/fp';
import { Theme } from 'styled-components';
import { IThemeAsProp, IThemePropertyGetter, ButtonState, ButtonProperty, ButtonType } from '../types';

export const getThemeProp = (key: keyof Theme): LodashGet11x1 => ldGet(`theme.${key}`);

export const get = (key: any) => ldGet(`theme.${key}`);

export function modal<P1 extends keyof Theme['modal']>(lvl1: P1): ({ theme }: IThemeAsProp) => Theme['modal'][P1];
export function modal<P1 extends keyof Theme['modal'], P2 extends keyof Theme['modal'][P1]>(
  lvl1: P1,
  lvl2: P2,
): ({ theme }: IThemeAsProp) => Theme['modal'][P1][P2];
export function modal<
  P1 extends keyof Theme['modal'],
  P2 extends keyof Theme['modal'][P1],
  P3 extends keyof Theme['modal'][P1][P2],
>(lvl1: P1, lvl2: P2, lvl3: P3): ({ theme }: IThemeAsProp) => Theme['modal'][P1][P2][P3];

export function modal<
  P1 extends keyof Theme['modal'],
  P2 extends keyof Theme['modal'][P1],
  P3 extends keyof Theme['modal'][P1][P2],
>(lvl1: P1, lvl2?: P2, lvl3?: P3) {
  return ({ theme }: IThemeAsProp): any => {
    if (lvl3) return theme.modal[lvl1][lvl2 as P2][lvl3];
    if (lvl2) return theme.modal[lvl1][lvl2];
    return theme.modal[lvl1];
  };
}

export function popover<P1 extends keyof Theme['popover']>(
  lvl1: P1,
): ({ theme }: IThemeAsProp) => Theme['popover'][P1];
export function popover<P1 extends keyof Theme['popover'], P2 extends keyof Theme['popover'][P1]>(
  lvl1: P1,
  lvl2: P2,
): ({ theme }: IThemeAsProp) => Theme['popover'][P1][P2];

export function popover<P1 extends keyof Theme['popover'], P2 extends keyof Theme['popover'][P1]>(
  lvl1: P1,
  lvl2?: P2,
): ({ theme }: IThemeAsProp) => any {
  return ({ theme }: IThemeAsProp) => (lvl2 ? theme.popover[lvl1][lvl2] : theme.popover[lvl1]);
}

export function button<P1 extends keyof Theme['button']>(lvl1: P1): ({ theme }: IThemeAsProp) => Theme['button'][P1];
export function button<P1 extends keyof Theme['button'], P2 extends keyof Theme['button'][P1]>(
  lvl1: P1,
  lvl2: P2,
): ({ theme }: IThemeAsProp) => Theme['button'][P1][P2];

export function button<P1 extends keyof Theme['button'], P2 extends keyof Theme['button'][P1]>(lvl1: P1, lvl2?: P2) {
  return ({ theme }: IThemeAsProp): any => (lvl2 ? theme.button[lvl1][lvl2] : theme.button[lvl1]);
}

export function card<P1 extends keyof Theme['card']>(lvl1: P1): ({ theme }: IThemeAsProp) => Theme['card'][P1];
export function card<P1 extends keyof Theme['card'], P2 extends keyof Theme['card'][P1]>(
  lvl1: P1,
  lvl2: P2,
): ({ theme }: IThemeAsProp) => Theme['card'][P1][P2];

export function card<P1 extends keyof Theme['card'], P2 extends keyof Theme['card'][P1]>(lvl1: P1, lvl2?: P2) {
  return ({ theme }: IThemeAsProp): any => (lvl2 ? theme.card[lvl1][lvl2] : theme.card[lvl1]);
}

export function nav<P1 extends keyof Theme['nav']>(lvl1: P1): ({ theme }: IThemeAsProp) => Theme['nav'][P1];
export function nav<P1 extends keyof Theme['nav'], P2 extends keyof Theme['nav'][P1]>(
  lvl1: P1,
  lvl2: P2,
): ({ theme }: IThemeAsProp) => Theme['nav'][P1][P2];

export function nav<P1 extends keyof Theme['nav'], P2 extends keyof Theme['nav'][P1]>(lvl1: P1, lvl2?: P2) {
  return ({ theme }: IThemeAsProp): any => (lvl2 ? theme.nav[lvl1][lvl2] : theme.nav[lvl1]);
}

export const dropdown: IThemePropertyGetter<keyof Theme['dropdown']> =
  (lvl1) =>
  ({ theme }) =>
    theme.dropdown[lvl1];
export const tooltip: IThemePropertyGetter<keyof Theme['tooltip']> =
  (lvl1) =>
  ({ theme }) =>
    theme.tooltip[lvl1];
export const table: IThemePropertyGetter<keyof Theme['table']> =
  (lvl1) =>
  ({ theme }) =>
    theme.table[lvl1];
export const transitions: IThemePropertyGetter<keyof Theme['transitions']> =
  (lvl1) =>
  ({ theme }) =>
    theme.transitions[lvl1];
export const fontStyleProperty = (key: any) => get(`fontStyles.${key}`);

/**
 * @param buttonType - eg: primary, secondary etc
 * @param buttonProperty - eg: bg, label, outline, icon etc
 * @param buttonState  - eg: hover, active, etc
 */
export function buttonColor(
  buttonType: ButtonType,
  buttonProperty: ButtonProperty,
  buttonState: ButtonState = 'default',
) {
  return ({ theme }: any): any => theme.buttonColors[buttonType][buttonProperty][buttonState];
}

export function headingBlockColor(element: string) {
  return ({ theme }: any): any => theme.headingBlockColors[element];
}

export function toggleColor(
  toggleProperty: keyof Theme['toggleColors'],
  toggleState: keyof Theme['toggleColors']['bg'],
) {
  return ({ theme }: IThemeAsProp): string => theme.toggleColors[toggleProperty][toggleState];
}
export const contentColor =
  (key: keyof Theme['contentColors']) =>
  ({ theme }: IThemeAsProp): string =>
    theme.contentColors[key];
