import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';
import { camelCase } from 'lodash/fp';
import { Row, Container, Col, CardBody } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import { useIntl } from 'react-intl';
import { GridColumns, GridColumnsOffset, HeadingTag, IFeatureBlock } from '@whitelabel/helpers/types';
import { transformLink } from '../../../helpers/transform';
import LinkWrapper from '../../LinkWrapper';
import calculateColumnWidth from './helper';
import {
  StyledFeatureColumnsBlock,
  StyledCol,
  StyledImageBlock,
  StyledHeading,
  StyledLeadText,
  StyledBody,
  StyledButtonBlock,
  StyledCard,
} from './styledFeatureColumnsBlock';

export interface IFeatureColumnsBlockProps {
  headingTag: HeadingTag;
  features: IFeatureBlock[];
  gridColumns: GridColumns;
  gridColumnsOffset: GridColumnsOffset;
  className?: string;
  fullWidth?: boolean;
  isCard: boolean;
  isCardClickable: boolean;
}

const FeatureColumnsBlock = ({
  headingTag,
  features,
  gridColumns,
  gridColumnsOffset,
  className,
  fullWidth = false,
  isCard,
  isCardClickable,
}: IFeatureColumnsBlockProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = !useMediaQuery({ minWidth: theme.gridBreakpoints.sm });
  const renderChildren = () => (
    <>
      {features.map(({ image, mobileImage, title, leadText, body, button }: any, index: number) => {
        const hasLeadText = leadText && leadText !== '<p></p>';
        const hasBody = body && body !== '<p></p>';
        const hasButton = button?.length > 0;

        const renderContent = () => (
          <>
            {title && (
              <StyledHeading className="h4" as={headingTag}>
                {title}
              </StyledHeading>
            )}

            {hasLeadText && (
              <StyledLeadText data-test-id="FeatureColumnsBlock-leadText">
                {ReactHtmlParser(leadText, { transform: transformLink(intl) })}
              </StyledLeadText>
            )}

            {hasBody && (
              <StyledBody data-test-id="FeatureColumnsBlock-body">
                {ReactHtmlParser(body, { transform: transformLink(intl) })}
              </StyledBody>
            )}
          </>
        );

        const renderImage = (withMargin: any) =>
          mobileImage && isMobile ? (
            <StyledImageBlock image={mobileImage} altText={title} withMargin={withMargin} />
          ) : (
            <StyledImageBlock image={image} altText={title} withMargin={withMargin} />
          );

        const renderCardContent = () => (
          <StyledCard>
            {renderImage(false)}
            <CardBody>
              {renderContent()}
              {hasButton && !isCardClickable && <StyledButtonBlock {...button[0].value} />}
            </CardBody>
          </StyledCard>
        );

        const renderCard = () =>
          isCardClickable ? (
            <LinkWrapper link={button[0].value.link}>{renderCardContent()}</LinkWrapper>
          ) : (
            <>{renderCardContent()}</>
          );

        return (
          <StyledCol
            sm={{ size: 12 / +gridColumns }}
            xl={{ size: calculateColumnWidth(+gridColumns, +gridColumnsOffset), offset: +gridColumnsOffset }}
            key={camelCase(title || image?.title) || index}
            isCard={isCard}
          >
            {isCard ? (
              renderCard()
            ) : (
              <>
                <Row>
                  <Col xs={+gridColumns > 1 ? { size: 8, offset: 2 } : 12} sm={{ size: 12, offset: 0 }}>
                    {renderImage(!!(title || hasLeadText || hasBody))}
                  </Col>
                </Row>
                {renderContent()}
                {hasButton && <StyledButtonBlock {...button[0].value} />}
              </>
            )}
          </StyledCol>
        );
      })}
    </>
  );
  return (
    <StyledFeatureColumnsBlock
      as={fullWidth ? Container : Row}
      className={className}
      data-test-id="FeatureColumnsBlock"
    >
      {fullWidth ? <Row>{renderChildren()}</Row> : renderChildren()}
    </StyledFeatureColumnsBlock>
  );
};

export default FeatureColumnsBlock;
