import styled from 'styled-components';
import { Col } from 'reactstrap';
import { fontStyle } from '@whitelabel/helpers/style/type';
import { gray } from '@whitelabel/helpers/style/colors';
import { mediaBreakpointUp, mediaBreakpointOnly, marginBottom } from '@whitelabel/helpers/style/grid';
import { spacer, multiply } from '@whitelabel/helpers/style/units';
import HeadingBlock from '../HeadingBlock';
import ImageBlock from '../ImageBlock';
import ButtonBlock from '../ButtonBlock';

export const StyledUspItem = styled.div`
  display: flex;

  :not(:last-child) {
    margin-bottom: ${multiply(spacer, 2)};
  }
`;

export const StyledUspIcon = styled.img`
  display: flex;
  align-self: flex-start;
  width: ${multiply(spacer, 3)};
  height: ${multiply(spacer, 3)};
  margin-inline-end: ${multiply(spacer)};
`;

export const StyledUspHeading = styled.h4`
  margin-bottom: ${multiply(spacer, 0.5)};
  text-transform: uppercase;
`;

export const StyledUspContent = styled.p`
  ${fontStyle('paragraph')}
  margin: 0;
  color: ${gray(600)};
`;

export const StyledButtonBlock = styled(ButtonBlock)`
  ${mediaBreakpointUp('sm')`
    width: ${multiply(spacer, 21.44)};
  `}
`;

export const StyledImageBlock = styled(ImageBlock)`
  ${mediaBreakpointOnly('sm')`
      width: 70%;
      display: flex;
      margin: 0 auto;
    `}
`;

export const StyledHeading = styled(HeadingBlock)`
  ${marginBottom}
`;

export const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
