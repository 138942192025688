import styled, { css } from 'styled-components';
import { fontSize, lineHeight } from '@whitelabel/helpers/style/type';
import { borderRadius, getMarginLeft } from '@whitelabel/helpers/style/box';
import { spacer, multiply } from '@whitelabel/helpers/style/units';
import { get } from '@whitelabel/helpers/style/get';
import ChevronDown from '@whitelabel/media/icons/small/chevron-down.svg';
import { inputColors } from '@whitelabel/global-theme/colors';
import { focusLinkStyle } from '@whitelabel/helpers/style/utils';

export const StyledPhoneInputContainer = styled.div<{ $rtl: boolean; $error: boolean; $disabled?: boolean }>`
  .PhoneInput {
    border: ${get('borderWidth')} solid ${inputColors.border.default};
    border-radius: ${borderRadius('base')};
    cursor: pointer;

    ${({ $error }) =>
      $error &&
      css`
        border-color: ${inputColors.border.error};
      `}

    &:hover {
      border-color: ${inputColors.border.hover};
    }

    ${({ $disabled }) =>
      $disabled &&
      css`
        && {
          background-color: ${inputColors.background.disabled};
          border: 1px solid ${inputColors.border.disabled};
          color: ${inputColors.value.disabled};
        }
      `}

    .PhoneInputCountry {
      margin-inline-end: 0;
      padding: ${spacer} ${multiply(spacer, 0.6875)} ${spacer} ${spacer};
      border-inline-end: ${get('borderWidth')} solid ${inputColors.border.default};

      select:disabled {
        opacity: 0;
      }

      &:focus {
        border: 1px solid ${inputColors.border.focus};
      }

      ${({ $disabled }) =>
        $disabled &&
        css`
          && {
            border-inline-end: 1px solid ${inputColors.border.disabled};
          }
        `}
    }
  }

  .PhoneInput--focus {
    border-color: ${inputColors.border.focus};
  }

  .PhoneInputInput {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem 0.5rem 1rem;
    font-size: ${multiply(fontSize('base'), 1.25)};
    line-height: ${lineHeight('base')};
    text-align: ${({ $rtl }) => ($rtl ? 'end' : 'start')};
    border: 0;
    color: ${inputColors.value.default};

    &:focus {
      outline: 0;
      box-shadow: none;
      color: ${inputColors.value.focus};
    }
  }

  .PhoneInputCountryIconImg {
    vertical-align: top;
  }

  .PhoneInputCountrySelectArrow {
    ${getMarginLeft('5.6px')}
    width: ${spacer};
    height: ${spacer};
    background-image: url(${ChevronDown});
    border: 0;
    transform: none;
    opacity: 1;
  }

  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
    ${focusLinkStyle}
  }
`;

export default StyledPhoneInputContainer;
