import React from 'react';
import { captureExceptionWithFullStory } from '@whitelabel/helpers/utils';
import ErrorPage from '../components/ErrorPage';

type Props = any;

type State = any;

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: any) {
    this.setState({ error });
    captureExceptionWithFullStory(error);
  }

  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;
    if (hasError) {
      return <ErrorPage error={error} />;
    }
    return children;
  }
}

export default ErrorBoundary;
