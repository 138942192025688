import { css } from 'styled-components';
import { fontSize } from '@whitelabel/helpers/style/type';
import { themeColor } from '@whitelabel/helpers/style/colors';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { spacer, multiply } from '@whitelabel/helpers/style/units';
import { get, getThemeProp } from '@whitelabel/helpers/style/get';
import { contentColors, inputColors } from '@whitelabel/global-theme/colors';

const globalFormStyle = css`
  .form-control {
    height: ${multiply(spacer, 2.6875)};
    padding: ${multiply(spacer, 0.5)} ${multiply(spacer, 0.75)};
    font-size: ${multiply(fontSize('base'), 1.125)};

    ${mediaBreakpointUp('md')`
      padding: ${multiply(spacer, 0.5)} ${spacer};
    `}

    ${mediaBreakpointUp('lg')`
      height: ${multiply(spacer, 2.875)};
      font-size: ${fontSize('lg')};
    `}
    border: ${get('borderWidth')} solid ${inputColors.border.default};
    background-color: ${inputColors.background.default};
    color: ${inputColors.value.default};

    &.is-invalid {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem;
      background-image: none;
      border-color: ${inputColors.border.error};
      &:focus {
        box-shadow: none; // Remove the red ring from bootstrap
        border-color: ${inputColors.border.focus}; // Otherwise it reverts to the error
      }
    }

    &:hover {
      border: ${get('borderWidth')} solid ${inputColors.border.hover};
      background-color: ${inputColors.background.hover};
      color: ${inputColors.value.hover};
    }

    &&:focus {
      box-shadow: none;
      border: ${get('borderWidth')} solid ${inputColors.border.focus};
      background-color: ${inputColors.background.focus};
      color: ${inputColors.value.focus};
    }

    &:active {
      border: ${get('borderWidth')} solid ${inputColors.border.active};
      background-color: ${inputColors.background.active};
      color: ${inputColors.value.active};
    }

    &&:disabled {
      border: ${get('borderWidth')} solid ${inputColors.border.disabled};
      background-color: ${inputColors.background.disabled};
      color: ${inputColors.value.disabled};
    }

    &::placeholder {
      color: ${contentColors.listLabel};
    }
  }

  .custom-control-input {
    width: 1.25rem;
    &:focus ~ .custom-control-label::before {
      box-shadow: none;
    }

    &:focus:not(:checked) ~ .custom-control-label::before {
      border-color: ${inputColors.border.focus};
    }

    &:checked ~ .custom-control-label::before {
      background-color: ${themeColor('primary')};
    }
  }

  .custom-control-label:after,
  .custom-control-label:before {
    left: -1.75rem;
    width: 1.25rem;
    height: 1.25rem;
  }

  .text-muted {
    color: ${inputColors.value.default};
  }

  .form-control[readonly],
  .form-control:disabled,
  .form-control-plaintext {
    direction: inherit;
    border-color: ${getThemeProp('inputDisabledBG')};

    &:focus {
      border-color: ${getThemeProp('inputDisabledBG')};
    }
  }

  .form-text {
    display: block;
  }

  .invalid-feedback {
    color: ${inputColors.border.error};
  }

  .input-group-append,
  .input-group-prepend {
    display: flex;
  }

  .input-group > .form-control,
  .input-group > .form-select {
    flex: 1 1;
    width: 100vw;
  }
`;

export default globalFormStyle;
