import React from 'react';
import { ThemeProvider } from 'styled-components';

export interface ILevelUpProps {
  type: string;
  children: React.ReactNode;
}

const LevelUp = ({ type, children }: ILevelUpProps): JSX.Element => {
  const updateZIndex = ({ zIndex, ...restTheme }: any) => {
    const newZIndex = { ...zIndex };
    newZIndex[type] += 1;
    const newTheme = {
      zIndex: newZIndex,
      ...restTheme,
    };
    return newTheme;
  };

  return <ThemeProvider theme={updateZIndex}>{children}</ThemeProvider>;
};

export default LevelUp;
