import { mapKeys } from 'lodash/fp';

/**
 * Returns a new object with updated keys
 * @param {Object} keyMap - map old keys to new ones, e.g. { oldKey: 'newKey' }
 * @param {Object} object - the object you want to update
 * @returns {Object} New object with updated keys
 */
export const updateKeys = (keyMap: any, object: any) => mapKeys((key) => keyMap[key] || key, object);

export const isJSON = (input: unknown): boolean => {
  let value = typeof input === 'string' ? input : JSON.stringify(input);
  try {
    value = JSON.parse(value);
  } catch (e) {
    return false;
  }
  return typeof value === 'object' && value !== null;
};
