import { CarouselIndicators, Row, Col, Carousel, CarouselItem } from 'reactstrap';
import styled from 'styled-components';
import { fontStyle, fontSize } from '@whitelabel/helpers/style/type';
import { gray, themeColor, color } from '@whitelabel/helpers/style/colors';
import { mediaBreakpointDown, mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { getThemeProp } from '@whitelabel/helpers/style/get';

export const StyledCarouselItem = styled(CarouselItem)`
  .slideContent {
    color: ${themeColor('body')};
  }
`;

export const StyledCarousel = styled(Carousel)`
  .carousel-item {
    opacity: 0;
    transition-duration: 0.6s;
    transition-property: opacity;
  }

  .carousel-item.active,
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    opacity: 1;
  }

  .active.carousel-item-left,
  .active.carousel-item-right {
    opacity: 0;
  }

  .carousel-item-next,
  .carousel-item-prev,
  .carousel-item.active,
  .active.carousel-item-left,
  .active.carousel-item-prev {
    transform: translateX(0);
    transform: translate3d(0, 0, 0);
  }
`;

export const StyledCarouselFooter = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    width: 100%;
  }

  ${mediaBreakpointDown('sm')`
    & > *:last-child {
      margin-bottom: 1rem;
    }
  `}
  @media print {
    & {
      display: none;
      page-break-before: always;
    }
  }
`;

export const StyledCarouselIndicators = styled(CarouselIndicators)`
  position: relative;
  margin: 0;
  ${mediaBreakpointDown('sm')`
    margin-bottom: 1rem;
  `}
  li {
    box-sizing: border-box;
    width: 1rem;
    height: 1rem;
    margin: 0 0.5rem;
    padding: 0;
    background: ${color('white')};
    border: ${getThemeProp('borderWidth')} solid ${gray(500)};
    border-radius: 50%;
  }

  li.active {
    background: ${gray(700)};
    border: 0.3125rem solid ${themeColor('primary')};
  }
  @media print {
    & {
      display: none;
    }
  }
`;

export const StyledCarouselItemHeaderSection = styled(Row)`
  align-items: center;
  margin-bottom: 1rem;
  padding: 0;
  background: ${gray(100)};
  ${mediaBreakpointUp('sm')`
    margin-bottom: 1.5rem;
    padding: 1rem;
  `}
  img {
    width: 100%;
    height: auto;
  }
  @media print {
    img {
      display: none;
    }
  }
`;

export const StyledCarouselItemHeader = styled(Col)`
  padding: 0.5rem;
  padding: 1rem;
  header {
    margin-bottom: 0;
  }
  ${mediaBreakpointUp('sm')`
    padding: 1.5rem;
  `}
`;

export const StyledCarouselItemNumber = styled.div`
  ${fontStyle('listLabel')}
  margin-bottom: 0;
  color: ${themeColor('body')};
  font-size: ${fontSize('md')};
  @media print {
    & {
      display: none;
    }
  }
`;

export const StyledCarouselItemDivider = styled.hr`
  width: 100%;
  margin-top: 0;
  margin-bottom: 1rem;
  border-top: 1px solid ${gray(400)};
  border-top: ${getThemeProp('borderWidth')} solid ${gray(400)};
  @media print {
    & {
      display: none;
    }
  }
`;
