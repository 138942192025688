import React from 'react';
import IconCardButton from '../../IconCardButton';
import { StyledGridContainer, StyledBlockHeader, StyledBlockSubTitle } from './styledThreeColumnButtonGrid';
import { iconMap, IGridBox, IThreeColumnButtonGridProps } from './IThreeColumnButtonGrid';

const ThreeColumnButtonGrid = ({ title, gridBoxes, subTitle }: IThreeColumnButtonGridProps): JSX.Element => (
  <div>
    <StyledBlockHeader>{title}</StyledBlockHeader>
    <StyledBlockSubTitle>{subTitle}</StyledBlockSubTitle>
    <StyledGridContainer>
      {gridBoxes.map(
        ({ title: titleValue, description, icon, link }: IGridBox) =>
          iconMap[icon] && (
            <IconCardButton
              key={link}
              description={description}
              title={titleValue}
              link={link}
              svgIcon={iconMap[icon]}
            />
          ),
      )}
    </StyledGridContainer>
  </div>
);

export default ThreeColumnButtonGrid;
