import styled, { css } from 'styled-components';
import { UncontrolledTooltip } from 'reactstrap';
import { fontSize } from '@whitelabel/helpers/style/type';
import { gray, themeColor, color } from '@whitelabel/helpers/style/colors';
import { boxShadow } from '@whitelabel/helpers/style/box';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { spacer } from '@whitelabel/helpers/style/units';
import { get } from '@whitelabel/helpers/style/get';

export const StyledUncontrolledTooltip = styled(UncontrolledTooltip)`
  .tooltip {
    .tooltip-inner {
      padding: 0.875rem ${spacer};
      color: ${themeColor('header')};
      font-size: ${fontSize('base')};
      background: ${color('white')};
      border: ${get('borderWidth')} solid ${gray(300)};
      box-shadow: ${boxShadow('base')};

      ${mediaBreakpointUp('lg')`
        padding: ${spacer};
        font-size: 1.25rem;
        line-height: 1.6875rem;
      `}

      p:last-child {
        margin: 0;
      }
    }

    &.show {
      opacity: 1;
    }
  }

  .tooltip.bs-tooltip-auto {
    &[x-placement^='top'] .arrow {
      bottom: 0.4rem;

      &::before {
        border-top-color: ${color('white')};
      }
    }

    &[x-placement^='right'] .arrow {
      left: 0.4rem;

      &::before {
        border-right-color: ${color('white')};
      }
    }

    &[x-placement^='bottom'] .arrow {
      top: 0.6rem;

      &::before {
        border-bottom-color: ${color('white')};
      }
    }

    &[x-placement^='left'] .arrow {
      right: 0.4rem;

      &::before {
        border-left-color: ${color('white')};
      }
    }
  }

  ${({ backgroundColor, hasMaxWidth }) =>
    backgroundColor &&
    css`
  .tooltip {
    .tooltip-inner {
      max-width: ${hasMaxWidth ? `initial` : `none`};
      color: ${color('white')};
      background: ${themeColor(backgroundColor)};
      padding: 8px 16px;
    }

    &.bs-tooltip-auto {
      &[x-placement^='top'] .arrow::before {
        border-top-color: ${themeColor(backgroundColor)};
      }

      &[x-placement^='right'] .arrow::before {
        border-right-color: ${themeColor(backgroundColor)};
      }

      &[x-placement^='bottom'] .arrow {
        top: 12px;

        &::before {
          border-bottom-color: ${themeColor(backgroundColor)};
        }
      }

      &[x-placement^='left'] .arrow::before {
        border-left-color: ${themeColor(backgroundColor)};
      }
    }
  `}
`;

export default StyledUncontrolledTooltip;
