import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import htmlbars from 'react-syntax-highlighter/dist/cjs/languages/hljs/htmlbars';
import style from 'react-syntax-highlighter/dist/cjs/styles/hljs/github';
import { ReactComponent as CopyIcon } from '@whitelabel/media/icons/copy.svg';
import Button from '../../Button';
import { customStyles, lineNumberStyles, StyledSyntaxHighlighterBlock } from './styledCodeBlock';
import messages from './messages';

export interface ICodeBlock {
  value: {
    code: string;
  };
}

SyntaxHighlighter.registerLanguage('htmlbars', htmlbars);

const CodeBlock = ({ value }: ICodeBlock): JSX.Element => {
  const { formatMessage } = useIntl();
  const [isCopied, setIsCopied] = useState<boolean>(false);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(!isCopied);
      }, 850);
    }
  }, [isCopied]);

  const handleCopyCode = () => {
    navigator.clipboard.writeText(value.code);
    if (!isCopied) {
      setIsCopied(!isCopied);
    }
  };
  return (
    <StyledSyntaxHighlighterBlock>
      <Button
        icon={CopyIcon}
        buttonType="tertiary"
        aria-label={formatMessage(messages.copyButtonAriaLabel)}
        onClick={handleCopyCode}
      >
        {isCopied ? formatMessage(messages.copied) : formatMessage(messages.copy)}
      </Button>
      <SyntaxHighlighter
        style={style}
        customStyle={customStyles}
        language="htmlbars"
        showLineNumbers
        lineNumberContainerStyle={lineNumberStyles}
      >
        {value.code}
      </SyntaxHighlighter>
    </StyledSyntaxHighlighterBlock>
  );
};

export default CodeBlock;
