import React from 'react';
import { StyledModalAlert, StyledModalHeader, StyledModalBody, StyledModalFooter } from '../../styled/StyledModal';

interface IModalContentProps {
  toggle?: () => void;
  alert?: React.ReactNode;
  header?: string | React.ReactElement;
  body?: string | React.ReactElement | React.ReactElement[];
  footer?: string | React.ReactElement | false;
  headingTag?: string;
  showCloseButton?: boolean;
}

const ModalContent = ({
  toggle,
  header,
  body,
  footer,
  headingTag = 'h1',
  alert,
  showCloseButton = true,
}: IModalContentProps): JSX.Element => (
  <>
    {alert && <StyledModalAlert>{alert}</StyledModalAlert>}
    <StyledModalHeader
      tag={headingTag}
      toggle={toggle}
      data-test-id="Modal-header"
      $header={header}
      $showCloseButton={showCloseButton}
    >
      {header}
    </StyledModalHeader>
    <StyledModalBody data-test-id="Modal-body">{body}</StyledModalBody>
    {footer && <StyledModalFooter data-test-id="Modal-footer">{footer}</StyledModalFooter>}
  </>
);

export default ModalContent;
