import { multiply } from './units';
import { spacer } from './grid';
import { themeColors } from './colors';

const nav = {
  tabs: {
    borderWidth: multiply(spacer, 0.25),
    borderColor: themeColors.secondary,
  },
  togglerIconColor: {
    inactive: themeColors.primary,
    active: themeColors.header,
  },
};

export default nav;
