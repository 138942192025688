import { css } from 'styled-components';
import { fontWeight, fontSize } from '@whitelabel/helpers/style/type';
import { themeColor } from '@whitelabel/helpers/style/colors';
import { borderRadius, boxShadow } from '@whitelabel/helpers/style/box';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';

const globalGenericStyle = css`
  .font-weight-bolder {
    font-weight: ${fontWeight('bold')} !important;
  }

  .font-weight-lighter {
    font-weight: ${fontWeight('light')} !important;
  }

  .dropdown-header {
    font-size: ${fontSize('sm')};
    ${mediaBreakpointUp('sm')`
      font-size: ${fontSize('md')};
    `}
  }

  .shadow-sm {
    box-shadow: ${boxShadow('base')} !important;
  }

  .shadow {
    box-shadow: ${boxShadow('base')} !important;
  }

  .shadow-lg {
    box-shadow: ${boxShadow('md')} !important;
  }

  .rounded-sm {
    border-radius: ${borderRadius('sm')} !important;
  }

  .rounded {
    border-radius: ${borderRadius('base')} !important;
  }

  .rounded-top {
    border-top-left-radius: ${borderRadius('base')} !important;
    border-top-right-radius: ${borderRadius('base')} !important;
  }

  .rounded-right {
    border-top-right-radius: ${borderRadius('base')} !important;
    border-bottom-right-radius: ${borderRadius('base')} !important;
  }

  .rounded-bottom {
    border-bottom-right-radius: ${borderRadius('base')} !important;
    border-bottom-left-radius: ${borderRadius('base')} !important;
  }

  .rounded-left {
    border-top-left-radius: ${borderRadius('base')} !important;
    border-bottom-left-radius: ${borderRadius('base')} !important;
  }

  .text-danger {
    color: ${themeColor('danger')} !important;
  }
`;

export default globalGenericStyle;
