import { defineMessages } from 'react-intl';

export default defineMessages({
  showAllPartners: {
    id: 'helpCenterHomePage.showAllPartners',
    defaultMessage: 'Show All Partners',
  },
  searchPartners: {
    id: 'helpCenterHomePage.searchPartners',
    defaultMessage: 'Search partners',
  },
});
