import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { ReactComponent as MessageIcon } from '@whitelabel/media/icons/message.svg';
import { StyledContactHoverButton, StyledSpan } from './styledHoverButton';

export interface IHoverButton {
  onClick: () => void;
  buttonHoverText: string;
}

const HoverButton = ({ onClick, buttonHoverText }: IHoverButton) => {
  const [showText, setShowText] = useState(false);

  const onMouseEnter = () => {
    setShowText(true);
  };
  const onMouseLeave = () => {
    setShowText(false);
  };

  return (
    <StyledContactHoverButton
      icon={MessageIcon}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...(showText ? undefined : { 'aria-label': buttonHoverText })}
    >
      <AnimatePresence>
        {showText && (
          <StyledSpan
            key="child"
            initial={{ maxWidth: 0 }}
            animate={{ maxWidth: '300px' }}
            exit={{ maxWidth: 0 }}
            transition={{ duration: 0.25, ease: 'easeInOut' }}
          >
            {buttonHoverText}
          </StyledSpan>
        )}
      </AnimatePresence>
    </StyledContactHoverButton>
  );
};

export default HoverButton;
