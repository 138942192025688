import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { Link } from 'react-router-dom';
import { fontStyle } from '@whitelabel/helpers/style/type';
import { gray, color } from '@whitelabel/helpers/style/colors';
import { boxShadow } from '@whitelabel/helpers/style/box';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';

export const StyledSlideWrapper = styled.div`
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  background-color: ${gray(100)};
  ${mediaBreakpointUp('md')`
    padding-top: 0.75rem;
    padding-bottom: 1.5rem;
  `}
  ${mediaBreakpointUp('lg')`
    padding-top: 1rem;
    padding-bottom: 2rem;
  `}
`;

export const StyledGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;

  ${mediaBreakpointUp('md')`
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1.5rem;
  `}

  ${mediaBreakpointUp('xl')`
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 2rem;
  `}
`;

const gridBlockCardStyles = css<{ $isComingSoon: boolean; $excludeAppLink: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${rem(76)};
  padding: 1rem;
  background: ${color('white')};
  box-shadow: ${boxShadow('base')};

  &:hover {
    box-shadow: ${boxShadow('base')};
  }

  ${({ $isComingSoon, $excludeAppLink }) =>
    ($isComingSoon || $excludeAppLink) &&
    css`
      pointer-events: none;
    `}

  ${mediaBreakpointUp('md')`
    height: ${rem(104)};
  `}

  ${mediaBreakpointUp('xl')`
    width: 10.125rem;
    height: ${rem(120)};
  `}

  .partner-logo {
    max-width: ${rem(130)};
    max-height: ${rem(30)};
    width: auto;
    height: auto;
  }

  .logo-status {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    color: ${gray(500)};
    text-transform: uppercase;
    box-shadow: none; /* override link styles */
    ${fontStyle('buttonSmall')}
  }

  a.logo-status,
  span.logo-icon {
    gap: 0.5rem;
    color: ${gray(600)};
  }
`;

export const StyledInternalGridBlockCard = styled(Link)<{ $isComingSoon: boolean; $excludeAppLink: boolean }>`
  ${gridBlockCardStyles}
`;

export const StyledExternalGridBlockCard = styled('a')<{ $isComingSoon: boolean; $excludeAppLink: boolean }>`
  ${gridBlockCardStyles}
`;
