import React from 'react';
import { StyledFieldRequirements, StyledRequirement, StyledIcon } from './styledFieldRequirements';

export interface IFieldRequirementsProps {
  value: string;
  requirements: {
    id: string;
    validate: (...args: any[]) => any;
    message: string;
  }[];
}

const FieldRequirements = ({ value, requirements }: IFieldRequirementsProps): JSX.Element => {
  const validatedRequirements = requirements.map(({ id, validate, message }) => ({
    id,
    validate,
    message,
    valid: value && !validate(value),
  }));

  return (
    <StyledFieldRequirements data-test-id="FieldRequirements">
      {validatedRequirements.map(({ id, message, valid }) => (
        <StyledRequirement key={id} id={id} data-test-id="FieldRequirements-requirement">
          <StyledIcon type="radio" color={valid ? 'success' : 'gray'} data-test-id="FieldRequirements-icon" />
          {message}
        </StyledRequirement>
      ))}
    </StyledFieldRequirements>
  );
};

export default FieldRequirements;
