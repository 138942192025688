import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Col, Card } from 'reactstrap';
import { fontStyle, fontWeight, fontSize } from '@whitelabel/helpers/style/type';
import { gray } from '@whitelabel/helpers/style/colors';
import { mediaBreakpointUp, mediaBreakpointOnly } from '@whitelabel/helpers/style/grid';
import { spacer, multiply } from '@whitelabel/helpers/style/units';
import { get } from '@whitelabel/helpers/style/get';
import ImageBlock from '../ImageBlock';
import ButtonBlock from '../ButtonBlock';
import StyledSection from '../../../styled/StyledSection';

export const StyledFeatureColumnsBlock = styled(StyledSection)`
  &&& {
    margin-bottom: ${multiply(spacer, 1.5)};

    ${mediaBreakpointUp('md')`
      margin-bottom: ${multiply(spacer, 2)};
    `}

    ${mediaBreakpointUp('lg')`
      margin-bottom: ${multiply(spacer, 3)};
    `}
  }
`;

// Disable eslint to keep the original logic: not pass no-unused-vars
// props down to child component.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledImageBlock = styled(({ withMargin, ...props }) => <ImageBlock {...props} />)`
  width: 100%;
  height: auto;

  ${({ withMargin }) =>
    withMargin &&
    css`
      margin-bottom: ${spacer};

      ${mediaBreakpointUp('md')`
        margin-bottom: ${multiply(spacer, 1.5)};
      `}

      ${mediaBreakpointUp('lg')`
        margin-bottom: ${multiply(spacer, 2)};
      `}
    `}
`;

StyledImageBlock.propTypes = {
  withMargin: PropTypes.bool,
};

StyledImageBlock.defaultProps = {
  withMargin: true,
};

// Disable eslint to keep the original logic: not pass no-unused-vars
// props down to child component.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledCol = styled(({ isCard, ...props }) => <Col {...props} />)`
  text-align: center;

  ${({ isCard }) =>
    isCard &&
    css`
      text-align: start;
    `}

  &:not(:last-child) {
    ${mediaBreakpointOnly('xs')`
      margin-bottom: ${multiply(spacer, 2)};
    `}
  }
`;

export const StyledLeadText = styled.div.attrs({ className: 'lead' })`
  font-size: ${fontSize('md')};

  p {
    margin-bottom: 0;
  }
`;

export const StyledHeading = styled.h4`
  margin-bottom: ${multiply(spacer, 0.5)};
  font-weight: ${fontWeight('bold')};
  text-transform: uppercase;
`;

export const StyledBody = styled.div`
  color: ${gray(600)};
  p {
    margin-bottom: 0;
  }
`;

export const StyledButtonBlock = styled(ButtonBlock)`
  margin-top: ${multiply(spacer, 0.5)};
`;

export const StyledCard = styled(Card)`
  ${fontStyle('paragraph')};
  border-width: ${get('borderWidth')};

  &:last-child {
    margin-bottom: 0;
  }
`;
