import styled, { css } from 'styled-components';
import { mediaBreakpointDown, mediaBreakpointUp } from '@whitelabel/helpers/style/grid';

const xsDisplayNone = css`
  ${mediaBreakpointDown('xs')`
    display: none;
  `}
`;

export const StyledImageWrapper = styled.img<{ $hasMobileImage: boolean }>`
  ${({ $hasMobileImage }) => ($hasMobileImage ? xsDisplayNone : '')};
`;
export const StyledMobileImageWrapper = styled.img`
  ${mediaBreakpointUp('sm')`
    display: none;
  `}
`;
