import { Card, CardText, Col, Row } from 'reactstrap';
import styled from 'styled-components';
import { resetLinkStyle } from '@whitelabel/helpers/style/type';
import { gray } from '@whitelabel/helpers/style/colors';
import { borderRadius } from '@whitelabel/helpers/style/box';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';

export const PageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  height: 100vh;
  padding-top: 1rem;
  background: ${gray(200)};
`;

export const StyledRow = styled(Row)`
  align-items: center;
  justify-content: center;
`;

export const SiteLogo = styled.img`
  display: block;
  width: auto;
  max-width: 10rem;
  max-height: 4rem;
  margin-right: auto;
  margin-bottom: 1rem;
  margin-left: auto;

  ${mediaBreakpointUp('md')`
    max-width: 20rem;
    margin-bottom: 3rem;
  `}
`;

export const CardSection = styled(Card)`
  padding: 1rem;
  text-align: center;
  border-radius: ${borderRadius('base')};

  ${mediaBreakpointUp('md')`
    padding: 3rem;
  `}
`;

export const CardHeading = styled.h3`
  margin-bottom: 0.5rem;
  color: ${gray(700)};
  text-transform: uppercase;

  ${mediaBreakpointUp('md')`
    margin-bottom: 1rem;
  `}
`;

export const CardBodyText = styled(CardText)`
  margin-bottom: 2rem;
  color: ${gray(600)};
`;

export const BrowserLogoContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${gray(700)};
`;

export const BrowserLogo = styled.img`
  max-width: 7.5rem;
  margin-bottom: 2rem;
`;

export const BrowserLink = styled.a`
  ${resetLinkStyle}
  color: ${gray(700)};
`;
