// This is only used in component <InsuredPerson /> but that component no longer used
import { css } from 'styled-components';
import { opacifyColor } from '@whitelabel/helpers/style/colors';
import { borderRadius } from '@whitelabel/helpers/style/box';
import { spacer, multiply } from '@whitelabel/helpers/style/units';
import { getThemeProp, popover } from '@whitelabel/helpers/style/get';

const globalPopoverStyle = css`
  .popover {
    font-size: ${popover('fontSize')};
    border: ${getThemeProp('borderWidth')} solid ${popover('borderColor')};
    border-radius: ${borderRadius('base')};
    box-shadow: ${popover('boxShadow')};

    .arrow {
      width: ${popover('arrowWidth')};
      height: ${popover('arrowHeight')};
    }
  }

  .popover-header {
    padding: ${spacer} ${spacer} 0 ${spacer};
    color: ${popover('header', 'color')};
    background-color: ${popover('header', 'bg')};
    border-bottom: 0;
    &::before {
      border-bottom: 0;
    }
  }

  .bs-popover-bottom {
    margin-top: calc(${popover('arrowHeight')} + 2px);

    > .arrow {
      top: calc((${popover('arrowHeight')} + ${getThemeProp('borderWidth')}) * -1);

      &::before {
        border-width: 0 (${popover('arrowWidth')} / 2) ${popover('arrowHeight')} (${popover('arrowWidth')} / 2);
        border-bottom-color: ${opacifyColor(0.05, popover('borderColor'))};
      }

      &::after {
        border-width: 0 (${popover('arrowWidth')} / 2) ${popover('arrowHeight')} (${popover('arrowWidth')} / 2);
      }
    }

    .popover-header::before {
      width: ${popover('arrowWidth')};
      margin-inline-start: -${popover('arrowWidth')} / 2;
      border-bottom: 0;
    }
  }

  .bs-popover-left {
    margin-inline-end: calc(${popover('arrowHeight')} + 2px);

    > .arrow {
      right: calc((${popover('arrowHeight')} + ${getThemeProp('borderWidth')}) * -1);
      width: ${popover('arrowHeight')};
      height: ${popover('arrowWidth')};

      &::before {
        border-width: (${popover('arrowWidth')} / 2) 0 (${popover('arrowWidth')} / 2) ${popover('arrowHeight')};
        border-left-color: ${opacifyColor(0.05, popover('borderColor'))};
      }

      &::after {
        border-width: (${popover('arrowWidth')} / 2) 0 (${popover('arrowWidth')} / 2) ${popover('arrowHeight')};
      }
    }
  }

  .bs-popover-right {
    margin-inline-start: calc(${popover('arrowHeight')} + 2px);

    > .arrow {
      left: calc((${popover('arrowHeight')} + ${getThemeProp('borderWidth')}) * -1);
      width: ${popover('arrowHeight')};
      height: ${popover('arrowWidth')};

      &::before {
        border-width: (${popover('arrowWidth')} / 2) ${popover('arrowHeight')} (${popover('arrowWidth')} / 2) 0;
        border-right-color: ${opacifyColor(0.05, popover('borderColor'))};
      }

      &::after {
        border-width: (${popover('arrowWidth')} / 2) ${popover('arrowHeight')} (${popover('arrowWidth')} / 2) 0;
      }
    }
  }

  .bs-popover-top {
    margin-bottom: calc(${popover('arrowHeight')} + 2px);

    > .arrow {
      bottom: calc((${popover('arrowHeight')} + ${getThemeProp('borderWidth')}) * -1);

      &::before {
        border-width: ${popover('arrowHeight')} (${popover('arrowWidth')} / 2) 0;
        border-top-color: ${opacifyColor(0.05, popover('borderColor'))};
      }

      &::after {
        border-width: ${popover('arrowHeight')} (${popover('arrowWidth')} / 2) 0;
      }
    }
  }

  .popover-body {
    padding: ${multiply(spacer, 0.5)} ${spacer} ${spacer} ${spacer};
    color: ${popover('bodyColor')};
  }
`;

export default globalPopoverStyle;
