import React from 'react';

import svgFlagAz from '@whitelabel/media/icons/flags/localeFlags/az.svg';
import svgFlagId from '@whitelabel/media/icons/flags/localeFlags/id.svg';
import svgFlagMs from '@whitelabel/media/icons/flags/localeFlags/ms.svg';
import svgFlagCa from '@whitelabel/media/icons/flags/localeFlags/ca.svg';
import svgFlagCs from '@whitelabel/media/icons/flags/localeFlags/cs.svg';
import svgFlagDa from '@whitelabel/media/icons/flags/localeFlags/da.svg';
import svgFlagDe from '@whitelabel/media/icons/flags/localeFlags/de.svg';
import svgFlagEt from '@whitelabel/media/icons/flags/localeFlags/et.svg';
import svgFlagEl from '@whitelabel/media/icons/flags/localeFlags/el.svg';
import svgFlagEs from '@whitelabel/media/icons/flags/localeFlags/es.svg';
import svgFlagMx from '@whitelabel/media/icons/flags/localeFlags/es-mx.svg';
import svgFlagFil from '@whitelabel/media/icons/flags/localeFlags/fil.svg';
import svgFlagFr from '@whitelabel/media/icons/flags/localeFlags/fr.svg';
import svgFlagHr from '@whitelabel/media/icons/flags/localeFlags/hr.svg';
import svgFlagIs from '@whitelabel/media/icons/flags/localeFlags/is.svg';
import svgFlagIt from '@whitelabel/media/icons/flags/localeFlags/it.svg';
import svgFlagSw from '@whitelabel/media/icons/flags/localeFlags/sw.svg';
import svgFlagLv from '@whitelabel/media/icons/flags/localeFlags/lv.svg';
import svgFlagLt from '@whitelabel/media/icons/flags/localeFlags/lt.svg';
import svgFlagHu from '@whitelabel/media/icons/flags/localeFlags/hu.svg';
import svgFlagMt from '@whitelabel/media/icons/flags/localeFlags/mt.svg';
import svgFlagNl from '@whitelabel/media/icons/flags/localeFlags/nl.svg';
import svgFlagNo from '@whitelabel/media/icons/flags/localeFlags/no.svg';
import svgFlagPl from '@whitelabel/media/icons/flags/localeFlags/pl.svg';
import svgFlagPt from '@whitelabel/media/icons/flags/localeFlags/pt.svg';
import svgFlagPtBr from '@whitelabel/media/icons/flags/localeFlags/pt-br.svg';
import svgFlagRo from '@whitelabel/media/icons/flags/localeFlags/ro.svg';
import svgFlagSk from '@whitelabel/media/icons/flags/localeFlags/sk.svg';
import svgFlagSl from '@whitelabel/media/icons/flags/localeFlags/sl.svg';
import svgFlagFi from '@whitelabel/media/icons/flags/localeFlags/fi.svg';
import svgFlagSv from '@whitelabel/media/icons/flags/localeFlags/sv.svg';
import svgFlagVi from '@whitelabel/media/icons/flags/localeFlags/vi.svg';
import svgFlagTr from '@whitelabel/media/icons/flags/localeFlags/tr.svg';
import svgFlagBg from '@whitelabel/media/icons/flags/localeFlags/bg.svg';
import svgFlagRu from '@whitelabel/media/icons/flags/localeFlags/ru.svg';
import svgFlagSr from '@whitelabel/media/icons/flags/localeFlags/sr.svg';
import svgFlagUk from '@whitelabel/media/icons/flags/localeFlags/uk.svg';
import svgFlagHe from '@whitelabel/media/icons/flags/localeFlags/he.svg';
import svgFlagAr from '@whitelabel/media/icons/flags/localeFlags/ar.svg';
import svgFlagTh from '@whitelabel/media/icons/flags/localeFlags/th.svg';
import svgFlagKa from '@whitelabel/media/icons/flags/localeFlags/ka.svg';
import svgFlagJa from '@whitelabel/media/icons/flags/localeFlags/ja.svg';
import svgFlagZh from '@whitelabel/media/icons/flags/localeFlags/zh.svg';
import svgFlagKo from '@whitelabel/media/icons/flags/localeFlags/ko.svg';
import svgFlagGb from '@whitelabel/media/icons/flags/localeFlags/gb.svg';
import svgFlagUs from '@whitelabel/media/icons/flags/localeFlags/us.svg';
import svgFlagGl from '@whitelabel/media/icons/flags/localeFlags/gl.svg';
import svgFlagEu from '@whitelabel/media/icons/flags/localeFlags/eu.svg';
import svgFlagCanada from '@whitelabel/media/icons/flags/localeFlags/canada.svg';

const IconFlagAz = () => (
  <img src={svgFlagAz} alt="az flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagId = () => (
  <img src={svgFlagId} alt="id flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagMs = () => (
  <img src={svgFlagMs} alt="ms flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagCa = () => (
  <img src={svgFlagCa} alt="ca flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagCs = () => (
  <img src={svgFlagCs} alt="cs flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagDa = () => (
  <img src={svgFlagDa} alt="da flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagDe = () => (
  <img src={svgFlagDe} alt="de flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagEt = () => (
  <img src={svgFlagEt} alt="et flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagEl = () => (
  <img src={svgFlagEl} alt="el flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagEs = () => (
  <img src={svgFlagEs} alt="es flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagMx = () => (
  <img src={svgFlagMx} alt="es-mx flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagFil = () => (
  <img src={svgFlagFil} alt="fil flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagFr = () => (
  <img src={svgFlagFr} alt="fr flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagHr = () => (
  <img src={svgFlagHr} alt="hr flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagIs = () => (
  <img src={svgFlagIs} alt="is flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagIt = () => (
  <img src={svgFlagIt} alt="it flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagSw = () => (
  <img src={svgFlagSw} alt="sw flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagLv = () => (
  <img src={svgFlagLv} alt="lv flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagLt = () => (
  <img src={svgFlagLt} alt="lt flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagHu = () => (
  <img src={svgFlagHu} alt="hu flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagMt = () => (
  <img src={svgFlagMt} alt="mt flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagNl = () => (
  <img src={svgFlagNl} alt="nl flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagNo = () => (
  <img src={svgFlagNo} alt="no flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagPl = () => (
  <img src={svgFlagPl} alt="pl flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagPt = () => (
  <img src={svgFlagPt} alt="pt flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagPtBr = () => (
  <img src={svgFlagPtBr} alt="pt-br flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagRo = () => (
  <img src={svgFlagRo} alt="ro flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagSk = () => (
  <img src={svgFlagSk} alt="sk flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagSl = () => (
  <img src={svgFlagSl} alt="sl flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagFi = () => (
  <img src={svgFlagFi} alt="fi flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagSv = () => (
  <img src={svgFlagSv} alt="sv flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagVi = () => (
  <img src={svgFlagVi} alt="vi flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagTr = () => (
  <img src={svgFlagTr} alt="tr flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagBg = () => (
  <img src={svgFlagBg} alt="bg flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagRu = () => (
  <img src={svgFlagRu} alt="ru flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagSr = () => (
  <img src={svgFlagSr} alt="sr flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagUk = () => (
  <img src={svgFlagUk} alt="uk flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagHe = () => (
  <img src={svgFlagHe} alt="he flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagAr = () => (
  <img src={svgFlagAr} alt="ar flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagTh = () => (
  <img src={svgFlagTh} alt="th flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagKa = () => (
  <img src={svgFlagKa} alt="ka flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagJa = () => (
  <img src={svgFlagJa} alt="ja flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagZh = () => (
  <img src={svgFlagZh} alt="zh flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagKo = () => (
  <img src={svgFlagKo} alt="ko flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagGb = () => (
  <img src={svgFlagGb} alt="gb flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagUs = () => (
  <img src={svgFlagUs} alt="us flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagGl = () => (
  <img src={svgFlagGl} alt="gl flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagEu = () => (
  <img src={svgFlagEu} alt="eu flag" width="25" height="16" className="flag-image flag-border" />
);
const IconFlagCanada = () => (
  <img src={svgFlagCanada} alt="Canada flag" width="25" height="16" className="flag-image flag-border" />
);

export const flagMap = {
  az: IconFlagAz,
  id: IconFlagId,
  ms: IconFlagMs,
  ca: IconFlagCa,
  cs: IconFlagCs,
  da: IconFlagDa,
  de: IconFlagDe,
  et: IconFlagEt,
  el: IconFlagEl,
  es: IconFlagEs,
  'es-mx': IconFlagMx,
  fil: IconFlagFil,
  fr: IconFlagFr,
  hr: IconFlagHr,
  is: IconFlagIs,
  it: IconFlagIt,
  sw: IconFlagSw,
  lv: IconFlagLv,
  lt: IconFlagLt,
  hu: IconFlagHu,
  mt: IconFlagMt,
  nl: IconFlagNl,
  no: IconFlagNo,
  pl: IconFlagPl,
  pt: IconFlagPt,
  'pt-br': IconFlagPtBr,
  ro: IconFlagRo,
  sk: IconFlagSk,
  sl: IconFlagSl,
  fi: IconFlagFi,
  sv: IconFlagSv,

  vi: IconFlagVi,
  tr: IconFlagTr,
  bg: IconFlagBg,
  ru: IconFlagRu,
  sr: IconFlagSr,

  uk: IconFlagUk,
  he: IconFlagHe,
  ar: IconFlagAr,
  th: IconFlagTh,
  ka: IconFlagKa,

  ja: IconFlagJa,
  'zh-hans': IconFlagZh,
  'zh-hant': IconFlagZh,
  ko: IconFlagKo,
  en: IconFlagGb,
  'en-us': IconFlagUs,

  gl: IconFlagGl,
  eu: IconFlagEu,
  'fr-ca': IconFlagCanada,
};
