import React from 'react';
import { useIntl } from 'react-intl';
import ReactHtmlParser from 'react-html-parser';
import { camelCase } from 'lodash/fp';
import { ListType, BlockComponentSpacing } from '@whitelabel/helpers/types';
import { transform } from '../../../helpers/reactHtmlParserUtils';
import { StyledIconList, StyledIconListItem } from './styledListBlock';

export interface IListBlockProps {
  listType: ListType;
  items: string[];
  className?: string;
  size?: 'small' | 'large';
  spacing?: BlockComponentSpacing;
}

const ListBlock = ({ listType, items, className, size, spacing = 'md' }: IListBlockProps): JSX.Element => {
  const intl = useIntl();
  if (listType === 'ordered' || listType === 'unordered') {
    const List = listType === 'ordered' ? 'ol' : 'ul';

    return (
      <List className={`${className} list-block`}>
        {items.map((item: any) => (
          <li key={camelCase(item)}>
            {ReactHtmlParser(item, {
              transform: transform(intl),
            })}
          </li>
        ))}
      </List>
    );
  }

  return (
    <StyledIconList className={`${className} list-block`} spacing={spacing}>
      {items.map((item: any) => (
        <StyledIconListItem $rtl={intl.bidi} $listType={listType} key={camelCase(item)} size={size}>
          {ReactHtmlParser(item, {
            transform: transform(intl),
          })}
        </StyledIconListItem>
      ))}
    </StyledIconList>
  );
};

export default ListBlock;
