import styled from 'styled-components';
import { gray, themeColor } from '@whitelabel/helpers/style/colors';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { spacer, multiply } from '@whitelabel/helpers/style/units';
import { getThemeProp } from '@whitelabel/helpers/style/get';

// eslint-disable-next-line import/prefer-default-export
export const StyledCallout = styled.div`
  padding: ${spacer};
  text-align: center;
  background: ${themeColor('primary')};

  ${mediaBreakpointUp('md')`
    align-items: center;
    display: flex;
    justify-content: center;
    padding: ${multiply(spacer, 2)} 0;
  `}

  ${mediaBreakpointUp('lg')`
    padding: ${multiply(spacer, 2)} 0;
  `}

  && > h3 {
    ${mediaBreakpointUp('md')`
      margin-top: 0;
      margin-bottom: 0;
      margin-inline-end: 2rem;
    }
  `}
  }

  a {
    &:not(:disabled):not(.disabled) {
      &:hover,
      &:active,
      &:focus {
        border: ${getThemeProp('borderWidth')} solid ${gray(700)};
      }
    }
  }
`;
