import { fontWeights } from './type';
import { grays } from './colors';

const button = {
  textTransform: 'uppercase',
  fontWeight: fontWeights.black,
  width: 'auto',
  borderRadii: {
    base: '3px',
    sm: '2px',
  },
  color: grays[700],
};

export default button;
