import styled, { css } from 'styled-components';
import { Input } from 'reactstrap';
import { rem } from 'polished';
import { gray } from '@whitelabel/helpers/style/colors';
import { borderRadius } from '@whitelabel/helpers/style/box';
import { get } from '@whitelabel/helpers/style/get';
import { inputColors } from '@whitelabel/global-theme/colors';

export const StyledPercentageInputBox = styled.div`
  display: flex;
  position: relative;
`;

export const StyledPercentageSymbol = styled.span`
  position: absolute;
  top: 0.7rem;
  color: ${gray(600)};
  font-size: 1.125rem;
  right: 0.75rem;
`;

export const StyledInput = styled(Input)<{ $isInvalid: boolean }>`
  width: 100%;
  height: ${rem('46px')};
  padding: 0.5rem 2rem 0.5rem 1rem;
  border-radius: ${borderRadius('base')};
  border: ${get('borderWidth')} solid ${inputColors.border.default};
  background-color: ${inputColors.background.default};
  color: ${inputColors.value.default};

  &:hover {
    border: ${get('borderWidth')} solid ${inputColors.border.hover};
    cursor: pointer;
    background-color: ${inputColors.background.hover};
    color: ${inputColors.value.hover};
  }

  &:focus {
    cursor: pointer;
    border: ${get('borderWidth')} solid ${inputColors.border.focus};
    background-color: ${inputColors.background.focus};
    color: ${inputColors.value.focus};
  }

  &:focus-visible {
    outline: none;
  }

  &:active {
    border: ${get('borderWidth')} solid ${inputColors.border.active};
    box-shadow: none;
    background-color: ${inputColors.background.active};
    color: ${inputColors.value.active};
  }

  &:disabled {
    border: ${get('borderWidth')} solid ${inputColors.border.disabled};
    background-color: ${inputColors.background.disabled};
    color: ${inputColors.value.disabled};
  }

  ${({ $isInvalid }) =>
    $isInvalid &&
    css`
      && {
        border: ${get('borderWidth')} solid ${inputColors.border.error};
        background-color: ${inputColors.background.error};
        color: ${inputColors.value.error};
      }
    `};

  // Remove number input arrows
  appearance: textfield;
  &::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    margin: 0;
    appearance: none;
  }
`;
