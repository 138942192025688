import React from 'react';
import { Row, Col } from 'reactstrap';
import { IFeatureBlock } from '@whitelabel/helpers/types';
import AccordionItem from './AccordionItem';
import { Wrapper } from './styledAccordionBlock';

export interface IAccordionBlockProps {
  items: IFeatureBlock[];
}

const AccordionBlock = ({ items }: IAccordionBlockProps): JSX.Element => (
  <Wrapper>
    {items.map((item, idx) => (
      <Row className="accordion-item-wrapper" key={item.title || `${idx}`}>
        <Col>
          <AccordionItem {...item} />
        </Col>
      </Row>
    ))}
  </Wrapper>
);

export default AccordionBlock;
