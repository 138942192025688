import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { IXCMSImage } from '@whitelabel/helpers/types';
import HeadingBlock from '../HeadingBlock';
import { StyledReview, StyledThumbnail, StyledWords, StyledImage, StyledCaption } from './styledTestimonialsBlock';

export interface ITestimonialsBlockProps {
  heading: string;
  testimonials: {
    icon?: IXCMSImage;
    name?: string;
    testimonial?: string;
    alignment?: 'left' | 'right';
  }[];
}

const TestimonialsBlock = ({ heading, testimonials }: ITestimonialsBlockProps): JSX.Element => (
  <>
    <HeadingBlock headingTag="h2" alignment="center" title={heading} shouldParse />
    {testimonials.map((singleReview) => {
      const { icon, name, testimonial, alignment } = singleReview;
      return (
        <StyledReview key={name} alignment={alignment}>
          {testimonial && <StyledWords>{ReactHtmlParser(testimonial)}</StyledWords>}
          <StyledThumbnail>
            <StyledImage src={icon?.file} alt={name} />
            {name && <StyledCaption>{ReactHtmlParser(name)}</StyledCaption>}
          </StyledThumbnail>
        </StyledReview>
      );
    })}
  </>
);

export default TestimonialsBlock;
