import React from 'react';
import { IImageBlock } from '@whitelabel/helpers/types';

const ImageBlock = ({ image, altText, className, ...props }: IImageBlock): any =>
  !!image && (
    <img
      src={image.file || image}
      alt={altText || image.title || ''}
      className={className}
      {...props}
      data-test-id="ImageBlock"
    />
  );

export default ImageBlock;
