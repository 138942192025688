import React from 'react';
import styled, { css } from 'styled-components';
import { Col } from 'reactstrap';
import {
  mediaBreakpointDown,
  mediaBreakpointUp,
  gridGutterWidth,
  justifyContent,
  mediaBreakpointOnly,
} from '@whitelabel/helpers/style/grid';

export const StyledImageBox = styled.div<{
  imageBoxMaxWidth: number;
  imageBoxMaxWidthSm: number;
  imageBoxMaxWidthMd: number;
  imageBoxMaxWidthLg?: number;
  totalImages: number;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: ${({ imageBoxMaxWidth }) => imageBoxMaxWidth}%;
  ${({ totalImages }) =>
    totalImages > 1 &&
    css`
      padding: 0 0.5rem;
    `}

  ${mediaBreakpointUp('sm')`
    max-width: ${({ imageBoxMaxWidthSm }: any) => imageBoxMaxWidthSm}%;
  `}

  ${mediaBreakpointUp('md')`
    max-width: ${({ imageBoxMaxWidthMd }: any) => imageBoxMaxWidthMd}%;
    ${({ totalImages }: { totalImages: number }) =>
      totalImages > 1 &&
      css`
        padding: 0 0.75rem;
      `}
  `}

  ${mediaBreakpointUp('lg')`
    ${({ totalImages }: { totalImages: number }) =>
      totalImages > 1 &&
      css`
        padding: 0 1rem;
      `}
    ${({ imageBoxMaxWidthLg }: any) =>
      imageBoxMaxWidthLg &&
      css`
        max-width: ${imageBoxMaxWidthLg}%;
      `}
  `}
`;

// Disable eslint to keep the original logic: not pass no-unused-vars
// props down to child component.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledImage = styled(({ imageMaxWidth, imageMaxWidthSm, imageMaxWidthMd, alt, ...props }) => (
  <img alt={alt} {...props} />
))`
  ${({ imageMaxWidth }) =>
    imageMaxWidth &&
    css`
      ${mediaBreakpointOnly('xs')`
        max-width: ${imageMaxWidth};
      `}
    `}
  ${({ imageMaxWidthSm }) =>
    imageMaxWidthSm &&
    css`
      ${mediaBreakpointOnly('sm')`
        max-width: ${imageMaxWidthSm};
      `}
    `}
  ${({ imageMaxWidthMd }) =>
    imageMaxWidthMd &&
    css`
      ${mediaBreakpointOnly('md')`
        max-width: ${imageMaxWidthMd};
      `}
    `}
`;

// Disable eslint to keep the original logic: not pass no-unused-vars
// props down to child component.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledCol = styled(({ alignment, ...props }) => <Col {...props} />)`
  display: flex;
  ${justifyContent}

  &:not(:last-child) {
    ${mediaBreakpointDown('sm')`
      margin-bottom: 1rem;
    `}
  }
`;

export const StyledImagesBlock = styled(
  // Disable eslint to keep the original logic: not pass no-unused-vars
  // props down to child component.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ alignment, addMarginTopAfter, addMarginTopAfterSm, addMarginTopAfterMd, ...props }) => <div {...props} />,
)`
  display: flex;
  flex-wrap: wrap;
  ${justifyContent}

  ${({ addMarginTopAfter }) =>
    addMarginTopAfter &&
    css`
      ${mediaBreakpointOnly('xs')`
        ${StyledImageBox}:nth-of-type(${addMarginTopAfter}) ~ ${StyledImageBox} {
          margin-top: ${gridGutterWidth('md')};
        }
      `}
    `}
  ${({ addMarginTopAfterSm }) =>
    addMarginTopAfterSm &&
    css`
      ${mediaBreakpointOnly('sm')`
        ${StyledImageBox}:nth-of-type(${addMarginTopAfterSm}) ~ ${StyledImageBox} {
          margin-top: ${gridGutterWidth('md')};
        }
      `}
    `}
  ${({ addMarginTopAfterMd }) =>
    addMarginTopAfterMd &&
    css`
      ${mediaBreakpointOnly('md')`
        ${StyledImageBox}:nth-of-type(${addMarginTopAfterMd}) ~ ${StyledImageBox} {
          margin-top: ${gridGutterWidth('md')};
        }
      `}
    `}
`;
