import supportedLanguages from './supportedLanguages';
import { Language, Languages, SupportedLanguage } from './types';

/**
 * This function is to return a new URL with this structure: newPathLanguage + (pathname excluding the locale). Have a look at the unit tests for some examples.
 * @param newPathLanguage The locale in the new URL
 * @param pathname
 * @param base The base URL including the scheme and host
 * @param search The query params
 * @param hash The hash value
 * @returns The new URL
 */

export function getNewURLWithLocale(
  newPathLanguage: string,
  pathname: string,
  base: string,
  search?: string,
  hash?: string,
) {
  let newPathname = pathname;
  const pathLanguage = newPathname.split('/')[1]?.toLowerCase() as SupportedLanguage;
  if (supportedLanguages[pathLanguage]) {
    newPathname = newPathname.replace(`/${pathLanguage}`, '');
  }
  return new URL(`/${newPathLanguage}${newPathname === '/' ? '' : newPathname}${search || ''}${hash || ''}`, base);
}

/**
 * This function is to get the first supported language from the accept-language HTTP header.
 * @param acceptLanguageHeader The accept-language HTTP header
 * @param languages The supported languages
 * @param userCountry
 * @param userContinent
 * @returns The supported language
 */
export function getLanguageFromAcceptLanguageHeader(
  acceptLanguageHeader: string,
  languages: Languages,
  userCountry: string,
  userContinent: string,
) {
  function getSupportedLanguage(language: string) {
    if (language.startsWith('pt') && userCountry === 'BR' && languages['pt-br']) {
      return 'pt-br';
    }
    if (language === 'en' && (userCountry === 'US' || userContinent === 'SA') && languages['en-us']) {
      return 'en-us';
    }

    if (languages[language as Language]) {
      return language;
    }
    return null;
  }

  const acceptLanguageRegex = /((([a-zA-Z]+)(-[a-zA-Z0-9]+){0,2})(;q=([0-1]\.[0-9]+)?)?)/g;
  let m;
  do {
    m = acceptLanguageRegex.exec(acceptLanguageHeader.toLocaleLowerCase());

    let supportedLanguage;
    const languageWithRegion = m?.[2];
    if (languageWithRegion) {
      supportedLanguage = getSupportedLanguage(languageWithRegion);
      if (supportedLanguage) return supportedLanguage;
    }
    const languageWithoutRegion = m?.[3];
    if (languageWithoutRegion) {
      supportedLanguage = getSupportedLanguage(languageWithoutRegion);
      if (supportedLanguage) return supportedLanguage;
    }
  } while (m !== null);
  return 'en';
}
