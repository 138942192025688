import { useField } from 'formik';
import React from 'react';
import { StyledToggleContainer, StyledToggleButton } from './styledToggleInput';

export interface IToggleInputProps {
  name: string;
  id: string;
  options: Array<{ label: string; value: string }>;
  size?: 'lg' | 'md' | 'sm';
  invalid?: boolean;
  onChange?: (value: string) => void;
}

const ToggleInput = ({
  name,
  options,
  size = 'md',
  invalid = false,
  onChange,
  ...props
}: IToggleInputProps): JSX.Element => {
  const [field, , { setValue, setTouched }] = useField(name);

  const handleOnClick = (value: string) => async () => {
    // await here is to fix stale state issue
    // because values in validate function or validateSchema in formik are one state behind
    await setValue(value);
    await setTouched(true);
    onChange?.(value);
  };

  return (
    <StyledToggleContainer {...props}>
      <input type="hidden" {...field} name={name} />
      {options.map(({ label, value }, idx) => (
        <StyledToggleButton
          $size={size}
          key={label}
          $isInvalid={invalid}
          $isFirst={idx === 0}
          $isLast={idx === options.length - 1}
          type="button"
          onClick={handleOnClick(value)}
          $selected={field.value === value}
        >
          {label}
        </StyledToggleButton>
      ))}
    </StyledToggleContainer>
  );
};

export default ToggleInput;
