import React, { useMemo } from 'react';
import { Label, Input, FormGroupProps } from 'reactstrap';
import { FieldProps, FieldAttributes } from 'formik';
import 'react-phone-number-input/style.css';
import { registerPlugin } from 'filepond';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import MaskedInput from 'react-text-mask';
import { useIntl } from 'react-intl';
import { get } from 'lodash/fp';
import { lower } from '@whitelabel/helpers/forms/normalize';
import { IRequirement, ISelectOption } from '@whitelabel/helpers/types';
import { mergeFunctions } from '@whitelabel/helpers/utils';
import PhoneInput from '../PhoneInput';
import PasswordInput from '../PasswordInput';
import SelectInput from '../SelectInput';
import SingleDatePickerInput from '../SingleDatePickerInput';
import CardOptions from '../CardOptions';
import AddressInput from '../AddressInput';
import FieldRequirements from '../FieldRequirements';
import FieldFeedback from '../FieldFeedback';
import FileInput from '../FileInput';
import StyledCustomInput from '../../styled/StyledCustomInput';
import SearchInput from '../SearchInput';
import DOBInput from '../DOBInput';
import messages from './messages';
import { StyledFormText, StyledInput, StyledFormGroup, StyledSingleCheckBox } from './styledFormikFormField';

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

export interface IFormikFormFieldProps extends FieldAttributes<any> {
  field: FieldProps['field'];
  form: FieldProps['form'];
  id: string;
  label?: React.ReactNode;
  type: string;
  options?: ISelectOption[];
  helpText?: React.ReactNode;
  subText?: string;
  requirements?: IRequirement[];
  formGroupProps?: FormGroupProps;
  className?: string;
  spacing?: 'md' | 'lg';
  icon?: React.FunctionComponent<React.PropsWithChildren<React.SVGProps<SVGSVGElement>>>;
}

const FormikFormField = ({
  field,
  form,
  id,
  label,
  type,
  options,
  helpText,
  subText,
  requirements,
  formGroupProps,
  className,
  spacing,
  ...initialProps
}: IFormikFormFieldProps): JSX.Element => {
  const intl = useIntl();
  const { touched, errors, setFieldTouched, setFieldValue } = form;

  const invalid = !!get(field.name, touched) && !!get(field.name, errors);
  const errorId = useMemo(
    () => (invalid ? `error-${(Math.random() + 1).toString(36).substring(7)}` : undefined),
    [invalid],
  );
  const props = {
    'aria-errormessage': errorId,
    ...initialProps,
  } as Record<string, any>;

  const renderInput = () => {
    switch (type) {
      case 'select': {
        const { onChange, icon, ...otherProps } = props; // eslint-disable-line react/prop-types
        return (
          <SelectInput
            input={{
              ...field,
              onChange: (value: any) => {
                if (onChange) onChange(value);
                setFieldValue(field.name, value);
              },
              onBlur: () => setFieldTouched(field.name, true),
            }}
            id={id}
            options={options}
            invalid={invalid}
            icon={icon}
            placeholder={intl.formatMessage(messages.select)}
            {...otherProps}
          />
        );
      }
      case 'file': {
        return <FileInput id={id} name={field.name} subText={subText} form={form} {...props} />;
      }

      case 'boolean':
        return (
          <StyledCustomInput type="checkbox" $rtl={intl.bidi} {...props}>
            <Input {...field} id={id} type="checkbox" label={label} invalid={invalid} {...props} />
            <Label check for={id}>
              {label}
            </Label>
          </StyledCustomInput>
        );

      case 'radio':
        return (
          <div>
            {options &&
              options.map(({ value, label: optionLabel, ...optionProps }: any) => (
                <StyledCustomInput key={`${id}-${value}`} type={type} $rtl={intl.bidi} {...props}>
                  <Input
                    {...field}
                    id={value}
                    type={type}
                    value={value}
                    invalid={invalid}
                    checked={value === get(field.name, form.values)}
                    {...props}
                    {...optionProps}
                  />
                  <Label check for={value}>
                    {optionLabel}
                  </Label>
                </StyledCustomInput>
              ))}
          </div>
        );

      case 'checkbox':
        return (
          <div>
            {options &&
              options.map(({ value, label: optionLabel, ...optionProps }: any) => (
                <StyledCustomInput key={`${id}-${value}`} $rtl={intl.bidi} type={type} {...props}>
                  <Input
                    {...field}
                    id={`${id}-${value}`}
                    type={type}
                    value={value}
                    invalid={invalid}
                    checked={field.value?.includes(value)}
                    onChange={(event: any) => {
                      const set = new Set(get(field.name, form.values));

                      if (event.target.checked) {
                        set.add(value);
                      } else {
                        set.delete(value);
                      }
                      setFieldValue(field.name, Array.from(set));
                    }}
                    {...props}
                    {...optionProps}
                  />
                  <Label check for={`${id}-${value}`}>
                    {optionLabel}
                  </Label>
                </StyledCustomInput>
              ))}
          </div>
        );
      case 'singleCheckbox':
        return (
          <StyledSingleCheckBox invalid={invalid}>
            <StyledCustomInput $rtl={intl.bidi} type={type} {...props}>
              <Input {...field} id={id} type="checkbox" invalid={invalid} {...props} />
              <Label check for={id}>
                {' '}
                {label}
              </Label>
            </StyledCustomInput>
          </StyledSingleCheckBox>
        );
      case 'email':
        if (props.onBlur) {
          props.onBlur = mergeFunctions(field.onBlur, props.onBlur);
        }
        return (
          <StyledInput
            {...field}
            onChange={(event: any) => {
              setFieldValue(field.name, lower(event.target.value));
            }}
            id={id}
            type={type}
            invalid={invalid}
            {...props}
          />
        );

      case 'password':
        return <PasswordInput {...field} id={id} type={type} invalid={invalid} {...props} />;

      case 'date':
      case 'datetime': {
        return <SingleDatePickerInput {...field} id={id} form={form} invalid={invalid} intl={intl} {...props} />;
      }

      case 'dob':
        return <DOBInput {...field} id={id} form={form} invalid={invalid} {...props} />;

      case 'dateMasked':
      case 'datetimeMasked': {
        const mask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-1]/, /[0-9]/, '-', /[0-3]/, /[0-9]/];

        return (
          <StyledInput {...field} tag={MaskedInput} id={id} type="text" invalid={invalid} mask={mask} {...props} />
        );
      }

      case 'card': {
        return <CardOptions options={options} {...field} form={form} {...props} />;
      }

      case 'tel': {
        return <PhoneInput {...field} id={id} type={type} form={form} {...props} />;
      }

      case 'address': {
        return <AddressInput invalid={invalid} label={label} {...field} form={form} {...props} />;
      }
      case 'search': {
        return <SearchInput $rtl={intl.bidi} {...field} id={id} {...props} />;
      }
      default:
        return <StyledInput {...field} id={id} type={type} invalid={invalid} {...props} />;
    }
  };

  return (
    <StyledFormGroup {...formGroupProps} className={`${className} form-group`}>
      {type !== 'boolean' && type !== 'singleCheckbox' && type !== 'address' && label && (
        <Label
          for={!['checkbox', 'radio'].includes(type) ? id : undefined}
          className={invalid ? 'text-danger form-label' : 'form-label'}
        >
          {label}
        </Label>
      )}
      {renderInput()}
      {requirements && <FieldRequirements value={field.value} requirements={requirements} />}
      {helpText && <StyledFormText>{helpText}</StyledFormText>}
      {invalid && <FieldFeedback error={get(field.name, errors) as string | Record<string, unknown>} id={errorId} />}
    </StyledFormGroup>
  );
};

export default FormikFormField;
