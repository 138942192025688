import { css } from 'styled-components';
import { gray, themeColor, darkenColor } from '@whitelabel/helpers/style/colors';
import { borderRadius } from '@whitelabel/helpers/style/box';
import { spacer, multiply } from '@whitelabel/helpers/style/units';
import { getThemeProp, dropdown } from '@whitelabel/helpers/style/get';

const globalDropdownStyle = css`
  .dropdown-menu {
    right: auto;
    left: 0;
    margin: ${multiply(spacer, 1.5)} 0 0;
    text-align: start;
    border: ${getThemeProp('borderWidth')} solid ${gray(300)};
    border-radius: ${borderRadius('base')};
    box-shadow: ${dropdown('boxShadow')};

    &::before,
    &::after {
      position: absolute;
      bottom: 100%;
      left: ${dropdown('itemPaddingX')};
      width: 0;
      height: 0;
      border: solid transparent;
      content: '';
      pointer-events: none;

      .dropup & {
        top: 100%;
        bottom: auto;
      }

      .dropright & {
        top: calc(${spacer} - 2px);
        right: 100%;
        bottom: auto;
        left: auto;
      }

      .dropleft & {
        top: ${spacer};
        bottom: auto;
        left: 100%;
      }
    }

    &::before {
      border-width: 16px 10px;
      border-bottom-color: ${dropdown('borderColor')};

      .dropup & {
        border-top-color: ${dropdown('borderColor')};
        border-bottom-color: transparent;
      }

      .dropright & {
        border-width: 10px 16px;
        border-right-color: ${dropdown('borderColor')};
        border-bottom-color: transparent;
      }

      .dropleft & {
        border-width: 10px 16px;
        border-bottom-color: transparent;
        border-left-color: ${dropdown('borderColor')};
      }
    }

    &::after {
      margin: 0 1px;
      border-width: 14px 9px;
      border-bottom-color: ${dropdown('backgroundColor')};

      .dropup & {
        border-top-color: ${dropdown('backgroundColor')};
        border-bottom-color: transparent;
      }

      .dropright & {
        margin: 1px 0;
        border-width: 9px 14px;
        border-right-color: ${dropdown('backgroundColor')};
        border-bottom-color: transparent;
      }

      .dropleft & {
        margin: 1px 0;
        border-width: 9px 14px;
        border-bottom-color: transparent;
        border-left-color: ${dropdown('backgroundColor')};
      }
    }
  }

  .dropup {
    .dropdown-menu {
      margin-bottom: ${multiply(spacer, 1.5)};
    }
  }

  .dropright {
    .dropdown-menu {
      margin-inline-start: ${multiply(spacer, 1.5)};
    }
  }

  .dropleft {
    .dropdown-menu {
      margin-inline-end: ${multiply(spacer, 1.5)};
    }
  }

  .dropdown-menu-end {
    right: 0;
    left: auto;
    &::before,
    &::after {
      right: ${dropdown('itemPaddingX')};
      left: auto;
    }
  }

  .dropdown-item {
    padding: ${multiply(spacer, 0.25)} ${spacer};
    color: ${gray(700)};
    text-decoration: none;
    cursor: pointer;

    &.disabled,
    &:disabled {
      color: ${gray(500)};
      cursor: auto;
    }

    &.active,
    &:active {
      color: ${gray(700)};
      background-color: ${themeColor('primary')};
    }

    &:hover,
    &:focus {
      color: ${darkenColor(0.05, gray(700))};
      background-color: ${gray(200)};
    }
  }

  .dropdown-header {
    padding: ${multiply(spacer, 0.25)} ${spacer};
    color: ${gray(500)};
  }

  .dropdown-item-text {
    padding: ${multiply(spacer, 0.25)} ${spacer};
    color: ${gray(700)};
  }

  .dropdown-divider {
    border-top: ${getThemeProp('borderWidth')} solid ${gray(300)};
  }
`;

export default globalDropdownStyle;
