import React from 'react';
import { useIntl } from 'react-intl';
import Button from '@whitelabel/component-library/src/components/Button';
import { ButtonType } from '@whitelabel/helpers/types';
import messages from './messages';
import { useZendeskChat } from './context';

interface IZendeskChatButtonProps {
  partnerSlug?: string;
  buttonType?: ButtonType;
  text?: string;
  onClick?: () => void;
}

const ZendeskChatButton = ({ partnerSlug, buttonType = 'secondary', text, onClick }: IZendeskChatButtonProps) => {
  const { formatMessage } = useIntl();
  const { openChat } = useZendeskChat();

  if (!partnerSlug) return null;
  const handleClick = async () => {
    await openChat(partnerSlug);
    onClick?.();
  };

  return (
    <Button type="button" buttonType={buttonType} onClick={handleClick}>
      {text || formatMessage(messages.zendeskChatCTA)}
    </Button>
  );
};

export default ZendeskChatButton;
