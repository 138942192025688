import styled, { css } from 'styled-components';
import { Container, Col } from 'reactstrap';
import { rem } from 'polished';
import { transition } from '@whitelabel/helpers/style/animation';
import { fontStyle, fontFamily } from '@whitelabel/helpers/style/type';
import { gray } from '@whitelabel/helpers/style/colors';
import { boxShadow } from '@whitelabel/helpers/style/box';
import {
  mediaBreakpointUp,
  gridGutterWidth,
  breakpointKeys,
  mediaBreakpointOnly,
} from '@whitelabel/helpers/style/grid';
import { StyledTitle } from '../HeadingBlock/styledHeadingBlock';
import ImagesBlock from '../ImagesBlock';
import { StyledImageBox } from '../ImagesBlock/styledImagesBlock';
import { StyledButton } from '../../Button/styledButton';

export const StyledBgContentContainer = styled(Container)`
  &&.container {
    padding-top: 0;
  }
`;

export const StyledTitleContainer = styled(Container)<{ $condensed?: boolean }>`
  position: relative;
  .mobile-title {
    position: absolute;
    bottom: ${rem(14)};
    width: ${rem(350)};
    max-width: 95vw;
  }

  ${({ $condensed }) =>
    $condensed &&
    css`
      &&& .mobile-title {
        height: 100%;
        position: relative;
        h1 {
          position: absolute;
          bottom: -1rem;
          margin-bottom: 0;
        }
      }
    `}
`;

export const StyledContainer = styled(Container)`
  > :last-child {
    margin-bottom: 0;
  }
`;

export const StyledCol = styled(Col)<{ $justifyContentCenter: boolean }>`
  position: relative;
  ${StyledTitle} {
    margin-right: 0;
  }

  ${({ $justifyContentCenter }) =>
    $justifyContentCenter &&
    mediaBreakpointUp('md')`
      display: flex;
      flex-direction: column;
      justify-content: center;
  `}

  ${mediaBreakpointUp('md')`
    && {
      padding-right: 0;
    }
    ${StyledTitle} {
      margin-bottom: 0;
    }
  `}
    
  ${mediaBreakpointUp('lg')`
    ${StyledTitle} {
      line-height: 4.625rem;
    }
  `}

  > :last-child {
    margin-bottom: 0;
  }
`;

export const StyledWrapper = styled.div<{ $isButtons?: boolean; $isMainContainer?: boolean; $condensed?: boolean }>`
  ${({ $isButtons }) =>
    $isButtons &&
    css`
      ${StyledButton} {
        height: ${rem(48)};
        line-height: ${rem(14)};

        ${mediaBreakpointUp('lg')`
          &:not(:first-child) {
            margin-inline-start: ${rem(24)};
          }
        `}
      }
      ${mediaBreakpointUp('sm')`
        margin-top: 0;
      `}
    `}
  ${({ $isMainContainer }) =>
    $isMainContainer &&
    css`
      ${mediaBreakpointUp('md')`
        min-height: ${rem(658)};
      `}
      ${mediaBreakpointUp('lg')`
        min-height: ${rem(693)};
      `}
      ${mediaBreakpointUp('xl')`
        min-height: ${rem(517)};
      `}
    `};
  ${({ $condensed }) =>
    $condensed &&
    css`
      ${mediaBreakpointUp('md')`
        min-height: ${rem(233)};
      `}
      ${mediaBreakpointUp('lg')`
        min-height: ${rem(368)};
      `}
    `};
`;

export const StyledCallout = styled.div`
  position: absolute;
  right: ${rem(12)};
  bottom: ${rem(85)};
  display: none;
  max-width: ${rem(286)};
  padding: ${rem(24)} ${rem(20)} ${rem(20)};
  background: linear-gradient(104.51deg, #fff 3.07%, #f5f5f5 86.56%);
  border-radius: ${rem(8)};
  box-shadow: ${boxShadow('md')};
  transition: ${transition('base')};

  ${mediaBreakpointUp('md')`
    display: block;
  `}

  ${mediaBreakpointUp('lg')`
    max-width: ${rem(349)};
    bottom: ${rem(70)};
  `}

  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  /* this is an extra extra large media query to nudge callout outside grid */
  @media (min-width: 1350px) {
    right: ${rem(-95)};
  }
`;

export const StyledCalloutListItem = styled.li`
  display: flex;
  margin-bottom: ${rem(4)};
  padding: 0;

  span {
    display: block;
    margin-left: ${rem(11)};
    color: ${gray(600)};
    font-weight: normal;
    font-size: ${rem(14)};
    line-height: ${rem(18)};
  }
`;

export const StyledCalloutHeading = styled.h4`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${rem(4)};
  color: ${gray(700)};
  font-weight: bold;
  font-size: ${rem(18)};
  line-height: ${rem(16)};
`;

export const StyledCalloutLowerText = styled.div`
  display: flex;
  margin-top: ${rem(11)};
  color: ${gray(600)};
  font-size: ${rem(14)};
  font-family: ${fontFamily('base')};
  line-height: ${rem(18)};

  img {
    max-width: ${rem(110)};
    margin-left: ${rem(4)};
  }
`;

export const StyledImagesBlock = styled(ImagesBlock)<{ $hero2021?: boolean; $condensed?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${mediaBreakpointUp('sm')`
    justify-content: center;
  `}

  &&& ${StyledImageBox} {
    display: inline-block;
    max-width: ${rem(302)};
    margin-left: ${rem(30)};
    padding: 0;
    img {
      max-width: 100%;
    }

    ${mediaBreakpointUp('md')`
      display: block;
      width: 100%;
      max-width: none;
      position: absolute;
      margin-left: 0;
      width: ${rem(509)};
      right: ${rem(-164)};
      bottom: ${rem(-32)};
    `}

    ${mediaBreakpointUp('lg')`
      right: ${rem(-132)};
    `}

    ${mediaBreakpointUp('xl')`
      bottom: -${rem(85)};
      right: -${rem(65)};
      width: ${rem(509)};
    `}
  }

  ${({ $condensed }) =>
    $condensed &&
    css`
      ${mediaBreakpointUp('sm')`
        justify-content: center;
      `}

      &&& ${StyledImageBox} {
        width: 100%;
        justify-content: center;
        margin-left: 0;
        max-width: ${rem(325)};
        max-height: ${rem(222)};
        overflow: hidden;

        ${mediaBreakpointUp('md')`
        display: block;
        width: 100%;
        max-height: none;
        max-width: ${rem(360)};
        right: auto;
        bottom: -3.75rem;
      `}

        ${mediaBreakpointUp('lg')`
        max-width: none;
        bottom: 0;
        width: ${rem(453)};
      `}
      }
    `}
`;

export const StyledBGContainer = styled.div<{ $rtl: boolean; $condensed?: boolean }>`
  display: block;
  align-items: center;
  min-height: 11.25rem;
  margin-top: ${rem(16)};
  overflow: hidden;

  ${({ $rtl }) =>
    $rtl &&
    css`
      position: relative;
    `}

  ${mediaBreakpointOnly('xs')`
    .container {
      padding: 1rem;
    }
    h1 {
      margin-bottom: 0;
    }
  `}

  ${mediaBreakpointUp('sm')`
    padding: ${rem(32)} 0;
  `}

  ${mediaBreakpointUp('lg')`
    padding: ${rem(32)} 0;
  `}
  
  ${mediaBreakpointUp('xl')`
    padding: ${rem(80)} 0;
    height: ${rem(675)};
    margin-top: ${rem(64)};   
  `}

  ${({ $condensed }) =>
    $condensed &&
    css`
      ${mediaBreakpointOnly('xs')`
        .container {
          padding: 0 1rem 1rem 1rem;
        }
        h1 {
          margin-bottom: 0;
        }
      `}

      ${mediaBreakpointUp('sm')`
        padding: 0;
      `}
    
      ${mediaBreakpointUp('lg')`
        padding: 0;
        height: ${rem(368)};
      `}

      ${({ theme }) =>
        breakpointKeys(theme).map(
          (breakpoint) =>
            mediaBreakpointUp(breakpoint)`
                margin-top: ${gridGutterWidth(breakpoint)};
              `,
        )}
    `}
`;

export const StyledLeadText = styled.div.attrs(() => ({ className: 'lead' }))`
  &&& {
    margin-bottom: 1rem;

    p {
      color: ${gray(600)};
      ${fontStyle('paragraph')}
      font-family: ${fontFamily('base')};
      margin-bottom: 0;
    }

    b {
      ${fontStyle('h4')}
      color: ${gray(700)};
      display: block;
      margin: 1rem 0 0.5rem 0;
    }

    ${mediaBreakpointUp('md')`
        margin: 1.5rem 0;
        b {
          margin: 1.5rem 0 0.5rem 0;
        }
    `}

    ${mediaBreakpointUp('lg')`
        margin: 2rem 0;
        b {
          margin: 2rem 0 1rem 0;
        }
    `}
  }
`;
