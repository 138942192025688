export const spacer = '1rem';

const gridGutterWidthSm = '16px';
const gridGutterWidthMd = '24px';
const gridGutterWidthLg = '32px';

export const gridBreakpoints = {
  xs: 0,
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
};

export const breakpoints = Object.fromEntries(
  Object.entries(gridBreakpoints).map(([key, value]) => [key, (value && +(value as string).slice(0, -2)) as number]),
);

export const gridGutterWidths = {
  xs: gridGutterWidthSm,
  sm: gridGutterWidthSm,
  md: gridGutterWidthMd,
  lg: gridGutterWidthLg,
  xl: gridGutterWidthLg,
};
