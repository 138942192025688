import styled from 'styled-components';
import { fontStyle, lineHeight } from '@whitelabel/helpers/style/type';
import { gray, themeColor } from '@whitelabel/helpers/style/colors';
import { spacer, multiply } from '@whitelabel/helpers/style/units';

export const StyledSellingPointsList = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
`;

export const StyledSellingPoint = styled.li`
  ${fontStyle('listValue')}
  display: flex;
  align-items: center;
  padding: 0;
  color: ${gray(600)};
  line-height: ${lineHeight('base')};
  list-style: none;
  &:not(:last-child) {
    margin-bottom: ${multiply(spacer, 0.5)};
  }
  svg {
    margin-inline-end: ${multiply(spacer, 0.5)};
    color: ${themeColor('primary')};
  }
`;
