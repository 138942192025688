import { defineMessages } from 'react-intl';

export default defineMessages({
  idle: {
    id: 'filepond.idle',
    defaultMessage: 'Drag and drop your files or',
  },
  browse: {
    id: 'filepond.browse',
    defaultMessage: 'Browse',
  },
  maxFileSizeExceeded: {
    id: 'filepond.maxFileSizeExceeded',
    defaultMessage: 'File is too large',
  },
  maxFileSizeExceededLong: {
    id: 'filepond.maxFileSizeExceededLong',
    defaultMessage: 'Uploaded file size must be less than {maxFileSize}',
  },
  minFileSizeExceeded: {
    id: 'filepond.minFileSizeExceeded',
    defaultMessage: 'File is too small',
  },
  minFileSizeExceededLong: {
    id: 'filepond.minFileSizeExceededLong',
    defaultMessage: 'Uploaded file size must be greater than {minFileSize}',
  },
  fileTypeNotAllowed: {
    id: 'filepond.fileTypeNotAllowed',
    defaultMessage: 'File is not supported',
  },
  fileTypeNotAllowedLong: {
    id: 'filepond.fileTypeNotAllowedLong',
    defaultMessage: 'Uploaded file type is not supported (Please use: {fileTypes})',
  },
  fileProcessing: {
    id: 'filepond.FileProcessing',
    defaultMessage: 'Uploading',
  },
  fileProcessingError: {
    id: 'filepond.fileProcessingError',
    defaultMessage: 'Error during upload',
  },
  fileProcessingComplete: {
    id: 'filepond.fileProcessingComplete',
    defaultMessage: 'Upload complete',
  },
  fileProcessingAborted: {
    id: 'filepond.fileProcessingAborted',
    defaultMessage: 'Upload cancelled',
  },
  tapToCancel: {
    id: 'filepond.tapToCancel',
    defaultMessage: 'tap to cancel',
  },
  tapToRetry: {
    id: 'filepond.tapToRetry',
    defaultMessage: 'tap to retry',
  },
  tapToUndo: {
    id: 'filepond.tapToUndo',
    defaultMessage: 'tap to undo',
  },
  buttonRemoveItem: {
    id: 'filepond.buttonRemoveItem',
    defaultMessage: 'Remove',
  },
  buttonRetryItemProcessing: {
    id: 'filepond.buttonRetryItemProcessing',
    defaultMessage: 'Retry',
  },
  buttonAbortItemProcessing: {
    id: 'filepond.buttonAbortItemProcessing',
    defaultMessage: 'Cancel',
  },
  buttonUndoItemProcessing: {
    id: 'filepond.buttonUndoItemProcessing',
    defaultMessage: 'Undo',
  },
});
