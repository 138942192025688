const copyClasses = (node: Element, nodeTobeCopied: Element) => {
  const classListTobeCopied = nodeTobeCopied.classList;
  const classList = Array.from(node.classList);
  classListTobeCopied.forEach((className) => {
    if (!classList.includes(className)) {
      node.classList.add(className);
    }
  });
};
export const modifyChargeWidget = (): void => {
  const card = document.querySelector('.xpay-contract__card-form-container');
  const submitButton = document.querySelector('.modal-footer .btn:first-child');
  const cancelButton = document.querySelector('.modal-footer .btn:nth-child(2)');
  const payButton = document.querySelector('.xpay-contract__pay-button');
  const footer = document.querySelector<HTMLElement>('.modal-footer');
  const buttonsContainer = document.createElement('div');

  if (footer && card && cancelButton && submitButton && payButton) {
    card.appendChild(buttonsContainer);
    buttonsContainer.appendChild(payButton);
    buttonsContainer.appendChild(cancelButton);
    copyClasses(buttonsContainer, footer);
    copyClasses(payButton, submitButton);
  }
};
