import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { getCurrencySymbolOrName, checkCurrency } from '@whitelabel/helpers/utils';
import currencies from '@whitelabel/helpers/currencies';
import { useClientRect } from '@whitelabel/helpers/hooks';
import { StyledPriceInputBox, StyledPriceInput, StyledCurrencySymbol, StyledCurrencyCode } from './StyledPriceInput';

export interface IPriceInputProps {
  id: string;
  invalid: boolean;
  currency: string;
  [inputAttr: string]: any;
}

const PriceInput = ({ id, invalid, currency, ...props }: IPriceInputProps): JSX.Element => {
  const { locale } = useIntl();
  const currencyCodeRef = useRef<HTMLElement>(null);
  const currencyCodeRect = useClientRect(currencyCodeRef);
  const currencySymbolRef = useRef<HTMLElement>(null);
  const currencySymbolRect = useClientRect(currencySymbolRef);
  const checkedCurrency = checkCurrency(currencies, currency);

  return (
    <StyledPriceInputBox>
      <StyledPriceInput
        id={id}
        invalid={invalid}
        $currencyCodeWidth={currencyCodeRect?.width ?? 0}
        $currencySymbolWidth={currencySymbolRect?.width ?? 0}
        step="0.01"
        {...props}
        type="number"
      />
      <StyledCurrencySymbol ref={currencySymbolRef}>
        {getCurrencySymbolOrName(locale, checkedCurrency)}
      </StyledCurrencySymbol>
      <StyledCurrencyCode ref={currencyCodeRef}>{checkedCurrency}</StyledCurrencyCode>
    </StyledPriceInputBox>
  );
};

export default PriceInput;
