import { IntlShape } from 'react-intl';
import supportedLanguages from './supportedLanguages';

export const localizePathname = (pathname: any, locale: any): string => {
  const pathnameArray = pathname.split('/');
  if (Object.keys(supportedLanguages).includes(pathnameArray[1])) {
    pathnameArray[1] = locale;
  } else {
    pathnameArray.splice(1, 0, locale);
  }
  return pathnameArray.join('/');
};

export const localizeLocation = (location: any, locale: any) => {
  const { pathname, state } = location;

  if (state && state.referrer) {
    state.referrer.pathname = localizePathname(state.referrer.pathname, locale);
  }

  return {
    ...location,
    pathname: localizePathname(pathname, locale),
    state,
  };
};

// TODO: put params into an option object and refactor usages
export const formatCurrency = (
  formatNumber: IntlShape['formatNumber'],
  value: unknown,
  currency: string,
  trimCents = false,
): string => {
  let newValue;
  if (typeof value === 'number') {
    newValue = value;
  } else if (typeof value === 'string') {
    newValue = Number(value);
  } else {
    // To maintain legacy usage (?)
    // casted to avoid TS issues while using the function
    return value as string;
  }

  const formattedValue = formatNumber(newValue, { style: 'currency', currency });
  if (trimCents && formattedValue.endsWith('.00')) {
    return formattedValue.substring(0, formattedValue.indexOf('.00'));
  }
  return formattedValue;
};
