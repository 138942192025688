import React, { AriaAttributes, HTMLAttributes } from 'react';
import { useIntl } from 'react-intl';
import { components, MenuProps, OptionProps, ValueContainerProps, InputProps } from 'react-select';
import { ReactComponent as IconCross } from '@whitelabel/media/icons/small/cross.svg';
import { ReactComponent as IconSearch } from '@whitelabel/media/icons/small/search.svg';
import { ISelectOption, ISelectInput } from '@whitelabel/helpers/types';
import { StyledCustomIcon, StyledRelativeWrapper, StyledSelect } from '../../styled/StyledSelect';
import { StyledSelectLoadingIcon } from './styledSelectInput';

// onChange from Redux Form Field has to be called explicity.
const singleChangeHandler = (func: any) => (value: any) => func(value ? value.value : '');

// onBlur from Redux Form Field has to be called explicity.
const multiChangeHandler = (func: any) => (values: any) => func(values.map((value: any) => value.value));

/**
 * For single select, Redux Form keeps the value as a string, while React Select
 * wants the value in the form { value: "AU", label: "Australia" }
 *
 * For multi select, Redux Form keeps the value as array of strings, while React Select
 * wants the array of values in the form [{ value: "AU", label: "Australia" }]
 */
const transformValue = (value: any, options: any, isMulti: any, ignoreTransformValue?: boolean) => {
  if (ignoreTransformValue) {
    // If custom components are used, no need to transform value
    return undefined;
  }
  if (isMulti && typeof value === 'string') {
    return [];
  }

  const filteredOptions = options.filter((option: any) =>
    isMulti ? value.indexOf(option.value) !== -1 : option.value === value,
  );
  return isMulti ? filteredOptions : filteredOptions[0] ?? '';
};

// TODO: Update ISelectInputProps
export interface ISelectInputProps extends Omit<HTMLAttributes<HTMLElement>, 'onChange'> {
  input: ISelectInput;
  // deprecated
  // todo: it is better to remove id here and use inputId only
  id?: string;
  inputId?: string;
  options?: ISelectOption[];
  isMulti?: boolean;
  invalid?: boolean;
  placeholder?: string;
  disabled?: boolean;
  reverseSelectionArrow?: boolean;
  isSearchable?: boolean;
  customComponents?: {
    Menu?: (props: MenuProps<Record<string, unknown>, false>) => JSX.Element;
    Option?: (props: OptionProps<Record<string, unknown>, false, any>) => JSX.Element;
    ValueContainer?: ({ children }: ValueContainerProps<ISelectOption, false>) => JSX.Element;
  };
  customSelectProps?: {
    [key: string]: unknown;
  };
  customOptionLabel?: (options: { value: string; label: string }) => string;
  useSingleValue?: boolean;
  instanceId?: string; // required by Nextjs
  icon?: React.FunctionComponent<React.PropsWithChildren<React.SVGProps<SVGSVGElement>>>;
  'aria-errormessage'?: string;
  ignoreTransformValue?: boolean;
}

const SelectInput = ({
  input,
  id,
  inputId,
  options,
  isMulti = false,
  disabled = false,
  reverseSelectionArrow = false,
  isSearchable = true,
  customComponents,
  customSelectProps,
  customOptionLabel,
  useSingleValue = true,
  instanceId,
  icon,
  ignoreTransformValue = false,
  ...props
}: ISelectInputProps): JSX.Element => {
  const intl = useIntl();
  // unset divID if we have inputId
  const divID = inputId ? id : undefined;
  const handleChange = isMulti ? multiChangeHandler(input.onChange) : singleChangeHandler(input.onChange);
  const MultiValueRemove = (parentProps: any) => (
    <components.MultiValueRemove {...parentProps}>
      <IconCross />
    </components.MultiValueRemove>
  );
  const DropdownIndicator = () => <IconSearch />;

  const LoadingIndicator = () => <StyledSelectLoadingIcon />;

  const SingleValue = (selectProps: any) => {
    const {
      data: { selectLabel, label },
    } = selectProps;
    return <components.SingleValue {...selectProps}>{selectLabel || label}</components.SingleValue>;
  };

  const handleBlur = () => input.onBlur && input.onBlur(input.value);

  const Input = (inputProps: InputProps) => {
    const ariaProps: AriaAttributes = {};
    // eslint-disable-next-line react/prop-types
    if (props['aria-describedby']) {
      ariaProps['aria-describedby'] = props['aria-describedby']; // eslint-disable-line react/prop-types
    }
    if (props.invalid) {
      ariaProps['aria-invalid'] = 'true';
      ariaProps['aria-errormessage'] = props['aria-errormessage'];
    }

    return <components.Input {...inputProps} {...ariaProps} />;
  };

  const getComponentsProp = () =>
    isMulti
      ? {
          ...customComponents,
          DropdownIndicator,
          MultiValueRemove,
          Input,
        }
      : {
          ...customComponents,
          LoadingIndicator,
          ...(useSingleValue && { SingleValue }),
          Input,
        };

  const Icon = icon && !isMulti ? StyledCustomIcon(icon) : null;

  const renderSelect = (
    <StyledSelect
      reverseSelectionArrow={reverseSelectionArrow}
      {...input}
      $rtl={intl.bidi}
      $hasIcon={!!icon && !isMulti}
      isSearchable={isSearchable}
      id={divID}
      inputId={inputId}
      valueKey="value"
      value={transformValue(input.value, options, isMulti, ignoreTransformValue)}
      isMulti={isMulti}
      options={options}
      onChange={handleChange}
      onBlur={handleBlur}
      classNamePrefix="react-select"
      components={getComponentsProp()}
      getOptionLabel={customOptionLabel}
      isDisabled={disabled}
      instanceId={instanceId}
      styles={{
        multiValueRemove: (provided: any, state: Record<string, any>) => ({
          ...provided,
          backgroundColor: state.isFocused ? '#inherit' : 'inherit',
          svg: {
            // ${gray(700)} This value is not picked up by inline styles, hence using hex value
            color: state.isFocused ? '#2D2D2D !important' : 'inherit',
          },
        }),
      }}
      {...customSelectProps}
      {...props}
    />
  );

  if (Icon) {
    return (
      <StyledRelativeWrapper>
        <Icon className="react-select__primary-icon" />
        {renderSelect}
      </StyledRelativeWrapper>
    );
  }
  return <>{renderSelect}</>;
};

export default SelectInput;
