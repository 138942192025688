import styled, { css } from 'styled-components';
import { Input } from 'reactstrap';
import { get } from '@whitelabel/helpers/style/get';
import { inputColors } from '@whitelabel/global-theme/colors';
import { themeColor } from '@whitelabel/helpers/style/colors';
import { fontWeight } from '@whitelabel/helpers/style/type';
import StyledCustomInput from '../../styled/StyledCustomInput';

export const StyledInput = styled(Input)`
  ${({ type }) =>
    type === 'textarea' &&
    css`
      && {
        height: 9.875rem;
      }
    `}
  && {
    padding-right: 0.9375rem;
  }

  border: ${get('borderWidth')} solid ${inputColors.border.default};
  background-color: ${inputColors.background.default};
  color: ${inputColors.value.default};

  &:hover:not(.is-invalid) {
    border: ${get('borderWidth')} solid ${inputColors.border.hover};
    cursor: pointer;
    background-color: ${inputColors.background.hover};
    color: ${inputColors.value.hover};
  }

  &:focus {
    border: ${get('borderWidth')} solid ${inputColors.border.focus};
    cursor: pointer;
    background-color: ${inputColors.background.focus};
    color: ${inputColors.value.focus};
  }

  &:active {
    border: ${get('borderWidth')} solid ${inputColors.border.active};
    background-color: ${inputColors.background.active};
    color: ${inputColors.value.active};
  }

  &:disabled {
    border: ${get('borderWidth')} solid ${inputColors.border.disabled};
    background-color: ${inputColors.background.disabled};
    color: ${inputColors.value.disabled};
  }
`;

export const StyledCheckboxInputWrapper = styled.div`
  ${StyledCustomInput} {
    padding: 0;
  }

  .form-check-label {
    color: ${themeColor('anchor')} !important;
    font-size: 0.875rem;
    font-weight: ${fontWeight('base')};
    padding: 0;
    padding-inline: 1.75rem;
  }

  .form-check-label::before,
  .form-check-label::after {
    left: 0;
    transform: none;
    top: 0;
  }
`;
