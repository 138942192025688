import { IAddressInputFields } from '@whitelabel/helpers/types';

export const manualAddressInitialValues = {
  address1: '',
  address2: '',
  country: '',
  region: '',
  city: '',
  postcode: '',
};

export const manualToGeoSuggestValue = (addressObj?: IAddressInputFields) => {
  if (!addressObj?.address1) {
    return undefined;
  }
  return `${addressObj.address1}, ${addressObj.address2 ? `${addressObj.address2}, ` : ''}${
    addressObj.city ? `${addressObj.city}, ` : ''
  }${addressObj.region ? `${addressObj.region}, ` : ''}${addressObj.postcode ? `${addressObj.postcode}, ` : ''}${
    addressObj.country ?? ''
  }`;
};
