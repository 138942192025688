import { css } from 'styled-components';
import { color } from '@whitelabel/helpers/style/colors';
import { getThemeProp } from '@whitelabel/helpers/style/get';

export const rentalcoverBorderStyle = css`
  body.rentalcover-theme & {
    border: ${getThemeProp('borderWidth')} solid ${color('paleGrayBlue')};
  }
`;

export const rentalcoverHeadingDarkStyle = css`
  body.rentalcover-theme & {
    color: ${color('prussianBlue')};
  }
`;

export const rentalcoverTextLightStyle = css`
  body.rentalcover-theme & {
    color: ${color('oliveDrab')};
  }
`;
