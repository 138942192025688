import React from 'react';
import { useIntl } from 'react-intl';
import { ICustomer } from '@whitelabel/helpers/types';
import { INavItem } from '../../../helpers/types';

import messages from '../messages';
import { filterGeneratedEmail } from '../../../helpers/utils';
import {
  StyledDropdown,
  StyledDropdownToggle,
  StyledDropdownCustomer,
  StyledDropdownName,
  StyledDropdownSubText,
  StyledDropdownMenu,
  StyledDropdownItem,
  StyledDropdownItemDivider,
  StyledNavIcon,
} from './styledGlobalCustomerDropdown';

interface IGlobalCustomerDropdownProps {
  customer: ICustomer;
  customerItems: INavItem[];
  item: INavItem;
  className?: string;
}

const GlobalCustomerDropdown = ({
  customerItems,
  customer: { firstName, lastName, email, phone },
  item: { icon, messageKey: itemMessageKey },
  className,
}: IGlobalCustomerDropdownProps): JSX.Element => {
  const intl = useIntl();
  const name = [firstName !== 'N/A' ? firstName : '', lastName !== 'N/A' ? lastName : '']
    .filter(Boolean)
    .join(' ')
    .trim();
  const displayName = firstName !== 'N/A' && firstName;
  return (
    <StyledDropdown nav inNavbar data-test-id="GlobalCustomerDropdown" className={className}>
      <StyledDropdownToggle nav data-test-id="GlobalCustomerDropdown-toggle">
        <StyledNavIcon as={icon} />
        {displayName ||
          (itemMessageKey ? intl.formatMessage(messages[itemMessageKey as keyof typeof messages]) : name)}
      </StyledDropdownToggle>

      <StyledDropdownMenu $rtl={intl.bidi} end={!intl.bidi} data-test-id="GlobalCustomerDropdown-menu">
        <StyledDropdownCustomer data-test-id="GlobalCustomerDropdown-customer">
          {name && <StyledDropdownName data-test-id="GlobalCustomerDropdown-customerName">{name}</StyledDropdownName>}
          <StyledDropdownSubText data-test-id="GlobalCustomerDropdown-customerSubText">
            {filterGeneratedEmail(email) || phone}
          </StyledDropdownSubText>
        </StyledDropdownCustomer>
        <StyledDropdownItemDivider />
        {customerItems.map(({ key, messageKey, path }) => (
          <StyledDropdownItem
            key={key}
            to={`/${intl.locale}${path}`}
            data-test-id="GlobalCustomerDropdown-item"
            data-analytics={`navbarDropdown${messageKey}`}
          >
            {intl.formatMessage(messages[messageKey as keyof typeof messages])}
          </StyledDropdownItem>
        ))}
      </StyledDropdownMenu>
    </StyledDropdown>
  );
};

GlobalCustomerDropdown.defaultProps = {
  customer: null,
  customerItems: [],
};

export default GlobalCustomerDropdown;
