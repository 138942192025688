import React from 'react';
import { useIntl } from 'react-intl';
import { StyledLoading, StyledIcon } from './styledLoading';
import messages from './messages';

interface ILoadingProps {
  className?: string;
  shouldBeFullPageHeight?: boolean;
}

const Loading = ({ className, shouldBeFullPageHeight = false }: ILoadingProps): JSX.Element => {
  const { messages: intlMessages, formatMessage } = useIntl();
  const hasLoadingMessage = intlMessages?.['loading.loadingMessage'];

  return (
    <StyledLoading $shouldBeFullPageHeight={shouldBeFullPageHeight} className={className}>
      <StyledIcon title={hasLoadingMessage ? `${formatMessage(messages.loadingMessage)}&hellip;` : 'Loading…'} />
    </StyledLoading>
  );
};

export default Loading;
