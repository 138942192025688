import React, { useState, useEffect, useRef } from 'react';
import 'filepond/dist/filepond.min.css';
import { get, set } from 'lodash/fp';
import { FilePondCallbackProps, ProcessServerConfigFunction } from 'filepond';
import { FieldProps } from 'formik';
import { FilePondProps } from 'react-filepond';
import { useIntl } from 'react-intl';
import { getAccessToken } from '@whitelabel/helpers/api';
import commonMessages from '@whitelabel/helpers/messages/commonMsg';
import formMessages from '@whitelabel/helpers/forms/messages';
import { getCookie, captureExceptionWithFullStory } from '@whitelabel/helpers/utils';
import { IMPERSONATOR_TOKEN, ZENDESK_FILE_LIMIT } from '@whitelabel/helpers/constants';
import { IError, IZendeskUploadResponse } from '@whitelabel/helpers/types';
import FieldFeedback from '../FieldFeedback';
import transUploadFileSizeToBytes from './helper';
import { StyledFilePond } from './styledFileInput';
import messages from './messages';

export interface IFileInputProps extends FilePondProps {
  form: FieldProps['form'];
  id: string;
  name: string;
  url?: string;
  uploadType?: 'logo';
  apiFieldName?: string;
  allowedFileTypes?: string;
  subText?: string;
  auth?: boolean | string;
  localStorageKey?: string;
  maxFileSize?: string;
  minFileSize?: string;
  isZendesk?: boolean;
  customLabelIdle?: string;
}

const FileInput = ({
  form: { setFieldTouched, setFieldValue, setFieldError, validateForm, values, setValues },
  id,
  name,
  url = '',
  uploadType,
  auth,
  apiFieldName = '',
  subText,
  allowedFileTypes = '',
  localStorageKey,
  maxFileSize = '10MB',
  minFileSize = '24Bytes',
  isZendesk = false,
  ...props
}: IFileInputProps): JSX.Element => {
  const intl = useIntl();
  const uploadSvg = `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32.6 32.5134H37.4C37.4 32.5134 46 31.4251 46 23.016C46 17.6738 41.6 13.3209 36.1
    13.6176C33.9 9.06685 29.3 6 24 6C16.9 6 11.1 11.5401 10.6 18.4652C6.1 17.5749 2 20.9385 2 25.3904C2 32.6123
    9.7 32.5134 9.7 32.5134H15.4" stroke="#ACB5BF" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M24 21.2354V43.0001" stroke="#FFD200" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"
    stroke-linejoin="round"/>
    <path d="M30 27.1712L24 21.2354L18 27.1712" stroke="#FFD200" stroke-width="2" stroke-miterlimit="10"
    stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`;

  const STORED_FILES = `${localStorageKey}_files`;
  const currentFileIDs = get(name, values);
  const impersonatorToken = sessionStorage.getItem(IMPERSONATOR_TOKEN);
  const getStoredFiles = () => JSON.parse(localStorage.getItem(STORED_FILES) as string) || [];
  const [filesTryToUpload, setFilesTryToUpload] = useState<null | number>(null);
  const [accessToken, setAccessToken] = useState();
  const [files, setFiles] = useState(getStoredFiles());
  const filesNumber = useRef<null | number>(null);
  const [initialFileIDs] = useState(() => {
    if (currentFileIDs || files.length) {
      const storedFileIDs: any = [];
      files.forEach(({ source }: any) => storedFileIDs.push(source));
      const idSet = new Set([...(currentFileIDs ?? []), ...storedFileIDs]);
      return Array.from(idSet);
    }
    return null;
  });

  function getFileFromStoredFiles(searchedFile: any) {
    const storedFiles = getStoredFiles();

    return storedFiles.find(
      ({ options: { file: storedFile } }: any) =>
        storedFile.name === searchedFile.name &&
        storedFile.type === searchedFile.type &&
        storedFile.size === searchedFile.size,
    );
  }
  const getFileFromFiles = (searchedFile: any) =>
    files.find((fileItem: any) => {
      const file = fileItem.options ? fileItem.options.file : fileItem;
      return file.name === searchedFile.name && file.type === searchedFile.type && file.size === searchedFile.size;
    });

  useEffect(() => {
    if (initialFileIDs?.length) {
      setFieldValue(name, initialFileIDs, false);
      setFieldTouched(name, true, false);
    }
  }, [initialFileIDs, name, setFieldValue, setFieldTouched]);

  const handleOnError = (error: any) => {
    setFieldTouched(name, true, false);
    if (error.main === intl.formatMessage(messages.maxFileSizeExceeded)) {
      setFieldError(name, intl.formatMessage(messages.maxFileSizeExceededLong, { maxFileSize }));
    } else if (error.main === intl.formatMessage(messages.minFileSizeExceeded)) {
      setFieldError(name, intl.formatMessage(messages.minFileSizeExceededLong, { minFileSize }));
    } else if (error.main === intl.formatMessage(messages.fileTypeNotAllowed)) {
      setFieldError(
        name,
        intl.formatMessage(messages.fileTypeNotAllowedLong, {
          fileTypes: allowedFileTypes,
        }),
      );
    } else if (error.code === 422) {
      setFieldError(name, intl.formatMessage(commonMessages.error));
    } else {
      setFieldError(name, ' ');
    }
  };

  function removeFromFiles(removedFile: any) {
    const newFiles = files.filter((fileItem: any) => {
      const file = fileItem.options ? fileItem.options.file : fileItem;
      return `${file.name}${file.type}${file.size}` !== `${removedFile.name}${removedFile.type}${removedFile.size}`;
    });
    setFiles(newFiles);
  }

  function removeFileIDFromFieldValue(removedFileID: any) {
    const newFileIDs = currentFileIDs.filter((fileID: any) => fileID !== removedFileID);
    setFieldValue(name, newFileIDs, false);
  }

  const handleOnRemoveFile: FilePondCallbackProps['onremovefile'] = (error, { file: removedFile }) => {
    if (localStorageKey) {
      const storedFiles = getStoredFiles();

      const fileIndex = storedFiles.findIndex(
        ({ options: { file } }: any) =>
          file.name === removedFile.name && file.type === removedFile.type && file.size === removedFile.size,
      );
      if (fileIndex > -1) {
        removeFromFiles(removedFile);
        const removedFileID = getFileFromStoredFiles(removedFile)?.source;

        if (initialFileIDs?.includes(removedFileID) && currentFileIDs.includes(removedFileID)) {
          removeFileIDFromFieldValue(removedFileID);
        }
        storedFiles.splice(fileIndex, 1);
        localStorage.setItem(STORED_FILES, JSON.stringify(storedFiles));
      } else if (getFileFromFiles(removedFile)) {
        removeFromFiles(removedFile);
      }
    }
    validateForm();
  };

  function storeFile(file: any, fileID: string) {
    const storedFiles = getStoredFiles();

    const newFile = {
      source: fileID,
      options: {
        type: 'local',
        file: {
          name: file.name,
          size: file.size,
          type: file.type,
        },
      },
    };

    storedFiles.push(newFile);
    localStorage.setItem(STORED_FILES, JSON.stringify(storedFiles));
  }

  const processFile: ProcessServerConfigFunction = (fieldName, file, metadata, load, error, progress, abort) => {
    const request = new XMLHttpRequest();
    if (isZendesk) {
      if (filesNumber.current && filesNumber.current > ZENDESK_FILE_LIMIT) {
        // call the error to exit after
        error('');
        return;
      }
      request.open('POST', `${url}/?filename=${file.name}`, true);
      request.setRequestHeader('Content-Type', 'application/binary');
      request.send(file);
    } else {
      request.open('POST', url);
      if (impersonatorToken || accessToken) {
        request.setRequestHeader(
          'Authorization',
          impersonatorToken ? `Impersonator ${impersonatorToken}` : `Bearer ${accessToken}`,
        );
      }

      const xsrfToken = getCookie('XSRF-TOKEN');
      if (xsrfToken) {
        request.withCredentials = true;
        request.setRequestHeader('X-XSRF-TOKEN', xsrfToken);
      }

      const formData = new FormData();
      formData.append(apiFieldName, file, file.name);
      if (uploadType) {
        formData.append('type', uploadType);
      }
      request.send(formData);
    }

    // Should call the progress method to update the progress to 100% before calling load
    // Setting computable to false switches the loading indicator to infinite mode
    request.upload.onprogress = (e) => {
      progress(e.lengthComputable, e.loaded, e.total);
    };

    // Should call the load method when done and pass the returned server file id
    // this server file id is then used later on when reverting or restoring a file
    // so your server knows which file to return without exposing that info to the client
    request.onload = () => {
      if (request.status >= 200 && request.status < 300) {
        // the load method accepts either a string (id) or an object
        let fileID: string;
        if (isZendesk) {
          const res = JSON.parse(request.response) as IZendeskUploadResponse;
          fileID = res.upload.token;
        } else {
          fileID = request.responseText;
        }
        setValues((prevValues: any) => {
          const prevFileIDs = get(name, prevValues);
          const newValues = set(name, [...prevFileIDs, fileID], prevValues);
          return newValues;
        });

        setFieldTouched(name, true);

        if (localStorageKey) {
          storeFile(file, fileID);
        }

        load(fileID);
      } else {
        const errorMsg = `Fail uploading file: ${file.name}, size: ${file.size}. Status code: ${request.status}`;
        captureExceptionWithFullStory(new Error(errorMsg));
        // Can call the error method if something is wrong, should exit after
        error(errorMsg);
      }
    };

    // Should expose an abort method so the request can be cancelled
    // eslint-disable-next-line consistent-return
    return {
      abort: () => {
        // This function is entered if the user has tapped the cancel button
        request.abort();

        // Let FilePond know the request has been cancelled
        abort();
      },
    };
  };

  const server = {
    process: processFile,
    revert: (fileID: any, load: any) => {
      removeFileIDFromFieldValue(fileID);
      load();
    },
    restore: null,
    load: null,
    fetch: null,
  };
  const labelIdle = `${uploadSvg}${
    props.customLabelIdle ?? intl.formatMessage(messages.idle)
  } <span class="filepond--label-action"> ${intl.formatMessage(messages.browse)}</span><br>`;

  const labelIdleSubMessage = `${labelIdle}<span class="filepost--sub-label">${subText}</span>`;

  const onupdatefiles = (fileArray: Array<any>) => {
    if (isZendesk) {
      setFilesTryToUpload(fileArray.length);
      filesNumber.current = fileArray.length;
    }
  };

  const beforeAddFile = async () => {
    try {
      if (!impersonatorToken) {
        const token = await getAccessToken(auth);
        setAccessToken(token);
      }
    } catch (error) {
      captureExceptionWithFullStory(error as IError);
      window.location.reload();
    }
    return true;
  };

  const onAddFile: FilePondCallbackProps['onaddfile'] = (error, { file: addedFile }) => {
    if (localStorageKey && !getFileFromStoredFiles(addedFile) && !getFileFromFiles(addedFile)) {
      if (error) {
        setFiles((prev: []) => [...prev, addedFile]);
      } else {
        setFiles((prev: []) => [
          ...prev.filter(
            (fileItem: { size: number }) =>
              fileItem.size > transUploadFileSizeToBytes(minFileSize) &&
              fileItem.size < transUploadFileSizeToBytes(maxFileSize),
          ),
          addedFile,
        ]);
      }
    }
  };

  return (
    <>
      <StyledFilePond
        id={id}
        labelIdle={subText ? labelIdleSubMessage : labelIdle}
        // @ts-ignore the packages doesn't have this type declaration
        labelMaxFileSizeExceeded={intl.formatMessage(messages.maxFileSizeExceeded)}
        labelMinFileSizeExceeded={intl.formatMessage(messages.minFileSizeExceeded)}
        labelFileTypeNotAllowed={intl.formatMessage(messages.fileTypeNotAllowed)}
        fileValidateTypeLabelExpectedTypes=""
        labelMaxFileSize=""
        labelMinFileSize=""
        labelFileProcessing={intl.formatMessage(messages.fileProcessing)}
        labelFileProcessingError={intl.formatMessage(messages.fileProcessingError)}
        labelFileProcessingComplete={intl.formatMessage(messages.fileProcessingComplete)}
        labelFileProcessingAborted={intl.formatMessage(messages.fileProcessingAborted)}
        labelTapToCancel={intl.formatMessage(messages.tapToCancel)}
        labelTapToRetry={intl.formatMessage(messages.tapToRetry)}
        labelTapToUndo={intl.formatMessage(messages.tapToUndo)}
        labelButtonRemoveItem={intl.formatMessage(messages.buttonRemoveItem)}
        labelButtonRetryItemProcessing={intl.formatMessage(messages.buttonRetryItemProcessing)}
        labelButtonAbortItemProcessing={intl.formatMessage(messages.buttonAbortItemProcessing)}
        labelButtonUndoItemProcessing={intl.formatMessage(messages.buttonUndoItemProcessing)}
        server={server}
        onerror={handleOnError}
        onremovefile={handleOnRemoveFile}
        allowMultiple
        maxFileSize={maxFileSize}
        minFileSize={minFileSize}
        name={name}
        beforeAddFile={beforeAddFile}
        onaddfile={onAddFile}
        onupdatefiles={onupdatefiles}
        {...props}
        {...(localStorageKey && { files })}
      />
      {isZendesk && filesTryToUpload && filesTryToUpload > ZENDESK_FILE_LIMIT ? (
        <FieldFeedback error={intl.formatMessage(formMessages.maxFiles)} />
      ) : null}
    </>
  );
};

FileInput.defaultProps = {
  auth: false,
  subText: null,
  localStorageKey: null,
};

export default FileInput;
